// constants
const UBEREATS_CLIENT_ID = process.env.REACT_APP_UBEREATS_CLIENT_ID;
const DOORDASH_CLIENT_ID = process.env.REACT_APP_DOORDASH_CLIENT_ID;

export const getAuthorizationRedirectUrl = (platform, redirectUri) => {
	if (platform === "ubereats") {
		return `https://login.uber.com/oauth/v2/authorize?client_id=${UBEREATS_CLIENT_ID}&response_type=code&redirect_uri=${redirectUri}`;
	}

	if (platform === "doordash") {
		return `https://identity.doordash.com/auth?response_type=code&redirect_uri=${redirectUri}&scope=*&state=test&client_id=${DOORDASH_CLIENT_ID}&prompt=none&layout=default`;
	}

	return "";
};

export const getAuthorizationStatus = (query, platform) => {
	if (platform === "ubereats") {
		// failed state: "?error=access_denied"
		if (query?.get("error") === "access_denied") {
			return {
				status: "failed",
				authCode: "",
				message: "Access Denied"
			};
		}
		// success state: "?code=[auth code]"
		if (query?.get("code")) {
			return {
				status: "success",
				authCode: `${query?.get("code")}#_`,
				message: ""
			};
		}
		return {};
	}

	if (platform === "doordash") {
		// success state: "?state=test&code=[auth code]>&action=Login&method=[email]"
		if (query?.get("code")) {
			return {
				status: "success",
				authCode: query?.get("code"),
				message: ""
			};
		}
		return {};
	}

	return {};
};
