import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";

// components
import Header from "./Header";
import Breadcrumbs from "../../_commons/Breadcrumbs";
import SectionHeader from "./SectionHeader";
import Filters from "./Filters";
import { SelectFilter } from "../../_commons/SelectFilter";
import { SearchFilter } from "../../_commons/SearchFilter";
import TableColumnSelector from "../../_commons/TableColumnSelector";
import { CustomTable } from "../../_commons/CustomTable";
import { Paginator } from "../../_commons/Paginator";
import { NestedEntityContainer } from "../../_commons/NestedEntityContainer";

// third party
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { debounce } from "lodash";
import history from "../../../history";

// store
import { store as str } from "../../../store/configureStore";

// utils
import {
	scroll,
	getSortOrder,
	breadcrumbs,
	capitaliseTextStrict,
	capitaliseText,
	adjustNestedContainer
} from "../../../atlas-utils";

// actions
import { ActionTypes } from "../../../actions/_types";
import { analyticsMetrics, updateAnalyticsState } from "../../../actions/analytics";

// constants
import { NESTED_ENTITY_TYPES } from "../../../client-config";
const METRIC_ENTITY_ID_MAP = {
	revenue_by_item: "item",
	orders_by_item: "item",
	item_performance: "item"
};
const METRIC_SORT_MAP = {
	popular_add_ons: "OPTION_COMPLETED_ORDERS",
	location_performance: "ITEM_ORDER_COMPLETED_ORDERS"
};
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const EntityList = ({ match, store, currencySymbol }) => {
	const { search } = useLocation();
	const query = useMemo(() => new URLSearchParams(search), [search]);
	const [section, setSection] = useState(`${match.url.split("/")[2]}`);
	const [reducer, setReducer] = useState(section === "detail" ? "analyticsEntityDetail" : `${section}Analytics`);
	const [metric, setMetric] = useState(match.params.query.replaceAll("-", "_"));
	const [bcLinks, setBcLinks] = useState(query.get("bc") ? breadcrumbs.decode(query.get("bc")) : []);
	const [isPageScrolled, setPageScrolled] = useState(false);
	const [currFilters, setCurrFilters] = useState({});
	const [applFilters, setApplFilters] = useState({});
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();
	const tableRef = useRef();
	const { appliedFilters, appliedDateFilter } = store.analyticsFiltersState;
	const {
		loading,
		limit,
		offset,
		sort,
		sortedField,
		tabularData,
		tableColumnsSelected,
		filters,
		searchKeywords,
		searchFieldSelected,
		searchFieldValue
	} = reducer && metric ? store?.[reducer]?.[metric] || {} : {};

	const handlePageScroll = () => {
		if (document.documentElement.scrollTop > 60) {
			setPageScrolled(true);
		} else {
			setPageScrolled(false);
		}
	};

	useEffect(() => {
		// set metric init data for entity detail view
		if (section === "detail") {
			str.dispatch({
				type: ActionTypes.UPDATE_ANALYTICS_ENTITY_DETAIL_INIT_STATE,
				payload: {
					[metric]: {
						loading: true,
						limit: 10,
						offset: 0,
						sort: {
							field: METRIC_SORT_MAP[metric] || "",
							order: "DESC"
						},
						sortedField: METRIC_SORT_MAP[metric] || "",
						tabularData: {
							fields: [],
							columns: [],
							rows: [],
							count: 0,
							isRowClickable: false
						},
						tableColumnsSelected: {
							columns: {}
						},
						filters: [],
						searchKeywords: [],
						searchFieldSelected: { key: "default", valueForDisplay: "Default" },
						searchFieldValue: ""
					}
				}
			});
		}

		document.documentElement.scrollTop = 0;
		// window.addEventListener('scroll', handlePageScroll);
		return () => {
			// window.removeEventListener('scroll', handlePageScroll);

			// reset metric state
			updateAnalyticsState[section](metric, {
				loading: true,
				offset: 0,
				tabularData: {
					...tabularData,
					rows: [],
					count: 0
				},
				tableColumnsSelected: {
					columns: {
						...(tabularData?.fields ?? []).reduce(
							(obj, col) => ({
								...obj,
								[col.key]: col.isDefault
							}),
							{}
						)
					}
				},
				searchFieldValue: ""
			});
		};
	}, []);

	useEffect(() => {
		if (analyticsMetrics[match.params.query]) {
			analyticsMetrics[match.params.query](metric, applFilters, match.params.id);
		}
	}, [
		Object.keys(appliedFilters).reduce((acc, f) => acc + appliedFilters[f]?.join(""), ""),
		...Object.keys(appliedDateFilter).reduce((arr, f) => [...arr, appliedDateFilter[f].dateFilter], []),
		match.params.query,
		limit,
		offset,
		sort,
		applFilters
	]);

	const applyFilters = useCallback(
		debounce((filters) => {
			setApplFilters(filters);
		}, 100),
		[]
	);

	const handleFilters = (field, value) => {
		const entityFilters = {
			...currFilters,
			[field]: value
		};
		setCurrFilters(entityFilters);
		applyFilters(entityFilters);
	};

	const applySearchFilter = useCallback(
		debounce(async () => {
			await analyticsMetrics[match.params.query](metric, applFilters, match.params.id);
		}, 500),
		[metric, applFilters]
	);

	const handleSearchField = (field, value) => {
		updateAnalyticsState[section](metric, {
			offset: searchFieldValue ? 0 : offset,
			[field]: value
		});
		if (searchFieldValue) {
			applySearchFilter();
		}
	};

	const setSearchFilter = (field, value) => {
		updateAnalyticsState[section](metric, {
			offset: 0,
			[field]: value
		});
		applySearchFilter();
	};

	const handleColumnSelection = (isSelected, field, opt) => {
		updateAnalyticsState[section](metric, {
			tableColumnsSelected: {
				[field]: {
					...tableColumnsSelected.columns,
					[opt.key]: isSelected
				}
			}
		});
	};

	const handleSort = (field) => {
		updateAnalyticsState[section](metric, {
			offset: 0,
			sort: {
				field: field,
				order: getSortOrder(sort, { field })
			},
			sortedField: field
		});
	};

	const handlePagination = (page) => {
		// set new offset
		const newOffset = (page - 1) * limit;
		updateAnalyticsState[section](metric, {
			offset: newOffset
		});

		// scroll to top of the list
		if (tableRef) {
			setTimeout(() => {
				scroll({ top: tableRef?.offsetTop - 57, left: 0 });
			}, 300);
		}
	};

	const handlePageSize = (field, size) => {
		// set new limit
		if (size && size?.value !== limit) {
			updateAnalyticsState[section](metric, {
				[field]: size.value
			});
		}

		// scroll to top of the list
		if (tableRef) {
			setTimeout(() => {
				scroll({ top: tableRef?.offsetTop - 57, left: 0 });
			}, 300);
		}
	};

	const handleRowClick = (rowData) => {
		if (rowData.name && (rowData?.[METRIC_ENTITY_ID_MAP[metric]] || rowData.id)) {
			// update breadcrumbs
			let updatedBc = [...bcLinks];
			updatedBc.push({
				title: capitaliseTextStrict(match.params.entity?.split("-")?.join(" "), true),
				to: match.url
			});
			const encodedName = encodeURIComponent(rowData?.name?.replaceAll("%", "percent"));
			const url = `${tabularData.rowLink.to}/${tabularData.rowLink.entity}/${encodedName}/${
				rowData?.[METRIC_ENTITY_ID_MAP[metric]] || rowData.id
			}${`?filters=${query.get("filters")}`}${`&bc=${breadcrumbs.encode(updatedBc)}`}`;
			history.push(url);
		}
	};

	const handleNestedEntity = (toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
		}
		adjustNestedContainer(toOpen);
	};

	const handlePiperAcademy = () => {
		str.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "analytics",
				start: "04:15"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	return (
		<div
			className={
				`analytics-entity-list section-container-common ${match.params.query}` +
				(isPageScrolled ? " scrolled" : "")
			}
			ref={tableRef}
		>
			<Header noScroll={true}>
				<Breadcrumbs
					connectedLinks={bcLinks?.map((link, i) => ({
						...link,
						to: `${link.to}${`${link.to.includes("?") ? "&" : "?"}filters=${query.get("filters")}`}${
							i > 0 ? `&bc=${breadcrumbs.encode(bcLinks.slice(0, i))}` : ""
						}`
					}))}
				/>
				<SectionHeader
					title={match.params.entity?.split("-")?.join(" ") || ""}
					showHelpBtn={true}
					handlePiperAcademy={handlePiperAcademy}
					isPageScrolled={isPageScrolled}
				/>
				<Filters
					showBrands
					showLocations={!match.params.entity.includes("location")}
					showPlatforms
					showDateCompare
					module={capitaliseText(section)}
				/>
				<EntityFilters
					filters={filters || []}
					currFilters={currFilters}
					handleFilters={handleFilters}
					searchKeywords={searchKeywords || []}
					searchFieldSelected={searchFieldSelected}
					searchFieldValue={searchFieldValue}
					handleSearchField={handleSearchField}
					setSearchFilter={setSearchFilter}
					tableFields={tabularData?.fields || []}
					handleColumnSelection={handleColumnSelection}
					selectedColumns={tableColumnsSelected}
				/>
			</Header>
			<CustomTable
				forAnalytics={true}
				bordered={true}
				loading={loading}
				data={tabularData?.rows || []}
				columns={tabularData?.columns?.filter((col) => tableColumnsSelected.columns[col.field]) || []}
				lastColumn={
					tableColumnsSelected?.columns
						? Object.keys(tableColumnsSelected?.columns)
								?.filter((key) => tableColumnsSelected?.columns?.[key])
								?.slice(-1)?.[0]
						: undefined
				}
				sortList={handleSort}
				sortedField={sortedField}
				sortedOrder={sort?.order}
				classes="entity-list-table-container"
				content="Data"
				currencySymbol={currencySymbol}
				isRowClickable={tabularData?.isRowClickable}
				rowClickHandler={handleRowClick}
			/>
			<Paginator
				limit={limit}
				offset={offset}
				count={tabularData?.count || 0}
				goToPage={handlePagination}
				showPageSize={true}
				setPageSize={handlePageSize}
			/>
			<NestedEntityContainer
				show={nestedEntity.show}
				type={nestedEntity.type}
				id={nestedEntity.id}
				closeNestedContainer={() => handleNestedEntity(false)}
				nestedRef={nestedRef}
				isNested={false}
				isForeignSource={true}
			/>
		</div>
	);
};
export default connect((store) => ({
	store: store,
	currencySymbol: store.login.loggedInbizDetail.currencySymbol
}))(EntityList);

const EntityFilters = ({
	filters,
	currFilters,
	handleFilters,
	searchKeywords,
	searchFieldSelected,
	searchFieldValue,
	handleSearchField,
	setSearchFilter,
	tableFields,
	handleColumnSelection,
	selectedColumns
}) => {
	return (
		<div className="entity-filters">
			<div className="filters-left">
				{filters.map((filter, i) => (
					<Filter key={i} {...filter} currFilters={currFilters} handleFilters={handleFilters} />
				))}
			</div>
			<div className="filters-right">
				{searchKeywords.length > 1 ? (
					<div className="search-input-container">
						<SelectFilter
							options={searchKeywords}
							field="searchFieldSelected"
							currValue={searchFieldSelected}
							setFilter={handleSearchField}
							labelKey="valueForDisplay"
							valueKey="key"
							isSearchable={false}
							isClearable={false}
						/>
						<SearchFilter
							filterOption={{ field: "searchFieldValue" }}
							value={searchFieldValue}
							setFilter={setSearchFilter}
							placeholder="Search"
						/>
					</div>
				) : (
					<SearchFilter
						filterOption={{ field: "searchFieldValue" }}
						value={searchFieldValue}
						setFilter={setSearchFilter}
						placeholder="Search"
					/>
				)}
				<TableColumnSelector
					options={tableFields?.map((opt, i) => ({ ...opt, readOnly: i === 0 }))}
					field="columns"
					labelKey="displayName"
					valueKey="key"
					handleColumnSelection={handleColumnSelection}
					selectedColumns={selectedColumns}
				/>
			</div>
		</div>
	);
};

const Filter = ({ type, values, field, currFilters, handleFilters }) => {
	return (
		<React.Fragment>
			{type === "SINGLE" && (
				<SelectFilter
					options={[{ valueForDisplay: "All", value: "all" }, ...values]}
					field={field}
					currValue={currFilters[field] || { valueForDisplay: "All", value: "all" }}
					setFilter={handleFilters}
					labelKey="valueForDisplay"
					valueKey="value"
					customDropdownLabel={
						currFilters[field] ? (
							<div className="custom-value">
								{capitaliseTextStrict(field)} <span>{currFilters[field]?.valueForDisplay}</span>
							</div>
						) : (
							<div className="custom-value">
								{capitaliseTextStrict(field)} <span>All</span>
							</div>
						)
					}
					isClearable={false}
					placeholder={`Select ${capitaliseTextStrict(field)}`}
				/>
			)}
		</React.Fragment>
	);
};
