import React from "react";
import StackProgressBar from "../../../components/Periscope/Dashboard/StackProgressBar";
import { connect } from "react-redux";
import Tooltip from "../../../components/Periscope/Dashboard/Tooltip";
import SkeletonLoader from "../../../components/Periscope/SkeletonLoader";
import { roundAndFormatNumber } from "../../../helpers/periscope";

const LocationStackedBar = ({ periscopeDashboard, handleStatusFilter, status }) => {
	const storeMetrics = periscopeDashboard?.storeMetrics;

	const offlineStorePercentage = storeMetrics?.data?.offlineStorePercentage;
	const totalStoreFronts = storeMetrics?.data?.totalStores;

	const isLoading = storeMetrics?.loading;
	const error = storeMetrics?.error;

	const locationStackProgressBarData = [
		{
			offlineCount: roundAndFormatNumber(offlineStorePercentage, 2),
			onlineCount: roundAndFormatNumber(100 - offlineStorePercentage, 2)
		}
	];

	const progressBarKeys = ["offlineCount", "onlineCount"];
	const keysColorMapping = {
		offlineCount: "#B02B2B",
		onlineCount: "#C6D2FF"
	};

	return (
		<>
			<div className="location-main-graph border-black">
				<div className="location-status-div">
					<div className="location-info-status">
						<div
							className={`location-status-percent ${status == "offline" ? "selected-status" : ""} `}
							onClick={() => handleStatusFilter("offline")}
						>
							<div className="info-status-color brown"> </div>
							<div className="loc-status-data">
								<div className="text-status small-font">
									Offline
									<div className="tooltip">
										<span className="graph-image-indicator">
											<img src="../../../../assets/periscope/tooltip-icon.svg" />
										</span>
										<Tooltip
											title={"Offline Locations"}
											description={"Locations that have all storefronts offline"}
										/>
									</div>
								</div>
								{isLoading || error ? (
									<>
										<SkeletonLoader classname={` ${error == true ? "stop-moving" : ""}  `} />
									</>
								) : (
									<span
										data-testid="location-stackedbar-offlineCount"
										className="text-right  percent-display-loc"
									>{`${locationStackProgressBarData[0].offlineCount}%`}</span>
								)}
							</div>
						</div>
						{/* <div
							className={`location-status-percent ${
								status == "partial_offline" ? "selected-status" : ""
							} `}
							onClick={() => handleStatusFilter("partial_offline")}
						>
							<div className="info-status-color orange"></div>
							<div className="loc-status-data">
								<div className="text-status small-font">
									Partially Offline
									<div className="tooltip">
										<span className="graph-image-indicator">
											<img src="../../../../assets/periscope/tooltip-icon.svg" />
										</span>
										<Tooltip
											title={"Partially Offline Locations"}
											description={"Locations that have one or more storefronts offline"}
										/>
									</div>
								</div>

								{isLoading || error ? (
									<>
										<SkeletonLoader classname={` ${error == true ? "stop-moving" : ""}  `} />
									</>
								) : (
									<span
										data-testid="location-stackedbar-partialOfflineCount"
										className="percent-display-loc"
									>
										{locationStackProgressBarData[0].partialOfflineCount}%
									</span>
								)}
							</div>
						</div> */}
						<div
							className={`location-status-percent ${status == "online" ? "selected-status" : ""} `}
							onClick={() => handleStatusFilter("online")}
						>
							<div className="info-status-color blue"></div>
							<div className="loc-status-data">
								<div className="text-status small-font">
									Online
									<div className="tooltip">
										<span className="graph-image-indicator">
											<img src="../../../../assets/periscope/tooltip-icon.svg" />
										</span>
										<Tooltip
											title={"Online Locations"}
											description={"Locations that have all storefronts Online"}
										/>
									</div>
								</div>

								{isLoading || error ? (
									<>
										<SkeletonLoader classname={` ${error == true ? "stop-moving" : ""}  `} />
									</>
								) : (
									<span className="percent-display-loc">
										{locationStackProgressBarData[0].onlineCount === "NaN"
											? "0%"
											: `${locationStackProgressBarData[0].onlineCount}%`}
									</span>
								)}
							</div>
						</div>
					</div>

					<div className="locations-total">
						<div className="small-font total-locations">Total Storefronts</div>
						<div>
							{isLoading || error ? (
								<>
									<SkeletonLoader
										classname={`totallocation-loading ${error == true ? "stop-moving" : ""} `}
									/>
								</>
							) : (
								<div
									data-testid="location-stackedbar-totalLocations"
									className="percent-display-loc"
									style={{ textAlign: "right" }}
								>
									{totalStoreFronts}
								</div>
							)}
						</div>
					</div>
				</div>

				{isLoading || error ? (
					<>
						<div style={{ margin: "16.5px 0 0 12px", display: "flex" }}>
							<SkeletonLoader classname={`loading-stackedbar ${error == true ? "stop-moving" : ""} `} />
							<SkeletonLoader
								classname={`loading-stackedbar space-middle ${error == true ? "stop-moving" : ""} `}
							/>
							<SkeletonLoader classname={`loading-stackedbar ${error == true ? "stop-moving" : ""} `} />
						</div>
					</>
				) : (
					<>
						<StackProgressBar
							outerDivClass={"location-status"}
							data={locationStackProgressBarData}
							keys={progressBarKeys}
							keysColorMapping={keysColorMapping}
							padding={2}
							borderRadius={2}
						/>
					</>
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	periscopeDashboard: state.periscopeDashboard
});
export default connect(mapStateToProps)(LocationStackedBar);
