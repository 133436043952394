import React, { useEffect, useRef } from "react";

// component
import Image from "../_commons/Image";
import { Button } from "../_commons/Button";
import Placeholder from "../_commons/Placeholder";

// utils
import { printCurrency } from "../SiteComp";

// constants
import { FOODTYPE_MAPS } from "../../client-config";
import { connect } from "react-redux";
import { fixedToTwoDecimal } from "../../atlas-utils";
import LightningIcon from "../_icons/LightningIcon";
import Popover from "../_commons/Popover";

const PreviewItems = ({
	showHideCustomisableItemDrawer,
	selectedParentSectionData,
	currSelectedSection,
	parentSections,
	isCustomiseButtonHidden,
	showRulesApplied = false,
	currClickedSection,
	updatedSelectedSection,
	previewContainerRef,
	handleRuleAppliedClick = () => {}
}) => {
	const categoryRef = useRef();

	const handleCardIntersection = (entries) => {
		entries.forEach((e) => {
			if (e.isIntersecting) {
				updatedSelectedSection(selectedParentSectionData?.id);
			}
		});
	};

	const findSubSectionItems = (id) => {
		const res = parentSections?.find((section) => String(section?.id) === String(id));
		if (res?.items) {
			return res?.items;
		}
		return [];
	};

	useEffect(() => {
		const cardObserver = new IntersectionObserver(handleCardIntersection, {
			root: previewContainerRef?.current,
			threshold: 0.001,
			rootMargin: "0px 0px -95% 0px"
		});

		if (categoryRef?.current) {
			cardObserver.observe(categoryRef.current);
		}

		return () => {
			cardObserver.disconnect();
		};
	}, [categoryRef, parentSections]);

	const handleScrollToSection = () => {
		if (categoryRef?.current && currClickedSection === selectedParentSectionData?.id) {
			updatedSelectedSection(selectedParentSectionData?.id);
			categoryRef.current.scrollIntoView();
		}
	};

	useEffect(() => {
		if (currClickedSection) {
			handleScrollToSection();
		}
	}, [currClickedSection, parentSections]);

	const renderSubCategoryItems = (id, title, sectionName) => {
		const subsectionItems = findSubSectionItems(id);

		return (
			<div className="subsection-items-container">
				<Header
					subSection={true}
					parentSection={sectionName}
					sectionTitle={title}
					entityCount={subsectionItems.length}
					dropShadow={currSelectedSection?.id === selectedParentSectionData?.id}
				/>
				{subsectionItems.length === 0 ? (
					<EmptySectionPlaceholder />
				) : (
					subsectionItems.map((item, i) => (
						<React.Fragment key={i}>
							<PreviewItem
								itemId={item?.id}
								foodType={item?.type}
								title={item?.name}
								desc={item?.description}
								contents={["--"]}
								imageUrl={item?.imageUrl || "/assets/empty-dish.jpg"}
								actualPrice={item?.price}
								markupPrice={item?.markupPrice}
								showHideCustomisableItemDrawer={showHideCustomisableItemDrawer}
								optionGroupsAssociated={item?.optionGroupsAssociated}
								activeRules={item?.activeRules}
								showRulesApplied={showRulesApplied}
								handleRuleAppliedClick={handleRuleAppliedClick}
							/>
							<div className="preview-items-container--border-bottom"></div>
						</React.Fragment>
					))
				)}
			</div>
		);
	};

	if (!(selectedParentSectionData?.items ?? []).length && !(selectedParentSectionData?.subCategories ?? []).length) {
		return (
			<div className="preview-items-container" ref={categoryRef}>
				<Header
					sectionTitle={selectedParentSectionData?.name}
					dropShadow={currSelectedSection?.id === selectedParentSectionData?.id}
				/>
				<Placeholder
					placeholderText="No items or subsections found!"
					placeholderImageUrl="/assets/empty_states/graphics-empty-catalogue.svg"
				/>
			</div>
		);
	}

	if (!!(selectedParentSectionData?.subCategories ?? []).length) {
		return (
			<div className="preview-items-container" ref={categoryRef}>
				{/* <Header
					sectionTitle={selectedParentSectionData?.name}
					entityCount={selectedParentSectionData?.subCategories?.length}
					type="subsections"
					dropShadow={currSelectedSection?.id === selectedParentSectionData?.id}
				/> */}
				{selectedParentSectionData?.subCategories.map((val) => {
					return renderSubCategoryItems(val?.id, val?.name, selectedParentSectionData?.name);
				})}
			</div>
		);
	}

	if (!!(selectedParentSectionData?.items ?? []).length) {
		return (
			<div className="preview-items-container" ref={categoryRef}>
				<Header
					sectionTitle={selectedParentSectionData?.name}
					entityCount={selectedParentSectionData?.items?.length}
					dropShadow={currSelectedSection?.id === selectedParentSectionData?.id}
				/>
				{selectedParentSectionData?.items.map((val, i) => (
					<React.Fragment key={i}>
						<PreviewItem
							itemId={val?.id}
							foodType={val?.type}
							title={val?.name}
							desc={val?.description}
							contents={["--"]}
							imageUrl={val?.imageUrl || "/assets/empty-dish.jpg"}
							actualPrice={val?.price}
							markupPrice={val?.markupPrice}
							showHideCustomisableItemDrawer={showHideCustomisableItemDrawer}
							isCustomiseButtonHidden={isCustomiseButtonHidden}
							optionGroupsAssociated={val?.optionGroupsAssociated}
							showRulesApplied={showRulesApplied}
							activeRules={val?.activeRules}
							handleRuleAppliedClick={handleRuleAppliedClick}
						/>
						<div className="preview-items-container--border-bottom"></div>
					</React.Fragment>
				))}
			</div>
		);
	}

	return (
		<div className="preview-items-container" ref={categoryRef}>
			<Header
				sectionTitle={selectedParentSectionData?.name}
				dropShadow={currSelectedSection?.id === selectedParentSectionData?.id}
			/>
			<EmptySectionPlaceholder />
		</div>
	);
};
export default PreviewItems;

const Header = ({
	sectionTitle = "Main Course",
	entityCount = 0,
	type = "items",
	dropShadow = false,
	subSection = false,
	parentSection
}) => {
	return (
		<div className={"items-header" + (dropShadow ? " shadow" : "")}>
			{subSection ? (
				<React.Fragment>
					<span>{parentSection}</span>
					<span className="arrow-icon">
						<img src="/assets/icons/icon-right-arrow.svg" alt="" />
					</span>
					<span>{sectionTitle}</span>
				</React.Fragment>
			) : (
				<span>{sectionTitle}</span>
			)}
			<span className="items-count">
				{entityCount} {type}
			</span>
		</div>
	);
};

const mapStateToProps = (store) => ({
	currencySymbol: store.login.loggedInbizDetail.currencySymbol
});
const PreviewItem = connect(mapStateToProps)(({
	itemId,
	foodType,
	title,
	desc,
	contents,
	imageUrl,
	actualPrice,
	markupPrice,
	isCustomisable = false,
	showHideCustomisableItemDrawer,
	isCustomiseButtonHidden,
	currencySymbol,
	optionGroupsAssociated = false,
	showRulesApplied = false,
	activeRules = [],
	handleRuleAppliedClick = () => {}
}) => {
	return (
		<div className="preview-item">
			{showRulesApplied && activeRules?.length > 0 && (
				<div
					onClick={() =>
						handleRuleAppliedClick({
							id: itemId,
							name: title
						})
					}
				>
					<RuleAppliedBadge />
				</div>
			)}
			<div className="preview-item-flex">
				<div className="item-info">
					<div className="foodtype-title">
						{foodType !== "NA" && <div className={"foodtype-indicator " + FOODTYPE_MAPS[foodType]}></div>}
						<div className="food-title">{title}</div>
					</div>
					<div className="item-description">{desc}</div>
					<div className="prices-container">
						<div className="markup-price">
							{printCurrency(currencySymbol)}
							{fixedToTwoDecimal(markupPrice)}
						</div>
						<div className="actual-price">
							{printCurrency(currencySymbol)}
							{fixedToTwoDecimal(actualPrice)}
						</div>
					</div>
				</div>
				<div className="item-actions">
					<Image
						src={imageUrl || "/assets/empty-dish.jpg"}
						alt={title}
						className="item-image"
						errorOverrideImage="/assets/empty-dish.jpg"
					/>
					{!isCustomiseButtonHidden && optionGroupsAssociated && (
						<Button
							type="secondary"
							clickHandler={() => {
								showHideCustomisableItemDrawer(true, itemId);
							}}
						>
							Customise
						</Button>
					)}
					{false && <span className="customisable-text">Customisable</span>}
				</div>
			</div>
		</div>
	);
});

const EmptySectionPlaceholder = () => {
	return (
		<div className="empty-items-placeholder">
			<Image src="/assets/icons/icon-menu-placeholder.svg" alt="no items found" className="empty-items-icon" />
			<span className="empty-items-text">No items found in this section</span>
		</div>
	);
};

export const RuleAppliedBadge = () => {
	const renderPopover = () => {
		return (
			<div className="rule-badge-popover">
				<p>Click to see rule based changes on this item.</p>
			</div>
		);
	};
	return (
		<div className="rule-badge-wrapper">
			<Popover showOnHover showOnClick renderPopover={renderPopover} position="middle-right">
				<div className="rule-applied-badge">
					<LightningIcon fillColor="#6D7AA8" />
					<p>Rule Applied</p>
				</div>
			</Popover>
		</div>
	);
};
