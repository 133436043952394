import PubSub from "pubsub-js";
import { inSample, lS } from ".";
import { TRACKING_EVENT_NAMES } from "../client-config";

// topics
export const IDENTIFY_AND_REGISTER = "IDENTIFY_AND_REGISTER";
export const TRACK_EVENT = "TRACK_EVENT";
export const RESET = "RESET";
export const TRIGGER_SURVEY = "TRIGGER_SURVEY";
export const UPDATE_USER_PROPERTIES = "UPDATE_USER_PROPERTIES";

// constants;
const TRACKING_ENABLED = process.env.REACT_APP_ANALYTICS_TRACKING_ENABLED;
const EVENT_SAMPLING_MAP = {
	// location
	[TRACKING_EVENT_NAMES.ARCHIVE_RESTORE]: 100,
	[TRACKING_EVENT_NAMES.RTGL_START]: 100,
	[TRACKING_EVENT_NAMES.RTGL_SUCCESS]: 100,
	[TRACKING_EVENT_NAMES.RTGL_FAILURE]: 100,
	[TRACKING_EVENT_NAMES.ON_DEMAND_VERIFICATION]: 100,
	[TRACKING_EVENT_NAMES.UPDATE_ENTITES_LOCATIONS_LIST_VIEW]: 10,
	[TRACKING_EVENT_NAMES.PUBLISH_CATALOGUE]: 100,
	[TRACKING_EVENT_NAMES.LOCATION_BASIC_INFO_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.LOCATION_HUB_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.LOCATION_MERAKI_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.LOCATION_SELF_DELIVERY_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.LOCATION_TIMING_GROUPS_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.LOCATION_LOCATION_GROUPS_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.LOCATION_TIMINGS_UPSTREAM_CALL]: 10,
	[TRACKING_EVENT_NAMES.LOCATION_COPY_CATALOGUE_FROM_ANOTHER_LOCATION]: 100,
	[TRACKING_EVENT_NAMES.LOCATION_CATALOGUE_CUSTOMIZE]: 10,
	[TRACKING_EVENT_NAMES.NEW_LOCATIONS_CREATED]: 10,
	[TRACKING_EVENT_NAMES.AVAILABILITY]: 100,

	// catalogue
	[TRACKING_EVENT_NAMES.NEW_TAG_CREATION]: 10,
	[TRACKING_EVENT_NAMES.NEW_TAG_GROUP_CREATION]: 10,
	[TRACKING_EVENT_NAMES.NEW_CATEGORY_CREATION]: 10,
	[TRACKING_EVENT_NAMES.NEW_ITEM_CREATION]: 10,
	[TRACKING_EVENT_NAMES.NEW_MODIFIER_CREATION]: 10,
	[TRACKING_EVENT_NAMES.NEW_MODIFIER_GROUP_CREATION]: 10,
	[TRACKING_EVENT_NAMES.NEW_TAX_CREATION]: 10,
	[TRACKING_EVENT_NAMES.NEW_CHARGE_CREATION]: 10,
	[TRACKING_EVENT_NAMES.CATEGORY_BASIC_INFO_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.CATEGORY_ITEM_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.CATEGORY_SUBCATEGORY_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.ITEM_BASIC_INFO_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.ITEM_IMAGES_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.ITEM_PLATFORM_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.ITEM_LOCATION_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.ITEM_MODIFIER_GROUP_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.CUSTOM_FIELDS_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.MODIFIER_GROUP_BASIC_INFO_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.MODIFIER_BASIC_INFO_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.MODIFIER_LOCATION_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.MODIFIER_GROUP_MODIFIER_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.MODIFIER_NESTED_MODIFIER_GROUP_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.TAXES_BASIC_INFO_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.TAXES_LOCATION_GROUP_ITEM_GROUP_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.CHARGES_BASIC_INFO_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.CHARGES_LOCATION_GROUP_ITEM_GROUP_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.TAG_GROUP_BASIC_INFO_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.TAG_GROUP_TAG_ASSOCIATION]: 10,
	[TRACKING_EVENT_NAMES.TAG_BASIC_INFO_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.TAG_ENTITY_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.ITEM_RECOMMENDED_ITEMS_UPDATE]: 10,
	[TRACKING_EVENT_NAMES.CASCADES]: 100
};

// subscriber to identify and regiser a user for future event's metadata
const identifyAndRegister = async (topic, data) => {
	const { uniqueId, eventMeta } = data;
	// Tracking on MixPanel
	if (window.mixpanel && TRACKING_ENABLED === "yes") {
		if (uniqueId) {
			lS.set("mixpanelUniqueId", uniqueId);
			await window.mixpanel.identify(uniqueId);
		}

		if (eventMeta) {
			// update user businesses
			// without this check, brave browser will throw an error as it blocks analytics tracking
			if (typeof window.mixpanel.get_property === "function") {
				const business_names = window.mixpanel.get_property("business_names") || { atlas: [] };
				const biz_ids = window.mixpanel.get_property("biz_ids") || { atlas: [] };
				if (biz_ids?.atlas?.length) {
					if (!biz_ids?.atlas?.includes(eventMeta?.biz_id)) {
						business_names.atlas.push(eventMeta?.biz_name);
						biz_ids.atlas.push(eventMeta?.biz_id);
					}
				} else {
					business_names.atlas = [eventMeta?.biz_name];
					biz_ids.atlas = [eventMeta?.biz_id];
				}
				eventMeta["business_names"] = business_names;
				eventMeta["biz_ids"] = biz_ids;
			}

			// setting mixpanel reserved properties
			const _eventMeta = { ...eventMeta };
			if (_eventMeta.name) {
				_eventMeta["$name"] = _eventMeta["name"];
				delete _eventMeta["name"];
			}

			if (_eventMeta.email) {
				_eventMeta["$email"] = _eventMeta["email"];
				delete _eventMeta["email"];
			}

			if (_eventMeta.phone) {
				_eventMeta["$phone"] = _eventMeta["isd_code"] + _eventMeta["phone"];
				delete _eventMeta["phone"];
				delete _eventMeta["isd_code"];
			}

			if (_eventMeta.country) {
				_eventMeta["mp_country_code"] = _eventMeta["biz_country"];
				delete _eventMeta["biz_country"];
			}
			// set user properties
			await window.mixpanel.people.set(_eventMeta);
			// common properties tracked in all events
			await window.mixpanel.register(_eventMeta);
		}
	}
	// Tracking on Amplitude
	if (window.amplitude && TRACKING_ENABLED === "yes") {
		if (uniqueId) {
			window.amplitude.getInstance().setUserId(uniqueId);
		}
		if (eventMeta) {
			window.amplitude.getInstance().setUserProperties(eventMeta);
		}
	}
	// Tracking on PostHog
	if (window?.posthog && window?.posthog?.__loaded) {
		if (uniqueId && eventMeta) {
			lS.set("posthogUniqueId", uniqueId);
			await window.posthog?.identify(uniqueId, {
				email: eventMeta?.email || null,
				isd_code: eventMeta?.isd_code || null,
				phone: eventMeta?.phone || null
			});
		}

		if (eventMeta) {
			// set user properties
			await window.posthog?.setPersonProperties(eventMeta);
			// common properties tracked in all events
			await window.posthog?.register(eventMeta);
		}
	}
	// initial login survey with Blitzllama
	if (window.blitz && TRACKING_ENABLED === "yes") {
		if (uniqueId && eventMeta) {
			window.blitz("createUser", uniqueId);
			window.blitz(
				"setUserAttribute",
				"user_id",
				String((eventMeta?.biz_name || "") + "_" + uniqueId + "_" + (eventMeta?.user_name || "")),
				"string"
			);
			window.blitz("setUserProperties", eventMeta);
		}
	}
	if (window.hj && TRACKING_ENABLED === "yes") {
		window.hj("identify", uniqueId, {
			first_name: eventMeta?.name,
			...eventMeta
		});
	}
};

const updateUserProperties = async (topic, data) => {
	if (window.mixpanel && TRACKING_ENABLED === "yes") {
		const uniqueId = lS.get("mixpanelUniqueId");
		await window.mixpanel.identify(uniqueId);
		await window.mixpanel.people.set(data);
	}
};

// subscriber to track an event by its name and associate metaData with it
const trackEvent = (topic, data) => {
	const { tracker, eventName = "atlas_web_event", eventMeta = {} } = data;
	const samplingThreshold = EVENT_SAMPLING_MAP[eventName] || 100;
	if (!inSample(samplingThreshold)) return;

	switch (tracker) {
		case "mixpanel":
			if (window.mixpanel && TRACKING_ENABLED === "yes") {
				window.mixpanel.track(eventName, eventMeta);
			}
			// Tracking on Amplitude
			if (window.amplitude && TRACKING_ENABLED === "yes") {
				window.amplitude.getInstance().logEvent(eventName, eventMeta);
			}
			// Tracking on PostHog
			if (window?.posthog && window?.posthog?.__loaded) {
				window.posthog.capture(eventName, eventMeta);
			}
			break;
		default:
			break;
	}
};

// reset tracking
const reset = () => {
	// reset MixPanel
	if (window?.mixpanel && TRACKING_ENABLED === "yes") {
		window.mixpanel.reset();
	}
	// reset PostHog
	if (window?.posthog && window?.posthog?.__loaded) {
		window.posthog.reset();
	}
};

// trigger surveys on UI using blitzllama services
const triggerSurvey = (topic, { surveyName, action = "triggerSurvey" }) => {
	if (action === "logoutSurvey" && TRACKING_ENABLED === "yes") {
		if (window.blitz) {
			window.blitz("logout");
		}
		return;
	}
	// checking for internal user
	if (lS?.get("auth")?.userInfo?.email?.includes("@urbanpiper.com")) {
		return;
	}
	if (window.blitz && TRACKING_ENABLED === "yes") {
		window.blitz("triggerEvent", surveyName);
	}
};

PubSub.subscribe(IDENTIFY_AND_REGISTER, identifyAndRegister);
PubSub.subscribe(TRACK_EVENT, trackEvent);
PubSub.subscribe(RESET, reset);
PubSub.subscribe(TRIGGER_SURVEY, triggerSurvey);
PubSub.subscribe(UPDATE_USER_PROPERTIES, updateUserProperties);
