import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { useTable } from "react-table";
import PlatformCell from "../../../components/Periscope/ItemIssues/PlatformCell";
import TablePlatform from "../../../components/Periscope/ItemIssues/TablePlatform";
import { getBrandDetail, getPlatformDetailViaName, getPlatformNames } from "../../../helpers/periscope";
import BrandItem from "../../../components/Periscope/ItemIssues/BrandItem";
import ToolTipIssues from "../../../components/Periscope/ItemIssues/ToolTipIssues";
import { store } from "../../../store/configureStore";
import { lS, scroll } from "../../../atlas-utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SkeletonLoader from "../../../components/Periscope/SkeletonLoader";
import { Paginator } from "../../../components/_commons/Paginator";
import { ActionTypes } from "../../../actions/_types";
import { CoachMark } from "react-coach-mark";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { createVisitHistory, visitHistory } from "../../../actions/periscope";
import { PERISCOPE_COUNTRY_WISE_SUPPORTED_PLATFORMS } from "../../../client-config";

const ItemIssuesTable = ({
	periscopeItemIssuesState,
	periscopeItemIssuesData,
	configItems,
	tableRef,
	refreshBtn,
	login,
	periscopeItemAvailabilityState
}) => {
	const data = periscopeItemIssuesData?.data?.objects?.data;
	const dataCount = periscopeItemIssuesData?.data?.objects?.meta?.totalCount;
	const loading = periscopeItemIssuesData?.loading;
	const error = periscopeItemIssuesData?.error;
	const limit = periscopeItemIssuesState?.limit;
	const offset = periscopeItemIssuesState?.offset;
	const country = login?.loggedInbizDetail?.country || lS.get("auth")?.biz?.country;
	const bizPlatforms = configItems?.bizPlatforms?.items.filter((platform) => {
		if (platform.platformName == "All Platforms") {
			return true;
		}
		return (PERISCOPE_COUNTRY_WISE_SUPPORTED_PLATFORMS?.[country] || []).includes(
			platform.platformName.toLowerCase()
		);
	});
	const brands = configItems?.brands?.items;
	const selectedPlatforms = periscopeItemIssuesState?.appliedFilters?.platform?.value;

	const dispatchSortFunction = (sort) => {
		if (sort == null) {
			store.dispatch({
				type: ActionTypes.ITEM_ISSUES_INITIAL_STATE_SORT
			});
		} else {
			store.dispatch({
				type: ActionTypes.ITEM_ISSUES_STATE_FILTERS,
				payload: sort
			});
		}
	};

	const headers = useMemo(() => {
		const platforms = selectedPlatforms ? selectedPlatforms.split(",") : getPlatformNames(bizPlatforms, true);
		return platforms;
	}, [periscopeItemIssuesState?.appliedFilters?.platform, bizPlatforms]);

	const sortThePlatform = (column) => {
		let order = "";
		let sort = null;
		store.dispatch({
			type: ActionTypes.ITEM_ISSUES_STATE_FILTERS,
			payload: { offset: 0 }
		});

		if (periscopeItemIssuesState?.sort?.field && periscopeItemIssuesState?.sort?.field == column) {
			if (!periscopeItemIssuesState?.sort?.order) {
				order = "asc";
				sort = { sort: { field: column, order: order } };
				dispatchSortFunction(sort);
			} else if (periscopeItemIssuesState?.sort?.order == "asc") {
				order = "desc";
				sort = { sort: { field: column, order: order } };
				dispatchSortFunction(sort);
			} else if (periscopeItemIssuesState?.sort?.order == "desc") {
				order = null;
				sort = null;
				dispatchSortFunction(sort);
			}
		} else {
			sort = { sort: { field: column, order: "asc" } };
			dispatchSortFunction(sort);
		}
	};

	const history = useHistory();
	const goToSpecificItemTable = (cell) => {
		if (cell?.value?.issue_count > 0) {
			let available = cell?.value?.issue_count;
			if (available !== 0) {
				const itemName = cell.row.allCells[0].value.item;
				const brandVal = cell.row.allCells[0].value.brand_id;
				const platformName = cell.column.Header;
				const brandDetails = getBrandDetail(brandVal, brands);
				const brandName = brandDetails?.name;
				const brandId = brandDetails?.id;

				store.dispatch({
					type: ActionTypes.SPECIFIC_ITEM_ISSUE_STATE_FILTERS,
					payload: {
						currentFilters: { platform_names: [platformName.toLowerCase()] },
						appliedFilters: { platform_names: [platformName.toLowerCase()] }
					}
				});
				const response = getPlatformDetailViaName(platformName, bizPlatforms);
				history.push(`/periscope/item-issues/${itemName}`, {
					itemName: itemName,
					brandName: brandName,
					brandId: brandId,
					platformId: response?.id
				});
			}
		}
	};

	const handlePagination = async (page) => {
		const offset = (page - 1) * limit;
		store.dispatch({
			type: ActionTypes.ITEM_ISSUES_STATE_FILTERS,
			payload: { offset: offset }
		});

		if (tableRef.current) {
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		}
	};
	const handlePageSize = async (field, size) => {
		store.dispatch({
			type: ActionTypes.ITEM_ISSUES_STATE_FILTERS,
			payload: { limit: size?.value }
		});
		store.dispatch({
			type: ActionTypes.ITEM_ISSUES_STATE_FILTERS,
			payload: { offset: 0 }
		});
		if (tableRef.current) {
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		}
	};

	const columns = useMemo(() => {
		if (!data || data.length === 0) return [];

		return Object.keys(data[0]).map((column) => {
			if (column === "brandItem") {
				return {
					Header: "Brand Item",
					accessor: column,
					Cell: ({ value }) => <BrandItem value={value} />
				};
			} else {
				return {
					Header: column.charAt(0).toUpperCase() + column.slice(1),
					accessor: column,
					Cell: (props) => (
						<>
							<PlatformCell props={props} />
						</>
					)
				};
			}
		});
	}, [data]);
	const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } = useTable({ columns, data });

	const [showCoachMark, setShowCoachMark] = useState(false);
	let tableDataInitialDiv = useRef(null);
	const [activatedNumber, setActivatedNumber] = useState(0);
	const coachRef = useRef(null);
	const coachList = [
		{
			activate: showCoachMark && activatedNumber === 0,
			component: (
				<div className="coach-mark-hover-div" style={{ padding: "8px 12px 12px 12px " }} ref={coachRef}>
					<span className="coach-mark-para1">Selecting Important Items</span> <br></br>
					<span className="coach-mark-para2">Monitor your important menu items across various reports</span>
				</div>
			),
			reference: tableDataInitialDiv,
			tooltip: { position: "right", margin: "" }
		}
	];
	const hasVisitedItemsIssue = periscopeItemAvailabilityState?.visitHistory?.hasVisitedItemsIssue;
	const hasVisitedItemsAvailability = periscopeItemAvailabilityState?.visitHistory?.hasVisitedItemsAvailability;
	const handleClickOutside = (event) => {
		setShowCoachMark(false);
		document.body.classList.remove("no-scroll");
		let variables = {
			bizId: String(login.loggedInbizDetail.id),
			hasVisitedItemsIssue: true,
			hasVisitedItemsAvailability: hasVisitedItemsAvailability
		};
		createVisitHistory(variables);
	};

	useEffect(() => {
		let variables = {
			bizId: String(login.loggedInbizDetail.id)
		};
		visitHistory(variables);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			if (data?.length > 0 && !hasVisitedItemsIssue) {
				if (tableRef.current) {
					if (tableRef.current) {
						scroll({ top: 0, left: 0 });
					}
					const firstTd = tableRef?.current?.querySelector(
						"table .item-issues-tbody tr:first-child td:first-child .issue-important-item"
					);
					if (firstTd) {
						tableDataInitialDiv.current = firstTd;
						setShowCoachMark(true);
						document.body.classList.add("no-scroll");
					}
				}
			}
		}, 1000);
	}, [data, loading]);

	useOnClickOutside(coachRef, (event) => handleClickOutside(event));

	return (
		<div data-testid="item-issues-table-data">
			<div className="coach-mark">
				<CoachMark {...coachList[activatedNumber]} />
			</div>
			<div ref={tableRef}>
				{bizPlatforms && bizPlatforms?.length > 0 ? (
					<table
						className="table-item-issues-availability"
						{...getTableProps()}
						style={{ borderCollapse: "collapse", border: " 1px solid #EAEAEA", overflow: "auto" }}
					>
						<thead className="item-issues-thead">
							<tr>
								<td rowSpan={2} className="issues-tablehead-text issues-brand-label">
									Brand - Item
								</td>
								<td className="issues-tablehead-text issues-location-label" colSpan={4}>
									Locations with item
								</td>
							</tr>
							<tr>
								{headers.map((table, index) => {
									return (
										<td
											key={index}
											className="issues-tablehead-text"
											style={{
												cursor: "pointer",
												width: `${100 / (headers.length || 1)}%`
											}}
											onClick={() => sortThePlatform(table)}
										>
											<TablePlatform tableName={table} />
										</td>
									);
								})}
							</tr>
						</thead>
						<>
							{loading || error ? (
								<ItemTableLoading tableData={headers} refreshBtn={refreshBtn} error={error} />
							) : (
								<>
									<tbody className="item-issues-tbody" {...getTableBodyProps()}>
										{rows.map((row, rowIndex) => {
											prepareRow(row);
											return (
												<tr key={rowIndex}>
													{row.cells.map((cell, cellIndex) => {
														let classname = "";
														if (cell.value.issue_count > 0) {
															classname = "issues-store";
														} else if (cell.value.issue_count == 0) {
															classname = "no-issues-store";
														}
														return (
															<td
																key={cellIndex}
																style={{
																	borderBottom: "1px dashed #EAEAEA",
																	...(cell.column.id !== "brandItem"
																		? { textAlign: "right" }
																		: { borderRight: "1px solid #EAEAEA" }),
																	...(cell.column.id !== "brandItem" &&
																	cell.value.issue_count > 0
																		? { cursor: "pointer", background: "#FFF5F5" }
																		: {})
																}}
																className={classname}
															>
																{cell.column.id !== "brandItem" ? (
																	<>
																		<div
																			className={`tooltip-itemm`}
																			onClick={() => goToSpecificItemTable(cell)}
																		>
																			<div
																				style={{
																					padding: "0 24px 0 16px",
																					...(cell.column.id !==
																						"brandItem" &&
																					cell.value.issue_count > 0
																						? {
																								display: "flex",
																								justifyContent: "end"
																						  }
																						: {})
																				}}
																			>
																				{cell.render("Cell")}
																			</div>
																			<ToolTipIssues value={cell.value} />
																		</div>
																	</>
																) : (
																	<> {cell.render("Cell")}</>
																)}
															</td>
														);
													})}
												</tr>
											);
										})}
									</tbody>
								</>
							)}

							{dataCount > 0 && !loading && !error ? (
								<tfoot>
									<tr>
										<td colSpan="100%" className="paginator-location">
											<Paginator
												limit={limit}
												offset={offset}
												count={dataCount || 0}
												showPageSize={true}
												goToPage={handlePagination}
												setPageSize={handlePageSize}
											/>
										</td>
									</tr>
								</tfoot>
							) : (
								<></>
							)}
						</>
					</table>
				) : (
					""
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	periscopeItemIssuesState: state.periscopeItemIssuesState,
	periscopeItemIssuesData: state.periscopeItemIssuesData,
	login: state.login,
	configItems: state.configItems,
	periscopeItemAvailabilityState: state.periscopeItemAvailabilityState
});

export default connect(mapStateToProps)(ItemIssuesTable);

const ItemTableLoading = ({ tableData = [], error = false, refreshBtn }) => {
	let tableRowsHead = ["brandItem", ...tableData];
	let displayErrorDiv = (
		<div className="error-state">
			<div className="load-chart-div">
				<img className="load-chart-error-icon" src="/assets/periscope/loading-error.svg" />
				<div className="load-chart-info">
					<div className="chart-info">
						<span className="chart-main-text">Could not load this chart!</span>
						<span className="chart-para-info">
							We are facing some issues in fetching this data for you.
						</span>
					</div>
					<div className="try-again" onClick={() => refreshBtn()}>
						<img className="load-again-img" src="/assets/periscope/load-again.svg" />
						<span className="try-again-text">Try Again</span>
					</div>
				</div>
			</div>
		</div>
	);
	return (
		<tbody
			className={`item-issues-loading sample-table  ${error == true ? "stop-moving" : ""}`}
			style={{ position: "relative" }}
		>
			{error ? displayErrorDiv : ""}
			{[...Array(10)].map((_, index) => {
				return (
					<tr key={index} className="all" style={{ borderBottom: "1px dashed #EAEAEA" }}>
						{tableRowsHead.map((table, index) => {
							if (table == "brandItem") {
								return (
									<td key={index} style={{ display: "flex" }}>
										<span>
											<SkeletonLoader classname={"intro-icon-loading"} />
										</span>
										<div className="all-loading-span">
											<span>
												<SkeletonLoader classname={"brand-image-loading-item"} />
											</span>
											<span>
												<SkeletonLoader classname={"branditem-text-loading"} />
											</span>
											<span>
												<SkeletonLoader classname={"star-loading-item"} />
											</span>
										</div>
									</td>
								);
							} else {
								return (
									<td
										style={{
											...(index == 1 ? { borderLeft: "1px solid rgb(234, 234, 234)" } : {})
										}}
									>
										<span>
											<SkeletonLoader classname={"platform-loading-item"} />
										</span>
									</td>
								);
							}
						})}
					</tr>
				);
			})}
		</tbody>
	);
};
