import React from "react";

// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// third party
import camelcase from "camelcase";
import moment from "moment";

// graphql
import {
	GET_REPORT_GROUPS_LIST,
	GET_REPORT_GROUPS_NEW_LIST,
	GET_REPORT_DETAIL,
	GENERATE_REPORT,
	GENERATE_REPORT_V2,
	GET_REPORTS_TASK_LIST,
	GET_REPORTS_EXPORT_HISTORY,
	GET_REPORT_PREVIEW
} from "../graphql/reports";

// utils
import { msaagesArrayToHtml } from "../atlas-utils";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

// services
import NotificationServices from "../services/NotificationService";

export const fetchReportsList = async () => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_REPORTS_LIST_REQUEST
	});
	try {
		const resp = await client.query({
			query: GET_REPORT_GROUPS_LIST,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_REPORTS_LIST_SUCCESS,
			payload: resp.data.reportGroups
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_REPORTS_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const fetchReportsNewList = async () => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_REPORTS_NEW_LIST_REQUEST
	});
	try {
		const resp = await client.query({
			query: GET_REPORT_GROUPS_NEW_LIST,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_REPORTS_NEW_LIST_SUCCESS,
			payload: resp.data.reportV2Groups?.objects
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_REPORTS_NEW_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const generateReport = async (variables) => {
	try {
		const resp = await client.mutate({
			mutation: GENERATE_REPORT,
			variables: { input: variables }
		});
		if (resp.data.generateReport.status.success) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.generateReport.status.messages),
					timeout: 5000,
					error: false
				}
			});
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.generateReport.status.messages),
					timeout: 3000,
					error: true
				}
			});
		}
		return resp.data.generateReport.status;
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchReportDetail = async (id) => {
	const isMultibrandEnabled = store.getState().login.loggedInbizDetail.isMultibrandEnabled;
	store.dispatch({
		type: ActionTypes.GET_REPORT_DETAIL_REQUEST
	});
	try {
		const variables = {
			reportId: id
		};
		const resp = await client.query({
			query: GET_REPORT_DETAIL,
			variables,
			fetchPolicy: "no-cache"
		});
		const reportDetail = resp.data.reportDetail;
		const isCheckedAll = {};
		const optionUpdates = {};
		let locationsList = [];

		reportDetail.filters = reportDetail.filters.map((f) => {
			// update locations filter's valueForDisplay for multi brand enabled biz
			if (isMultibrandEnabled && f.field === "locations") {
				f.values = f.values.map((val) => ({
					value: val.value,
					valueForDisplay: `${val.valueForDisplay} (Brand: ${
						val.additionals.find((obj) => obj.key === "brand_name")?.value || "--"
					})`,
					...val.additionals.reduce((obj, val) => {
						obj[camelcase(val.key)] = val.value;
						return obj;
					}, {})
				}));
				// save a copy of locations list
				locationsList = [...f.values];
			}

			// update brands field in filters
			if (f.field === "brand_id") {
				f.field = "brands";
			}

			// show pre-selected options for all filters based on default values
			isCheckedAll[f?.field?.replace(/([-_]\w)/g, (f) => f[1]?.toUpperCase())] =
				f?.default?.length === f?.values?.length;
			optionUpdates[f?.field?.replace(/([-_]\w)/g, (f) => f[1]?.toUpperCase())] = f.default.reduce((obj, val) => {
				obj[val.value] = true;
				return obj;
			}, {});

			return f;
		});

		// update report detail state
		store.dispatch({
			type: ActionTypes.GET_REPORT_DETAIL_SUCCESS,
			payload: {
				...reportDetail,
				locationsList
			}
		});
		store.dispatch({
			type: ActionTypes.UPDATE_REPORT_DETAIL,
			payload: {
				isCheckedAll,
				optionUpdates
			}
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_REPORT_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching report details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const generateReportV2 = async (exportType) => {
	try {
		const { data, optionUpdates, appliedDateFilter, emails } = store.getState().reportDetail;
		const variables = {
			id: data.id,
			name: data.name,
			exportFormat: exportType.id,
			emails,
			filters: {}
		};
		for (let filter in optionUpdates) {
			if (filter !== "brands") {
				variables.filters[filter] =
					data?.filters?.find((filter) => filter.field === "time_period") && filter === "timePeriod"
						? appliedDateFilter?.current?.dateFilter
						: [
								...(variables.filters[filter] ?? []),
								...Object.keys(optionUpdates[filter]).filter((key) => optionUpdates[filter][key])
							];
			}
		}
		const resp = await client.mutate({
			mutation: GENERATE_REPORT_V2,
			variables: { input: variables }
		});
		if (resp.data.generateReportV2.status.success) {
			// show global notification using NotificationServices
			NotificationServices.pushNotification({
				message: resp.data.generateReportV2.status.messages[0]?.message,
				timeout: 3000,
				type: "success",
				isClosable: true,
				theme: "dark"
			});
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.generateReportV2.status.messages),
					timeout: 3000,
					error: true
				}
			});
		}
		return resp.data.generateReportV2.status;
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchPendingReportsTaskList = async () => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const resp = await client.query({
			query: GET_REPORTS_TASK_LIST,
			variables: {
				limit: 100,
				offset: 0,
				filters: [
					{
						field: "status",
						value: "pending"
					}
				]
			},
			fetchPolicy: "no-cache"
		});

		// add pending report download tasks to ongoing tasks list
		const pendingReportTasks = resp.data?.reportTaskList?.objects || [];
		pendingReportTasks.forEach((task) => {
			store.dispatch({
				type: "ADD_TASK_TO_ONGOING_TASKS_LIST",
				payload: {
					id: task.jobId,
					type: "export-report",
					status: task.status,
					name: `Downloading ${task.name}`
				}
			});
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const fetchReportsTaskList = async (ongoingTasks = []) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const resp = await client.query({
			query: GET_REPORTS_TASK_LIST,
			variables: {
				limit: 100,
				offset: 0,
				filters: [
					{
						field: "status",
						value: "completed,failed"
					}
				]
			},
			fetchPolicy: "no-cache"
		});

		// update ongoing report download tasks status to completed/failed
		ongoingTasks.forEach((task) => {
			const report = resp.data.reportTaskList?.objects?.find((r) => r.jobId === task.id);
			if (report) {
				// download generated report
				if (report.status === "completed" && report.downloadUrl) {
					const link = document.createElement("a");
					link.href = report.downloadUrl;
					link.click();
				}

				// show global notification using NotificationServices
				setTimeout(() => {
					NotificationServices.pushNotification({
						message:
							report.status === "failed" ? "Report generation failed" : "Report generated successfully",
						timeout: 3000,
						type: report.status === "failed" ? "error" : "success",
						isClosable: true,
						theme: "dark"
					});

					// update task in ongoing tasks list
					store.dispatch({
						type: ActionTypes.UPDATE_TASK_IN_ONGOING_TASKS_LIST,
						payload: {
							id: report.jobId,
							status: report.status
						}
					});
				}, 1000);
			}
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

export const fetchReportsExportHistory = async (search = "") => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_EXPORT_REPORT_HISTORY_REQUEST
	});
	const { limit, offset, prevId, nextId, appliedFilters, appliedDateFilter, data } =
		store.getState().exportReportHistory;
	try {
		const variables = { limit };
		if (nextId) {
			variables.nextId = nextId;
		}
		if (prevId) {
			variables.prevId = prevId;
		}
		// sidebar filters
		let filtersObject = [];
		Object.keys(appliedFilters).forEach((f) => {
			if (
				(appliedFilters[f] && appliedFilters[f]?.value !== "all") ||
				(f === "downloaded_by" && appliedFilters[f]?.value)
			) {
				filtersObject.push({
					field: f,
					value: appliedFilters[f]?.value
				});
			}
		});
		// date filter
		if (appliedDateFilter.current.dateFilter) {
			filtersObject.push({
				field: "time_period",
				value: appliedDateFilter.current.dateFilter
			});
		}
		// set filter
		variables.filters = filtersObject;
		// search filter
		if (data.searchFieldSelected && search) {
			variables.search = [{ key: data.searchFieldSelected.key, value: search }];
		}
		const resp = await client.query({
			query: GET_REPORTS_EXPORT_HISTORY,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_EXPORT_REPORT_HISTORY_SUCCESS,
			payload: {
				...(resp.data.reportHistory ?? {}),
				searchFieldSelected: resp.data.reportHistory?.searchKeywords?.[0],
				hasPrevious: !!offset
			}
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_EXPORT_REPORT_HISTORY_FAILURE
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

// get tabular data for report preview
export const getTabularData = (dataset) => {
	const tabularData = {};

	// count
	tabularData.count = dataset?.rows?.length || 0;

	// column value data type map
	const columnValueTypeMap = {};

	// add serial no. column and row values
	if (dataset?.columns?.length > 0 && dataset?.rows?.length > 0) {
		dataset.columns = [{ key: "sr_no", display: "Sr No", dataType: "char" }, ...(dataset.columns ?? [])];
		dataset.rows = dataset?.rows?.map((row, i) => [{ key: "sr_no", value: `${i + 1}` }, ...(row ?? [])]);
	}

	// fields
	tabularData.fields =
		dataset.columns?.map((col) => {
			if (col.dataType) {
				columnValueTypeMap[col.key] = col.dataType;
			}
			return col;
		}) || [];

	// columns
	tabularData.columns =
		dataset.columns?.map((col) => ({
			name: col.display,
			field: col.key,
			classes: col.dataType === "float" ? "align-right" : "",
			render: (record, i, rest) => (
				<div
					className={`table-cell ${col.key?.toLowerCase()} ${col.dataType === "float" ? "align-right" : ""}`}
					title={record[col.key] && record[col.key] !== "--" ? record[col.key] : null}
					style={
						rest?.tableOverflow && rest?.columnWidths?.[col.key]
							? { minWidth: rest?.columnWidths?.[col.key] }
							: {}
					}
					key={i}
				>
					<div className="primary">
						{col.dataType === "date"
							? moment(record[col.key]).format("DD MMM, YYYY - hh:mm A")
							: col.dataType === "float"
								? parseFloat(record[col.key].toFixed(2))
								: record[col.key]}
					</div>
				</div>
			)
		})) || [];

	// rows
	tabularData.rows =
		dataset.rows?.map((row) => {
			const rowData = {};
			row.forEach((obj) => {
				rowData[obj.key] = obj.value === null ? "--" : obj.value;
			});
			return rowData;
		}) || [];

	return tabularData;
};

export const fetchReportPreview = async () => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_REPORT_PREVIEW_REQUEST
	});
	try {
		const { data, optionUpdates, appliedDateFilter } = store.getState().reportDetail;
		const variables = {
			limit: 20,
			filters: {
				reportTypeId: data.id
			}
		};
		for (let filter in optionUpdates) {
			if (filter !== "brands") {
				variables.filters[filter] =
					data?.filters?.find((filter) => filter.field === "time_period") && filter === "timePeriod"
						? appliedDateFilter?.current?.dateFilter
						: [
								...(variables.filters[filter] ?? []),
								...Object.keys(optionUpdates[filter]).filter((key) => optionUpdates[filter][key])
							];
			}
		}
		const resp = await client.query({
			query: GET_REPORT_PREVIEW,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_REPORT_PREVIEW_SUCCESS,
			payload: {
				...(resp?.data?.reportPreview || {}),
				tabularData: getTabularData(resp?.data?.reportPreview || {})
			}
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_REPORT_PREVIEW_FAILURE
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};
