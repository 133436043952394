import gql from "graphql-tag";

export const GET_ITEM_GROUPS_LIST = gql`
	query getItemGroupsList($limit: Int, $offset: Int, $filters: [ListFilterArgument], $sort: SortInput) {
		itemGroups(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			objects {
				id
				title
				description
				numAssociatedItems
			}
		}
	}
`;

export const GET_ITEM_GROUP = gql`
	query getItemGroup($id: Int) {
		itemGroup(id: $id) {
			id
			title
			description
			includesAll
			numAssociatedTaxes
			numAssociatedCharges
			items {
				count
			}
		}
	}
`;

export const CREATE_ITEM_GROUP = gql`
	mutation createItemGroup($title: String!, $description: String) {
		saveItemGroup(input: { title: $title, description: $description }) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				title
				description
			}
		}
	}
`;

export const EDIT_ITEM_GROUP = gql`
	mutation createItemGroup($id: Int, $title: String!, $description: String, $isDeleted: Boolean) {
		saveItemGroup(input: { id: $id, title: $title, description: $description, isDeleted: $isDeleted }) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				title
				description
			}
		}
	}
`;

export const GET_ITEM_GROUP_ITEMS = gql`
	query getItemGroupItems($id: Int, $limit: Int, $offset: Int, $sort: SortInput, $filters: [ListFilterArgument]) {
		itemGroup(id: $id) {
			id
			items(limit: $limit, offset: $offset, sort: $sort, filters: $filters) {
				count
				filters {
					hide
					field
					valueForDisplay
					type
					values {
						valueForDisplay
						value
					}
				}
				objects {
					id
					itemTitle
					itemPrice
					crmTitle
					tags
					category
					foodType
				}
			}
		}
	}
`;
