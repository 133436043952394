import React from "react";
import { extractInitials } from "../../../atlas-utils";
import { connect } from "react-redux";
import { handleCreateCheckItem, handleRemoveImportantItem } from "../../../actions/periscope";
import { store } from "../../../store/configureStore";
import { getBrandDetail } from "../../../helpers/periscope";

const BrandCell = React.memo(({ props, login, configItems, isMultibrandEnabled }) => {
	const brands = configItems?.brands?.items;
	let displayImage = "";
	let displayDiv = "";
	const currentBrandDetails = getBrandDetail(props?.value?.brand_id, brands);
	let color = currentBrandDetails?.color;
	if (props?.value?.brand_id && currentBrandDetails?.image?.length > 0) {
		displayImage = currentBrandDetails?.image;
	} else {
		displayDiv = (
			<span className={"brand-text-logo"}>{extractInitials(currentBrandDetails?.name?.split(" "))}</span>
		);
	}

	const checkItem = async (val) => {
		if (val?.is_important) {
			let variables = {
				itemId: String(val?.important_item_id)
			};
			const respItemId = await handleRemoveImportantItem(variables);
			if (respItemId) {
				store.dispatch({
					type: "ITEM_AVAILABILITY_ORIGINAL_DATA_CHANGE",
					payload: respItemId
				});
			}
		} else {
			let variables = {
				bizId: String(login.loggedInbizDetail.id),
				brandId: isMultibrandEnabled ? val.brand_id : null,
				name: val.item
			};
			let receivedId = await handleCreateCheckItem(variables);

			if (receivedId && receivedId?.id) {
				store.dispatch({
					type: "ITEM_AVAILABILITY_ORIGINAL_DATA_CHANGE_FALSE_VALUE",
					payload: receivedId
				});
			}
		}
	};

	return (
		<div className="branditem-image-container align-center">
			<span className="brand-item-img-span " onClick={() => checkItem(props.value)}>
				{props.value.is_important == true ? (
					<>
						<img className={`colored-branditem  `} src="/assets/periscope/brandLabelImportant.svg" />
					</>
				) : (
					<>
						<img className={`non-colored-branditem  `} src="/assets/periscope/brandLabel.svg" />
					</>
				)}
			</span>
			<div className={`branditem-display align-center  `}>
				<div className={`brand-display `}>
					{displayImage.length > 0 ? (
						<img className={`brand-image-display`} src={displayImage} />
					) : (
						<div className={`text-logo ${displayImage.length > 0 ? "" : color}`}>{displayDiv}</div>
					)}
				</div>
				<span className="brand-text-styling">{props.value.item}</span>
				<span className="rating-text-styling">{props.value.rating}</span>
				<img
					src="/assets/periscope/star-rating.svg"
					className="star-image-location"
					style={{ margin: "0px 4px" }}
				/>
			</div>
		</div>
	);
});
const mapStateToProps = (state) => ({
	login: state.login,
	periscopeItemAvailabilityData: state.periscopeItemAvailabilityData,
	isMultibrandEnabled: state.login.loggedInbizDetail.isMultibrandEnabled,
	configItems: state.configItems
});
export default connect(mapStateToProps)(BrandCell);
