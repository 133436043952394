import React, { Fragment, useState } from "react";
import SkeletonLoader from "../SkeletonLoader";

const LocationTableLoading = ({ tableData, refreshBtn, selectedBrand = "", error = false }) => {
	const sidebarLocation = {
		verticalAlign: "top",
		padding: "20px 16px 20px 24px ",
		minWidth: "176px"
	};

	let displayLocation = (
		<div className="display-location-loading">
			<span>
				<SkeletonLoader />
			</span>
		</div>
	);

	let displayBrand = (
		<div className="display-brand-loading">
			<span className="brand-img-loading">
				<SkeletonLoader />
			</span>
			<span className="brand-text-loading">
				<SkeletonLoader />
			</span>
		</div>
	);

	let displayPlatform = (
		<div className="display-platform-loading">
			<SkeletonLoader />
		</div>
	);

	let displayErrorDiv = (
		<div className="error-state">
			<div className="load-chart-div">
				<img className="load-chart-error-icon" src="/assets/periscope/loading-error.svg" />
				<div className="load-chart-info">
					<div className="chart-info">
						<span className="chart-main-text">Could not load this chart!</span>
						<span className="chart-para-info">
							We are facing some issues in fetching this data for you.
						</span>
					</div>
					<div className="try-again" onClick={() => refreshBtn()}>
						<img className="load-again-img" src="/assets/periscope/load-again.svg" />
						<span className="try-again-text">Try Again</span>
					</div>
				</div>
			</div>
		</div>
	);

	const displayComponents = {
		location: displayLocation,
		brand: displayBrand,
		default: displayPlatform
	};
	const getDisplayComponent = (value) => displayComponents[value] || displayComponents.default;

	return (
		<tbody className={`sample-table  ${error == true ? "stop-moving" : ""} `} style={{ position: "relative" }}>
			{error ? displayErrorDiv : ""}
			{Array.from({ length: 3 }).map((_, indexVal) => (
				<Fragment key={indexVal}>
					<tr>
						{tableData.map((tableValue, index) => {
							if (tableData[index] == tableValue) {
								if (selectedBrand?.id !== "all" && tableValue == "brand") {
									return null;
								} else {
									return (
										<td
											rowSpan={tableValue === "location" ? 3 : undefined}
											key={index}
											style={{
												...(tableValue == "brand"
													? { padding: "20px 16px", borderRight: "1px solid #EAEAEA" }
													: {}),
												...(tableValue == "location" ? sidebarLocation : {}),
												...(tableValue !== "location"
													? { borderBottom: "1px dashed #EAEAEA" }
													: {}),
												...(index == 0 ? { borderBottom: "1px solid #EAEAEA" } : {}),
												...(tableValue == "location" && selectedBrand?.id !== "all"
													? { borderRight: "1px solid #EAEAEA" }
													: {})
											}}
										>
											{getDisplayComponent(tableValue)}
										</td>
									);
								}
							}
						})}
					</tr>
					<tr>
						{tableData.map((tableValue, index) => {
							if (index === 0) {
								return null;
							} else if (selectedBrand?.id !== "all" && tableValue == "brand") {
								return null;
							} else {
								return (
									<td
										rowSpan={tableValue === "location" ? 3 : undefined}
										key={index}
										style={{
											...(tableValue == "brand"
												? { padding: "20px 16px", borderRight: "1px solid #EAEAEA" }
												: {}),
											...(tableValue == "location" ? sidebarLocation : {}),
											...(tableValue !== "location" ? { borderBottom: "1px dashed #EAEAEA" } : {})
										}}
									>
										{getDisplayComponent(tableValue)}
									</td>
								);
							}
						})}
					</tr>
					<tr>
						{tableData.map((tableValue, index) => {
							if (index === 0) {
								return null;
							} else if (selectedBrand?.id !== "all" && tableValue == "brand") {
								return null;
							} else {
								return (
									<td
										rowSpan={tableValue === "location" ? 3 : undefined}
										key={index}
										style={{
											...(tableValue == "brand"
												? { padding: "20px 16px", borderRight: "1px solid #EAEAEA" }
												: {}),
											...(tableValue == "location" ? sidebarLocation : {}),
											...(tableValue !== "location" ? { borderBottom: "1px dashed #EAEAEA" } : {})
										}}
									>
										{getDisplayComponent(tableValue)}
									</td>
								);
							}
						})}
					</tr>
				</Fragment>
			))}
		</tbody>
	);
};

export default LocationTableLoading;
