import React, { useEffect, useState } from "react";

// component
import { FormSidebar } from "../_commons/FormSidebar";
import Image from "../_commons/Image";
import { CheckBox } from "../_commons/CheckBox";
import { clientMenu } from "../../client-menu";
import { CUSTOMISABLE_ITEM_DETAILS } from "../../graphql/menus";
import { FOODTYPE_MAPS } from "../../client-config";
import { connect } from "react-redux";
import { printCurrency } from "../SiteComp";
import { fixedToTwoDecimal } from "../../atlas-utils";

const CustomisableItemDrawer = ({
	isOpen,
	showHideCustomisableItemDrawer,
	itemId,
	menuId,
	currencySymbol,
	selectedLocation = null,
	selectedPlatform = ""
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [itemInfo, setItemInfo] = useState({});
	const [selectedAddOns, setSelectedAddOns] = useState({});

	const fetchItemCustomisableInfo = async () => {
		try {
			setIsLoading(true);
			const variables = {
				menuId,
				itemId,
				applyRules: true,
				locationId: selectedLocation?.id && String(selectedLocation?.id),
				platform: selectedPlatform?.platformName,
				limit: 100
			};
			const resp = await clientMenu.query({
				query: CUSTOMISABLE_ITEM_DETAILS,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp?.data?.item) {
				setItemInfo(resp?.data?.item);
			}

			setIsLoading(false);
		} catch (e) {
			setIsLoading(false);
			console.log(e);
		}
	};

	const handleClose = () => {
		setSelectedAddOns({});
		showHideCustomisableItemDrawer(false);
	};

	const handleAddOnSelection = (id) => {
		if (selectedAddOns[id]) {
			setSelectedAddOns((current) => {
				const presentSelections = {
					...current
				};
				delete presentSelections[id];
				return presentSelections;
			});
			return;
		}
		setSelectedAddOns((current) => ({
			...current,
			[id]: true
		}));
	};

	useEffect(() => {
		if (isOpen && itemId) {
			fetchItemCustomisableInfo();
		}
	}, [isOpen, itemId]);

	if (isLoading) {
		return (
			<div className="customisable-item-drawer-container">
				<FormSidebar
					isOpen={isOpen}
					title="Item Details"
					subTitle="Item details shown on adding to cart"
					hideActions
					close={handleClose}
				>
					<div className="shimmer-container">
						<div className="shimmer H(60px) Mb(10px)" />
						<div className="shimmer H(60px) Mb(10px)" />
					</div>
				</FormSidebar>
			</div>
		);
	}

	const { optionGroups } = itemInfo;

	return (
		<div className="customisable-item-drawer-container">
			<FormSidebar
				isOpen={isOpen}
				title="Item Details"
				subTitle="Item details shown on adding to cart"
				hideActions
				close={() => showHideCustomisableItemDrawer(false)}
			>
				<ItemBasicDetails itemInfo={itemInfo} />
				{(optionGroups?.objects ?? []).map((modifierGroup) =>
					modifierGroup?.isVariant ? (
						<VariantSelector
							name={modifierGroup?.name}
							key={modifierGroup?.id}
							options={modifierGroup?.options}
							currencySymbol={currencySymbol}
						/>
					) : (
						<AddOnSelector
							name={modifierGroup?.name}
							key={modifierGroup?.id}
							options={modifierGroup?.options}
							maxSelectable={modifierGroup?.maxSelectable}
							currencySymbol={currencySymbol}
							handleAddOnSelection={handleAddOnSelection}
							selectedAddOns={selectedAddOns}
						/>
					)
				)}
			</FormSidebar>
		</div>
	);
};
const mapStateToProps = (store) => ({
	currencySymbol: store.login.loggedInbizDetail.currencySymbol
});
export default connect(mapStateToProps)(CustomisableItemDrawer);

const ItemBasicDetails = ({ itemInfo }) => {
	const { name, imageUrl, description, foodType } = itemInfo;
	return (
		<div className="item-basic-details">
			<div className="item-image-container">
				<Image
					src={imageUrl || "/assets/empty-dish.jpg"}
					alt={name || "item title"}
					errorOverrideImage="/assets/empty-dish.jpg"
				/>
			</div>
			<div className="item-info">
				<div className="foodtype-item-name">
					<div className={`foodtype-indicator ${FOODTYPE_MAPS[foodType]}`}></div>
					<div className="item-name">{name || "--"}</div>
				</div>
				<div className="desc">{description || "--"}</div>
				<div className="food-content">
					Contains:
					<br />
					--
				</div>
			</div>
		</div>
	);
};

const VariantSelector = ({ name, options, currencySymbol }) => {
	return (
		<div className="modifier-quantity-selector-container">
			<div className="header">
				<div className="action-header">
					{name || "--"} <span className="qty-min-limit">(min. 1)</span>
				</div>
				<div className="total-modifiers-count">{options?.totalObjectCount || 0} modifiers</div>
			</div>
			{(options.objects ?? []).map((option) => (
				<div className="radio-value-container" key={option?.id}>
					<div className="radio-selector">
						{/* <input type="radio" name="modifier_qnty" value={option?.id} /> */}
						<label for="html">{option?.name || "--"}</label>
					</div>
					<div className="price">
						{printCurrency(currencySymbol)} {fixedToTwoDecimal(option?.price) || "--"}
					</div>
				</div>
			))}
		</div>
	);
};

const AddOnSelector = ({ name, maxSelectable = 0, options, currencySymbol, handleAddOnSelection, selectedAddOns }) => {
	return (
		<div className="modifier-quantity-selector-container">
			<div className="header">
				<div className="action-header">
					{name || "--"} <span className="qty-min-limit">(max. {maxSelectable})</span>
				</div>
				<div className="total-modifiers-count">{options?.totalObjectCount || 0} modifiers</div>
			</div>
			{(options?.objects ?? []).map((option) => (
				<div className="radio-value-container">
					<div className="radio-selector">
						{/* <CheckBox
								clickHandler={() => handleAddOnSelection(option?.id)}
								checked={selectedAddOns[option?.id]}
							>
								{option?.name || "--"}
							</CheckBox> */}
						<label for="html">{option?.name || "--"}</label>
					</div>
					<div className="price">
						+ {printCurrency(currencySymbol)} {fixedToTwoDecimal(option?.price)}
					</div>
				</div>
			))}
		</div>
	);
};
