import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { dateFormat } from "../../../helpers/periscope";

const SpecificItemIssueHeader = ({ itemName, brandName, periscopeDataResponse }) => {
	const timestamp = periscopeDataResponse?.timestamp;
	return (
		<div className="specific-item-issue-table-head">
			<div className="specific-item-issue-table-header">
				<div className="specific-item-issue-text-display">
					<Link to="/periscope/item-issues" style={{ textDecoration: "none" }}>
						<div className="specific-item-issue-header-display">
							<span data-testid="specificLocationTableHeader" className="table-header-grey-text">
								Item Issues
							</span>
							<img
								className="specific-item-issue-right-arrow"
								src="/assets/periscope/right-arrow-action.svg"
							/>
						</div>
					</Link>
					<div className="specific-item-issue-brand-item">
						<span>
							{itemName}({brandName})
						</span>
					</div>
				</div>
				{timestamp ? <div className="fetch-time"> {dateFormat(timestamp)} </div> : ""}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	periscopeDataResponse: state.periscopeDataResponse
});

export default connect(mapStateToProps)(SpecificItemIssueHeader);
