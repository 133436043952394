import React, { useState, useEffect, useMemo, useRef } from "react";

// components
import Home from "./Home";
import Revenue from "./Revenue";
import Orders from "./Orders";
import Operations from "./Operations";
import Catalogue from "./Catalogue";
import EntityList from "../../components/Analytics/Common/EntityList";
import EntityDetail from "../../components/Analytics/Common/EntityDetail";

// third party
import history from "../../history";
import { connect } from "react-redux";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import moment from "moment";

// utils
import { jsonParser } from "../../atlas-utils";

// store
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";
import { getEncodedAnalyticsFilters } from "../../actions/analytics";

// constants
import { PRESET_TYPES, DATE_TYPES, COMPARE_DATE_TYPES } from "../../components/_commons/NewDateCompareFilter";

const AnalyticsContainer = ({ analyticsFiltersState, access }) => {
	const { pathname, search } = useLocation();
	const query = useMemo(() => new URLSearchParams(search), [search]);
	const { appliedFilters, appliedDateFilter } = analyticsFiltersState;
	const firstRender = useRef(true);
	const [urlToBeUpdated, setUrlToBeUpdated] = useState(true);
	const [stateToBeUpdated, setStateToBeUpdated] = useState(true);

	const encodeUrlParams = () => {
		// get encoded filters
		const encodedFilters = getEncodedAnalyticsFilters();

		// check for metric param in URL
		const metric = query.get("metric");

		// check for breadcrumbs param in URL
		const bc = query.get("bc");

		// analytics filters state need not be updated after encoding
		setStateToBeUpdated(false);

		// update URL with metric and new filters
		history.push(
			`${pathname}?${metric ? `metric=${metric}&` : ""}${encodedFilters ? `filters=${encodedFilters}&` : ""}${
				bc ? `bc=${bc}` : ""
			}`
		);
	};

	const decodeUrlParams = (filters) => {
		// decode and parse filters from url
		const decodedFilters = decodeURIComponent(filters);
		const parsedFilters = jsonParser(decodedFilters);

		// get filters
		const applFilters = {
			...appliedFilters,
			brand_id: parsedFilters?.brand_id?.split(",") || ["all"],
			location_id: parsedFilters?.location_id?.split(",") || ["all"],
			platform_names: parsedFilters?.platform_names?.split(",") || ["all"]
		};

		// get date filter
		const applDateFilter = { ...appliedDateFilter };
		if (parsedFilters?.dateFilter?.current) {
			const dateFilter = PRESET_TYPES.find((type) => type.value === parsedFilters?.dateFilter?.current);
			applDateFilter.current = {
				dateFilter: dateFilter ? dateFilter.value : parsedFilters?.dateFilter?.current,
				dateTypeSelected: dateFilter ? DATE_TYPES[0] : DATE_TYPES[1],
				presetTypeSelected: dateFilter ? dateFilter : PRESET_TYPES[1],
				rangeStartDate: !dateFilter ? moment(parsedFilters?.dateFilter?.current?.split(",")?.[0]) : undefined,
				rangeEndDate: !dateFilter ? moment(parsedFilters?.dateFilter?.current?.split(",")?.[1]) : undefined
			};
		}
		if (parsedFilters?.dateFilter?.compare) {
			applDateFilter.compare = {
				dateFilter: parsedFilters?.dateFilter?.compare,
				dateTypeSelected: COMPARE_DATE_TYPES[2],
				rangeStartDate: moment(parsedFilters?.dateFilter?.compare?.split(",")?.[0]),
				rangeEndDate: moment(parsedFilters?.dateFilter?.compare?.split(",")?.[1])
			};
		} else {
			// reset compare filter
			applDateFilter.compare = {
				dateFilter: "",
				dateTypeSelected: COMPARE_DATE_TYPES[0],
				rangeStartDate: undefined,
				rangeEndDate: undefined
			};
		}

		// update analytics filters state
		store.dispatch({
			type: ActionTypes.ANALYTICS_FILTERS_STATE_CHANGE,
			payload: {
				currentFilters: applFilters,
				appliedFilters: applFilters,
				initialFiltersFromUrl: applFilters,
				currentDateFilter: applDateFilter,
				appliedDateFilter: applDateFilter
			}
		});

		// url need not be updated after decoding
		setUrlToBeUpdated(false);
	};

	const getUrlParams = () => {
		const filters = query.get("filters");
		if (filters && stateToBeUpdated) {
			decodeUrlParams(filters);
		} else {
			encodeUrlParams();
		}
	};

	useEffect(() => {
		if (!pathname.match(/\/analytics$/g) && !pathname.includes("/home") && stateToBeUpdated) {
			getUrlParams();
		}
	}, [pathname, search]);

	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
			return;
		}
		if (!pathname.match(/\/analytics$/g) && !pathname.includes("/home") && urlToBeUpdated) {
			encodeUrlParams();
		}
	}, [
		Object.keys(appliedFilters).reduce((acc, f) => acc + appliedFilters[f]?.join(""), ""),
		...Object.keys(appliedDateFilter).reduce((arr, f) => [...arr, appliedDateFilter[f].dateFilter], [])
	]);

	useEffect(() => {
		if (!urlToBeUpdated) {
			setUrlToBeUpdated(true);
		}
		if (!stateToBeUpdated) {
			setStateToBeUpdated(true);
		}
	}, [urlToBeUpdated, stateToBeUpdated]);

	return (
		<div className="analytics-container">
			<Route exact path="/analytics/home" component={Home} />
			{(access?.isSalesAnalysis || access?.isMarketing || access?.isNonHqAnalytics) && (
				<>
					<Route exact path="/analytics/revenue" component={Revenue} />
					<Route exact path="/analytics/revenue/list/:entity/:query" component={EntityList} />
					<Route exact path="/analytics/revenue/view/:entity/:name/:id" component={EntityDetail} />
				</>
			)}
			{(access?.isSalesAnalysis || access?.isMarketing || access?.isNonHqAnalytics) && (
				<>
					<Route exact path="/analytics/orders" component={Orders} />
					<Route exact path="/analytics/orders/list/:entity/:query" component={EntityList} />
					<Route exact path="/analytics/orders/view/:entity/:name/:id" component={EntityDetail} />
				</>
			)}
			{(access?.isOperationsAnalysis || access?.isMarketing || access?.isNonHqAnalytics) && (
				<Route exact path="/analytics/operations" component={Operations} />
			)}
			{(access?.isSalesAnalysis || access?.isMarketing || access?.isNonHqAnalytics) && (
				<>
					<Route exact path="/analytics/catalogue" component={Catalogue} />
					<Route exact path="/analytics/catalogue/list/:entity/:query" component={EntityList} />
					<Route exact path="/analytics/catalogue/view/:entity/:name/:id" component={EntityDetail} />
				</>
			)}
			<Route exact path="/analytics/detail/list/:entity/:query/:id" component={EntityList} />

			<Route exact path="/analytics" render={() => <Redirect to="/analytics/home" />} />
		</div>
	);
};
export default connect((store) => ({
	analyticsFiltersState: store.analyticsFiltersState,
	access: store.login.loginDetail.access
}))(AnalyticsContainer);
