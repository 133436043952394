import React, { useEffect, useState, useRef } from "react";

// components
import Header from "../components/OnboardingBrand/Header";
import CreateBrand from "../components/OnboardingBrand/CreateBrand";
import ViewBrands from "../components/OnboardingBrand/ViewBrands";
import BrandCreationSuccess from "../components/OnboardingBrand/BrandCreationSuccess";
import Footer from "../components/OnboardingBrand/Footer";
import { ProgressBar } from "../components/_commons/ProgressBar";

// utils
import { getPrinterCompatibleImages, dataURLtoFile, generateUniqueId, scroll } from "../atlas-utils";
import history from "../history";

// client
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import { CREATE_BRAND } from "../graphql/brands";

// third party
import { useLocation } from "react-router-dom";

const BRAND_CREATION_STEPS = ["Create Brand", "View Brand", "Success"];

function OnBoardingBrands() {
	const [origImgFile, setOrigImgFile] = useState(null);
	const [brandName, setBrandName] = useState("");
	const [currentStep, setCurrentStep] = useState(BRAND_CREATION_STEPS[0]);
	const [loading, setLoading] = useState(false);
	const canvasSaveRef = useRef();
	const previewCanvasRef = useRef();
	const imgRenderRef = useRef();
	const location = useLocation();

	useEffect(() => {
		scroll({ top: 0, left: 0 });
	}, [location.pathname]);

	const addAnotherBrandCTA = () => {
		setOrigImgFile(null);
		setBrandName("");
		setLoading(false);
		canvasSaveRef.current = null;
		previewCanvasRef.current = null;
		imgRenderRef.current = null;
		setCurrentStep(BRAND_CREATION_STEPS[0]);
	};

	const handleBack = () => {
		const currentStepIndex = BRAND_CREATION_STEPS.indexOf(currentStep);
		if (currentStepIndex === 0) {
			history.push("/onboarding");
		} else {
			setCurrentStep(BRAND_CREATION_STEPS[currentStepIndex - 1]);
		}
	};

	const handleContinue = async () => {
		const currentStepIndex = BRAND_CREATION_STEPS.indexOf(currentStep);
		if (currentStepIndex === 0) {
			if (previewCanvasRef.current) {
				canvasSaveRef.current = dataURLtoFile(
					previewCanvasRef.current.toDataURL(),
					generateUniqueId() + "_brand_logo.png"
				);
				imgRenderRef.current = previewCanvasRef.current.toDataURL();
			}
			setLoading(true);
			const success = await handleCreateBrand();
			setLoading(false);
			if (!success) {
				return;
			}
		}
		setCurrentStep(BRAND_CREATION_STEPS[currentStepIndex + 1]);
	};

	const handleCreateBrand = async () => {
		try {
			if (brandName?.trim() === "") {
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Brand name is required",
						timeout: 3000,
						error: true
					}
				});
				return false;
			}

			const variables = {};
			// get image formats to support printer compatibility
			if (origImgFile) {
				const { base64Image, starLineImage, escPosImage } = await getPrinterCompatibleImages(origImgFile);
				if (base64Image && starLineImage && escPosImage) {
					variables.base64Image = base64Image;
					variables.starLineImage = starLineImage;
					variables.escPosImage = escPosImage;
				} else {
					store.dispatch({
						type: "SHOW_GLOBAL_MESSAGE",
						payload: {
							message: "Image size is too large, please keep it below 1 MB",
							timeout: 3000,
							error: true
						}
					});
					return false;
				}
			}

			if (canvasSaveRef.current) {
				variables.image = canvasSaveRef.current;
			}
			variables.name = brandName;
			variables.locationIds = [];

			const resp = await client.mutate({
				mutation: CREATE_BRAND,
				variables
			});
			const success = resp?.data?.saveBrand?.status?.success;
			if (!success) {
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Brand creation failed",
						timeout: 3000,
						error: true
					}
				});
			} else {
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Brand created successfully",
						timeout: 3000,
						error: false
					}
				});
			}
			return success;
		} catch (e) {
			setLoading(false);
			store.dispatch({
				type: "SHOW_GLOBAL_MESSAGE",
				payload: {
					message: "Brand creation failed",
					timeout: 3000,
					error: true
				}
			});
			return false;
		}
	};

	return (
		<div className="onboarding-brands section-container-common">
			<Header />

			<div className="onboarding-brand-step">
				{loading ? (
					<ProgressBar maximum={100} currProgress={100} animated />
				) : (
					<React.Fragment>
						{currentStep === BRAND_CREATION_STEPS[0] && (
							<CreateBrand
								handleContinue={handleContinue}
								origImgFile={origImgFile}
								setOrigImgFile={setOrigImgFile}
								brandName={brandName}
								setBrandName={setBrandName}
								canvasSaveRef={canvasSaveRef}
								previewCanvasRef={previewCanvasRef}
								imgRenderRef={imgRenderRef}
							/>
						)}
						{currentStep === BRAND_CREATION_STEPS[1] && (
							<ViewBrands addAnotherBrandCTA={addAnotherBrandCTA} />
						)}
						{currentStep === BRAND_CREATION_STEPS[2] && <BrandCreationSuccess />}
					</React.Fragment>
				)}
			</div>
			{currentStep !== BRAND_CREATION_STEPS[2] ? (
				<Footer
					isContinueDisabled={
						currentStep === BRAND_CREATION_STEPS[0] ? (brandName?.trim() ? false : true) : false
					}
					handleBack={handleBack}
					handleContinue={handleContinue}
					isLoading={loading}
				/>
			) : null}
		</div>
	);
}

export default OnBoardingBrands;
