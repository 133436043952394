import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import FormTable from "../_commons/FormTable";
import { CATALOGUE_PLATFORMS_LOGO, NESTED_ENTITY_TYPES, TRACKING_EVENT_NAMES } from "../../client-config";
import { NestedEntityContainer } from "../_commons/NestedEntityContainer";
import { Button } from "../_commons/Button";
import Placeholder from "../_commons/Placeholder";

import { clientMenu } from "../../client-menu";

import { VALIDATE_FAILED_PUBLISH } from "../../graphql/menus";

import NotificationServices from "../../services/NotificationService";
import {
	getMenuPreviewValidationStatus,
	getMenuPublishValidationStatus,
	handleDestructiveSave
} from "../../actions/menus";
import { InfiniteLoader } from "../SiteComp";
import CreateSection from "./CreateSection";
import { trackEvent } from "../../atlas-utils";

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};
const FixErrorsDrawer = ({
	isOpen,
	menuTitle,
	menuId,
	isNested = false,
	handleDrawerClose = () => {},
	handleItemOverridesUpdate,
	selectedLocationId,
	selectedPlatform,

	menuDetailsData,
	parentSectionDetailsData,
	handleDetailsEditSection,

	isFixPublishMenuFlow = false,
	handleStepChange,

	menuPreviewValidationStatus,
	menuPublishValidationStatus,
	publishType = "instant"
}) => {
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();
	const [showReviewFixes, setShowReviewFixes] = useState(false);
	const [editSectionDetails, setEditSectionDetails] = useState({
		id: null,
		show: false,
		messaage: {}
	});
	const getNestedEntity = (entity) => {
		switch (entity) {
			case "ITEM":
				return NESTED_ENTITY_TYPES[1];
			case "CATEGORY":
				return NESTED_ENTITY_TYPES[0];
			case "OPTION":
				return NESTED_ENTITY_TYPES[6];
			case "OPTION_GROUP":
				return NESTED_ENTITY_TYPES[5];
		}
	};
	const handleNestedEntity = useCallback((toOpen = false, type, id, item) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({
				show: true,
				type,
				id,
				item
			});
		}
	}, []);

	const handleFixItemClicked = (item) => {
		if (item?.itemType !== "MISC" && item?.itemType !== "CATEGORY") {
			handleNestedEntity(true, getNestedEntity(item?.itemType), item?.itemId, item);
		} else if (item?.itemType === "CATEGORY") {
			//handle edit section flow
			// handleFixPreviewPublishEditSection &&
			// 	handleFixPreviewPublishEditSection(item);
			setEditSectionDetails({
				id: item?.itemId,
				show: true,
				message: {
					errorMsg: item?.errorMsg,
					warningMsg: item?.warningMsg
				}
			});
		}
	};

	const columns = [
		{
			title: "NAME",
			dataIndex: "item",
			render: (item) => {
				return (
					<div className="fix-error-item-list-data" onClick={() => handleFixItemClicked(item)}>
						<p className="name hyperlink hyperlink--black-color">{item?.itemName}</p>
						<p className="type">{item?.itemType}</p>
					</div>
				);
			}
		},
		{
			title: "ISSUE",
			dataIndex: "issue",
			render: (issue) => (
				<div className="fix-error-issue-list-data">
					<img
						src={
							issue?.type === "ERROR"
								? "/assets/icons/error-red-icon.svg"
								: "/assets/icons/warning-icon.svg"
						}
					/>
					<p>{issue?.message}</p>
				</div>
			)
		},
		{
			title: "PLATFORM",
			dataIndex: "platforms",
			render: (platforms) => (
				<div className="fix-error-issue-list-data">
					{platforms?.map((plt) => (
						<img src={CATALOGUE_PLATFORMS_LOGO[plt.toLowerCase()]} alt={plt} className="logo-size-adjust" />
					))}
				</div>
			)
		}
	];
	const handleClose = () => {
		handleDrawerClose && handleDrawerClose();
	};
	const handleReviewFixes = async () => {
		try {
			if (menuDetailsData?.metadata?.pendingChangesCount > 0) {
				handleDestructiveSave(menuId, "SAVE");
				trackEvent(TRACKING_EVENT_NAMES.MENU_CHANGES_SAVED, {
					type: "publish_triggered",
					no_of_changes: menuDetailsData?.metadata?.pendingChangesCount
				});
			}
			setTimeout(async () => {
				const validateFailedPublishResp = await clientMenu.mutate({
					mutation: VALIDATE_FAILED_PUBLISH,
					variables: {
						menuId: menuId
					}
				});
				if (validateFailedPublishResp?.data?.validateFailedPublish?.success) {
					NotificationServices.pushNotification({
						message: "Validation queued successfully",
						timeout: 5000,
						type: "success",
						isClosable: true,
						theme: "dark"
					});
					setShowReviewFixes(true);
				}
			}, 2000);
		} catch (e) {
			console.log(e);
		}
		trackEvent(TRACKING_EVENT_NAMES.MENU_REVERIFY, {});
	};
	let styleInfo = {
		backgroundColor: "#FFF5F5",
		borderColor: "#D64949",
		icon: "/assets/icons/error-red-icon.svg"
	};
	if (
		isFixPublishMenuFlow
			? menuPublishValidationStatus?.totalErrors === 0 && menuPublishValidationStatus?.totalWarnings > 0
			: menuPreviewValidationStatus.totalErrors === 0 && menuPreviewValidationStatus.totalWarnings > 0
	) {
		styleInfo.backgroundColor = "#FEF6E9";
		styleInfo.borderColor = "#E5B917";
		styleInfo.icon = "/assets/icons/warning-icon.svg";
	}
	return (
		<FormSidebar
			close={handleClose}
			isOpen={isOpen}
			title={`${menuTitle} Issues`}
			headerRight={
				isFixPublishMenuFlow ? (
					<Button type="secondary" onClick={handleReviewFixes}>
						Review Fixes
					</Button>
				) : null
			}
			hideActions
			isNested={isNested}
			subTitle="Make your menu compliant with platform rules to avoid publish failures"
		>
			{showReviewFixes ? (
				<ReviewFixComponent
					menuTitle={menuTitle}
					menuId={menuId}
					menuPublishValidationStatus={menuPublishValidationStatus}
					menuPreviewValidationStatus={menuPreviewValidationStatus}
					setShowReviewFixes={setShowReviewFixes}
					handleDrawerClose={handleDrawerClose}
					handleStepChange={handleStepChange}
					isFixPublishMenuFlow={isFixPublishMenuFlow}
					selectedLocationId={selectedLocationId}
					selectedPlatform={selectedPlatform}
					publishType={publishType}
				/>
			) : (
				<>
					<div className="fix-error-drawer-table-wrapper">
						<div
							className="fix-error-msg-wrapper"
							style={{
								borderLeft: `6px solid ${styleInfo.borderColor}`,
								backgroundColor: styleInfo.backgroundColor
							}}
						>
							<img src={styleInfo?.icon} />
							<p>
								{isFixPublishMenuFlow
									? `${menuPublishValidationStatus.totalErrors} Errors, ${menuPublishValidationStatus.totalWarnings} Warnings to Fix`
									: `${menuPreviewValidationStatus.totalErrors} Errors, ${menuPreviewValidationStatus.totalWarnings} Warnings to Fix`}
							</p>
						</div>
						<FormTable
							columns={columns}
							dataSource={
								isFixPublishMenuFlow
									? menuPublishValidationStatus?.itemValidationDataStructured
									: menuPreviewValidationStatus?.itemValidationDataStructured
							}
						/>
					</div>
					{nestedEntity?.show && (
						<NestedEntityContainer
							show={nestedEntity.show}
							type={nestedEntity.type}
							id={nestedEntity.id}
							closeNestedContainer={() => handleNestedEntity(false)}
							nestedRef={nestedRef}
							isFromMenuSection
							isOpen
							handleItemOverridesUpdate={handleItemOverridesUpdate}
							message={{
								errorMsg: nestedEntity?.item?.errorMsg,
								warningMsg: nestedEntity?.item?.warningMsg
							}}
						/>
					)}
					{editSectionDetails?.show && (
						<CreateSection
							isNested
							isOpen={editSectionDetails?.show}
							isEditSectionMode={true}
							menuDetailsData={menuDetailsData}
							editSectionId={editSectionDetails?.id}
							sectionsData={parentSectionDetailsData?.objects ?? []}
							handleDetailsEditSection={() => {
								setEditSectionDetails({
									show: false,
									id: null,
									message: {}
								});
								handleDetailsEditSection();
							}}
							isFromMenuSection
							message={editSectionDetails?.message}
						/>
					)}
				</>
			)}
		</FormSidebar>
	);
};
const ReviewFixComponent = ({
	menuTitle,
	menuId,
	menuPublishValidationStatus,
	menuPreviewValidationStatus,
	setShowReviewFixes,
	handleDrawerClose,
	handleStepChange,
	isFixPublishMenuFlow,
	selectedLocationId,
	selectedPlatform,
	publishType = "instant"
}) => {
	const [showSuccessPlaceholder, setShowSuccessPlaceholder] = useState(false);

	const getValidationdata = (interval) => {
		if (isFixPublishMenuFlow) {
			setTimeout(async () => {
				const res = await getMenuPublishValidationStatus(menuId, publishType !== "instant");
				if (res?.status === "FAILURE") {
					interval = 80;
					if (
						!menuPublishValidationStatus.isLoading &&
						(menuPublishValidationStatus.totalErrors > 0 || menuPublishValidationStatus.totalWarnings > 0)
					) {
						setShowReviewFixes(false);
					}
				} else if (res?.status === "PENDING") {
					if (interval < 80) {
						getValidationdata(interval + 5);
					}
				} else if (!menuPublishValidationStatus.isLoading && res?.status === "SUCCESS") {
					setShowSuccessPlaceholder(true);
				} else if (res?.status === "ERROR") {
					handleDrawerClose();
				}
			}, interval * 1000);
		} else {
			setTimeout(async () => {
				const res = await getMenuPreviewValidationStatus(menuId, selectedLocationId, selectedPlatform);
				if (res?.status === "FAILURE") {
					interval = 80;
					if (
						!menuPreviewValidationStatus.isLoading &&
						(menuPreviewValidationStatus.totalErrors > 0 || menuPreviewValidationStatus.totalWarnings > 0)
					) {
						setShowReviewFixes(false);
					}
				} else if (!menuPreviewValidationStatus.isLoading && res?.status === "SUCCESS") {
					setShowSuccessPlaceholder(true);
				} else if (res?.status === "PENDING") {
					if (interval < 80) {
						getValidationdata(interval + 5);
					}
				} else if (res?.status === "ERROR") {
					handleDrawerClose();
				}
			}, interval * 1000);
		}
	};
	useEffect(() => {
		getValidationdata(0);
	}, []);
	const handlePublishNow = () => {
		handleDrawerClose && handleDrawerClose();
		handleStepChange && handleStepChange();
	};
	const handlePreviewPublishNow = () => {
		handleDrawerClose && handleDrawerClose();
	};
	return (
		<>
			{showSuccessPlaceholder ? (
				<Placeholder
					placeholderImageUrl={"/assets/empty-states/office-meeting.svg"}
					placeholderText={"All issues fixed!"}
					placeholderSubtext={`You have successfully fixed all your errors and warnings. Your menus is ready for publish!`}
					placeholderButtonClickAction={isFixPublishMenuFlow ? handlePublishNow : handlePreviewPublishNow}
					placeholderButtonContent="Publish Now"
				/>
			) : (
				<>
					<InfiniteLoader />
					<Placeholder
						placeholderImageUrl={"/assets/empty-states/verification-in-progress.svg"}
						placeholderText={"Reviewing issue fixes"}
						placeholderSubtext={`We are reviewing all the errors and warnings you attempted to fix for ${menuTitle}.`}
					/>
				</>
			)}
		</>
	);
};
const mapStateToProps = (store) => ({
	menuPreviewValidationStatus: store.menuPreviewValidationStatus,
	menuPublishValidationStatus: store.menuPublishValidationStatus
});
export default connect(mapStateToProps)(FixErrorsDrawer);
