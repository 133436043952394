import React, { useState, useEffect } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import BasicInfo from "../components/LocationGroupEdit/BasicInfo";

// utils
import history from "../history";
import { client } from "../client";
import { store } from "../store/configureStore";
import { parseErrorMessages } from "../atlas-utils";

// actions
import { fetchLocationGroupsList } from "../actions/locationGroups";
import { ActionTypes } from "../actions/_types";

// graphql
import { CREATE_LOCATION_GROUP } from "../graphql/locationGroups";

const LocationGroupCreate = ({ hasAccess = false }) => {
	const [isFormOpen, setFormState] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [error, setError] = useState({});
	const [data, setData] = useState({
		title: "",
		description: ""
	});

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
	}, []);

	const handleCancel = () => {
		setFormState(false);
		fetchLocationGroupsList();
		setTimeout(() => {
			history.push("/location-groups");
		}, 100);
	};

	const handleForm = (field, value) => {
		setData({
			...data,
			[field]: value
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		setConfirmLoading(true);
		try {
			const variables = {
				...data
			};
			const resp = await client.mutate({
				mutation: CREATE_LOCATION_GROUP,
				variables
			});
			if (resp.data.saveLocationGroup.status.success) {
				setConfirmLoading(false);
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Location Group created",
						timeout: 5000,
						error: false
					}
				});
				store.dispatch({
					type: ActionTypes.UPDATE_LOCATION_GROUPS_LIST,
					payload: resp.data.saveLocationGroup.object
				});
				history.push(`/location-groups/edit/${resp.data.saveLocationGroup.object.id}`);
			} else {
				setConfirmLoading(false);
				setError(parseErrorMessages(resp.data.saveLocationGroup.status.messages));
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
			setConfirmLoading(false);
		}
	};

	return (
		<FormSidebar
			isOpen={isFormOpen}
			close={handleCancel}
			submit={handleSubmit}
			title="Location Group"
			subTitle="Create a new location group"
			loading={confirmLoading}
			submitTitle="Create"
			disabled={!isFormTouched}
			hideActions={!isFormTouched}
		>
			<div className="form-content">
				<BasicInfo data={data} handleForm={handleForm} validations={error.fields || {}} readOnly={!hasAccess} />
			</div>
		</FormSidebar>
	);
};
export default LocationGroupCreate;
