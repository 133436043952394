import React, { useState, useEffect, useMemo } from "react";

// components
import Authorization from "../components/DSPIntegration/Authorization";
import LocationsAndMenu from "../components/DSPIntegration/LocationsAndMenu";
import { SelectFilterCustom } from "../components/_commons/SelectFilterCustom";

// third party
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";

// utils
import { extractInitials } from "../atlas-utils";

// store
import { store } from "../store/configureStore";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchBrands } from "../actions/actions";
import { fetchDspPlatformsList } from "../actions/dspIntegration";

const DSPIntegration = ({
	match,
	dspIntegration,
	dspPlatformsList,
	brands,
	editMode = false,
	isMultibrandEnabled = false
}) => {
	const { search } = useLocation();
	const query = useMemo(() => new URLSearchParams(search), [search]);
	const { isDSPIntegrated, isValidDSP, selectedBrand } = dspIntegration;
	const { isLoading } = dspPlatformsList;
	const [tempBrandSelected, setTempBrandSelected] = useState(null);

	useEffect(() => {
		if (!dspPlatformsList?.objects?.length) {
			fetchDspPlatformsList(match?.params?.dsp);
		}
		if (isMultibrandEnabled) {
			fetchBrands("");
		}
	}, []);

	const handleBrand = (field, value) => {
		if (isDSPIntegrated) {
			setTempBrandSelected(value);
		} else {
			store.dispatch({
				type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
				payload: {
					[field]: value
				}
			});
		}
	};

	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 18 ? brand.name.slice(0, 18) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	const renderBrandSelection = (props = {}) => {
		return (
			<SelectFilterCustom
				options={brands.items}
				isLoading={brands.isLoading}
				field="selectedBrand"
				currValue={selectedBrand}
				setFilter={handleBrand}
				labelKey={"name"}
				valueKey={"id"}
				isSearchable={false}
				customLabel={true}
				customOptions={true}
				renderLabel={handleBrandsLabelOption}
				renderOptions={handleBrandsLabelOption}
				placeholder="Select brand"
				{...props}
			/>
		);
	};

	// redirect back to integrations page if dsp is not a valid platform of dspPlatformList api
	if (!isValidDSP && !isLoading) {
		return <Redirect to="/hub/integrations" />;
	}

	// if dsp is not integrated (access not granted) proceed to authorization workflow
	if (!isDSPIntegrated) {
		return (
			<div className="dsp-integration-container section-container-common">
				<Authorization
					dsp={match?.params?.dsp}
					isLoading={isLoading}
					dspIntegration={dspIntegration}
					isMultibrandEnabled={isMultibrandEnabled}
					renderBrandSelection={renderBrandSelection}
				/>
			</div>
		);
	}

	// if dsp is integrated, show locations and menu associations in a list view
	return (
		<div className="dsp-integration-container section-container-common">
			<LocationsAndMenu
				match={match}
				editMode={editMode}
				isMultibrandEnabled={isMultibrandEnabled}
				renderBrandSelection={renderBrandSelection}
				tempBrandSelected={tempBrandSelected}
				setTempBrandSelected={setTempBrandSelected}
				brands={brands}
			/>
		</div>
	);
};
const mapStateToProps = (store) => ({
	dspPlatformsList: store.dspPlatformsList,
	dspIntegration: store.dspIntegration,
	brands: store.configItems.brands,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled
});
export default connect(mapStateToProps)(DSPIntegration);
