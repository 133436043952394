import React from "react";

const Tooltip = ({ title, description, classname = "" }) => {
	return (
		<div className={`tooltiptext border ${classname}`}>
			<span className="medium-size-text tool-tip-heading">{title}</span>
			<span className="tooltip-description"> {description} </span>
		</div>
	);
};

export default Tooltip;
