import { gql } from "graphql-tag";

export const GET_DASHBOARD_METRICS_QUERY = gql`
	query getDashboardMetrics($bizId: String!, $brand: String, $city: String, $location: String, $platform: String) {
		getDashboardMetrics(bizId: $bizId, brand: $brand, city: $city, location: $location, platform: $platform) {
			totalStores
			offlineStores
			offlineStorePercentage
			offlineItemsPercentage
			missingItemDetailsPercentage
			lastUpdate
		}
	}
`;

export const GET_STORE_METRICS_QUERY = gql`
	query getStoreMetrics($bizId: String!, $brand: String, $city: String, $location: String, $platforms: [String!]!) {
		getStoreMetrics(bizId: $bizId, brand: $brand, city: $city, location: $location, platforms: $platforms) {
			totalStores
			offlineStores
			offlineStorePercentage
		}
	}
`;

export const GET_LOCATION_AVAILABILITY_METRICS_QUERY = gql`
	query getLocationAvailabilityMetrics(
		$bizId: String!
		$brand: String
		$city: String
		$location: String
		$platform: String
	) {
		getLocationAvailabilityMetrics(
			bizId: $bizId
			brand: $brand
			city: $city
			location: $location
			platform: $platform
		) {
			totalLocations
			offlineLocations
			partialOfflineLocations
			offlineLocationsPercentage
			partialOfflineLocationsPercentage
		}
	}
`;

export const GET_ITEM_AVAILABAILITY_METRICS_QUERY = gql`
	query getItemAvailabilityMetrics(
		$bizId: String!
		$brand: String
		$city: String
		$location: String
		$platforms: [String!]!
		$items: String
		$category: String
	) {
		getItemAvailabilityMetrics(
			bizId: $bizId
			brand: $brand
			city: $city
			location: $location
			platforms: $platforms
			items: $items
			category: $category
		) {
			totalItems
			offlineItems
			partialOfflineItems
			offlineItemsPercentage
			partialOfflineItemsPercentage
		}
	}
`;

export const GET_LOCATION_AVAILABILITY_TABLE_METRICS = gql`
	query getLocationAvailabilityMatrix(
		$bizId: String!
		$brand: String
		$city: String!
		$limit: Int
		$location: String
		$offset: Int
		$platforms: [String!]!
		$sortBy: String
		$status: String!
		$sort: SortOnPlatform
		$search: String
	) {
		getLocationAvailabilityMatrix(
			bizId: $bizId
			platforms: $platforms
			status: $status
			sortBy: $sortBy
			brand: $brand
			limit: $limit
			offset: $offset
			city: $city
			location: $location
			sort: $sort
			search: $search
		) {
			meta {
				totalCount
				lastUpdate
			}
			data
		}
	}
`;

export const GET_ITEM_AVAILABILITY_TABLE_ITEMS_METRICS = gql`
	query getItemAvailabilityMatrix(
		$bizId: String!
		$brand: String
		$location: String
		$city: String!
		$status: String!
		$offset: Int
		$limit: Int
		$platforms: [String!]!
		$search: String
		$view: String!
		$sortBy: String
		$sort: SortOnPlatform
		$category: String
		$items: String
	) {
		getItemAvailabilityMatrix(
			bizId: $bizId
			brand: $brand
			location: $location
			city: $city
			status: $status
			offset: $offset
			limit: $limit
			platforms: $platforms
			search: $search
			view: $view
			sortBy: $sortBy
			sort: $sort
			category: $category
			items: $items
		) {
			meta {
				totalCount
				lastUpdate
			}
			data
		}
	}
`;

export const GET_SPECIFIC_ITEM_DETAILS = gql`
	query getItemDetails(
		$bizId: String!
		$itemName: String!
		$brand: String
		$platforms: [String!]!
		$sortBy: String
		$offset: Int
		$limit: Int
		$city: String!
		$status: String!
		$location: String
		$sort: SortOnPlatform
		$search: String
	) {
		getItemDetails(
			bizId: $bizId
			itemName: $itemName
			brand: $brand
			platforms: $platforms
			sortBy: $sortBy
			offset: $offset
			limit: $limit
			city: $city
			status: $status
			location: $location
			sort: $sort
			search: $search
		) {
			meta {
				totalCount
				lastUpdate
			}
			data
		}
	}
`;

export const GET_MENU_CATERGORIES_FILTER_VALUES = gql`
	query getMenuCategories($bizId: String!) {
		getMenuCategories(bizId: $bizId)
	}
`;

export const REMOVE_IMPORTANT_ITEM_MUTATION = gql`
	mutation removeImportantItem($itemId: String!) {
		removeImportantItem(itemId: $itemId)
	}
`;

export const VISIT_HISTORY_QUERY = gql`
	query getVisitHistoryForBiz($bizId: String!) {
		getVisitHistoryForBiz(bizId: $bizId) {
			bizId
			hasVisitedItemsAvailability
			hasVisitedItemsIssue
			id
		}
	}
`;

export const CREATE_IMPORTANT_ITEM_MUTATION = gql`
	mutation createImportantItem($bizId: String!, $brandId: String, $name: String!) {
		createImportantItem(input: { bizId: $bizId, brandId: $brandId, name: $name }) {
			id
			brandId
			name
			bizId
		}
	}
`;

export const CREATE_OR_UPDATE_HISTORY = gql`
	mutation createOrUpdateVisitHistory(
		$bizId: String!
		$hasVisitedItemsAvailability: Boolean
		$hasVisitedItemsIssue: Boolean
	) {
		createOrUpdateVisitHistory(
			input: {
				bizId: $bizId
				hasVisitedItemsAvailability: $hasVisitedItemsAvailability
				hasVisitedItemsIssue: $hasVisitedItemsIssue
			}
		) {
			bizId
			hasVisitedItemsAvailability
			hasVisitedItemsIssue
			id
		}
	}
`;

export const ITEM_ISSUES_METRICS = gql`
	query getItemsIssuesMetrics(
		$bizId: String!
		$brand: String
		$city: String
		$platforms: [String!]!
		$location: String
		$items: String
		$category: String
	) {
		getItemsIssuesMetrics(
			bizId: $bizId
			brand: $brand
			city: $city
			platforms: $platforms
			location: $location
			items: $items
			category: $category
		) {
			totalItems
			totalItemsWithIssues
			noMissingDetailsPercentage
			missingImagePercentage
			missingImageAndDescriptionPercentage
			missingDescriptionPercentage
		}
	}
`;

export const ITEM_ISSUES_INFO_MATRIX = gql`
	query getItemsIssuesInfo(
		$bizId: String!
		$brand: String
		$location: String
		$city: String!
		$offset: Int
		$platforms: [String!]!
		$limit: Int
		$sortBy: String
		$category: String
		$items: String
		$issueType: String
		$search: String
		$sort: SortOnPlatform
	) {
		getItemsIssuesInfo(
			bizId: $bizId
			brand: $brand
			location: $location
			city: $city
			offset: $offset
			limit: $limit
			platforms: $platforms
			sortBy: $sortBy
			category: $category
			items: $items
			issueType: $issueType
			sort: $sort
			search: $search
		) {
			meta {
				totalCount
				lastUpdate
			}
			data
		}
	}
`;

export const DETAILS_OF_ITEM_ISSUE = gql`
	query getItemIssuesDetails(
		$bizId: String!
		$brand: String
		$location: String
		$city: String!
		$itemName: String!
		$platforms: [String!]!
		$issueType: String
		$category: String
		$limit: Int
		$offset: Int
		$search: String
		$sortBy: String
		$sort: SortOnPlatform
	) {
		getItemIssuesDetails(
			bizId: $bizId
			brand: $brand
			location: $location
			city: $city
			itemName: $itemName
			platforms: $platforms
			issueType: $issueType
			category: $category
			limit: $limit
			offset: $offset
			search: $search
			sortBy: $sortBy
			sort: $sort
		) {
			meta {
				totalCount
				lastUpdate
			}
			data
		}
	}
`;

export const ACTIVITY_LOG_LOCATION_AVAILABILITY = gql`
	query getActivityLogForLocationAvailability(
		$bizId: String!
		$brand: String
		$location: String!
		$status: String!
		$platform: String!
		$limit: Int
		$offset: Int
		$time: String!
		$search: String
	) {
		getActivityLogForLocationAvailability(
			bizId: $bizId
			brand: $brand
			location: $location
			status: $status
			platform: $platform
			limit: $limit
			offset: $offset
			time: $time
			search: $search
		) {
			meta {
				totalCount
				lastUpdate
			}
			data {
				brand {
					id
					name
				}
				platform {
					id
					name
				}
				location {
					id
					name
				}
				status
				timestamp
			}
		}
	}
`;
export const ACTIVITY_LOG_ITEM_AVAILABILITY = gql`
	query getActivityLogForItemAvailability(
		$bizId: String!
		$brand: String
		$location: String!
		$status: String!
		$platform: String!
		$limit: Int
		$offset: Int
		$time: String!
		$search: String
	) {
		getActivityLogForItemAvailability(
			bizId: $bizId
			brand: $brand
			location: $location
			status: $status
			platform: $platform
			limit: $limit
			offset: $offset
			time: $time
			search: $search
		) {
			meta {
				totalCount
				lastUpdate
			}
			data {
				item
				brand {
					id
					name
				}
				platform {
					id
					name
				}
				location {
					id
					name
				}
				status
				timestamp
			}
		}
	}
`;

export const GET_LOCATION = gql`
	query getLocation($id: Int) {
		store(id: $id) {
			id
			name
			merchantRefId
			address
			latitude
			longitude
			orderDeliveryRadius
			city
			associatedItemsCount
			tags
			sortOrder
			contactPhone
			gstin
			minOrderTotal
			minimumOrderDeliveryTime
			minimumOrderPickupTime
			isActive
			primaryContactPhone
			primaryContactEmail
			hideNameForUi
			isEnabledForOrdering
			orderingDisabledTemporary
			description
			seoDescription
			orderPrepTime
			busyOrderPrepTime
			excludedPlatforms {
				id
				platformName
			}
			fulfillmentModesList {
				id
				name
				nameForDisplay
			}
			paymentModesList {
				id
				name
				nameForDisplay
			}
			polygons {
				id
				coordinates
				deliveryCharge
			}
			otherPolygons {
				id
				coordinates
			}
			orderingSlots {
				id
				title
			}
			associatedPlatforms {
				id
				platform {
					id
					platformName
				}
				state
				outletUrl
				externalId
				testId
			}
			holidayHours {
				objects {
					id
					title
					date
				}
			}
			nearByLocations {
				id
				name
				city
				latitude
				longitude
			}
			brandLocations {
				id
				name
				description
				seoDescription
				merchantRefId
				orderingSlots {
					id
					title
				}
				holidayHours {
					objects {
						id
						title
						date
					}
				}
				isActive
				associatedPlatforms {
					id
					platform {
						id
						platformName
					}
					state
					outletUrl
					externalId
					testId
				}
				excludedPlatforms {
					id
					platformName
				}
				brand {
					id
					name
					image
				}
			}
		}
	}
`;

export const PERISCOPE_EARLY_ACCESS_STORES_REGISTER = gql`
	mutation saveBulkStores($input: [StoreInput!]!) {
		saveBulkStores(input: $input) {
			bizId
			city
			enabled
		}
	}
`;

export const CREATE_BIZ_SUBSCRIPTION_MUTATION = gql`
	mutation CreateBizSubscription($input: BizSubscriptionInput!) {
		createBizSubscription(input: $input) {
			isActive
		}
	}
`;

export const PERISCOPE_BIZ_SUBSCRIPTION_QUERY = gql`
	query getBizSubscription($bizId: String!) {
		getBizSubscription(bizId: $bizId) {
			isActive
		}
	}
`;

export const PERISCOPE_BIZ_WAITLIST_QUERY = gql`
	query getWaitlistForBiz($bizId: String!) {
		getWaitlistForBiz(bizId: $bizId) {
			bizId
		}
	}
`;

export const PERISCOPE_JOIN_WAITLIST = gql`
	mutation joinWaitlist(
		$bizId: String!
		$bizName: String!
		$email: String
		$mobile: String!
		$isdCode: String!
		$country: String!
	) {
		joinWaitlist(
			input: {
				bizId: $bizId
				bizName: $bizName
				email: $email
				mobile: $mobile
				isdCode: $isdCode
				country: $country
			}
		) {
			bizId
			id
		}
	}
`;

export const GET_PERISCOPE_ENABLED_CITIES = gql`
	query getPeriscopeEnabledCities($bizId: String!, $search: String!, $limit: Int!, $offset: Int!) {
		getPeriscopeEnabledCities(bizId: $bizId, search: $search, limit: $limit, offset: $offset)
	}
`;

export const GET_PERISCOPE_ENABLED_BRANDS = gql`
	query getPeriscopeEnabledBrands($bizId: String!) {
		getPeriscopeEnabledBrands(bizId: $bizId)
	}
`;

export const GET_PERISCOPE_ENABLED_LOCATIONS = gql`
	query getPeriscopeEnabledLocations($bizId: String!) {
		getPeriscopeEnabledLocations(bizId: $bizId)
	}
`;
