import React from "react";

// components
import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/atoms/text";
import Breadcrumbs from "../_commons/Breadcrumbs";

// util
import history from "../../history";

function Header() {
	return (
		<div className="onboarding-brand-header">
			<Breadcrumbs
				connectedLinks={[
					{
						title: "Setup",
						onClick: () => history.push("/onboarding")
					}
				]}
			/>

			<Text variant={TextVariant.HEADING2}>Create Brand</Text>
		</div>
	);
}

export default Header;
