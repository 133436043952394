import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import SpecificItemLocationCell from "../../../components/Periscope/ItemAvailability/SpecificItemLocationCell";
import SpecificItemPlatfromCell from "../../../components/Periscope/ItemAvailability/SpecificItemPlatfromCell";
import { connect } from "react-redux";
import SkeletonLoader from "../../../components/Periscope/SkeletonLoader";
import SpecificItemTableHead from "../../../components/Periscope/ItemAvailability/SpecificItemTableHead";
import { Paginator } from "../../../components/_commons/Paginator";
import { store } from "../../../store/configureStore";
import { scroll } from "../../../atlas-utils";
import { ActionTypes } from "../../../actions/_types";

const SelectedItemTable = ({
	periscopeSpecificItemAvailabilityItemData,
	defaultPlatforms,
	periscopeSpecificItemAvailabilityItemState,
	tableRef
}) => {
	const data = periscopeSpecificItemAvailabilityItemData?.data?.objects?.data;
	const dataCount = periscopeSpecificItemAvailabilityItemData?.data?.objects?.meta?.totalCount;
	const isLoading = periscopeSpecificItemAvailabilityItemData?.loading;
	const error = periscopeSpecificItemAvailabilityItemData?.error;
	const appliedPlatformNames = periscopeSpecificItemAvailabilityItemState?.appliedFilters?.platform_names;
	const offset = periscopeSpecificItemAvailabilityItemState?.offset;
	const limit = periscopeSpecificItemAvailabilityItemState?.limit;
	const handlePagination = async (page) => {
		const offset = (page - 1) * limit;
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_ITEM_FILTERS,
			payload: { offset: offset }
		});

		if (tableRef.current) {
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		}
	};

	const handlePageSize = async (field, size) => {
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_ITEM_FILTERS,
			payload: { limit: size.value }
		});
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_ITEM_FILTERS,
			payload: { offset: 0 }
		});
		if (tableRef.current) {
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		}
	};

	const columns = useMemo(() => {
		return Object.keys(data?.[0] || {}).map((column) => ({
			Header: column,
			accessor: column,
			enableRowSpan: column == "location",
			Cell: (props) => {
				if (column == "location") {
					return (
						<>
							<SpecificItemLocationCell props={props} />
						</>
					);
				} else {
					return (
						<>
							<SpecificItemPlatfromCell props={props} />
						</>
					);
				}
			}
		}));
	}, [data]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, rowSpanHeaders } = useTable(
		{ columns, data },
		useSortBy
	);

	const currentPlatforms =
		appliedPlatformNames?.length > 0 && appliedPlatformNames?.[0] !== "all"
			? appliedPlatformNames
			: defaultPlatforms;
	const totalPercent = 100 / currentPlatforms.length;
	const platformCount = useMemo(() => String(`${totalPercent}%`), [totalPercent]);
	const tableData = useMemo(() => {
		return ["location", ...currentPlatforms];
	}, [currentPlatforms]);

	const dispatchSortFunction = (sort = null) => {
		if (sort == null) {
			store.dispatch({
				type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_ITEM_INITIAL_STATE_SORT
			});
		} else {
			store.dispatch({
				type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_ITEM_FILTERS,
				payload: sort
			});
		}
	};

	let displaySortImages = tableData.length > 2;
	const handleClickSort = (column) => {
		if (displaySortImages && column !== "location") {
			store.dispatch({
				type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_ITEM_FILTERS,
				payload: { offset: 0 }
			});
			const { sort } = periscopeSpecificItemAvailabilityItemState || {};
			let order = "";
			if (sort?.field === column) {
				order = sort?.order === "asc" ? "desc" : sort?.order === "desc" ? null : "asc";
			} else {
				order = "asc";
			}
			const newSort = order ? { sort: { field: column, order } } : null;
			dispatchSortFunction(newSort);
		}
	};

	return (
		<div className="selected-item-table-div">
			{(appliedPlatformNames?.[0] == "all" || appliedPlatformNames?.length > 0) &&
				defaultPlatforms?.length > 0 &&
				tableData &&
				tableData.length > 1 && (
					<>
						<table className="selected-item-table-display" {...getTableProps()}>
							<thead className="selectedissue-thead">
								<tr className="selectedissue-firstrow">
									{tableData.map((data, index) => {
										return (
											<td
												key={index}
												onClick={() => handleClickSort(data)}
												style={{
													...(data !== "location" && { width: `${platformCount}` }),
													...(displaySortImages ? { cursor: "pointer" } : {})
												}}
												className={`${data == "location" ? "location-div" : "platform-div"}`}
											>
												<SpecificItemTableHead
													displaySortImages={displaySortImages}
													data={data}
													periscopeSpecificItemAvailabilityItemState={
														periscopeSpecificItemAvailabilityItemState
													}
												/>
											</td>
										);
									})}
								</tr>
							</thead>
							{!isLoading && !error ? (
								<>
									<tbody
										data-testid="selectedItem-id"
										{...getTableBodyProps()}
										style={{ borderBottom: "1px solid #EAEAEA" }}
									>
										{rows.map((row, index) => {
											prepareRow(row);
											return (
												<>
													<tr {...row.getRowProps()} key={index}>
														{row.cells.map((cell, index) => {
															const total = cell.value.total;
															const available = cell.value.available;
															let displayImage;
															let classname = "";
															if (total == available && total !== 0 && available !== 0) {
																classname = "specificitem-online";
																displayImage = (
																	<img src="/assets/periscope/online.svg" />
																);
															} else if (total == 0) {
																classname = "specificitem-partially-offline";
																displayImage = (
																	<img src="/assets/periscope/partiallyOffline.svg" />
																);
															} else {
																classname = "specificitem-offline";
																displayImage = (
																	<img src="/assets/periscope/offline.svg" />
																);
															}
															return (
																<td
																	key={index}
																	{...cell.getCellProps()}
																	style={{
																		...(cell.column.Header !== "location"
																			? {
																					borderBottom: "1px dashed #EAEAEA",
																					width: `${platformCount}`
																			  }
																			: {
																					border: "1px solid #EAEAEA"
																			  })
																	}}
																	className={`selecteditem-tabledata ${
																		cell.column.Header === "location"
																			? "selecteditem-location-display"
																			: `${classname} selecteditem-platform-display `
																	}`}
																>
																	{cell.render("Cell")}
																</td>
															);
														})}
													</tr>
												</>
											);
										})}
									</tbody>
									<tfoot>
										<tr>
											<td colSpan="100%" className="paginator-location">
												<Paginator
													limit={limit}
													offset={offset}
													count={dataCount || 0}
													showPageSize={true}
													goToPage={handlePagination}
													setPageSize={handlePageSize}
												/>
											</td>
										</tr>
									</tfoot>
								</>
							) : (
								<> </>
							)}
						</table>

						{isLoading || error ? (
							<Loader tableData={tableData} platformCount={platformCount} error={error} />
						) : (
							<></>
						)}
					</>
				)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	periscopeSpecificItemAvailabilityItemData: state.periscopeSpecificItemAvailabilityItemData,
	periscopeSpecificItemAvailabilityItemState: state.periscopeSpecificItemAvailabilityItemState
});

export default connect(mapStateToProps)(SelectedItemTable);

const Loader = ({ tableData, platformCount, error = false }) => {
	let displayErrorDiv = (
		<div className="error-state">
			<div className="load-chart-div">
				<img className="load-chart-error-icon" src="/assets/periscope/loading-error.svg" />
				<div className="load-chart-info">
					<div className="chart-info">
						<span className="chart-main-text">Could not load this chart!</span>
						<span className="chart-para-info">
							We are facing some issues in fetching this data for you.
						</span>
					</div>
					<div className="try-again">
						<img className="load-again-img" src="/assets/periscope/load-again.svg" />
						<span className="try-again-text">Try Again</span>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<>
			<table className={`selecteditem-table-loader specficitem-loading-state `}>
				<tbody
					className={`sample-table  ${error == true ? "stop-moving" : ""}`}
					style={{ position: "relative" }}
				>
					{error ? displayErrorDiv : ""}
					{[...Array(7)].map((_, index) => {
						return (
							<tr key={index}>
								{tableData.map((table, indexVal) => {
									if (table !== "location") {
										return (
											<td
												key={indexVal}
												style={{ width: `${platformCount}` }}
												className="specific-platform-loader"
											>
												<SkeletonLoader classname={"platform-loader"} />
											</td>
										);
									} else {
										return (
											<td key={indexVal} className="specific-item-location-loader">
												<SkeletonLoader classname={"location-loader"} />
											</td>
										);
									}
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
};
