import React, { useState, useEffect, useCallback, useRef } from "react";

// components
import { Loading } from "./Loading";
import { ButtonIcon } from "./ButtonIcon";
import ChevronIcon from "../_icons/ChevronIcon";

// third party
import { connect } from "react-redux";
import { debounce } from "lodash";
import { Transition, config, animated as a } from "react-spring/renderprops";

// utils
import { lS } from "../../atlas-utils";

// store
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchReportsTaskList } from "../../actions/reports";

// constants
const TASK_TYPE_ACTION_MAP = {
	"export-report": fetchReportsTaskList
};

const OngoingTasks = ({ ongoingTasks, biz, user }) => {
	const [containerClass, setContainerClass] = useState("collapsed");
	const firstRender = useRef(true);
	const {
		isExpanded,
		isHidden,
		data: { objects, count }
	} = ongoingTasks;

	const handleCompletedFailedTasks = useCallback(
		debounce(() => {
			// check for completed/failed tasks and remove them from the list
			if (objects?.filter((task) => ["completed", "failed"].includes(task.status))?.length) {
				const updatedObjects = objects.filter((task) => !["completed", "failed"].includes(task.status));
				store.dispatch({
					type: ActionTypes.UPDATE_ONGOING_TASKS_STATE,
					payload: {
						data: {
							objects: updatedObjects,
							count: updatedObjects.length
						}
					}
				});
			}
		}, 3000),
		[objects]
	);

	const handleHideOngoingTasks = (hide = false) => {
		// if there are no tasks, hide the component
		if (hide || objects.length === 0) {
			store.dispatch({
				type: ActionTypes.UPDATE_ONGOING_TASKS_STATE,
				payload: {
					isExpanded: false
				}
			});
			if (containerClass === "expanded") {
				setContainerClass("collapsing");
			}
			setTimeout(() => {
				store.dispatch({
					type: ActionTypes.UPDATE_ONGOING_TASKS_STATE,
					payload: {
						isHidden: true
					}
				});
				setContainerClass("collapsed");
			}, 400);
		}
	};

	const handleShowOngoingTasks = () => {
		// if there are ongoing tasks, show the component
		if (objects.length > 0 && isHidden && !isExpanded) {
			store.dispatch({
				type: ActionTypes.UPDATE_ONGOING_TASKS_STATE,
				payload: {
					isHidden: false
				}
			});
			setTimeout(() => {
				setContainerClass("expanding");
			}, 500);
			setTimeout(() => {
				store.dispatch({
					type: ActionTypes.UPDATE_ONGOING_TASKS_STATE,
					payload: {
						isExpanded: true
					}
				});
			}, 800);
		}
	};

	const handleExpandOngoingTasks = () => {
		setContainerClass(isExpanded ? "collapsing" : "expanding");
		setTimeout(
			() => {
				store.dispatch({
					type: ActionTypes.UPDATE_ONGOING_TASKS_STATE,
					payload: {
						isExpanded: !isExpanded
					}
				});
			},
			isExpanded ? 0 : 250
		);
	};

	const updateOngoingTasksStatus = (tasksByType = {}) => {
		// for each task type, call action to check and update its ongoing tasks
		Object.keys(tasksByType).forEach(async (type) => {
			await TASK_TYPE_ACTION_MAP[type](tasksByType[type]);
		});
	};

	const syncOngoingTasks = () => {
		// update ongoing tasks in the store on first render
		if (firstRender.current) {
			const tasks = lS.get("ongoingTasks")?.[`${biz.id}_${user.id}`] || { objects: [], count: 0 };
			store.dispatch({
				type: ActionTypes.UPDATE_ONGOING_TASKS_STATE,
				payload: {
					data: { ...tasks }
				}
			});
			if (tasks?.objects?.length > 0) {
				handleShowOngoingTasks();

				// group ongoing tasks by 'type' and check for any status change or any update for the tasks
				const tasksByType = {};
				tasks.objects.forEach((task) => {
					tasksByType[task.type] = [...(tasksByType[task.type] ?? []), task];
				});
				setTimeout(() => {
					updateOngoingTasksStatus(tasksByType);
				}, 3000);
			}
			firstRender.current = false;
			return;
		}
		// update ongoing tasks in the local storage
		const tasks = {
			...(lS.get("ongoingTasks") ?? {}),
			[`${biz.id}_${user.id}`]: { objects, count }
		};
		lS.set("ongoingTasks", tasks);
	};

	const checkAndUpdateOngoingTasks = useCallback(() => {
		handleCompletedFailedTasks();
		handleHideOngoingTasks();
	}, [handleCompletedFailedTasks]);

	useEffect(() => {
		setTimeout(() => {
			handleShowOngoingTasks();
		}, 1000);
	}, [objects?.length]);

	useEffect(() => {
		checkAndUpdateOngoingTasks();
		syncOngoingTasks();
	}, [objects]);

	useEffect(() => {
		if (containerClass === "expanding") {
			setTimeout(() => setContainerClass("expanded"), 600);
		}
		if (containerClass === "collapsing") {
			setTimeout(() => setContainerClass("collapsed"), 600);
		}
	}, [containerClass]);

	return (
		<Transition
			native
			initial={null}
			from={{ transform: "translate(450px, 0px)" }}
			enter={{ transform: "translate(0px, 0px)" }}
			leave={{ transform: "translate(450px, 0px)" }}
			items={!isHidden}
			config={{ ...config.stiff, ...{ duration: 500 } }}
		>
			{(show) =>
				show &&
				((containerProps) => (
					<a.div className={`ongoing-tasks-container ${containerClass}`} style={{ ...containerProps }}>
						<div className="header">
							<div className="title" onClick={handleExpandOngoingTasks}>
								<div>Ongoing Tasks</div>
								<ChevronIcon styles={{ rotate: !isExpanded ? "-180deg" : "0deg" }} color="#FFFFFF" />
							</div>
							<div className="hide" onClick={() => handleHideOngoingTasks(true)}>
								<ButtonIcon icon="close" color="#FFFFFF" />
							</div>
							<ButtonIcon icon="report" color="#FFFFFF" clickHandler={handleExpandOngoingTasks} />
							{count > 0 && <div className="count">{count}</div>}
						</div>
						<Transition
							native
							initial={null}
							from={{ height: 0 }}
							enter={{ height: "auto" }}
							leave={{ height: 0, delay: 0 }}
							items={isExpanded}
							config={{ ...config.stiff, ...{ duration: 150 } }}
						>
							{(isOpen) =>
								isOpen &&
								((props) => (
									<a.div className="tasks" style={props}>
										{objects.map((task, i) => (
											<div className="task" key={i}>
												<div className="status">
													{task.status === "pending" ? (
														<Loading circular filled color="#FFFFFF" />
													) : task.status === "completed" ? (
														<ButtonIcon icon="success-circle" color="#0DA125" />
													) : task.status === "failed" ? (
														<ButtonIcon icon="error-circle" color="#D64949" />
													) : null}
												</div>
												<div className="name">{task.name}</div>
											</div>
										))}
										{objects.length === 0 && <div className="no-tasks">No ongoing tasks</div>}
									</a.div>
								))
							}
						</Transition>
					</a.div>
				))
			}
		</Transition>
	);
};
export default connect((store) => ({
	ongoingTasks: store.ongoingTasks,
	biz: store.login.loggedInbizDetail,
	user: store.login.loginDetail
}))(OngoingTasks);
