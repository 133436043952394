import React from "react";

export const Loading = ({ inlineStyle = undefined, circular = false, filled = false, color = "#2f58f2" }) => {
	if (circular) {
		return (
			<div className={"loader-circular" + (filled ? " filled" : "")}>
				<svg viewBox="22 22 44 44">
					<circle cx="44" cy="44" r="19" fill="none" stroke={color} strokeWidth="5"></circle>
				</svg>
			</div>
		);
	}
	return (
		<div className="loader-backdrop" style={inlineStyle}>
			<div className="loading-indicator-container">
				<div className="loading-indicator loading-indicator-in" />
				<div className="loading-indicator loading-indicator-out" />
			</div>
		</div>
	);
};
