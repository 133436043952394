import React, { useState, useEffect, useMemo } from "react";

// components
import { Button } from "../_commons/Button";
import { Modal } from "../_commons/Modal";
import { Loading } from "../_commons/Loading";

// third party
import history from "../../history";
import { useLocation } from "react-router-dom";

// store
import { store } from "../../store/configureStore";

// utils
import { capitaliseText } from "../../atlas-utils";

// helpers
import { getAuthorizationRedirectUrl, getAuthorizationStatus } from "../../helpers/dspIntegration";

// actions
import { ActionTypes } from "../../actions/_types";
import { handleDSPAuthorization } from "../../actions/dspIntegration";

// constants
import { CATALOGUE_PLATFORMS_LOGO } from "../../client-config";
import { PLATFORM_MAP } from "../Hub/Integrations";
const ATLAS_URL = process.env.REACT_APP_ATLAS_URL;
const INIT_CARD_PROPS = {
	header: {
		isShown: true,
		text: "Authorise access to fetch store details",
		subText: "",
		icon: ""
	},
	content: {
		isShown: true
	},
	footer: {
		isShown: true,
		alignment: "right",
		primary: "Authorise",
		secondary: "Back",
		isRedirecting: false
	}
};

const Authorization = ({ dsp, isLoading = false }) => {
	const { search } = useLocation();
	const query = useMemo(() => new URLSearchParams(search), [search]);
	const [authorizationStatus, setAuthorizationStatus] = useState(undefined);
	const [cardProps, setCardProps] = useState(INIT_CARD_PROPS);
	const [modalOpen, setModalOpen] = useState(false);
	const [modalBtnLoading, setModalBtnLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [modalContent, setModalContent] = useState({
		header: "",
		message: `You will now be redirected to ${PLATFORM_MAP[dsp] || capitaliseText(dsp)} for authorisation.`,
		primary: "Proceed",
		secondary: "Cancel"
	});

	const handleCardProps = (state, message) => {
		if (state === "failed") {
			return {
				...cardProps,
				header: {
					...cardProps.header,
					text: message || "Something went wrong!",
					subText: `${PLATFORM_MAP[dsp] || capitaliseText(dsp)} integration with UrbanPiper has failed`,
					icon: "/assets/icons/icon-cross.svg"
				},
				content: {
					isShown: false
				},
				footer: {
					isShown: true,
					alignment: "center",
					primary: "Go back to Integrations",
					primaryHandler: handleCancel,
					isRedirecting: false
				}
			};
		}

		if (state === "success") {
			return {
				...cardProps,
				header: {
					...cardProps.header,
					text: message || "Authorisation Successful",
					subText: `${
						PLATFORM_MAP[dsp] || capitaliseText(dsp)
					} has been successfully integrated with UrbanPiper`,
					icon: "/assets/icons/icon-check.svg"
				},
				content: {
					isShown: false
				},
				footer: {
					isShown: true,
					alignment: "",
					primary: "",
					primaryHandler: () => {},
					isRedirecting: true
				}
			};
		}
	};

	const handleInitAuthStatus = async () => {
		const { status = "", authCode = "", message = "" } = getAuthorizationStatus(query, dsp);

		if (status === "failed") {
			setAuthorizationStatus(status);
			setCardProps(handleCardProps(status, message));
			return;
		}

		if (status === "success" && authCode) {
			// logic for saving auth code on BE
			setLoading(true);
			const resp = await handleDSPAuthorization(authCode, dsp);
			if (!resp?.status?.success) {
				// update card
				setAuthorizationStatus("failed");
				setCardProps(handleCardProps("failed", resp?.status?.messages?.[0]?.message));
				setLoading(false);
				return;
			}

			// update card
			setAuthorizationStatus("success");
			setCardProps(handleCardProps("success"));
			setLoading(false);

			// continue and redirect to location & menu association (edit view)
			setTimeout(() => handleContinue(resp), 2000);
			return;
		}

		// handle init card state
		setCardProps({
			...cardProps,
			header: {
				...cardProps.header,
				text: `Authorise on ${PLATFORM_MAP[dsp] || capitaliseText(dsp)}`,
				subText: `${
					PLATFORM_MAP[dsp] || capitaliseText(dsp)
				} is a leading ordering platform and currently supported in the UK and US`
			}
		});
	};

	useEffect(() => {
		handleInitAuthStatus();
	}, []);

	const handleAuthorizationRedirect = () => {
		// redirect
		const redirectUri = `${ATLAS_URL}/hub/integrations/${dsp}`;
		const url = getAuthorizationRedirectUrl(dsp, redirectUri);

		if (url) {
			setTimeout(() => {
				window.open(url, "_self").focus();
			}, 1000);
		}
	};

	const handleContinue = (resp) => {
		const selectedDsp = resp?.object;
		store.dispatch({
			type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
			payload: {
				isDSPIntegrated: !selectedDsp?.isExpired,
				isValidDSP: !!selectedDsp,
				platform: selectedDsp?.platform?.toLowerCase()
			}
		});
		history.push(`/hub/integrations/${dsp}/edit`);
	};

	const handleCancel = () => {
		history.push("/hub/integrations");
	};

	const handleModalAction = (primary = false) => {
		if (primary) {
			setModalBtnLoading(true);
			handleAuthorizationRedirect();
			return;
		}
		setModalBtnLoading(false);
		setModalOpen(false);
	};

	if (isLoading || loading) {
		return (
			<div className={"dsp-authorization loading"}>
				<div className="card-container">
					<div className="header">
						<div className="platforms">
							<div className="platform" title={PLATFORM_MAP[dsp] || capitaliseText(dsp)}>
								<img
									src={CATALOGUE_PLATFORMS_LOGO[dsp] || "/assets/icons/icons8-globe-40.png"}
									alt=""
								/>
							</div>
							<div className="integration">
								<img src="/assets/icons/icon-integrate.svg" alt="" />
							</div>
							<div className="platform" title="UrbanPiper">
								<img src={CATALOGUE_PLATFORMS_LOGO["urbanpiper"]} alt="" />
							</div>
						</div>
						<Loading />
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={"dsp-authorization " + (authorizationStatus ? `card-status-${authorizationStatus}` : "")}>
			<div className="card-container">
				<div className="header">
					<div className="platforms">
						<div className="platform" title={PLATFORM_MAP[dsp] || capitaliseText(dsp)}>
							<img src={CATALOGUE_PLATFORMS_LOGO[dsp] || "/assets/icons/icons8-globe-40.png"} alt="" />
						</div>
						<div className="integration">
							<img src="/assets/icons/icon-integrate.svg" alt="" />
						</div>
						<div className="platform" title="UrbanPiper">
							<img src={CATALOGUE_PLATFORMS_LOGO["urbanpiper"]} alt="" />
						</div>
					</div>
					{cardProps?.header?.icon && (
						<div className="status-icon">
							<img src={cardProps?.header?.icon} alt="" />
						</div>
					)}
					{cardProps?.header?.text && <div className="header-text">{cardProps?.header?.text}</div>}
					{cardProps?.header?.subText && <div className="header-sub-text">{cardProps?.header?.subText}</div>}
				</div>
				{cardProps?.content?.isShown && (
					<div className="content">
						<div className="description">
							With this integration you will be able to automate and control the following actions right
							from your POS —
						</div>
						<div className="benifits">
							<div className="point">
								<img src="/assets/icons/icon-catalogue.svg" alt="" />
								<div className="text">Publish your menu</div>
							</div>
							<div className="point">
								<img src="/assets/icons/icon-spoon-fork.svg" alt="" />
								<div className="text">Availability of your items and modifiers</div>
							</div>
							<div className="point">
								<img src="/assets/icons/icon-store.svg" alt="" />
								<div className="text">Store pause/unpause</div>
							</div>
							<div className="point">
								<img src="/assets/icons/icon-basket.svg" alt="" />
								<div className="text">Order injection</div>
							</div>
							<div className="point">
								<img src="/assets/icons/icon-check-grey.svg" alt="" />
								<div className="text">Acknowledge/cancel orders</div>
							</div>
							<div className="point">
								<img src="/assets/icons/icon-scooter.svg" alt="" />
								<div className="text">Track delivery agent</div>
							</div>
						</div>
					</div>
				)}
				{cardProps?.footer?.isShown && (
					<div className={`footer ${cardProps?.footer?.alignment || ""}`}>
						{cardProps?.footer?.secondary && (
							<Button type="secondary" clickHandler={handleCancel}>
								{cardProps?.footer?.secondary}
							</Button>
						)}
						{cardProps?.footer?.primary && (
							<Button
								clickHandler={
									cardProps?.footer?.primaryHandler
										? cardProps?.footer?.primaryHandler
										: () => setModalOpen(true)
								}
							>
								{cardProps?.footer?.primary}
							</Button>
						)}
						{cardProps?.footer?.isRedirecting && <div className="footer-sub-text">Redirecting...</div>}
					</div>
				)}
			</div>
			<Modal
				isOpen={modalOpen}
				close={handleModalAction}
				title="Authorisation redirect"
				showSubmitAction={true}
				submitTitle={modalContent.primary}
				submitAction={() => handleModalAction(true)}
				showCancelAction={true}
				cancelTitle={modalContent.secondary}
				submitBtnLoading={modalBtnLoading}
				showCloseIcon={false}
			>
				{modalContent.message}
			</Modal>
		</div>
	);
};
export default Authorization;
