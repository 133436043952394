import React from "react";
import HomeList from "./HomeList.js";
import ActionsRequired from "../../../components/Periscope/Dashboard/ActionsRequired";
import LocationAvailability from "../../../components/Periscope/Dashboard/LocationAvailability";
import ItemAvailability from "../../../components/Periscope/Dashboard/ItemAvailability";
import MainHeader from "../../../components/Periscope/MainHeader";

const Home = () => {
	return (
		<div className="periscope-container">
			<div className=" section-container-common-periscope periscope-dashboard ">
				<MainHeader classname={"header-periscope"} headerText="Periscope Dashboard" />
				<HomeList />
				<div className="dashboard-container-periscope">
					<div className="parent-container-peri ">
						<div className="first-div-peri">
							<div className="actions-required-peri border">
								<div className="action-div-peri">
									<p className="title-action-peri">Actions Required</p>
									<ActionsRequired />
								</div>
							</div>
							<div className="location-item-data-peri">
								<div className="location-availability-periscope">
									<LocationAvailability />
									<ItemAvailability />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
