import React, { useEffect, useState } from "react";

// graphql
import { fetchBrands } from "../../actions/actions";

// components
import MonogramGenerator from "../_commons/MonogramGenerator";
import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/atoms/text";
import InfiniteSpinner from "../_commons/InfiniteSpinner";

function ViewBrands({ addAnotherBrandCTA }) {
	const [isLoading, setIsLoading] = useState(true);
	const [brands, setBrands] = useState([]);

	useEffect(() => {
		fetchBrandsList();
	}, []);

	const fetchBrandsList = async () => {
		setIsLoading(true);
		const brands = await fetchBrands("", false);
		setBrands(brands);
		setIsLoading(false);
	};

	return (
		<div className="onboarding-view-brands">
			{isLoading ? (
				<div className="brands-list-loading">
					<InfiniteSpinner />
				</div>
			) : (
				<div className="brands-list">
					{brands.map(({ id, image, name }) => {
						return (
							<div className="brand-card" key={id}>
								{image ? (
									<img className="brand-img" src={image} alt={name} />
								) : (
									<MonogramGenerator value={name} size="xlarge" />
								)}
								<Text className="brand-title" variant={TextVariant.HEADING5}>
									{name}
								</Text>
							</div>
						);
					})}

					<div className="add-another-brand-card" onClick={addAnotherBrandCTA}>
						<img
							className="add-another-brand-icon"
							src="/assets/icons/four-square-icon.svg"
							alt="add another brand"
						/>
						<Text className="add-another-brand-text" variant={TextVariant.HEADING5}>
							Add another brand
						</Text>
					</div>
				</div>
			)}
		</div>
	);
}

export default ViewBrands;
