import React, { useEffect, useRef, useState } from "react";

// third party
import { connect } from "react-redux";

// component
import { FormSidebar } from "../_commons/FormSidebar";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Button } from "../_commons/Button";
import UploaderV2 from "../_commons/UploaderV2";
import FormTable from "../_commons/FormTable";
import { Paginator } from "../_commons/Paginator";
import { SearchFilter } from "../_commons/SearchFilter";
// client
import { store } from "../../store/configureStore";
import { clientMenu } from "../../client-menu";
import { client } from "../../client";

// graphql
import {
	ADD_RULE,
	FETCH_RULE_EDIT_ITEMS_LIST,
	FETCH_RULE_EDIT_OPTIONS_LIST,
	GET_PUBLISH_LOCATIONS_LIST,
	GET_RULE_INFO,
	UPDATE_MENU_RULE,
	REVIEW_TEMP_RULE
} from "../../graphql/menus";
import { GET_BIZ_PLATFORMS_MINI } from "../../graphql/locations";

// utils
import { printCurrency } from "../SiteComp";
import {
	generateUniqueId,
	removeProp,
	truncateText,
	snakeToCamel,
	trackEvent,
	fixedToTwoDecimal
} from "../../atlas-utils";
import Compressor from "compressorjs";
import Image from "../_commons/Image";

// services
import NotificationServices from "../../services/NotificationService";
import { CheckBox } from "../_commons/CheckBox";
import { getMenuMetaData } from "../../actions/menus";
import { Textarea } from "../_commons/Textarea";
import { TRACKING_EVENT_NAMES } from "../../client-config";
import SatismeterService from "../../services/SatismeterService";

const LOCATION_PLATFORM_SELECTOR_OPTIONS = [
	{
		label: "Location is",
		value: "location"
	},
	{
		label: "Platform is",
		value: "platform"
	}
];

const ITEM_OPTION_SELECTOR_OPTIONS = [
	{
		label: "Item",
		value: "item"
	},
	{
		label: "Modifier",
		value: "option"
	}
];

const currencySymbol = store.getState().login.loggedInbizDetail?.currencySymbol;

const ACTION_OPTION_SELECTOR_OPTIONS = [
	{
		label: "Do Not Sell",
		value: "doNotSell"
	},
	{
		label: "Change Name to",
		value: "changeName"
	},
	{
		label: (
			<span>
				Change Markup <span>{printCurrency(currencySymbol)}</span>
			</span>
		),
		value: "changeMarkupPrice"
	},
	{
		label: (
			<span>
				Change Default <span>{printCurrency(currencySymbol)}</span>
			</span>
		),
		value: "changeDefaultPrice"
	},
	{
		label: "Change Image to",
		value: "changeImage"
	},
	{
		label: "Change Description to",
		value: "changeDescription"
	}
];

const MAIN_OPTIONS_INIT_VALUES = {
	when: null,
	for: null,
	then: null,
	isPlatformsAdderVisible: null
};

const PRICING_TYPE_SELECTOR = [
	{
		label: "to Fixed Value",
		value: "TO_FIXED_AMOUNT"
	},
	{
		label: "by Amount",
		value: "BY_FIXED_AMOUNT"
	},
	{
		label: "by Percentage",
		value: "BY_PERCENTAGE"
	}
];

const PRICE_VARIATION_SYMBOLS = [
	{
		label: "Increase by",
		value: 1
	},
	{
		label: "Decrease by",
		value: -1
	}
];

const OPERATIONS_INIT_STATE = {
	changeName: null,
	changeImage: null,
	changeDescription: null,
	changeDefaultPrice: {
		subOperation: null,
		value: null,
		actionValue: 1
	},
	changeMarkupPrice: {
		subOperation: null,
		value: null,
		actionValue: 1
	},
	doNotSell: false
};

const LOCATIONS_LIST_INIT_STATE = {
	count: 0,
	objects: [],
	filters: []
};

const LOADING_STATES = {
	locations: false,
	platforms: false,
	items: false,
	options: false,
	mainView: false
};

const PRICE_OPERATIONS = {
	changeMarkupPrice: true,
	changeDefaultPrice: true
};

const VALIDATIONS_DATA = {
	locationPlf: "",
	ruleTitle: ""
};

const RulesCreation = ({
	isOpen,
	close,
	isEditMode = false,
	brandId,
	menuId,
	ruleId,
	isNested = false,
	isForOptionFixed = false,
	forOptionFixedValue = null,
	entityType,
	latestModifiedRuleDetails = {},
	setLatestModifiedRuleDetails = () => {},
	setConflictingRuleModalOpen,
	handleUpdateRuleResolveSuccess,
	menuDetailsState
}) => {
	const { menuDetailsData } = menuDetailsState;
	const isPOSmenuType = menuDetailsData?.metadata?.menuType === "POS_INTEGRATED";
	const [mainOptions, setMainOptions] = useState(MAIN_OPTIONS_INIT_VALUES);
	const [loadingStates, setLoadingStates] = useState(LOADING_STATES);

	const [selectedLocations, setSelectedLocations] = useState({});
	const [selectedPlatforms, setSelectedPlatforms] = useState([]);
	const [ruleOperations, setRuleOperations] = useState(OPERATIONS_INIT_STATE);

	const [isLocationsSelectDrawerOpen, setIsLocationsSelectDrawerOpen] = useState(false);
	const [platformsData, setPlatformsData] = useState({});
	const [attributesExtendedKeyValue, setAttributesExtendedKeyValue] = useState({});
	const [itemsList, setItemsList] = useState({});
	const [optionsList, setOptionsList] = useState({});
	const [forOptionSelectedValue, setForOptionSelectedValue] = useState(null);
	const [ruleTitle, setRuleTitle] = useState("");
	const [ruleDetails, setRuleDetails] = useState({});
	const [isFormTouched, setFormTouched] = useState(false);

	const debouncedConflictRef = useRef();
	const [revTempRuleConflict, setRevTempRuleConflict] = useState([]);
	const [doNotSell, setDoNotSell] = useState(false);
	const [validationsData, setValidationsData] = useState(VALIDATIONS_DATA);
	const isResolveConflictMode = isEditMode && latestModifiedRuleDetails?.conflicts?.length;

	const getOperationsData = (ruleOperations) => {
		const ruleMaps = {};

		Object.keys(ruleOperations).forEach((operation) => {
			if (ruleOperations[operation]) {
				if (PRICE_OPERATIONS[operation]) {
					if (
						(ruleOperations[operation]?.value || ruleOperations[operation]?.value === 0) &&
						ruleOperations[operation]?.subOperation
					) {
						ruleMaps[operation] = {
							subOperation: ruleOperations[operation]?.subOperation,
							value: ruleOperations[operation]?.actionValue
								? ruleOperations[operation]?.actionValue * ruleOperations[operation]?.value
								: ruleOperations[operation]?.value
						};
					}
				} else {
					ruleMaps[operation] = {
						value: ruleOperations[operation]
					};
				}
			}
		});
		const transformedRuleMapsArray = getTransformedRuleOperationsArray(ruleMaps);
		return transformedRuleMapsArray;
	};

	const getTransformedRuleOperationsArray = (ruleMaps = {}) => {
		let transformedRuleMapsArray = [];
		Object.keys(ruleMaps).forEach((operation) => {
			switch (operation) {
				case "changeName":
					transformedRuleMapsArray.push({
						operator: "REPLACE_BY",
						input: {
							value: ruleMaps?.changeName?.value,
							type: "STRING",
							format: null
						},
						field: "name"
					});
					break;
				case "changeImage":
					transformedRuleMapsArray.push({
						operator: "REPLACE_BY",
						input: {
							value: ruleMaps?.changeImage?.value,
							type: typeof ruleMaps?.changeImage?.value === "string" ? "STRING" : "FILE",
							format: null
						},
						field: "image_url"
					});
					break;
				case "changeDescription":
					transformedRuleMapsArray.push({
						operator: "REPLACE_BY",
						input: {
							value: ruleMaps?.changeDescription?.value,
							type: "STRING",
							format: null
						},
						field: "description"
					});
					break;
				case "changeDefaultPrice":
					if (ruleMaps?.changeDefaultPrice?.subOperation === "TO_FIXED_AMOUNT") {
						transformedRuleMapsArray.push({
							operator: "REPLACE_BY",
							input: {
								value: parseFloat(ruleMaps?.changeDefaultPrice?.value),
								type: "FLOAT",
								format: "AMOUNT"
							},
							field: "price"
						});
						break;
					} else if (ruleMaps?.changeDefaultPrice?.subOperation === "BY_FIXED_AMOUNT") {
						transformedRuleMapsArray.push({
							operator: "CHANGE_BY",
							input: {
								value: parseFloat(ruleMaps?.changeDefaultPrice?.value),
								type: "FLOAT",
								format: "AMOUNT"
							},
							field: "price"
						});
						break;
					} else if (ruleMaps?.changeDefaultPrice?.subOperation === "BY_PERCENTAGE") {
						transformedRuleMapsArray.push({
							operator: "CHANGE_BY",
							input: {
								value: parseFloat(ruleMaps?.changeDefaultPrice?.value),
								type: "FLOAT",
								format: "PERCENTAGE"
							},
							field: "price"
						});
						break;
					}
					break;
				case "changeMarkupPrice":
					if (ruleMaps?.changeMarkupPrice?.subOperation === "TO_FIXED_AMOUNT") {
						transformedRuleMapsArray.push({
							operator: "REPLACE_BY",
							input: {
								value: parseFloat(ruleMaps?.changeMarkupPrice?.value),
								type: "FLOAT",
								format: "AMOUNT"
							},
							field: "markup_price"
						});
						break;
					} else if (ruleMaps?.changeMarkupPrice?.subOperation === "BY_FIXED_AMOUNT") {
						transformedRuleMapsArray.push({
							operator: "CHANGE_BY",
							input: {
								value: parseFloat(ruleMaps?.changeMarkupPrice?.value),
								type: "FLOAT",
								format: "AMOUNT"
							},
							field: "markup_price"
						});
						break;
					} else if (ruleMaps?.changeMarkupPrice?.subOperation === "BY_PERCENTAGE") {
						transformedRuleMapsArray.push({
							operator: "CHANGE_BY",
							input: {
								value: parseFloat(ruleMaps?.changeMarkupPrice?.value),
								type: "FLOAT",
								format: "PERCENTAGE"
							},
							field: "markup_price"
						});
						break;
					}
					break;
			}
		});
		if (ruleMaps?.doNotSell?.value) {
			// sold_at will be opposite value of doNotSell, if do not sell remove all other operations
			transformedRuleMapsArray = [
				{
					operator: "REPLACE_BY",
					input: {
						value: !ruleMaps?.doNotSell?.value,
						type: "BOOLEAN",
						format: null
					},
					field: "sold_at"
				}
			];
		}
		return transformedRuleMapsArray;
	};

	const handleResolveConflictEditChanges = async () => {
		if ((selectedPlatforms || selectedLocations) && isFormTouched) {
			const ruleOperationsArr = getOperationsData(ruleOperations);

			const ruleInput = {
				name: ruleTitle,
				entityId: isForOptionFixed ? forOptionFixedValue?.id : forOptionSelectedValue?.id,
				entityType: isForOptionFixed ? entityType : mainOptions?.for?.value,
				platforms: selectedPlatforms.map((plf) => plf?.platformName.toLowerCase()),
				locations: Object.keys(selectedLocations).map((loc) => String(loc)),
				operations: ruleOperationsArr
			};
			reviewTempCall(menuId, ruleInput);
		}
	};

	const handleResolveConflictForOperationsEditChanges = async () => {
		if (ruleOperations && isFormTouched) {
			const ruleOperationsArr = getOperationsData(ruleOperations);
			const ruleInput = {
				name: ruleTitle,
				entityId: isForOptionFixed ? forOptionFixedValue?.id : forOptionSelectedValue?.id,
				entityType: isForOptionFixed ? entityType : mainOptions?.for?.value,
				platforms: selectedPlatforms.map((plf) => plf?.platformName.toLowerCase()),
				locations: Object.keys(selectedLocations).map((loc) => String(loc)),
				operations: ruleOperationsArr
			};
			reviewTempCall(menuId, ruleInput);
		}
	};

	const handleLoadingStates = (field, state) => {
		setLoadingStates((current) => ({
			...current,
			[field]: state
		}));
	};
	const handleRuleTitleChange = (e) => {
		if (!e.target.value) {
			setValidationsData((current) => ({
				...current,
				ruleTitle: "Rule title is a required field"
			}));
		} else {
			setValidationsData((current) => ({
				...current,
				ruleTitle: ""
			}));
		}
		setRuleTitle(e.target.value);
		setFormTouched(true);
	};

	const handlePlatformsSelection = (field, value) => {
		setSelectedPlatforms(value);
		setFormTouched(true);
	};

	const handleAttributesKeyValueExtension = (field, value) => {
		setAttributesExtendedKeyValue((current) => ({
			...current,
			[field]: value
		}));
	};

	const handleClose = (refresh) => {
		close(refresh);
		setMainOptions(MAIN_OPTIONS_INIT_VALUES);
		setRuleOperations(OPERATIONS_INIT_STATE);
		setIsLocationsSelectDrawerOpen(false);
		setSelectedLocations({});
		setPlatformsData({});
		setSelectedPlatforms([]);
		setAttributesExtendedKeyValue({});
		setForOptionSelectedValue(null);
		setRuleTitle("");
		setRuleDetails({});
		setFormTouched(false);
		setRevTempRuleConflict([]);
		setValidationsData(VALIDATIONS_DATA);
		setDoNotSell(false);
	};

	const reviewTempCall = async (menuId, ruleInput) => {
		try {
			const reviewTempRuleConflictResp = await clientMenu.mutate({
				mutation: REVIEW_TEMP_RULE,
				variables: {
					menuId,
					ruleInput
				}
			});
			setRevTempRuleConflict(reviewTempRuleConflictResp?.data?.reviewTempRuleConflictV2);
		} catch (e) {
			console.log(e);
		}
	};

	const fetchPlatformsList = async () => {
		handleLoadingStates("platforms", true);
		try {
			const variables = {
				filters: [
					{
						field: "is_enabled",
						value: true
					}
				],
				includeUrbanpiper: false
			};
			const respPlatforms = await client.query({
				query: GET_BIZ_PLATFORMS_MINI,
				variables,
				fetchPolicy: "no-cache"
			});

			setPlatformsData(respPlatforms?.data?.bizPlatforms ?? {});
			handleLoadingStates("platforms", false);
		} catch (e) {
			handleLoadingStates("platforms", false);
			console.log(e);
		}
	};

	const fetchItemsList = async () => {
		handleLoadingStates("items", true);
		try {
			const variables = {
				menuId,
				brandId,
				limit: 500,
				offset: 0,
				itemFilters: {},
				fetchMenuItems: true,
				searchKeyword: ""
			};
			const respItems = await clientMenu.query({
				query: FETCH_RULE_EDIT_ITEMS_LIST,
				variables,
				fetchPolicy: "no-cache"
			});

			setItemsList(respItems?.data?.items);
			handleLoadingStates("items", false);
		} catch (e) {
			handleLoadingStates("items", false);
			console.log(e);
		}
	};

	const fetchRuleDetails = async () => {
		handleLoadingStates("mainView", true);
		try {
			const variables = {
				menuId,
				ruleId
			};
			const ruleDetailsResp = await clientMenu.query({
				query: GET_RULE_INFO,
				variables,
				fetchPolicy: "no-cache"
			});
			setRuleDetails(ruleDetailsResp?.data?.ruleV2);
		} catch (e) {
			console.log(e);
		}
		handleLoadingStates("mainView", false);
	};
	const validateForm = () => {
		return (
			!ruleOperations?.changeName &&
			!ruleOperations?.changeDescription &&
			!ruleOperations?.changeImage &&
			!ruleOperations.changeDefaultPrice?.value &&
			!ruleOperations?.changeMarkupPrice?.value &&
			!ruleOperations?.doNotSell
		);
	};

	const updateRuleInfo = async () => {
		if (!ruleTitle) {
			NotificationServices.pushNotification({
				message: "Rule title is required",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			return;
		}

		if (!Object.keys(selectedLocations)?.length && !selectedPlatforms?.length) {
			NotificationServices.pushNotification({
				message: "At least a location or a platform should be selected",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			return;
		}

		if (!forOptionFixedValue && !forOptionSelectedValue) {
			NotificationServices.pushNotification({
				message: "An entity should be selected",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			return;
		}

		if (validateForm()) {
			NotificationServices.pushNotification({
				message: "Atleast one rule operation should be defined.",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			return;
		}

		handleLoadingStates("mainView", true);
		try {
			const ruleOperationsArr = getOperationsData(ruleOperations);
			const ruleInput = {
				id: ruleId,
				name: ruleTitle,
				entityId: isForOptionFixed ? forOptionFixedValue?.id : forOptionSelectedValue?.id,
				entityType: isForOptionFixed ? entityType : mainOptions?.for?.value,
				platforms: selectedPlatforms.map((plf) => plf?.platformName.toLowerCase()),
				locations: Object.keys(selectedLocations).map((loc) => String(loc)),
				operations: ruleOperationsArr
			};

			const updateRuleResp = await clientMenu.mutate({
				mutation: UPDATE_MENU_RULE,
				variables: {
					menuId,
					ruleInput
				}
			});

			if (updateRuleResp?.data?.updateRuleV2?.success) {
				getMenuMetaData(menuId);
				NotificationServices.pushNotification({
					message: "Rule configurations updated successfully!",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
				if (isResolveConflictMode) {
					handleUpdateRuleResolveSuccess && handleUpdateRuleResolveSuccess(updateRuleResp);
				}
				handleClose(true);
			} else {
				NotificationServices.pushNotification({
					message: "Failed to update rule configurations",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
				handleClose(true);
				if (setLatestModifiedRuleDetails) {
					setLatestModifiedRuleDetails({
						ruleObject: updateRuleResp?.data?.updateRuleV2?.ruleObject,
						error: updateRuleResp?.data?.updateRuleV2?.error,
						success: updateRuleResp?.data?.updateRuleV2?.success,
						conflicts: updateRuleResp?.data?.updateRuleV2?.conflicts
					});
				}
				if (
					updateRuleResp?.data?.updateRuleV2?.error === "CONFLICT_DETECTED" &&
					updateRuleResp?.data?.updateRuleV2?.conflicts?.length > 0
				) {
					setConflictingRuleModalOpen && setConflictingRuleModalOpen(true);
				}
			}
		} catch (e) {
			NotificationServices.pushNotification({
				message: "Failed to update rule configurations",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			console.log(e);
		}
		handleLoadingStates("mainView", false);
	};

	const createRule = async () => {
		if (!ruleTitle) {
			NotificationServices.pushNotification({
				message: "Rule title is required",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			return;
		}

		if (!Object.keys(selectedLocations)?.length && !selectedPlatforms?.length) {
			NotificationServices.pushNotification({
				message: "At least a location or a platform should be selected",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			return;
		}

		if (!forOptionFixedValue && !forOptionSelectedValue) {
			NotificationServices.pushNotification({
				message: "An entity should be selected",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			return;
		}
		if (validateForm()) {
			NotificationServices.pushNotification({
				message: "Atleast one rule operation should be defined.",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			return;
		}

		handleLoadingStates("mainView", true);
		try {
			const ruleOperationsArr = getOperationsData(ruleOperations);
			const ruleInput = {
				name: ruleTitle,
				entityId: isForOptionFixed ? forOptionFixedValue?.id : forOptionSelectedValue?.id,
				entityType: isForOptionFixed ? entityType : mainOptions?.for?.value,
				platforms: selectedPlatforms.map((plf) => plf?.platformName.toLowerCase()),
				locations: Object.keys(selectedLocations).map((loc) => String(loc)),
				operations: ruleOperationsArr
			};

			const addRuleResp = await clientMenu.mutate({
				mutation: ADD_RULE,
				variables: {
					menuId,
					ruleInput
				}
			});

			if (addRuleResp?.data?.addRuleV2?.success) {
				getMenuMetaData(menuId);
				NotificationServices.pushNotification({
					message: "Rule added successfully!",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
				handleClose(true);
				trackEvent(TRACKING_EVENT_NAMES.MENU_NEW_RULE_CREATED, {
					no_of_locations: Object.keys(selectedLocations)?.length,
					no_of_platforms: selectedPlatforms?.length,
					entity: isForOptionFixed ? entityType : mainOptions?.for?.value,
					attribute_modified: Object.keys(ruleOperations),
					status: "success"
				});
				// satismeter events
				SatismeterService.menuOverCatalogueEvents();
			} else {
				trackEvent(TRACKING_EVENT_NAMES.MENU_NEW_RULE_CREATED, {
					no_of_locations: Object.keys(selectedLocations)?.length,
					no_of_platforms: selectedPlatforms?.length,
					entity: isForOptionFixed ? entityType : mainOptions?.for?.value,
					attribute_modified: Object.keys(ruleOperations),
					status: "failure"
				});
				if (addRuleResp?.data?.addRuleV2?.error !== "CONFLICT_DETECTED") {
				}
				handleClose(true);
				if (setLatestModifiedRuleDetails) {
					setLatestModifiedRuleDetails({
						ruleObject: addRuleResp?.data?.addRuleV2?.ruleObject,
						error: addRuleResp?.data?.addRuleV2?.error,
						success: addRuleResp?.data?.addRuleV2?.success,
						conflicts: addRuleResp?.data?.addRuleV2?.conflicts
					});
				}
				if (
					addRuleResp?.data?.addRuleV2?.error === "CONFLICT_DETECTED" &&
					addRuleResp?.data?.addRuleV2?.conflicts?.length > 0
				) {
					setConflictingRuleModalOpen && setConflictingRuleModalOpen(true);
				}
			}
			handleLoadingStates("mainView", false);
		} catch (e) {
			// NotificationServices.pushNotification({
			// 	message: "Failed to add rule",
			// 	timeout: 5000,
			// 	type: "error",
			// 	isClosable: true,
			// 	theme: "dark",
			// });
			trackEvent(TRACKING_EVENT_NAMES.MENU_NEW_RULE_CREATED, {
				no_of_locations: Object.keys(selectedLocations)?.length,
				no_of_platforms: selectedPlatforms?.length,
				entity: isForOptionFixed ? entityType : mainOptions?.for?.value,
				attribute_modified: Object.keys(ruleOperations),
				status: "failure"
			});
			handleLoadingStates("mainView", false);
			console.log(e);
		}
	};

	const fetchOptionsList = async () => {
		handleLoadingStates("options", true);
		try {
			const variables = {
				menuId,
				limit: 500,
				offset: 0,
				searchKeyword: ""
			};
			const respOptions = await clientMenu.query({
				query: FETCH_RULE_EDIT_OPTIONS_LIST,
				variables,
				fetchPolicy: "no-cache"
			});

			setOptionsList(respOptions?.data?.options);

			handleLoadingStates("options", false);
		} catch (e) {
			handleLoadingStates("options", false);
			console.log(e);
		}
	};

	const handleInitialMainOptionSelection = (field, value) => {
		if (field === "for") {
			setForOptionSelectedValue(null);
		}

		if (field === "then") {
			if (mainOptions[field]?.value) {
				handleRuleOperationValueUpdate(
					mainOptions[field]?.value,
					OPERATIONS_INIT_STATE[mainOptions[field]?.value]
				);
			}
		}

		setMainOptions((current) => ({
			...current,
			[field]: value
		}));
	};

	const handleExtendedAttributeMainOptionSelection = (field, value, currentValue) => {
		if (currentValue?.value) {
			handleRuleOperationValueUpdate(currentValue?.value, OPERATIONS_INIT_STATE[currentValue]);
		}
		setAttributesExtendedKeyValue((current) => ({
			...current,
			[field]: value
		}));
	};

	const handleRuleOperationValueUpdate = (field, value) => {
		setFormTouched(true);
		setRuleOperations((current) => ({
			...current,
			[field]: value
		}));
	};

	const handleForOptionSelection = (field, value) => {
		setForOptionSelectedValue(value);
		setFormTouched(true);
	};

	const openLocationsSelectDrawer = () => {
		setIsLocationsSelectDrawerOpen(true);
	};

	const closeLocationsSelectDrawer = () => {
		setIsLocationsSelectDrawerOpen(false);
	};

	const handlePlatformsPreselection = () => {
		if (!!ruleDetails?.platforms?.length && !!platformsData?.objects?.length) {
			const platformMap = {};
			ruleDetails.platforms.forEach((plf) => {
				platformMap[plf.toLowerCase()] = true;
			});

			const preSelectedPlatforms = [];
			platformsData.objects.forEach((plf) => {
				if (platformMap[plf?.platformName?.toLowerCase()]) {
					preSelectedPlatforms.push(plf);
				}
			});
			if (!!preSelectedPlatforms?.length) {
				handleInitialMainOptionSelection("isPlatformsAdderVisible", true);
			}

			setSelectedPlatforms(preSelectedPlatforms);
		}
	};

	const getOperationsTransformedDict = (operations = []) => {
		let operationsDict = {};
		operations.forEach((operation) => {
			if (operation?.field) {
				switch (operation.field) {
					case "name":
						operationsDict["changeName"] = operation?.input.value;
						break;
					case "sold_at":
						operationsDict["doNotSell"] = !operation?.input.value;
						break;
					case "image_url":
						operationsDict["changeImage"] = operation?.input.value;
						break;
					case "description":
						operationsDict["changeDescription"] = operation?.input.value;
						break;
					case "price":
						if (operation?.operator === "REPLACE_BY" && operation?.input?.format === "AMOUNT") {
							operationsDict["changeDefaultPrice"] = {
								subOperation: "TO_FIXED_AMOUNT",
								value: operation?.input.value,
								actionValue: operation?.input.value < 0 ? -1 : 1
							};
							break;
						} else if (operation?.operator === "CHANGE_BY" && operation?.input?.format === "AMOUNT") {
							operationsDict["changeDefaultPrice"] = {
								subOperation: "BY_FIXED_AMOUNT",
								value: operation?.input.value,
								actionValue: operation?.input.value < 0 ? -1 : 1
							};
							break;
						} else if (operation?.operator === "CHANGE_BY" && operation?.input?.format === "PERCENTAGE") {
							operationsDict["changeDefaultPrice"] = {
								subOperation: "BY_PERCENTAGE",
								value: operation?.input.value,
								actionValue: operation?.input.value < 0 ? -1 : 1
							};
							break;
						}
						break;
					case "markup_price":
						if (operation?.operator === "REPLACE_BY" && operation?.input?.format === "AMOUNT") {
							operationsDict["changeMarkupPrice"] = {
								subOperation: "TO_FIXED_AMOUNT",
								value: operation?.input.value,
								actionValue: operation?.input.value < 0 ? -1 : 1
							};
							break;
						} else if (operation?.operator === "CHANGE_BY" && operation?.input?.format === "AMOUNT") {
							operationsDict["changeMarkupPrice"] = {
								subOperation: "BY_FIXED_AMOUNT",
								value: operation?.input.value,
								actionValue: operation?.input.value < 0 ? -1 : 1
							};
							break;
						} else if (operation?.operator === "CHANGE_BY" && operation?.input?.format === "PERCENTAGE") {
							operationsDict["changeMarkupPrice"] = {
								subOperation: "BY_PERCENTAGE",
								value: operation?.input.value,
								actionValue: operation?.input.value < 0 ? -1 : 1
							};
							break;
						}
						break;
				}
			}
		});
		return operationsDict;
	};
	const handlePreselections = () => {
		const { entityType: ruleEntityType, entityId, locations, entityName, name } = ruleDetails ?? {};

		setRuleTitle(name ?? "");

		handleInitialMainOptionSelection(
			"for",
			ITEM_OPTION_SELECTOR_OPTIONS?.find((opt) => opt?.value === ruleEntityType)
		);

		if (entityId) {
			setForOptionSelectedValue(
				ruleEntityType === "item"
					? (itemsList?.objects ?? []).find((item) => item.id === entityId)
					: ruleEntityType === "option"
					? (optionsList?.objects ?? []).find((option) => option.id === entityId)
					: null
			);
		} else if (isForOptionFixed && forOptionFixedValue?.id && !isEditMode) {
			setForOptionSelectedValue(
				entityType === "item"
					? (itemsList?.objects ?? []).find((item) => item.id === forOptionFixedValue?.id)
					: entityType === "option"
					? (optionsList?.objects ?? []).find((option) => option.id === forOptionFixedValue?.id)
					: null
			);
		}

		setSelectedLocations(() => {
			const selectedLocations = {};
			(locations ?? []).forEach((loc) => {
				selectedLocations[loc?.locationId] = {
					id: loc?.locationId,
					name: loc?.locationName
				};
			});
			return selectedLocations;
		});

		const operationsDictionary = getOperationsTransformedDict(ruleDetails?.operations);
		const operationsKeys = Object.keys(removeProp(operationsDictionary ?? {}, "__typename"));
		let count = 0;
		const attributes = {};
		const ruleOperationsUpdateValue = { ...operationsDictionary };
		operationsKeys.forEach((operation) => {
			if (count === 0) {
				handleInitialMainOptionSelection(
					"then",
					ACTION_OPTION_SELECTOR_OPTIONS.find((opr) => opr.value === operation)
				);
			} else {
				attributes["extendAttribute_" + generateUniqueId()] = ACTION_OPTION_SELECTOR_OPTIONS.find(
					(opr) => opr.value === operation
				);
			}
			count++;
		});

		if (operationsDictionary?.doNotSell) {
			setDoNotSell(true);
		}
		setRuleOperations(ruleOperationsUpdateValue);
		setAttributesExtendedKeyValue(attributes);
	};

	const handleAttributeRemoval = (attribute, attributeId = null) => {
		if (attributeId) {
			if (attributesExtendedKeyValue[attributeId]) {
				setAttributesExtendedKeyValue((current) => {
					const updatedAttributes = {
						...current
					};
					delete updatedAttributes[attributeId];
					return updatedAttributes;
				});
			}
			handleRuleOperationValueUpdate(attribute, OPERATIONS_INIT_STATE[attribute]);
			return;
		}
		handleRuleOperationValueUpdate(attribute, OPERATIONS_INIT_STATE[attribute]);
		handleInitialMainOptionSelection("then", MAIN_OPTIONS_INIT_VALUES.then);
	};

	useEffect(() => {
		if (isEditMode) {
			fetchRuleDetails();
		}
		fetchPlatformsList();
		fetchItemsList();
		fetchOptionsList();
	}, [isOpen]);

	useEffect(handlePlatformsPreselection, [ruleDetails, platformsData]);
	useEffect(handlePreselections, [ruleDetails, itemsList, optionsList]);

	// resolve conflict flow useEffects
	useEffect(() => {
		if (isEditMode && !!latestModifiedRuleDetails?.conflicts?.length) {
			handleResolveConflictEditChanges();
		}
	}, [selectedLocations, selectedPlatforms]);

	useEffect(() => {
		if (isEditMode && !!latestModifiedRuleDetails?.conflicts?.length) {
			if (debouncedConflictRef.current) {
				clearTimeout(debouncedConflictRef.current);
				debouncedConflictRef.current = setTimeout(() => {
					handleResolveConflictForOperationsEditChanges();
				}, 500);
			} else {
				debouncedConflictRef.current = setTimeout(() => {
					handleResolveConflictForOperationsEditChanges();
				}, 500);
			}

			return () => {
				if (debouncedConflictRef.current) {
					clearTimeout(debouncedConflictRef.current);
				}
			};
		}
	}, [ruleOperations]);

	return (
		<div className="rules-creation-container">
			<FormSidebar
				isOpen={isOpen}
				title={isEditMode ? "Edit Rule" : "Create Rule"}
				subTitle={isEditMode ? "Edit the details of your menu rule here" : ""}
				close={handleClose}
				submitTitle={"Save"}
				submit={isEditMode ? updateRuleInfo : createRule}
				hideActions={!isFormTouched}
				loading={loadingStates?.mainView}
				isNested={isNested}
			>
				<div className="rules-info-preview-container">
					<BasicInformation
						whenOption={mainOptions?.when}
						forOption={mainOptions?.for ? { ...(mainOptions?.for ?? {}) } : mainOptions?.for}
						thenOption={mainOptions?.then}
						handleInitialMainOptionSelection={handleInitialMainOptionSelection}
						isPlatformsAdderVisible={mainOptions.isPlatformsAdderVisible}
						handleRuleOperationValueUpdate={handleRuleOperationValueUpdate}
						ruleOperations={ruleOperations}
						openLocationsSelectDrawer={openLocationsSelectDrawer}
						platformsData={platformsData}
						selectedPlatforms={selectedPlatforms}
						handlePlatformsSelection={handlePlatformsSelection}
						loadingStates={loadingStates}
						attributesExtendedKeyValue={attributesExtendedKeyValue}
						handleAttributesKeyValueExtension={handleAttributesKeyValueExtension}
						handleExtendedAttributeMainOptionSelection={handleExtendedAttributeMainOptionSelection}
						handleForOptionSelection={handleForOptionSelection}
						itemsList={itemsList}
						optionsList={optionsList}
						forOptionSelectedValue={forOptionSelectedValue}
						ruleTitle={ruleTitle}
						handleRuleTitleChange={handleRuleTitleChange}
						ruleConflicts={!isFormTouched ? latestModifiedRuleDetails?.conflicts : revTempRuleConflict}
						isResolveConflictMode={isResolveConflictMode}
						selectedLocations={selectedLocations}
						isForOptionFixed={isForOptionFixed}
						forOptionFixedValue={forOptionFixedValue}
						handleAttributeRemoval={handleAttributeRemoval}
						validationsData={validationsData}
						setDoNotSell={setDoNotSell}
						doNotSell={doNotSell}
						isPOSmenuType={isPOSmenuType}
					/>
					<Preview
						forOption={mainOptions?.for}
						forOptionSelectedValue={forOptionSelectedValue}
						ruleOperations={ruleOperations}
					/>
				</div>
				<LocationsSelectionDrawer
					isOpen={isLocationsSelectDrawerOpen}
					handleLoadingStates={handleLoadingStates}
					brandId={brandId}
					menuId={menuId}
					loadingStates={loadingStates}
					selectedLocations={selectedLocations}
					setSelectedLocations={setSelectedLocations}
					close={closeLocationsSelectDrawer}
					ruleId={ruleId}
					setFormTouched={setFormTouched}
				/>
			</FormSidebar>
		</div>
	);
};
const mapStateToProps = (store) => ({
	currencySymbol: store.login.loggedInbizDetail?.currencySymbol,
	menuDetailsState: store.menuDetailsState
});
export default connect(mapStateToProps)(RulesCreation);

const BasicInformation = ({
	whenOption,
	forOption,
	thenOption,
	handleInitialMainOptionSelection,
	isPlatformsAdderVisible,
	handleRuleOperationValueUpdate,
	ruleOperations,
	openLocationsSelectDrawer,
	platformsData,
	selectedPlatforms,
	handlePlatformsSelection,
	loadingStates,
	attributesExtendedKeyValue,
	handleAttributesKeyValueExtension,
	handleExtendedAttributeMainOptionSelection,
	handleForOptionSelection,
	itemsList,
	optionsList,
	forOptionSelectedValue,
	ruleTitle,
	handleRuleTitleChange,
	ruleConflicts,
	isResolveConflictMode,
	selectedLocations,
	isForOptionFixed,
	forOptionFixedValue,
	handleAttributeRemoval,
	validationsData,
	doNotSell,
	setDoNotSell,
	isPOSmenuType = false
}) => {
	const attributesExtendedKeyValueArray = Object.keys(attributesExtendedKeyValue);

	const filterPreselectedField = (isFirstAttribute = false) => {
		const selectedAttributes = {};

		if (thenOption?.value) {
			selectedAttributes[thenOption?.value] = true;
		}

		Object.keys(attributesExtendedKeyValue).forEach((attr) => {
			if (attributesExtendedKeyValue[attr]?.value) {
				selectedAttributes[attributesExtendedKeyValue[attr]?.value] = true;
			}
		});

		return ACTION_OPTION_SELECTOR_OPTIONS.filter((option) => {
			if (option?.value === "doNotSell") {
				return isFirstAttribute;
			}

			return !selectedAttributes[option?.value];
		});
	};

	const handleImage = (files) => {
		const imgFile = files[0];
		if (imgFile) {
			const image = document.createElement("img");
			image.src = window.URL.createObjectURL(imgFile);

			// proceed only if the uploaded file is a valid image
			image.onload = async function () {
				if (imgFile.size < 1047520) {
					handleRuleOperationValueUpdate("changeImage", imgFile);
				} else {
					const result = await new Promise((resolve, reject) => {
						new Compressor(imgFile, {
							quality: 0.6,
							maxWidth: 4000,
							success: resolve,
							error: reject
						});
					});
					if (result && result.size < 1047520) {
						handleRuleOperationValueUpdate("changeImage", imgFile);
					} else {
						store.dispatch({
							type: "SHOW_GLOBAL_MESSAGE",
							payload: {
								message: "Image size is too large, please keep it below 1 MB",
								timeout: 3000,
								error: true
							}
						});
					}
				}
			};

			// handle error if uploaded file is not an image
			image.onerror = function () {
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Please upload a valid image.",
						timeout: 3000,
						error: true
					}
				});
			};
		} else {
			store.dispatch({
				type: "SHOW_GLOBAL_MESSAGE",
				payload: {
					message: "Upload an image from your current device",
					timeout: 3000,
					error: true
				}
			});
		}
	};

	const handleClearImage = (e) => {
		e.stopPropagation();
		handleRuleOperationValueUpdate("changeImage", null);
	};

	const handleSetFilterChange = (f, v) => {
		if (v?.value !== "doNotSell") {
			setDoNotSell(false);
			handleRuleOperationValueUpdate("doNotSell", false);
		} else {
			setDoNotSell(true);
			handleRuleOperationValueUpdate("doNotSell", true);
		}
		handleInitialMainOptionSelection(f, v);
	};

	const renderPriceSelectionSubOperationInterface = (mainField, type) => {
		switch (type) {
			case "BY_FIXED_AMOUNT":
				return (
					<div className="price-update--fixed-amount">
						<SelectFilter
							options={PRICE_VARIATION_SYMBOLS}
							currValue={
								ruleOperations?.[mainField]?.actionValue &&
								PRICE_VARIATION_SYMBOLS.find(
									(symbol) => symbol.value === ruleOperations?.[mainField]?.actionValue
								)
							}
							setFilter={(field, value) =>
								handleRuleOperationValueUpdate(mainField, {
									...(ruleOperations?.[mainField] ?? {}),
									[field]: value?.value
								})
							}
							isClearable={false}
							field="actionValue"
						/>
						<InputWithLabel
							onChange={(e) =>
								handleRuleOperationValueUpdate(mainField, {
									...(ruleOperations?.[mainField] ?? {}),
									value: e.target.value
								})
							}
							value={ruleOperations?.[mainField]?.value}
							showLabel
							currency
							currencySymbol={currencySymbol}
							isPaddingRequired
						></InputWithLabel>
					</div>
				);

			case "TO_FIXED_AMOUNT":
				return (
					<InputWithLabel
						onChange={(e) =>
							handleRuleOperationValueUpdate(mainField, {
								...(ruleOperations?.[mainField] ?? {}),
								value: e.target.value
							})
						}
						value={ruleOperations?.[mainField]?.value}
						showLabel
						currency
						currencySymbol={currencySymbol}
						isPaddingRequired
						isClearable={false}
					></InputWithLabel>
				);

			case "BY_PERCENTAGE":
				return (
					<div className="price-update--fixed-amount">
						<SelectFilter
							options={PRICE_VARIATION_SYMBOLS}
							currValue={
								ruleOperations?.[mainField]?.actionValue &&
								PRICE_VARIATION_SYMBOLS.find(
									(symbol) => symbol.value === ruleOperations?.[mainField]?.actionValue
								)
							}
							setFilter={(field, value) =>
								handleRuleOperationValueUpdate(mainField, {
									...(ruleOperations?.[mainField] ?? {}),
									[field]: value?.value
								})
							}
							field="actionValue"
						/>
						<InputWithLabel
							onChange={(e) =>
								handleRuleOperationValueUpdate(mainField, {
									...(ruleOperations?.[mainField] ?? {}),
									value: e.target.value
								})
							}
							value={ruleOperations?.[mainField]?.value}
							showLabel
							symbol="%"
							type="number"
							isPaddingRequired
						></InputWithLabel>
					</div>
				);
			default:
				return null;
		}
	};

	const renderSelectedThenOptionField = (field) => {
		switch (field) {
			case "changeName":
				return (
					<InputWithLabel
						placeholder="Enter Name"
						onChange={(e) => handleRuleOperationValueUpdate("changeName", e.target.value)}
						value={ruleOperations?.changeName}
					/>
				);
			case "changeDescription":
				return (
					<Textarea
						placeholder="Enter Description"
						onChange={(e) => handleRuleOperationValueUpdate("changeDescription", e.target.value)}
						value={ruleOperations?.changeDescription}
					/>
				);
			case "changeMarkupPrice":
				return (
					<>
						<SelectFilter
							options={PRICING_TYPE_SELECTOR}
							setFilter={(field, value) =>
								handleRuleOperationValueUpdate(field, {
									...(ruleOperations?.changeDefaultPrice ?? {}),
									subOperation: value?.value
								})
							}
							currValue={
								ruleOperations?.changeMarkupPrice?.subOperation &&
								PRICING_TYPE_SELECTOR.find(
									(priceType) => priceType.value === ruleOperations?.changeMarkupPrice?.subOperation
								)
							}
							isClearable={false}
							field="changeMarkupPrice"
						/>
						{ruleOperations?.changeMarkupPrice?.subOperation &&
							renderPriceSelectionSubOperationInterface(
								"changeMarkupPrice",
								ruleOperations?.changeMarkupPrice?.subOperation
							)}
					</>
				);
			case "changeDefaultPrice":
				return (
					<>
						<SelectFilter
							options={PRICING_TYPE_SELECTOR}
							field="changeDefaultPrice"
							currValue={
								ruleOperations?.changeDefaultPrice?.subOperation &&
								PRICING_TYPE_SELECTOR.find(
									(priceType) => priceType.value === ruleOperations?.changeDefaultPrice?.subOperation
								)
							}
							setFilter={(field, value) =>
								handleRuleOperationValueUpdate(field, {
									...(ruleOperations?.changeDefaultPrice ?? {}),
									subOperation: value?.value
								})
							}
							isClearable={false}
						/>
						{ruleOperations?.changeDefaultPrice?.subOperation &&
							renderPriceSelectionSubOperationInterface(
								"changeDefaultPrice",
								ruleOperations?.changeDefaultPrice?.subOperation
							)}
					</>
				);
			case "changeImage":
				return (
					<UploaderV2
						onChange={handleImage}
						showDelete={true}
						handleDelete={handleClearImage}
						file={typeof ruleOperations?.changeImage === "object" ? ruleOperations?.changeImage : null}
						url={typeof ruleOperations?.changeImage === "string" ? ruleOperations?.changeImage : null}
					/>
				);
			default:
				return null;
		}
	};

	// resolve conflict edit mode operations
	const OverLappingCard = ({ attr, attrList }) => {
		return (
			<div className="overlapping-error-card">
				<img src="/assets/icons/error-red-icon.svg" />
				<span className="attr-header">Overlapping {attr} : </span>
				<span className="attr-list">{attrList}</span>
			</div>
		);
	};
	const getOverlappingPlatforms = (ruleConflicts = [], platforms = []) => {
		const normalizedPlatformNames = platforms?.map((platform) => platform?.platformName?.toLowerCase());

		const overlappingPlatforms = ruleConflicts?.reduce((commonPlatforms, conflict) => {
			const conflictPlatformNames = conflict?.conflictingPlatforms?.map((platformName) =>
				platformName?.toLowerCase()
			);

			const common = conflictPlatformNames?.filter((platformName) =>
				normalizedPlatformNames?.includes(platformName)
			);

			return [...commonPlatforms, ...common];
		}, []);

		const uniqueOverlappingPlatforms = Array.from(new Set(overlappingPlatforms))?.map((platformName) => {
			const platform = platforms?.find((p) => p?.platformName?.toLowerCase() === platformName);
			return platform ? platform?.platformName : platformName;
		});
		let resStr = "";
		if (uniqueOverlappingPlatforms?.length > 1) {
			resStr = uniqueOverlappingPlatforms?.join(", ");
		} else if (uniqueOverlappingPlatforms?.length) {
			resStr = uniqueOverlappingPlatforms?.[0];
		}

		return resStr;
	};
	function findCommonValues(arrayOne = [], arrayTwo = []) {
		const OPERATION_MAP = {
			changeImage: "image_url",
			changeDescription: "description",
			changeMarkupPrice: "markup_price",
			changeDefaultPrice: "price",
			changeName: "name"
		};
		const arrayTwoModified = [];
		arrayTwo.forEach((e) => arrayTwoModified.push(OPERATION_MAP[e]));
		let commonValues = [];
		let conflictingOperationsArrays = arrayOne?.map((rule) => rule.conflictingOperations) || [];

		const allConflictingOperations = conflictingOperationsArrays.flat() || [];
		commonValues = allConflictingOperations?.filter((operation) => {
			return arrayTwoModified?.includes(operation);
		});

		return commonValues;
	}
	const getConflictingOperationStr = (conflictingOperations = []) => {
		const OPERATION_UI_LABEL_MAP = {
			price: "Default price",
			description: "Description",
			image_url: "Image",
			markup_price: "Markup price",
			name: "Name"
		};
		let resStr = "";
		const tempArr = conflictingOperations?.map((ct) => OPERATION_UI_LABEL_MAP[ct]) || [];

		const uniqueValues = [...new Set(tempArr)];
		if (uniqueValues?.length > 1) {
			resStr = tempArr?.join(", ");
		} else if (uniqueValues?.length) {
			resStr = tempArr?.[0];
		}
		return resStr;
	};

	const getConflictingLocationsStr = (ruleConflicts = [], selectedLocations = {}) => {
		const conflictingLocationIdArray = [
			...new Set(ruleConflicts?.map((rule) => rule?.conflictingLocations)?.flat())
		];
		const tempArr = conflictingLocationIdArray?.map((item) => {
			if (selectedLocations[item]) {
				return selectedLocations[item];
			}
			return {};
		});
		let resStr = "";
		if (tempArr?.length > 1) {
			resStr = tempArr
				?.filter((i) => i?.name)
				.map((i) => i.name)
				.join(", ");
		} else if (tempArr?.length) {
			resStr = tempArr?.[0]?.name;
		}
		return resStr;
	};
	let overlappingPlatforms = "";
	if (ruleConflicts?.length > 0 && selectedPlatforms?.length > 0) {
		overlappingPlatforms = getOverlappingPlatforms(ruleConflicts, selectedPlatforms);
	}
	const isEmptyExtraAttr = Object.keys(attributesExtendedKeyValue).length === 0;

	const ruleOperationsArray = isEmptyExtraAttr
		? []
		: Object.values(attributesExtendedKeyValue)?.map((item) => item?.value);

	ruleOperationsArray.push(thenOption?.value);

	let conflictingOperations = [];
	if (ruleConflicts?.length > 0 && ruleOperationsArray?.length > 0) {
		conflictingOperations = findCommonValues(ruleConflicts, ruleOperationsArray);
	}
	let conflictingOperationMsg = "";
	if (conflictingOperations?.length > 0) {
		conflictingOperationMsg = getConflictingOperationStr(conflictingOperations);
	}
	let conflictingLocationStr = "";
	if (ruleConflicts?.length > 0 && selectedLocations) {
		conflictingLocationStr = getConflictingLocationsStr(ruleConflicts, selectedLocations);
	}

	return (
		<div className="rule-basic-information-container">
			<InputWithLabel
				classes="rule-name-input"
				value={ruleTitle}
				onChange={handleRuleTitleChange}
				requiredLabel
				validationMessage={validationsData?.ruleTitle}
			>
				Rule Name
			</InputWithLabel>
			<RuleSection criterionTitle="When">
				<div className="selectors-container">
					<Button
						type="secondary"
						clickHandler={openLocationsSelectDrawer}
						classes={`select-locations-cta ${
							isResolveConflictMode && conflictingLocationStr?.length && "select-error-location-indicator"
						} `}
					>
						{!!Object.keys(selectedLocations).length ? (
							<>
								<span className="opted-locations-list">
									{truncateText(
										Object.keys(selectedLocations)
											.map((loc) => selectedLocations[loc]?.name)
											.join(", ") ?? "",
										35
									)}
									<Image
										src="/assets/icons/icon-edit--filled-blue.svg"
										alt="edit icon"
										className="edit-loc-icon"
									/>
								</span>
							</>
						) : (
							"Select Locations"
						)}
					</Button>
					{isResolveConflictMode && conflictingLocationStr?.length > 0 && ruleConflicts?.length > 0 ? (
						<OverLappingCard attr={"Locations"} attrList={conflictingLocationStr} />
					) : null}

					{isPlatformsAdderVisible ? (
						<>
							<SelectFilter
								placeholder="Select Platforms"
								multi
								classes={
									isResolveConflictMode && overlappingPlatforms?.length && "select-error-indicator"
								}
								options={platformsData?.objects}
								currValue={selectedPlatforms}
								setFilter={handlePlatformsSelection}
								labelKey="platformName"
								valueKey="id"
								isLoading={loadingStates?.platforms}
							/>
							{isResolveConflictMode && overlappingPlatforms?.length > 0 && ruleConflicts?.length > 0 ? (
								<OverLappingCard attr={"Platforms"} attrList={overlappingPlatforms} />
							) : null}
						</>
					) : (
						<div className="location-plf-selection-drawer-cta-container">
							<span
								className="hyperlink"
								onClick={() => handleInitialMainOptionSelection("isPlatformsAdderVisible", true)}
							>
								+ Add Platforms
							</span>
						</div>
					)}
				</div>
			</RuleSection>
			<RuleSection criterionTitle="For">
				<div className="selectors-container">
					{!isForOptionFixed && (
						<SelectFilter
							options={ITEM_OPTION_SELECTOR_OPTIONS}
							labelKey="label"
							valueKey="value"
							field="for"
							currValue={forOption ? forOption : null}
							setFilter={handleInitialMainOptionSelection}
							placeholder="Item / Modifier"
						/>
					)}
					{(forOption || isForOptionFixed) && (
						<SelectFilter
							options={forOption?.value === "item" ? itemsList?.objects : optionsList?.objects}
							currValue={isForOptionFixed ? forOptionFixedValue : forOptionSelectedValue}
							labelKey="name"
							valueKey="id"
							field={forOption?.value}
							setFilter={handleForOptionSelection}
							placeholder={`Select ${forOption?.label}`}
							readOnly={isForOptionFixed}
						/>
					)}
				</div>
			</RuleSection>
			<RuleSection criterionTitle="Then">
				<div className="selectors-container">
					<div className="attribute-container attribute-container--primary">
						<SelectFilter
							options={filterPreselectedField(true)}
							labelKey="label"
							valueKey="value"
							field="then"
							classes={
								isResolveConflictMode &&
								conflictingOperations?.includes(thenOption?.value) &&
								"select-error-indicator"
							}
							currValue={thenOption}
							setFilter={handleSetFilterChange}
							isClearable={false}
						/>
						{renderSelectedThenOptionField(thenOption?.value)}
						<Image
							className="close-icon"
							src="/assets/icons/cancel.png"
							alt="remove rule attribute"
							onClick={() => handleAttributeRemoval(thenOption?.value)}
						/>
					</div>
					{!doNotSell && (
						<>
							{attributesExtendedKeyValueArray.map((attr) => (
								<div className="attribute-container">
									<SelectFilter
										options={filterPreselectedField()}
										labelKey="label"
										valueKey="value"
										field={attr}
										classes={
											conflictingOperations?.includes(attributesExtendedKeyValue[attr]?.value) &&
											"select-error-indicator"
										}
										currValue={attributesExtendedKeyValue[attr]}
										setFilter={(f, v) =>
											handleExtendedAttributeMainOptionSelection(
												f,
												v,
												attributesExtendedKeyValue[attr]
											)
										}
										isClearable={false}
									/>
									{renderSelectedThenOptionField(attributesExtendedKeyValue[attr]?.value)}
									<Image
										className="close-icon"
										src="/assets/icons/cancel.png"
										alt="remove rule attribute"
										onClick={() =>
											handleAttributeRemoval(attributesExtendedKeyValue[attr]?.value, attr)
										}
									/>
								</div>
							))}
							{attributesExtendedKeyValueArray.length !== ACTION_OPTION_SELECTOR_OPTIONS.length - 1 && (
								<div className="location-plf-selection-drawer-cta-container">
									<span
										className="hyperlink"
										onClick={() =>
											handleAttributesKeyValueExtension(
												"extendAttribute_" + generateUniqueId(),
												null
											)
										}
									>
										+ Add Another Attribute
									</span>
								</div>
							)}
						</>
					)}
					{isResolveConflictMode && conflictingOperationMsg?.length > 0 && ruleConflicts?.length > 0 ? (
						<OverLappingCard attr={"Operations"} attrList={conflictingOperationMsg} />
					) : null}
				</div>
			</RuleSection>
		</div>
	);
};

const Preview = ({ forOptionSelectedValue, ruleOperations }) => {
	const calculatePrice = (baseValue, subOperation, value, actionValue) => {
		if (baseValue !== 0 && !baseValue) {
			return null;
		}

		switch (subOperation) {
			case "TO_FIXED_AMOUNT":
				return value;

			case "BY_FIXED_AMOUNT":
				return actionValue === -1
					? +(parseFloat(baseValue) - parseFloat(value)).toFixed(2)
					: +(parseFloat(baseValue) + parseFloat(value)).toFixed(2);

			case "BY_PERCENTAGE":
				const res =
					actionValue === -1
						? parseFloat(baseValue) - (parseFloat(value) / 100) * parseFloat(baseValue)
						: parseFloat(baseValue) + (parseFloat(value) / 100) * parseFloat(baseValue);
				return +res.toFixed(2);
			default:
				return null;
		}
	};

	const checkIsEmptyOverrides = () => {
		if (
			ruleOperations?.changeName ||
			ruleOperations?.changeDescription ||
			ruleOperations?.changeDefaultPrice?.value ||
			ruleOperations?.changeDefaultPrice?.value === 0 ||
			ruleOperations?.changeMarkupPrice?.value ||
			ruleOperations?.changeMarkupPrice?.value === 0 ||
			ruleOperations?.changeImage
		) {
			return false;
		}

		return true;
	};

	return (
		<div className="rule-preview-container">
			<div className="header">PREVIEW</div>
			<div className="status-container">
				<span className="status default">Default</span>
			</div>
			<EntityDetails forOptionSelectedValue={forOptionSelectedValue} isEmptyOverrides={false} />
			<div className="status-container">
				<span className="status overriden">Overridden</span>
			</div>
			<EntityDetails
				isEmptyOverrides={!forOptionSelectedValue}
				forOptionSelectedValue={{
					name: ruleOperations?.changeName || forOptionSelectedValue?.name,
					description: ruleOperations?.changeDescription || forOptionSelectedValue?.description,
					price:
						(ruleOperations?.changeDefaultPrice?.value || ruleOperations?.changeDefaultPrice?.value === 0
							? calculatePrice(
									forOptionSelectedValue?.price,
									ruleOperations?.changeDefaultPrice?.subOperation,
									ruleOperations?.changeDefaultPrice?.value,
									ruleOperations?.changeDefaultPrice?.actionValue
							  )
							: null) || forOptionSelectedValue?.price,
					markupPrice:
						(ruleOperations?.changeMarkupPrice?.value || ruleOperations?.changeMarkupPrice?.value === 0
							? calculatePrice(
									forOptionSelectedValue?.markupPrice,
									ruleOperations?.changeMarkupPrice?.subOperation,
									ruleOperations?.changeMarkupPrice?.value,
									ruleOperations?.changeMarkupPrice?.actionValue
							  )
							: null) || forOptionSelectedValue?.markupPrice,
					imageUrl: ruleOperations?.changeImage
						? typeof ruleOperations?.changeImage === "string"
							? ruleOperations?.changeImage
							: window.URL.createObjectURL(ruleOperations?.changeImage)
						: forOptionSelectedValue?.imageUrl ?? "/assets/icons/icon-empty-img.svg"
				}}
			/>
		</div>
	);
};

const EntityDetails = ({ forOptionSelectedValue, isEmptyOverrides }) => {
	if (isEmptyOverrides) {
		return (
			<div className="item-details-container">
				<div className="image-placeholder"></div>
				<div className="item-info-container-placeholder">
					<div className="item-name">Item Name</div>
					<div className="item-details-pending"></div>
					<div className="item-details-pending"></div>
				</div>
			</div>
		);
	}
	return !forOptionSelectedValue ? (
		<div className="item-details-container">
			<div className="image-placeholder"></div>
			<div className="item-info-container-placeholder">
				<div className="item-name">Item Name</div>
				<div className="item-details-pending"></div>
				<div className="item-details-pending"></div>
			</div>
		</div>
	) : (
		<div className="entity-details-container">
			<div className="image-container">
				<Image
					alt={forOptionSelectedValue?.name}
					className="entity-image"
					src={forOptionSelectedValue?.imageUrl ?? "/assets/icons/icon-empty-img.svg"}
				/>
			</div>
			<div className="entity-info-container">
				<div className="entity-name">{forOptionSelectedValue?.name || "--"}</div>
				<div className="entity-description">{forOptionSelectedValue?.description || "--"}</div>
				<div className="entity-pricing-container">
					<span className="markup-price">
						{printCurrency(currencySymbol)} {fixedToTwoDecimal(forOptionSelectedValue?.markupPrice) ?? "--"}
					</span>
					<span className="default-price">
						{printCurrency(currencySymbol)} {fixedToTwoDecimal(forOptionSelectedValue?.price) ?? "--"}
					</span>
				</div>
			</div>
		</div>
	);
};

const RuleSection = ({ criterionTitle = "Then", children }) => {
	return (
		<div className="rule-section-container">
			<div className="title">{criterionTitle}</div>
			<div className="values-input">{children}</div>
		</div>
	);
};

const LocationsSelectionDrawer = ({
	isOpen,
	brandId,
	handleLoadingStates,
	menuId,
	loadingStates,
	selectedLocations,
	setSelectedLocations,
	close,
	setFormTouched,
	ruleId = null
}) => {
	const [offset, setOffset] = useState(0);
	const [locationsData, setLocationsData] = useState(LOCATIONS_LIST_INIT_STATE);
	const [appliedFilters, setAppliedFilters] = useState({
		city: null,
		searchKey: ""
	});
	const [isAllLocationsSelected, setIsAllLocationsSelected] = useState(false);
	const debouncedSearchRef = useRef();
	const { searchKey, city } = appliedFilters;
	const [tempLocationsSelection, setTempLocationsSelection] = useState({});
	const [isSortedBySelection, setIsSortedBySelection] = useState(false);

	const locationsColumn = [
		{
			title: "location name",
			dataIndex: "name",
			render: (data) => data
		},
		{
			title: "city",
			dataIndex: "city",
			render: (data) => data
		}
	];

	const fetchLocationsList = async (offset = 0) => {
		handleLoadingStates("locations", true);
		try {
			const variables = {
				limit: 10,
				offset: offset,
				filters: {
					brand: brandId ? String(brandId) : null
				},
				searchKeyword: appliedFilters?.searchKey || null,
				menuId,
				ruleId,
				sortOptions: {
					showAssociatedLocationsFirst: isSortedBySelection
				}
			};
			const respLocation = await clientMenu.query({
				query: GET_PUBLISH_LOCATIONS_LIST,
				variables,
				fetchPolicy: "no-cache"
			});

			if (respLocation?.data?.locations) {
				setLocationsData({
					objects: respLocation.data.locations?.objects,
					count: respLocation?.data?.locations?.totalObjectCount
				});
			}
			handleLoadingStates("locations", false);
		} catch (e) {
			console.log(e);
			handleLoadingStates("locations", false);
		}
	};

	const handlePagination = (page) => {
		setOffset((page - 1) * 10);
		fetchLocationsList((page - 1) * 10);
	};

	const handleSingleFieldSelection = (id) => {
		if (tempLocationsSelection[id]) {
			const updatedSelectedLocation = {
				...tempLocationsSelection
			};
			delete updatedSelectedLocation[id];
			setTempLocationsSelection({
				...updatedSelectedLocation
			});
			setIsAllLocationsSelected(false);
			return;
		}
		setTempLocationsSelection((current) => ({
			...current,
			[id]: locationsData?.objects.find((loc) => loc?.id === id)
		}));
	};

	const handleAllFieldSelection = (state) => {
		if (state) {
			const updatedSelectedLocation = {
				...tempLocationsSelection
			};
			locationsData.objects.forEach((loc) => {
				updatedSelectedLocation[loc?.id] = loc;
			});
			setTempLocationsSelection(updatedSelectedLocation);
			setIsAllLocationsSelected(true);
		} else {
			const updatedSelectedLocation = {};
			setTempLocationsSelection(updatedSelectedLocation);
			setIsAllLocationsSelected(false);
		}
	};

	const handleSearch = (field, value) => {
		setOffset(0);
		setAppliedFilters((current) => ({
			...current,
			[field]: value
		}));
	};

	const handleSelectionsConfirmation = () => {
		setSelectedLocations((current) => ({
			...tempLocationsSelection
		}));
		setFormTouched(true);
		close();
	};

	useEffect(() => {
		setOffset(0);
		fetchLocationsList();
	}, [isSortedBySelection]);

	useEffect(() => {
		if (debouncedSearchRef.current) {
			clearTimeout(debouncedSearchRef.current);
			debouncedSearchRef.current = setTimeout(() => {
				fetchLocationsList();
			}, 500);
		} else {
			debouncedSearchRef.current = setTimeout(() => {
				fetchLocationsList();
			}, 500);
		}

		return () => {
			if (debouncedSearchRef.current) {
				clearTimeout(debouncedSearchRef.current);
			}
		};
	}, [searchKey]);

	useEffect(() => {
		if (isOpen) {
			fetchLocationsList();
			setTempLocationsSelection((current) => ({
				...selectedLocations
			}));
		}

		return () => {
			setOffset(0);
		};
	}, [isOpen]);

	return (
		<FormSidebar
			isOpen={isOpen}
			isNested
			close={close}
			title="Select Locations"
			subTitle="Select which locations you want to publish this rule for"
			submitTitle="Confirm Selection"
			submit={handleSelectionsConfirmation}
			submitButtonWidth={200}
			cancelTitle="Close"
			loading={loadingStates?.locations}
		>
			<div className="locations-selection-table-container">
				<div className="filters-container">
					<div className="dropdown-filters-container">
						{/* <SelectFilter
							placeholder="City"
							options={locationsData?.filters?.find(filter => filter.field === "city")?.values}
							valueKey="value"
							labelKey="valueForDisplay"
							setFilter={handleFilters}
							field="city"
						/> */}
						{/* <SelectFilter
							placeholder="Location Group"
						/> */}
					</div>
					<SearchFilter
						placeholder="Search"
						value={appliedFilters.searchKey}
						setFilter={handleSearch}
						filterOption={{ field: "searchKey" }}
					/>
				</div>
				<div className="sort-by-association-checkbox-container">
					<CheckBox
						checked={isSortedBySelection}
						clickHandler={() => setIsSortedBySelection((current) => !current)}
					>
						Show selected on top
					</CheckBox>
				</div>
				<FormTable
					columns={locationsColumn}
					dataSource={locationsData?.objects}
					placeholderContent={{
						placeholderText: "No locations found!",
						placeholderImageUrl: "/assets/empty_states/graphics-empty-locations-main.svg"
					}}
					isSelectionEnabled
					handleSingleFieldSelection={handleSingleFieldSelection}
					handleAllFieldsSelection={handleAllFieldSelection}
					selectedFields={tempLocationsSelection}
					isAllFieldSelected={isAllLocationsSelected}
					isLoading={loadingStates?.initLoading}
					contentTableClass={loadingStates?.loadingLocations ? "disabled" : ""}
				/>
				<Paginator
					count={locationsData?.count}
					limit={10}
					offset={offset}
					goToPage={handlePagination}
					readOnly={loadingStates?.loadingLocations}
				/>
			</div>
		</FormSidebar>
	);
};
