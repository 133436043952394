import React from "react";

export class CopyToClipboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			copyStatus: false
		};
	}

	timer = () =>
		setTimeout(() => {
			this.setState({
				copyStatus: !this.state.copyStatus
			});
		}, 3000);

	componentDidUpdate() {
		const { copyStatus } = this.state;
		if (!copyStatus) {
			clearTimeout(this.timerID);
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timerID);
	}

	handleCopy = () => {
		const { copyStatus } = this.state;
		const { content = "" } = this.props;
		if (!copyStatus) {
			navigator.clipboard.writeText(content);
			this.setState({
				copyStatus: !copyStatus
			});
		}
		this.timerID = this.timer();
	};

	render() {
		const { copyStatus } = this.state;
		const { label, showIcon = false } = this.props;
		return (
			<div
				onClick={this.handleCopy}
				className={(!copyStatus ? "copy-btn " : "copy-btn copy-success ") + (showIcon ? "icon" : "")}
			>
				{!showIcon ? (!copyStatus ? label || "Copy" : "Copied!") : ""}
				{showIcon && (
					<img
						title="Copy"
						src={"/assets/icons/icon-copy-" + (!copyStatus ? "gray" : "green") + ".svg"}
						alt=""
					/>
				)}
				{copyStatus && <div className="copied">Copied!</div>}
			</div>
		);
	}
}
