import React from "react";

// components
import { Link } from "react-router-dom";
import { Button } from "../_commons/Button";
import { SelectFilter } from "../_commons/SelectFilter";
import { SearchFilter } from "../_commons/SearchFilter";

// assets
import CreateIcon from "../_commons/CreateIcon";
import BulkActionIcon from "../_commons/BulkActionIcon";
import HelpIcon from "../_commons/HelpIcon";
import { ButtonIcon } from "../_commons/ButtonIcon";

// constants
export const LOCATION_CREATION_DISABLED_COUNTRIES = ["USA", "United States", "Canada"];

export const Header = (props) => {
	const disabled = (Array.isArray(props.platforms) && props.platforms.length < 1) || false;

	const ACCESS_ALLOWED_BIZ_IDS = {
		76720224: true,
		44007425: true,
		18480538: true,
		46759187: true,
		71574355: true,
		22694411: true,
		85772719: true,
		87361915: true
	};

	return (
		<div className="credits-section-header transactions-list-header">
			<div className="header-text">
				<div className="title">Locations</div>
				<div className="subtitle">Manage your business locations from here</div>
			</div>
			{!props.hideHeaderSectionRight && (
				<div className="header-action-button">
					<div className="filter-buttons">
						{
							// props.switchActions ?
							// <React.Fragment>
							// 	{
							// 		props.isHubManagement &&
							// 		<div className="action-buttons-container no-margin publish-catalogue">
							// 			{
							// 				disabled ?
							// 				<span
							// 					aria-label={"Store(s) are not enabled for any of \n the online ordering platforms."}
							// 					data-balloon-pos={"down-right"}
							// 				>
							// 					<Button
							// 						clickHandler={() => props.openPublishSidebar()}
							// 						classes={"disabled"}
							// 					>
							// 						<img src="assets/icons/icon-publish.svg" alt="" />
							// 						<span>
							// 							Publish
							// 						</span>
							// 					</Button>
							// 				</span>
							// 				:
							// 				<Button
							// 					clickHandler={() => props.openPublishSidebar()}
							// 				>
							// 					<img src="assets/icons/icon-publish.svg" alt="" />
							// 					<span>
							// 						Publish
							// 					</span>
							// 				</Button>
							// 			}
							// 		</div>
							// 	}
							// 	{
							// 		props.isHubManagement &&
							// 		<div className="action-buttons-container store-toggle">
							// 			{
							// 				disabled ?
							// 				<span
							// 					aria-label={"Store(s) are not enabled for any of \n the online ordering platforms."}
							// 					data-balloon-pos={"down"}
							// 				>
							// 					<Button
							// 						type="secondary"
							// 						clickHandler={() => props.openToggleSidebar()}
							// 						classes={"disabled"}
							// 					>
							// 						<img src="assets/icons/icon-open-close.svg" alt="" />
							// 						<span>
							// 							Toggle
							// 						</span>
							// 					</Button>
							// 				</span>
							// 				:
							// 				<Button
							// 						type="secondary"
							// 						clickHandler={() => props.openToggleSidebar()}
							// 					>
							// 						<img src="assets/icons/icon-open-close.svg" alt="" />
							// 						<span>
							// 							Toggle
							// 						</span>
							// 				</Button>
							// 			}
							// 		</div>
							// 	}
							// 	{
							// 		props.isAdmin &&
							// 		<div className="action-buttons-container update-items">
							// 			<Button
							// 				type='secondary'
							// 				clickHandler={() => props.openAssociationSidebar(false)}
							// 			>
							// 				Update Items
							// 			</Button>
							// 		</div>
							// 	}
							// 	{
							// 		props.isAdmin &&
							// 		<div className="action-buttons-container update-items">
							// 			<Button
							// 				type='secondary'
							// 				clickHandler={() => props.openAssociationSidebar(true)}
							// 			>
							// 				Update Modifiers
							// 			</Button>
							// 		</div>
							// 	}
							// 	{
							// 		props.isHubManagement &&
							// 		<div className="action-buttons-container update-items">
							// 			<Button
							// 				classes={"at-btn--success"}
							// 				clickHandler={() => props.openLocationPlatformMappingSidebar(false)}
							// 			>
							// 				<ButtonIcon icon='live' />
							// 				Request To Go Live
							// 			</Button>
							// 		</div>
							// 	}
							// </React.Fragment>
							// :
							<React.Fragment>
								{props.isAdmin && (
									<div
										className={
											"secondary-action-buttons-container" +
											(!props.isInternalUser &&
											LOCATION_CREATION_DISABLED_COUNTRIES.includes(props?.bizCountry)
												? " disabled"
												: "")
										}
									>
										<Link to="/bulk/locations">
											<Button type="secondary">
												<BulkActionIcon />
												<span>Bulk Actions</span>
											</Button>
										</Link>
									</div>
								)}
								{props.isAdmin && (
									<div
										className={
											"action-buttons-container" +
											(!props.isInternalUser &&
											LOCATION_CREATION_DISABLED_COUNTRIES.includes(props?.bizCountry)
												? " disabled"
												: "")
										}
									>
										<Link to="/locations/new">
											<Button>
												<CreateIcon />
												<span>Add Location</span>
											</Button>
										</Link>
									</div>
								)}
							</React.Fragment>
						}
						<div className="help-btn-container">
							<Button clickHandler={props.handlePiperAcademy} type="secondary">
								<HelpIcon />
								<span>Help</span>
							</Button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
