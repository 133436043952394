import React, { useState, useCallback, useEffect } from "react";

// third party
import { SelectFilter } from "../_commons/SelectFilter";
import Compressor from "compressorjs";

// component
import { Uploader } from "../_commons/Uploader";
import { CheckBox } from "../_commons/CheckBox";
import { Button } from "../_commons/Button";
import { Loading } from "../_commons/Loading";
import UploaderV2 from "../_commons/UploaderV2";

// utils
import { client } from "../../client";
import { store } from "../../store/configureStore";
import { msaagesArrayToHtml, trackEvent } from "../../atlas-utils";

// graphql
import {
	GET_MODIFIER_NON_PLATFORM_IMAGES,
	GET_MODIFIER_PLATFORM_IMAGES,
	UPLOAD_MODIFIER_IMAGE,
	DELETE_IMAGE
} from "../../graphql/modifiers";

// actions
import { ActionTypes } from "../../actions/_types";

// constants
import { TRACKING_EVENT_NAMES, TRACKING_STATUS } from "../../client-config";
const ZOMATO = "Zomato";

/**
 * Currently this feature is only supported for Zomato and the platform is hardcoded to Zomato
 * Generic code is commented out for now
 */
function Images({
	modifierId,
	menuModifierInfo,
	isFromMenuSection = false,
	readOnly = true,
	handleForm = () => {},
	modulesEnabled = [],
	platforms = [] // for now only zomato is supported
}) {
	const [loading, setLoading] = useState(false);
	const [platformImages, setPlatformImages] = useState([]);
	const [nonPlatformImages, setNonPlatformImages] = useState([]);
	const [uploadPlatforms, setUploadPlatforms] = useState([]);
	const [uploadImage, setUploadImage] = useState(undefined);
	const [uploadImageResolutions, setUploadImageResolutions] = useState({
		width: 0,
		height: 0
	});
	const [confirmLoading, setConfirmLoading] = useState(false);

	const fetchNonPlatformImages = useCallback((variables) => {
		return client.query({
			query: GET_MODIFIER_NON_PLATFORM_IMAGES,
			variables,
			fetchPolicy: "no-cache"
		});
	}, []);

	const fetchImages = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				id: parseInt(modifierId)
			};
			const respPlatform = await client.query({
				query: GET_MODIFIER_PLATFORM_IMAGES,
				variables,
				fetchPolicy: "no-cache"
			});
			setPlatformImages(
				respPlatform.data.modifier.platformImages?.filter((img) => img?.platforms?.includes(ZOMATO)) || []
			);
			// const respNonPlatform = await fetchNonPlatformImages(variables);
			// setNonPlatformImages(respNonPlatform.data.modifier.nonPlatformImages);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [modifierId]);

	useEffect(() => {
		if (!isFromMenuSection) {
			fetchImages();
		}
	}, [fetchImages]);

	const handleImage = (files) => {
		const imgFile = files[0];
		if (imgFile) {
			const image = new Image();
			image.src = window.URL.createObjectURL(imgFile);

			// proceed only if the uploaded file is a valid image
			image.onload = async function () {
				if (imgFile.size < 2097152) {
					isFromMenuSection ? handleForm("image", imgFile) : setUploadImage(imgFile);
					setUploadImageResolutions({
						width: this.width,
						height: this.height
					});
				} else {
					setConfirmLoading(true);
					const result = await new Promise((resolve, reject) => {
						new Compressor(imgFile, {
							quality: 0.6,
							maxWidth: 4000,
							success: resolve,
							error: reject
						});
					});
					if (result && result.size < 10475200) {
						isFromMenuSection ? handleForm("image", result) : setUploadImage(result);

						setUploadImageResolutions({
							width: this.width,
							height: this.height
						});
						setConfirmLoading(false);
					} else {
						setConfirmLoading(false);
						store.dispatch({
							type: "SHOW_GLOBAL_MESSAGE",
							payload: {
								message: "Image size is too large, please keep it below 10 MB",
								timeout: 3000,
								error: true
							}
						});
					}
				}
			};

			// handle error if uploaded file is not an image
			image.onerror = function () {
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Please upload a valid image.",
						timeout: 3000,
						error: true
					}
				});
			};
		} else {
			store.dispatch({
				type: "SHOW_GLOBAL_MESSAGE",
				payload: {
					message: "Upload an image from your current device",
					timeout: 3000,
					error: true
				}
			});
		}
	};

	const handleClearImage = (e) => {
		if (isFromMenuSection) {
			if (menuModifierInfo?.image) {
				handleForm("image", null);
			} else {
				handleForm("imageUrl", "");
			}
			e.stopPropagation();
			return;
		}
		setUploadImage(undefined);
		setUploadImageResolutions({
			width: 0,
			height: 0
		});
	};

	const handleUpload = useCallback(async () => {
		const eventMeta = {
			// platform: uploadPlatforms.map((plf) => plf.platformName),
			platform: [ZOMATO], // for now only zomato is supported
			format: uploadImage.type,
			size: uploadImage.size / 1000,
			resolution: `${uploadImageResolutions.width}x${uploadImageResolutions.height} px`
		};

		setConfirmLoading(true);
		try {
			const variables = {
				entityId: modifierId,
				entityType: "modifier",
				image: uploadImage
			};
			variables.tags = [ZOMATO]; // for now only zomato is supported

			// only allow one image at a time
			if (platformImages?.length) {
				let existingZomatoImageId = null;
				platformImages.forEach((modifierImages) => {
					if (modifierImages.platforms?.includes(ZOMATO)) {
						existingZomatoImageId = modifierImages.id;
					}
				});
				if (existingZomatoImageId) {
					await client.mutate({
						mutation: DELETE_IMAGE,
						variables: {
							imageIds: [existingZomatoImageId]
						}
					});
				}
			}

			const resp = await client.mutate({
				mutation: UPLOAD_MODIFIER_IMAGE,
				variables
			});
			if (resp.data.saveImage.status.success) {
				eventMeta.status = TRACKING_STATUS.SUCCESS;
				trackEvent(TRACKING_EVENT_NAMES.MODIFIER_IMAGES_UPDATE, eventMeta);

				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Image Uploaded!",
						timeout: 5000,
						error: false
					}
				});
				fetchImages();
				setUploadPlatforms([]);
				setUploadImage(undefined);
				setUploadImageResolutions({
					width: 0,
					height: 0
				});
			} else {
				eventMeta.status = TRACKING_STATUS.FAILURE;
				trackEvent(TRACKING_EVENT_NAMES.MODIFIER_IMAGES_UPDATE, eventMeta);

				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: msaagesArrayToHtml(resp.data.saveImage.status.messages) || "Something went wrong",
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (error) {
			eventMeta.status = TRACKING_STATUS.FAILURE;
			trackEvent(TRACKING_EVENT_NAMES.MODIFIER_IMAGES_UPDATE, eventMeta);

			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setConfirmLoading(false);
	}, [modifierId, uploadImage, uploadPlatforms, fetchImages]);

	const handleDownload = async (url) => {
		await fetch(url.includes("https://") ? url : url.replace("http://", "https://"), {
			method: "GET",
			cache: "no-cache"
		})
			.then((resp) => resp.blob())
			.then((blob) => {
				// process to auto download it
				const fileURL = URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = fileURL;
				link.download = url.replace(/^.*[\\\/]/, "");
				link.click();
			})
			.catch((error) => {
				console.log(error);
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Something went wrong. Please try again later.",
						timeout: 3000,
						error: true
					}
				});
			});
	};

	const handleDelete = useCallback(
		async (id) => {
			setConfirmLoading(true);
			try {
				const variables = {
					imageIds: [id]
				};
				const resp = await client.mutate({
					mutation: DELETE_IMAGE,
					variables
				});
				if (resp.data.deleteImage.status.success) {
					store.dispatch({
						type: "SHOW_GLOBAL_MESSAGE",
						payload: {
							message: "Image Deleted!",
							timeout: 5000,
							error: false
						}
					});
					fetchImages();
					setUploadPlatforms([]);
					setUploadImage(undefined);
					setUploadImageResolutions({
						width: 0,
						height: 0
					});
				} else {
					store.dispatch({
						type: "SHOW_GLOBAL_MESSAGE",
						payload: {
							message:
								msaagesArrayToHtml(resp.data.deleteImage.status.messages) || "Something went wrong",
							timeout: 5000,
							error: true
						}
					});
				}
			} catch (error) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setConfirmLoading(false);
		},
		[fetchImages]
	);

	if (loading) {
		return (
			<div className="P(10px 0)">
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		);
	}

	if (isFromMenuSection) {
		return (
			<div className={"item-images-tab" + (loading ? " no-click" : "")}>
				<UploaderV2
					onChange={handleImage}
					file={menuModifierInfo?.image}
					url={menuModifierInfo?.imageUrl}
					showDelete
					handleDelete={handleClearImage}
				/>
			</div>
		);
	}

	return (
		<div className={"modifier-images-tab" + (loading ? " no-click" : "")}>
			<div className="modifier-upload-container">
				<Uploader
					onChange={handleImage}
					file={uploadImage}
					classes={readOnly ? "read-only" : ""}
					showDelete={true}
					handleDelete={handleClearImage}
				/>
				{/* {!(modulesEnabled.includes("Meraki") && !modulesEnabled.includes("Hub")) && (
					<SelectFilter
						title="Platforms"
						options={platforms}
						readOnly={readOnly}
						field="platform"
						currValue={uploadPlatforms}
						isSearchable={false}
						setFilter={(field, value) => setUploadPlatforms(value)}
						placeholder="Select"
						multi={true}
						labelKey="platformName"
						valueKey="platformName"
					/>
				)} */}
				{!readOnly && (
					<Button clickHandler={handleUpload} classes={!uploadImage ? "disabled" : ""}>
						Upload
					</Button>
				)}
			</div>
			{/* {nonPlatformImages.length > 0 && (
				<div className="image-container">
					<div className="header-text">Select Default</div>
					<div className="images">
						{nonPlatformImages.map((data, i) => (
							<NonPlatformImage
								key={i}
								data={data}
								handleDelete={handleDelete}
								handleDownload={handleDownload}
								readOnly={readOnly}
							/>
						))}
					</div>
				</div>
			)} */}
			{platformImages.length > 0 && (
				<div className="image-container">
					<div className="header-text">Platform Specific</div>
					<div className="images">
						{platformImages.map((data, i) => (
							<PlatformImage
								key={i}
								data={data}
								handleDelete={handleDelete}
								handleDownload={handleDownload}
								readOnly={readOnly}
							/>
						))}
					</div>
				</div>
			)}
			{confirmLoading && <Loading />}
		</div>
	);
}

export default Images;

// const NonPlatformImage = ({ data, handleDelete, handleDownload, readOnly }) => {
// 	const [imageHover, setImageHover] = useState(false);
// 	return (
// 		<div className="image-modifier">
// 			<div onMouseEnter={() => setImageHover(true)} onMouseLeave={() => setImageHover(false)}>
// 				<div className="image">
// 					<img
// 						src={data.image || "/assets/empty-dish.jpg"}
// 						onError={(e) => (e.target.src = "/assets/empty-dish.jpg")}
// 						alt="image"
// 					/>
// 				</div>
// 				{imageHover && (
// 					<div className="image-hover-icons">
// 						<a href={data.image} target="_blank">
// 							<img title="View" src="/assets/icons/icon-view.svg" alt="" />
// 						</a>
// 						<div onClick={() => handleDownload(data.image)}>
// 							<img title="Download" src="/assets/icons/icon-download.svg" alt="" />
// 						</div>
// 						{!readOnly && (
// 							<div onClick={() => handleDelete(data.id)}>
// 								<img title="Delete" src="/assets/icons/icon-delete.svg" alt="" />
// 							</div>
// 						)}
// 					</div>
// 				)}
// 			</div>
// 		</div>
// 	);
// };

const PlatformImage = ({ data, handleDelete, handleDownload, readOnly }) => {
	const [imageHover, setImageHover] = useState(false);
	return (
		<div className="image-modifier">
			<div onMouseEnter={() => setImageHover(true)} onMouseLeave={() => setImageHover(false)}>
				<div className="image">
					<img
						src={data.image || "/assets/empty-dish.jpg"}
						onError={(e) => (e.target.src = "/assets/empty-dish.jpg")}
						alt="image"
					/>
				</div>
				{imageHover && (
					<div className="image-hover-icons">
						<a href={data.image} target="_blank">
							<img title="View" src="/assets/icons/icon-view.svg" alt="" />
						</a>
						<div onClick={() => handleDownload(data.image)}>
							<img title="Download" src="/assets/icons/icon-download.svg" alt="" />
						</div>
						{!readOnly && (
							<div onClick={() => handleDelete(data.id)}>
								<img title="Delete" src="/assets/icons/icon-delete.svg" alt="" />
							</div>
						)}
					</div>
				)}
			</div>
			<div className="platform-tag">
				<div className="title">Platforms</div>
				{data.platforms && data.platforms.length && (
					<div className="platform-value">
						{data.platforms
							.filter((plf) => plf === ZOMATO) // need to remove this filter once other platforms are supported
							.map((plf, i) => (
								<div className="tag" key={i}>
									{plf}
								</div>
							))}
					</div>
				)}
			</div>
		</div>
	);
};
