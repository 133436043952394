import { ActionTypes } from "../actions/_types";

const initialState = {
	dashboardMetrics: {
		data: {},
		error: false,
		loading: false
	},
	storeMetrics: {
		data: {},
		error: false,
		loading: false
	},
	locationMetrics: {
		data: {},
		error: false,
		loading: false
	},
	itemMetrics: {
		data: {},
		error: false,
		loading: false
	}
};

export const periscopeDashboard = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.PERISCOPE_DASHBOARD_REQUEST:
			return {
				...state,
				dashboardMetrics: {
					...state.dashboardMetrics,
					loading: true
				}
			};
		case ActionTypes.PERISCOPE_DASHBOARD_SUCCESS:
			return {
				...state,
				dashboardMetrics: {
					...state.dashboardMetrics,
					data: { ...state.dashboardMetrics.data, ...action.payload.data.getDashboardMetrics },
					error: false,
					loading: false
				}
			};
		case ActionTypes.PERISCOPE_DASHBOARD_FAILURE:
			return {
				...state,
				dashboardMetrics: {
					...state.dashboardMetrics,
					loading: false,
					error: true
				}
			};

		case ActionTypes.PERISCOPE_STORE_METRIC_REQUEST:
			return {
				...state,
				storeMetrics: {
					...state.storeMetrics,
					loading: true
				}
			};
		case ActionTypes.PERISCOPE_STORE_METRIC_SUCCESS:
			return {
				...state,
				storeMetrics: {
					...state.storeMetrics,
					data: { ...state.storeMetrics.data, ...action.payload.data.getStoreMetrics },
					error: false,
					loading: false
				}
			};
		case ActionTypes.PERISCOPE_STORE_METRIC_FAILURE:
			return {
				...state,
				storeMetrics: {
					...state.storeMetrics,
					loading: false,
					error: true
				}
			};

		case ActionTypes.PERISCOPE_LOCATION_REQUEST:
			return {
				...state,
				locationMetrics: {
					...state.locationMetrics,
					loading: true
				}
			};
		case ActionTypes.PERISCOPE_LOCATION_SUCCESS:
			return {
				...state,
				locationMetrics: {
					...state.locationMetrics,
					data: { ...state.locationMetrics.data, ...action.payload.data.getLocationAvailabilityMetrics },
					error: false,
					loading: false
				}
			};
		case ActionTypes.PERISCOPE_LOCATION_FAILURE:
			return {
				...state,
				locationMetrics: {
					...state.locationMetrics,
					error: true,
					loading: false
				}
			};

		case ActionTypes.PERISCOPE_ITEM_REQUEST:
			return {
				...state,
				itemMetrics: {
					...state.itemMetrics,
					loading: true
				}
			};
		case ActionTypes.PERISCOPE_ITEM_SUCCESS:
			return {
				...state,
				itemMetrics: {
					...state.itemMetrics,
					data: { ...action.payload.data.getItemAvailabilityMetrics },
					loading: false,
					error: false
				}
			};
		case ActionTypes.PERISCOPE_ITEM_FAILURE:
			return {
				...state,
				itemMetrics: {
					...state.itemMetrics,
					loading: false,
					error: true
				}
			};

		default:
			return state;
	}
};

const LOCATION_INITIAL_STATE_FILTERS = {
	limit: 10,
	offset: 0,
	count: 0,
	sort: null,
	searchText: "",
	currentFilters: {},
	appliedFilters: {},
	date: null,
	searchText: ""
};

export const periscopeLocationAvailabilityState = (state = LOCATION_INITIAL_STATE_FILTERS, action) => {
	switch (action.type) {
		case ActionTypes.LOCATION_AVAILABILITY_LIST_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.LOCATION_AVAILABILITY_LIST_INITIAL_STATE_SORT:
			return {
				...state,
				sort: null
			};
		case ActionTypes.LOCATION_AVAILABILITY_LIST_STATE_RESET:
			return LOCATION_INITIAL_STATE_FILTERS;

		case ActionTypes.LOCATION_AVAILABILITY_LIST_STATE_APPLY_FILTERS_CHANGE:
			return {
				...state,
				appliedFilters: { ...action.payload }
			};

		default:
			return state;
	}
};

const LOCATION_AVAIL_LIST_INITIAL_STATE = {
	loading: false,
	data: {
		objects: [],
		filters: []
	},
	error: false
};

export const periscopeLocationAvailabilityData = (state = LOCATION_AVAIL_LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.LOCATION_AVAILABILITY_PERISCOPE_STATE_REQUEST: {
			return {
				...state,
				loading: true,
				error: false
			};
		}
		case ActionTypes.LOCATION_AVAILABILITY_PERISCOPE_STATE_SUCCESS: {
			return {
				...state,
				loading: false,
				error: false
			};
		}
		case ActionTypes.LOCATION_AVAILABILITY_PERISCOPE_STATE_FAILURE: {
			return {
				...state,
				error: true,
				loading: false
			};
		}
		case ActionTypes.LOCATION_AVAILABILITY_LIST_STATE_FILTERS_CHANGE:
			return {
				...state,
				data: {
					...state.data,
					filters: [...state.data.filters, action.payload]
				}
			};
		case ActionTypes.LOCATION_AVAILABILITY_LIST_STATE_OBJECTS_CHANGE:
			return {
				...state,
				data: {
					...state.data,
					objects: action.payload
				}
			};

		case ActionTypes.LOCATION_AVAILABILITY_LIST_DATA_RESET:
			return LOCATION_INITIAL_STATE_FILTERS;
		default:
			return state;
	}
};

const ITEM_INITIAL_STATE_FILTERS = {
	limit: 10,
	offset: 0,
	sort: null,
	count: 0,
	searchText: "",
	currentFilters: {},
	appliedFilters: {},
	menuCategoriesFilterValues: [],
	date: null,
	visitHistory: {}
};

export const periscopeItemAvailabilityState = (state = ITEM_INITIAL_STATE_FILTERS, action) => {
	switch (action.type) {
		case ActionTypes.ITEM_AVAILABILITY_STATE_FILTERS:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.ITEM_AVAILABILITY_INITIAL_STATE:
			return {
				ITEM_INITIAL_STATE_FILTERS
			};
		case ActionTypes.ITEM_AVAILABILITY_INITIAL_SORT:
			return {
				...state,
				sort: null
			};
		case ActionTypes.ITEM_AVAILABILITY_APPLY_FILTERS_CHANGE:
			return {
				...state,
				appliedFilters: { ...action.payload }
			};
		default:
			return state;
	}
};

const ITEM_AVAILABILITY_ITEM_INITIAL_DATA = {
	loading: false,
	data: {
		objects: {},
		filters: []
	},
	error: false,
	view: "item"
};

export const periscopeItemAvailabilityData = (state = ITEM_AVAILABILITY_ITEM_INITIAL_DATA, action) => {
	switch (action.type) {
		case ActionTypes.ITEM_AVAIALABILITY_ITEM_STATE_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.ITEM_AVAIALABILITY_ITEM_STATE_SUCCESS: {
			return {
				...state,
				loading: false,
				error: false
			};
		}
		case ActionTypes.ITEM_AVAIALABILITY_ITEM_STATE_DATA_FAILURE: {
			if (action.payload.view !== state.view) {
				return { ...state, error: true, loading: false, data: [] };
			} else {
				return { ...state, error: true, loading: false };
			}
		}
		case ActionTypes.ITEM_AVAIALABILITY_ITEM_STATE_FAILURE: {
			return {
				...state,
				error: true,
				loading: false,
				data: []
			};
		}
		case ActionTypes.ITEM_AVAIALABILITY_STATE_FILTERS:
			return {};
		case ActionTypes.ITEM_AVAILABILITY_STATE_OBJECTS_CHANGE:
			return {
				...state,
				data: {
					...state.data,
					objects: action.payload
				}
			};
		case ActionTypes.ITEM_AVAILABILITY_DATA_FILTERS_CHANGE:
			return {
				...state,
				data: {
					...state.data,
					filters: [...state.data.filters, action.payload]
				}
			};
		case ActionTypes.ITEM_AVAILABILITY_ORIGINAL_DATA_CHANGE:
			let changedData = state.data.objects.data.map((val) => {
				if (val.brandItem.important_item_id == action.payload) {
					let val2 = { ...val, brandItem: { ...val.brandItem, is_important: !val.brandItem.is_important } };
					return val2;
				} else {
					return val;
				}
			});
			return {
				...state,
				data: { ...state.data, objects: { ...state.data.objects, data: changedData } }
			};
		case ActionTypes.ITEM_AVILABILITY_VIEW_CHANGE:
			return {
				...state,
				...action.payload
			};

		case ActionTypes.ITEM_AVAILABILITY_ORIGINAL_DATA_CHANGE_FALSE_VALUE:
			let dataChanged = state.data.objects.data.map((val) => {
				if (val.brandItem.brand_id == action.payload.brandId && val.brandItem.item == action.payload.name) {
					let val2 = {
						...val,
						brandItem: {
							...val.brandItem,
							is_important: !val.brandItem.is_important,
							important_item_id: action.payload.id
						}
					};
					return val2;
				} else {
					return val;
				}
			});
			return {
				...state,
				data: { ...state.data, objects: { ...state.data.objects, data: dataChanged } }
			};

		default:
			return state;
	}
};

const SPECIFIC_ITEM_AVAILABILITY_STATE_ITEM_FILTERS = {
	currentFilters: {
		platform_names: ["all"]
	},
	appliedFilters: {
		platform_names: ["all"]
	},
	menuCategoriesFilterValues: [],
	currentFiltersCollection: {},
	appliedFiltersCollection: {},
	offset: 0,
	limit: 10,
	sort: null
};

export const periscopeSpecificItemAvailabilityItemState = (
	state = SPECIFIC_ITEM_AVAILABILITY_STATE_ITEM_FILTERS,
	action
) => {
	switch (action.type) {
		case ActionTypes.SPECIFIC_ITEM_AVAILABILITY_ITEM_INITIAL_STATE_SORT:
			return {
				...state,
				sort: null
			};
		case ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_ITEM_FILTERS:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.SPECIFIC_ITEM_AVAILABILITY_ITEM_INITIAL_STATE:
			return {
				...state,
				currentFiltersCollection: {},
				appliedFiltersCollection: {},
				offset: 0,
				limit: 10,
				sort: null
			};
		case ActionTypes.SPECIFIC_ITEM_AVAILABILITY_ITEM_FILTERS_CHANGE:
			return {
				...state,
				appliedFiltersCollection: { ...action.payload }
			};
		default:
			return state;
	}
};

const SPECIFIC_ITEM_AVAILABILITY_ITEM_DATA = {
	loading: false,
	data: {
		objects: [],
		filters: []
	},
	error: false
};

export const periscopeSpecificItemAvailabilityItemData = (state = SPECIFIC_ITEM_AVAILABILITY_ITEM_DATA, action) => {
	switch (action.type) {
		case ActionTypes.SPECIFIC_ITEM_AVAILABILITY_DATA_ITEM_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.SPECIFIC_ITEM_AVAILABILITY_DATA_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
				error: false
			};
		case ActionTypes.SPECIFIC_ITEM_AVAILABILITY_DATA_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
				error: true
			};
		case ActionTypes.SPECIFIC_ITEM_AVAILABILITY_DATA_OBJECTS_CHANGE:
			return {
				...state,
				data: { ...state.data, objects: action.payload }
			};
		case ActionTypes.SPECIFIC_ITEM_AVAILABILITY_DATA_FILTERS_CHANGE:
			return {
				...state,
				data: {
					...state.data,
					filters: [...state.data.filters, action.payload]
				}
			};
		default:
			return state;
	}
};

const SPECIFIC_ITEM_AVAILABILITY_STATE_LOCATION_FILTERS = {
	currentFilters: {
		platform_names: ["all"]
	},
	appliedFilters: {
		platform_names: ["all"]
	},
	menuCategoriesFilterValues: [],
	currentFiltersCollection: {},
	appliedFiltersCollection: {},
	offset: 0,
	limit: 10,
	initialCall: false,
	sort: null,
	searchText: ""
};

export const periscopeSpecificItemAvailabilityLocationState = (
	state = SPECIFIC_ITEM_AVAILABILITY_STATE_LOCATION_FILTERS,
	action
) => {
	switch (action.type) {
		case ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_LOCATION_FILTERS:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.SPECIFIC_ITEM_AVAILABILITY_LOCATION_INITIAL_STATE:
			return {
				...state,
				currentFiltersCollection: {},
				appliedFiltersCollection: {},
				offset: 0,
				limit: 10,
				sort: null
			};
		case ActionTypes.SPECIFIC_ITEM_AVAILABILITY_ITEM_LOCATION_FILTERS_CHANGE:
			return {
				...state,
				appliedFiltersCollection: { ...action.payload }
			};
		case ActionTypes.SPECIFIC_ITEM_AVAILABILITY_LOCATION_INITIAL_STATE_SORT:
			return {
				...state,
				sort: null
			};
		default:
			return state;
	}
};

const SPECIFIC_ITEM_AVAILABILITY_LOCATION_DATA = {
	loading: false,
	data: {
		objects: {},
		filters: []
	},
	error: false
};

export const periscopeSpecificItemAvailabilityLocationData = (
	state = SPECIFIC_ITEM_AVAILABILITY_LOCATION_DATA,
	action
) => {
	switch (action.type) {
		case ActionTypes.SPECIFIC_ITEM_AVAILABILITY_DATA_LOCATION_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.SPECIFIC_ITEM_AVAILABILITY_LOCATION_DATA_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
				error: false
			};
		case ActionTypes.SPECIFIC_ITEM_AVAILABILITY_LOCATION_DATA_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
				error: true
			};
		case ActionTypes.SPECIFIC_ITEM_AVAILABILITY_LOCATION_DATA_OBJECTS_CHANGE:
			return {
				...state,
				data: { ...state.data, objects: action.payload }
			};
		case ActionTypes.SPECIFIC_ITEM_AVAILABILITY_LOCATION_DATA_FILTERS_CHANGE:
			return {
				...state,
				data: {
					...state.data,
					filters: [...state.data.filters, action.payload]
				}
			};
		case ActionTypes.ITEM_AVAILABILITY_LOCATION_ORIGINAL_DATA_CHANGE:
			let changedData = state.data.objects.data.map((val) => {
				if (val.brandItem.important_item_id == action.payload) {
					let val2 = { ...val, brandItem: { ...val.brandItem, is_important: !val.brandItem.is_important } };
					return val2;
				} else {
					return val;
				}
			});
			return {
				...state,
				data: { ...state.data, objects: { ...state.data.objects, data: changedData } }
			};

		case ActionTypes.ITEM_AVAILABILITY_LOCATION_ORIGINAL_DATA_CHANGE_FALSE_VALUE:
			let dataChanged = state.data.objects.data.map((val) => {
				if (val.brandItem.brand_id == action.payload.brandId && val.brandItem.item == action.payload.name) {
					let val2 = {
						...val,
						brandItem: {
							...val.brandItem,
							is_important: !val.brandItem.is_important,
							important_item_id: action.payload.id
						}
					};
					return val2;
				} else {
					return val;
				}
			});
			return {
				...state,
				data: { ...state.data, objects: { ...state.data.objects, data: dataChanged } }
			};
		default:
			return state;
	}
};

const ITEM_ISSUES_FILTERS = {
	currentFilters: {},
	appliedFilters: {},
	offset: 0,
	limit: 10,
	initialCall: false,
	sort: null,
	menuCategoriesFilterValues: [],
	text: ""
};

export const periscopeItemIssuesState = (state = ITEM_ISSUES_FILTERS, action) => {
	switch (action.type) {
		case ActionTypes.ITEM_ISSUES_STATE_FILTERS:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.ITEM_ISSUES_INITIAL_SORT:
			return {
				...state,
				sort: null
			};
		case ActionTypes.ITEM_ISSUES_FILTERS_CHANGE:
			return {
				...state,
				appliedFilters: { ...state.appliedFilters, ...action.payload },
				currentFilters: { ...state.currentFilters, ...action.payload }
			};
		case ActionTypes.ITEM_ISSUES_INITIAL_STATE_SORT:
			return {
				...state,
				sort: null
			};
		case ActionTypes.ITEM_ISSUES_APPLY_FILTERS_CHANGE:
			return {
				...state,
				appliedFilters: { ...action.payload }
			};
		default:
			return state;
	}
};

const ITEM_ISSUES_DATA = {
	getItemsIssuesMetrics: {
		loading: false,
		data: {},
		error: false
	},
	loading: false,
	data: {
		objects: {},
		filters: []
	},
	error: false
};

export const periscopeItemIssuesData = (state = ITEM_ISSUES_DATA, action) => {
	switch (action.type) {
		case ActionTypes.ITEM_ISSUES_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.ITEM_ISSUES_STATE_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.ITEM_ISSUES_STATE_SUCCESS:
			return {
				...state,
				loading: false,
				error: false
			};
		case ActionTypes.ITEM_ISSUES_STATE_FAILURE:
			return {
				...state,
				loading: false,
				error: true
			};
		case ActionTypes.ITEM_ISSUES_DATA_FILTERS_CHANGE:
			return {
				...state,
				data: {
					...state.data,
					filters: [...state.data.filters, action.payload]
				}
			};

		case ActionTypes.GET_ITEM_ISSUES_METRIC_STATE_CHANGE:
			return {
				...state,
				getItemsIssuesMetrics: {
					...state.getItemsIssuesMetrics,
					...action.payload
				}
			};
		case ActionTypes.ITEM_ISSUES_OBJECTS_CHANGE:
			return {
				...state,
				data: {
					...state.data,
					objects: action.payload
				}
			};
		case ActionTypes.ITEM_ISSUES_ORIGINAL_DATA_CHANGE:
			let changedData = state.data.objects.data.map((val) => {
				if (val.brandItem.important_item_id == action.payload) {
					let val2 = { ...val, brandItem: { ...val.brandItem, is_important: !val.brandItem.is_important } };
					return val2;
				} else {
					return val;
				}
			});
			return {
				...state,
				data: { ...state.data, objects: { ...state.data.objects, data: changedData } }
			};
		case ActionTypes.ITEM_ISSUES_ORIGINAL_DATA_CHANGE_FALSE_VALUE:
			let dataChanged = state.data.objects.data.map((val) => {
				if (val.brandItem.brand_id == action.payload.brandId && val.brandItem.item == action.payload.name) {
					let val2 = {
						...val,
						brandItem: {
							...val.brandItem,
							is_important: !val.brandItem.is_important,
							important_item_id: action.payload.id
						}
					};
					return val2;
				} else {
					return val;
				}
			});
			return {
				...state,
				data: { ...state.data, objects: { ...state.data.objects, data: dataChanged } }
			};

		default:
			return state;
	}
};

const SPECIFIC_ITEM_ISSUE_STATE = {
	currentFilters: {
		platform_names: ["all"]
	},
	appliedFilters: {
		platform_names: ["all"]
	},
	menuCategoriesFilterValues: [],
	currentFiltersCollection: {},
	appliedFiltersCollection: {},
	offset: 0,
	limit: 10,
	sort: null,
	text: ""
};

export const periscopeSpecificItemIssueState = (state = SPECIFIC_ITEM_ISSUE_STATE, action) => {
	switch (action.type) {
		case ActionTypes.SPECIFIC_ITEM_ISSUE_STATE_FILTERS:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.SPECIFIC_ITEM_ISSUE_STATE_FILTERS_INITIAL_STATE:
			return {
				...state,
				currentFiltersCollection: {},
				appliedFiltersCollection: {},
				offset: 0,
				limit: 10,
				sort: null
			};
		case ActionTypes.SPECIFIC_ITEM_STATE_FILTERS_CHANGE:
			return {
				...state,
				appliedFiltersCollection: { ...action.payload }
			};

		default:
			return state;
	}
};

const SPECIFIC_ISSUE_ITEM_ISSUE_DATA = {
	loading: false,
	data: {
		objects: {},
		filters: []
	},
	error: false
};

export const periscopeSpecificItemIssueData = (state = SPECIFIC_ISSUE_ITEM_ISSUE_DATA, action) => {
	switch (action.type) {
		case ActionTypes.SPECIFIC_ITEM_ISSUE_DATA_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.SPECIFIC_ITEM_ISSUE_DATA_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
				error: false
			};
		case ActionTypes.SPECIFIC_ITEM_ISSUE_DATA_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
				error: true
			};
		case ActionTypes.SPECIFIC_ITEM_ISSUE_DATA_OBJECTS_CHANGE:
			return {
				...state,
				data: { ...state.data, objects: action.payload }
			};
		case ActionTypes.SPECIFIC_ITEM_ISSUE_DATA_FILTERS_CHANGE:
			return {
				...state,
				data: {
					...state.data,
					filters: [...state.data.filters, action.payload]
				}
			};

		default:
			return state;
	}
};

export const ACTIVITY_LOG_STATE = {
	currentFilters: {
		platform_names: ["all"]
	},
	appliedFilters: {
		platform_names: ["all"]
	},
	offset: 0,
	limit: 50,
	text: ""
};

export const periscopeActivityLogState = (state = ACTIVITY_LOG_STATE, action) => {
	switch (action.type) {
		case ActionTypes.ACTIVITY_LOG_STATE_FILTERS:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
};

export const ACTIVITY_LOG_DATA = {
	loading: false,
	data: {
		objects: {},
		filters: []
	},
	error: false
};

export const periscopeActivityLogData = (state = ACTIVITY_LOG_DATA, action) => {
	switch (action.type) {
		case ActionTypes.ACTIVITY_LOG_DATA_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case ActionTypes.ACTIVITY_LOG_DATA_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
				error: false
			};
		case ActionTypes.ACTIVITY_LOG_DATA_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
				error: true
			};
		case ActionTypes.ACTIVITY_LOG_DATA_OBJECTS_CHANGE:
			return {
				...state,
				data: { ...state.data, objects: action.payload }
			};
		default:
			return state;
	}
};

const earlyAccessState = {
	limit: 20,
	offset: 0,
	text: "",
	dataSelected: {},
	locations: {},
	locationsPlatformMapping: [],
	isCheckedAll: false,
	bizSubscription: false,
	waitlist: false
};
export const periscopeEarlyAccessState = (state = earlyAccessState, action) => {
	switch (action.type) {
		case ActionTypes.PERISCOPE_EARLY_ACCESS_STATE_CHANGE:
			return { ...state, ...action.payload };
		default:
			return state;
	}
};

const earlyAccessData = {
	loading: false,
	error: false,
	data: {}
};
export const periscopeEarlyAccessData = (state = earlyAccessData, action) => {
	switch (action.type) {
		case ActionTypes.PERISCOPE_EARLY_ACCESS_DATA_CHANGE:
			return { ...state, data: action.payload, loading: false };
		case ActionTypes.PERISCOPE_EARLY_ACCESS_DATA_REQUEST:
			return { ...state, loading: true, error: false };
		case ActionTypes.PERISCOPE_EARLY_ACCESS_DATA_FAILURE:
			return { ...state, loading: false, error: true };

		default:
			return state;
	}
};

const initialPeriscopeDataResponse = {
	periscopeDataFetch: false,
	event: "",
	bizId: "",
	timestamp: "",
	status: ""
};
export const periscopeDataResponse = (state = initialPeriscopeDataResponse, action) => {
	switch (action.type) {
		case ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE:
			return { ...state, ...action.payload };
		default:
			return state;
	}
};

const initalPeriscopeFilters = {
	cities: [],
	brands: [],
	locations: []
};

export const periscopeFilters = (state = initalPeriscopeFilters, action) => {
	switch (action.type) {
		case ActionTypes.GET_PERISCOPE_ENABLED_CITIES:
			return { ...state, cities: action.payload };
		case ActionTypes.GET_PERISCOPE_ENABLED_BRANDS:
			return { ...state, brands: action.payload };
		case ActionTypes.GET_PERISCOPE_ENABLED_LOCATIONS:
			return { ...state, locations: action.payload };
		default:
			return state;
	}
};
