import React from "react";

// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// components
import { ButtonIcon } from "../components/_commons/ButtonIcon";
import ChartMetric from "../components/Analytics/Common/ChartMetric";
import Popover from "../components/_commons/Popover";

// third party
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";

// graphql
import {
	GET_METRIC_DATA,
	GET_LINE_CHART_DATA,
	GET_GROUPED_LINE_CHART_DATA,
	GET_PIE_CHART_DATA,
	GET_BAR_CHART_DATA,
	GET_STACKED_BAR_CHART_DATA,
	GET_SANKEY_CHART_DATA,
	GET_HEATMAP_CHART_DATA,
	GET_AVAILABILITY_HEATMAP_CHART_DATA,
	GET_TABULAR_DATA
} from "../graphql/analytics";

// utils
import { printCurrency, commifyNumbers, timeStampToDurPreset, formatDate, capitaliseTextStrict } from "../atlas-utils";

// helpers
import { getReadableDateFilter } from "../helpers/analytics";

// actions
import { ActionTypes } from "./_types";

// constants
import { ANALYTICS_DEFAULT_COLORS, ANALYTICS_SANKEY_COLORS } from "../client-config";
const BAR_CHART_KEY_MAP = {
	// lost revenue and lost orders bar chart
	"Platform (Pre-Ack)": "Cancelled Pre Acknowledgement",
	"Platform (Post-Ack)": "Cancelled Post Acknowledgement",
	"Merchant (Pre-Ack)": "Cancelled Pre Acknowledgement",
	"Merchant (Post-Ack)": "Cancelled Post Acknowledgement"
};

// generate random numbers for dummy data
export const getRandomNum = (min, max, decimals = false, nullableBelow) => {
	const num = Math.random() * (max - min + 1) + min;
	return decimals ? num.toFixed(1) : nullableBelow && Math.floor(num) < nullableBelow ? null : Math.floor(num);
};

// get nearest round value for 'yScaleMax' in line and bar charts
// this is to keep the lines and bars within the cartesian grid
export const getNearestRoundValue = (val, multiplier = 1.2) => {
	return Math.floor(val !== 0 ? val * multiplier : 1);
};

// get duration object from applied date filter
export const getDurationObject = (appliedDateFilter) => {
	const { dateFilter: currDateFilter } = appliedDateFilter.current;
	const { dateFilter: compDateFilter } = appliedDateFilter.compare;
	const currDates = currDateFilter.split(",");
	const compDates = compDateFilter.split(",");
	const obj = {};

	// for current date range
	if (currDates.length === 1) {
		obj.duration = {
			preset: currDateFilter
		};
	} else if (currDates[0] && currDates[1]) {
		obj.duration = {
			custom: {
				startDate: currDates[0],
				endDate: currDates[1]
			}
		};
	}

	// for comparison date range
	if (compDates.length === 2 && compDates[0] && compDates[1]) {
		obj.comparisonDuration = {
			startDate: compDates[0],
			endDate: compDates[1]
		};
	}

	return obj;
};

// get applied filters and applied date filter
export const getAllAppliedFilters = (includeBrand = true, includeLocation = true, includePlatform = true) => {
	const isMultibrandEnabled = store.getState().login.loggedInbizDetail.isMultibrandEnabled;
	const { appliedFilters, appliedDateFilter } = store.getState().analyticsFiltersState;
	const bizId = store.getState().login.loggedInbizDetail.id;
	const bizPlatforms = store.getState().configItems.bizPlatforms;
	const durationObject = getDurationObject(appliedDateFilter);
	let filters = [];

	Object.keys(appliedFilters).forEach((f) => {
		const appliedFiltersExludingAll = appliedFilters[f]?.filter((entity) => entity !== "all");

		if (f === "brand_id" && includeBrand && isMultibrandEnabled && appliedFiltersExludingAll?.length) {
			filters.push({
				field: f,
				value: String(appliedFiltersExludingAll.join(","))
			});
		} else if (f === "location_id" && includeLocation && appliedFiltersExludingAll?.length) {
			filters.push({
				field: f,
				value: String(appliedFiltersExludingAll.join(","))
			});
		} else if (f === "platform_names" && includePlatform && appliedFiltersExludingAll?.length) {
			//we need to send platform names instead of id's
			const platformNames = [];
			appliedFilters[f].forEach((platformId) => {
				if (platformId === "prime") {
					platformNames.push("Prime");
				} else {
					const plf = bizPlatforms?.items?.find((plf) => String(plf.id) === String(platformId));
					if (plf) {
						if (plf.platformName === "Meraki") {
							platformNames.push("Urbanpiper");
						} else {
							platformNames.push(plf?.platform?.name || plf.platformName?.split(" ")?.join(""));
						}
					}
				}
			});

			filters.push({
				field: f,
				value: String(platformNames.join(","))
			});
		}
	});

	return { durationObject, filters, bizId, isMultibrandEnabled };
};

// encode applied filters and applied date filter
export const getEncodedAnalyticsFilters = () => {
	const isMultibrandEnabled = store.getState().login.loggedInbizDetail.isMultibrandEnabled;
	const { appliedFilters, appliedDateFilter } = store.getState().analyticsFiltersState;

	// filters to be encoded
	const filters = {};

	// get applied filters
	if (isMultibrandEnabled && appliedFilters?.brand_id) {
		filters.brand_id = appliedFilters?.brand_id?.join(",");
	}
	if (appliedFilters?.location_id) {
		filters.location_id = appliedFilters?.location_id?.join(",");
	}
	if (appliedFilters?.platform_names) {
		filters.platform_names = appliedFilters?.platform_names?.join(",");
	}

	// get applied date filter
	const dateFilter = {};
	if (appliedDateFilter?.current?.dateFilter) {
		dateFilter.current = appliedDateFilter?.current?.dateFilter;
	}
	if (appliedDateFilter?.compare?.dateFilter) {
		dateFilter.compare = appliedDateFilter?.compare?.dateFilter;
	}
	filters.dateFilter = dateFilter;

	// encode filters
	const encodedFilters = encodeURIComponent(JSON.stringify(filters));

	return encodedFilters;
};

// get x-axis readable timestamps for line and bar charts based on duration presets
export const getReadableTimestamp = (timestamp, durationPreset) => {
	let period = "";
	if (durationPreset == "THIS_YEAR") {
		const diff = moment().dayOfYear();
		if (diff < 31) {
			period = "day";
		} else {
			period = "month";
		}
	} else if (durationPreset == "LAST_90_DAYS" || durationPreset == "LAST_30_DAYS" || durationPreset == "THIS_MONTH") {
		period = "90day";
	} else if (durationPreset == "TODAY" || durationPreset == "YESTERDAY") {
		period = "hour";
	} else if (durationPreset == "THIS_WEEK") {
		const diff = moment().diff(moment().day("Monday"), "d");
		if (diff < 2) {
			period = "hour";
		} else {
			period = "day";
		}
	} else if (durationPreset?.includes(",")) {
		const dates = durationPreset.split(",");
		const diff = moment(dates[1]).diff(moment(dates[0]), "d");
		if (diff < 2) {
			period = "hour";
		} else {
			period = "day";
		}
	} else {
		period = "day";
	}
	return timeStampToDurPreset(timestamp, period, true);
};

// get tooltip readable timestamps for line and bar charts based on duration preests
export const getReadableTooltipTime = (timestamp, durationPreset) => {
	switch (durationPreset) {
		case "TODAY":
			return formatDate(timestamp, "DD MMM h A");
		case "YESTERDAY":
			return formatDate(timestamp, "DD MMM h A");
		case "LAST_7_DAYS":
			return formatDate(timestamp, "DD MMM");
		case "THIS_WEEK":
			return formatDate(timestamp, "DD MMM");
		case "LAST_30_DAYS":
			return formatDate(timestamp, "DD MMM");
		case "THIS_MONTH":
			return formatDate(timestamp, "DD MMM");
		case "LAST_90_DAYS":
			return formatDate(timestamp, "DD MMM");
		case "THIS_YEAR":
			return formatDate(timestamp, "DD MMM YYYY");
		default:
			return formatDate(timestamp);
	}
};

// show secondary text in a table column based on metric
const getSecondaryText = (metric, record) => {
	switch (metric) {
		case "revenue_by_location":
			// city
			return (
				<div className="secondary-below">
					<div className="sub-text">{record.field || ""}</div>
				</div>
			);
		case "revenue_by_item":
			// category
			return (
				<div className="secondary-below">
					<div className="sub-text">{record.field || ""}</div>
				</div>
			);
		case "orders_by_location":
			// city
			return (
				<div className="secondary-below">
					<div className="sub-text">{record.field || ""}</div>
				</div>
			);
		case "orders_by_item":
			// category
			return (
				<div className="secondary-below">
					<div className="sub-text">{record.field || ""}</div>
				</div>
			);
		case "item_performance":
			// category
			return (
				<div className="secondary-below">
					<div className="sub-text">{record.field || ""}</div>
				</div>
			);
		default:
			return null;
	}
};

const isPercentageChangeColorsInverted = (metric, column) => {
	let invertedColors = false;
	switch (metric) {
		case "revenue_by_location":
			if (["ORDER_LOST_REVENUE", "ORDER_LOST_ORDERS"].includes(column.key)) {
				invertedColors = true;
			}
			break;
		case "revenue_by_item":
			if (column.key === "ITEM_LOST_ORDERS") {
				invertedColors = true;
			}
			break;
		case "orders_by_location":
			if (["ORDER_LOST_REVENUE", "ORDER_LOST_ORDERS"].includes(column.key)) {
				invertedColors = true;
			}
			break;
		case "orders_by_item":
			if (column.key === "ITEM_LOST_ORDERS") {
				invertedColors = true;
			}
			break;
		case "lost_revenue":
			if (["lost_revenue", "lost_orders"].includes(column.key)) {
				invertedColors = true;
			}
			break;
		case "lost_revenue_breakdown":
			if (column.key === "ORDER_LOST_REVENUE") {
				invertedColors = true;
			}
			break;
		case "lost_orders":
			if (["lost_revenue", "lost_orders"].includes(column.key)) {
				invertedColors = true;
			}
			break;
		case "lost_orders_breakdown":
			if (column.key === "ORDER_LOST_ORDERS") {
				invertedColors = true;
			}
			break;
		default:
			break;
	}
	return invertedColors;
};

// get tabular data for analytics entity list screen
export const getTabularData = (dataset, metric, entity) => {
	const { dateFilter } = store.getState().analyticsFiltersState.appliedDateFilter.compare;
	const compDates = dateFilter?.split(",") || [];
	let isComparisonApplied = false;
	if (compDates.length === 2 && compDates[0] && compDates[1]) {
		isComparisonApplied = true;
	}

	const tabularData = {};

	// count
	tabularData.count = dataset.count || 0;

	// column value type map
	const columnValueTypeMap = {};

	// fields
	tabularData.fields = dataset.columns
		?.filter((col) => col.isPrimary)
		?.map((col) => {
			if (col.valueType) {
				columnValueTypeMap[col.key] = col.valueType;
			}
			return col;
		});

	// columns
	tabularData.columns =
		dataset.columns
			?.filter((col) => col.isPrimary)
			?.map((col) => ({
				name: col.displayName,
				subName: col.subDisplayName,
				field: col.key,
				classes: col.valueType !== "str" ? "align-right" : "",
				sortKey: col.isSortable ? col.key : null,
				render: (record, i, rest) => (
					<div
						className={`table-cell ${col.key?.toLowerCase()} ${
							col.valueType !== "str" ? "align-right" : ""
						}`}
						title={i === 0 && !record?.[`${col.key.toLowerCase()}_description`] ? record[col.key] : null}
						key={i}
					>
						{i === 0 ? (
							<>
								<div className="primary">
									{rest.legends && rest.legends[record[col.key]?.toLowerCase()] && (
										<div
											className="color"
											style={{ backgroundColor: rest.legends[record[col.key]?.toLowerCase()] }}
										></div>
									)}
									{record?.[`${col.key.toLowerCase()}_description`] ? (
										<ChartMetric
											size="small"
											label={record[col.key] || record["id"] || ""}
											description={record?.[`${col.key.toLowerCase()}_description`]}
										/>
									) : (
										<b>{record[col.key] || record["id"] || ""}</b>
									)}
								</div>
								{getSecondaryText(metric, record)}
							</>
						) : (
							<>
								<div className="primary">
									{col.type === "currency" ? printCurrency(rest.currencySymbol) : ""}
									{col.valueType === "str" ? record[col.key] : commifyNumbers(record[col.key])}
									{col.type === "percentage" ? "%" : ""}
									{col.type === "time" ? " mins" : ""}
								</div>
								{isComparisonApplied &&
									record?.[`${col.key.toLowerCase()}_percentage_change`] !== undefined &&
									rest.sortedField === col.key && (
										<div className="secondary-right">
											<Popover
												data={{
													compareValue: record?.[`${col.key.toLowerCase()}_compare_value`],
													compareDate: getReadableDateFilter(true),
													type: col.type
												}}
												showOnHover={true}
												renderPopover={(data) => (
													<div className="compare-info">
														<div className="compare-date">{data.compareDate}</div>
														<div className="compare-value">
															{col.type === "currency"
																? printCurrency(rest.currencySymbol)
																: ""}
															{commifyNumbers(Math.round(data.compareValue || 0))}
															{col.type === "percentage" ? "%" : ""}
															{col.type === "time" ? " mins" : ""}
														</div>
													</div>
												)}
												position={
													i === dataset.columns?.filter((col) => col.isPrimary)?.length - 1 ||
													col.key === rest?.lastColumn
														? "middle-left"
														: "middle-right"
												}
											>
												<div
													className={
														"rate " +
														(!isPercentageChangeColorsInverted(metric, col)
															? record?.[`${col.key.toLowerCase()}_percentage_change`] >=
															  0
																? "up"
																: "down"
															: record?.[`${col.key.toLowerCase()}_percentage_change`] > 0
															? "down"
															: "up")
													}
												>
													<ButtonIcon
														icon={
															!isPercentageChangeColorsInverted(metric, col)
																? record?.[
																		`${col.key.toLowerCase()}_percentage_change`
																  ] >= 0
																	? "up"
																	: "down"
																: record?.[
																		`${col.key.toLowerCase()}_percentage_change`
																  ] <= 0
																? "down"
																: "up"
														}
														classes="icon"
														color={
															!isPercentageChangeColorsInverted(metric, col)
																? record?.[
																		`${col.key.toLowerCase()}_percentage_change`
																  ] >= 0
																	? "#0DA125"
																	: "#D64949"
																: record?.[
																		`${col.key.toLowerCase()}_percentage_change`
																  ] > 0
																? "#D64949"
																: "#0DA125"
														}
													/>
													<div>{record?.[`${col.key.toLowerCase()}_percentage_change`]}%</div>
												</div>
											</Popover>
										</div>
									)}
							</>
						)}
					</div>
				)
			})) || [];

	// rows
	tabularData.rows =
		dataset.rows?.map((row) => {
			const rowData = {};
			row.forEach((obj) => {
				rowData[obj.key] =
					!columnValueTypeMap[obj.key] || columnValueTypeMap[obj.key] === "str"
						? obj.value
						: Math.round(obj.value);
				if (obj.compareValue !== null) {
					rowData[`${obj.key.toLowerCase()}_compare_value`] = isComparisonApplied
						? parseFloat(obj.compareValue).toFixed(1)
						: undefined;
				}
				if (obj.percentageChange !== null) {
					rowData[`${obj.key.toLowerCase()}_percentage_change`] = isComparisonApplied
						? parseFloat(obj.percentageChange).toFixed(1)
						: undefined;
				}
				if (obj.description) {
					rowData[`${obj.key.toLowerCase()}_description`] = obj.description;
				}
			});
			return rowData;
		}) || [];

	return tabularData;
};

// get metrics data
export const getMetricsData = (metricsData = []) => {
	const { dateFilter } = store.getState().analyticsFiltersState.appliedDateFilter.compare;
	const compDates = dateFilter?.split(",") || [];
	let isComparisonApplied = false;
	if (compDates.length === 2 && compDates[0] && compDates[1]) {
		isComparisonApplied = true;
	}

	const metrics = {};
	metricsData.forEach((metric) => {
		metrics[metric.selection?.toLowerCase()?.split(" ")?.join("_")] = {
			...metric,
			value: metric.value === "nan" ? "0" : Math.round(metric.value),
			compareValue: isComparisonApplied
				? !metric.compareValue || metric.compareValue === "nan"
					? 0
					: Math.round(metric.compareValue)
				: undefined,
			percentageChange: isComparisonApplied
				? metric.percentageChange === "nan"
					? "0.0"
					: parseFloat(metric.percentageChange) !== null
					? parseFloat(metric.percentageChange).toFixed(1)
					: undefined
				: undefined
		};
	});
	return metrics;
};

// get bar chart data
export const getBarChartData = (data = [], type, groupKeys = false, showComparison = true) => {
	const { dateFilter } = store.getState().analyticsFiltersState.appliedDateFilter.compare;
	const compDates = dateFilter?.split(",") || [];
	let isComparisonApplied = false;
	if (compDates.length === 2 && compDates[0] && compDates[1] && showComparison) {
		isComparisonApplied = true;
	}

	const chartData = [];
	if (type === "grouped-stacked") {
		data.forEach((obj) => {
			const bar = {
				label: capitaliseTextStrict(obj.label, true),
				current: 0,
				previous: 0,
				dataset: {
					current: {},
					previous: {}
				}
			};
			obj.values.forEach((val) => {
				// current
				bar.dataset.current[val.key] = Math.round(val.value);
				bar.current = bar.current + Math.round(val.value);

				// previous
				bar.dataset.previous[val.key] = Math.round(val.compareValue);
				bar.previous = bar.previous + Math.round(val.compareValue);
			});
			chartData.push(bar);
		});
	} else {
		data.forEach((obj) => {
			const bar = {
				label: capitaliseTextStrict(obj.label, true)
			};
			obj.values.forEach((val) => {
				bar[groupKeys ? BAR_CHART_KEY_MAP[val.key] : val.key ? val.key : "value"] = Math.round(val.value);
				if (isComparisonApplied) {
					bar[groupKeys ? `${BAR_CHART_KEY_MAP[val.key]}*` : val.key ? `${val.key}*` : "compare"] =
						Math.round(val.compareValue);
				}
			});
			chartData.push(bar);
		});
	}
	return chartData;
};

// get pie chart data
export const getPieChartData = (data) => {
	let transformedData = cloneDeep(data);

	// group the bottom values into 'Others'
	if (data.length > 5) {
		transformedData = data.sort((a, b) => b.value - a.value);
		const othersValue = transformedData
			.slice(5)
			.reduce((acc, obj) => acc + (isNaN(obj.value) ? 0 : Number(obj.value)), 0);
		transformedData = transformedData.slice(0, 5);
		transformedData.push({
			id: "others",
			name: "Others",
			value: othersValue,
			compareValue: null
		});
	}

	let total = 0;
	transformedData.forEach((obj) => {
		total += Math.round(obj.value);
	});

	let pieChartData = [...transformedData];
	if (total) {
		pieChartData = pieChartData.map((obj) => ({
			...obj,
			name: capitaliseTextStrict(obj.name, true),
			percent: `${((Math.round(obj.value) / total) * 100).toFixed(2)}%`
		}));
	}
	return pieChartData;
};

// get heatmap chart data
export const getHeatmapChartData = (data = [], showComparison = false, calculatePercentage = true) => {
	const { dateFilter } = store.getState().analyticsFiltersState.appliedDateFilter.compare;
	const compDates = dateFilter?.split(",") || [];
	let isComparisonApplied = false;
	if (compDates.length === 2 && compDates[0] && compDates[1] && showComparison) {
		isComparisonApplied = true;
	}

	let total = 0;
	data.forEach((obj) => {
		obj.data.forEach((cell) => {
			total += Number(cell?.value);
		});
	});

	const chartData = [];
	data.forEach((obj, i) => {
		const row = {};
		row.id = isComparisonApplied ? getReadableDateFilter(i !== 0) : obj.y;
		row.data = obj.data.map((cell) => ({
			...cell,
			x: cell.x,
			y: calculatePercentage ? Math.round(cell?.value) : cell?.value?.toFixed(2),
			percent: calculatePercentage
				? Math.round(total) > 0
					? `${((Number(cell?.value) / Math.round(total)) * 100).toFixed(2)}%`
					: "0.00%"
				: `${cell?.value?.toFixed(2)}%`
		}));
		chartData.push(row);
	});
	return chartData;
};

// verify selected option and get filtered list of options in compare filter
const verifyAndGetCompareFilter = (selected, options) => {
	const { current } = store.getState().analyticsFiltersState.appliedDateFilter;
	let validOptions = [];
	let currCompare = selected || {};
	let filteredOptions = [];
	let compareFilterDisabled = false;

	if (current.dateTypeSelected.value === "range" || ["TODAY", "YESTERDAY"].includes(current.dateFilter)) {
		// hours
		validOptions = ["Hours"];
		// disable compare filter when date filter is either Today, Yesterday or custom date selection
		compareFilterDisabled = true;
	} else if (["THIS_WEEK", "LAST_7_DAYS", "THIS_MONTH"].includes(current.dateFilter)) {
		// hours, days
		validOptions = ["Hours", "Days of the Week"];
	} else if (["LAST_30_DAYS", "LAST_15_DAYS"].includes(current.dateFilter)) {
		// hours, days, dates
		validOptions = ["Hours", "Days of the Week", "Dates"];
	} else if (current.dateFilter === "LAST_90_DAYS") {
		// days, weeks
		validOptions = ["Days of the Week", "Weeks"];
	} else if (current.dateFilter === "THIS_YEAR") {
		// days, dates, weeks, months
		validOptions = ["Days of the Week", "Dates", "Weeks", "Months"];
	}
	filteredOptions = options.filter((opt) => validOptions.includes(opt.label));
	currCompare = !validOptions.includes(selected?.label) ? filteredOptions[0] : selected;

	return { currCompare, filteredOptions, compareFilterDisabled };
};

// get table columns selector fields
export const getTableColumnsSelectorFields = (tableData, currSelectedColumns) => {
	const tableColumnsSelected = {
		columns: {
			...tableData.fields.reduce(
				(obj, col) => ({
					...obj,
					[col.key]:
						currSelectedColumns?.[col.key] !== undefined ? currSelectedColumns?.[col.key] : col.isDefault
				}),
				{}
			)
		}
	};
	return tableColumnsSelected;
};

// revenue analytics
export const updateRevenueAnalyticsState = (metric, data) => {
	store.dispatch({
		type: ActionTypes.UPDATE_REVENUE_ANALYTICS_STATE,
		payload: {
			metric,
			data
		}
	});
};

export const fetchNetRevenueMetrics = async (metric) => {
	updateRevenueAnalyticsState(metric, { metricsLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "revenue_net_revenue_metrics",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateRevenueAnalyticsState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchNetRevenueChart = async (metric) => {
	updateRevenueAnalyticsState(metric, { chartLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "revenue_line",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let maxValue = 0;
		const graphData = resp.data.getLineChartData
			? resp.data.getLineChartData?.objects?.map((obj, i) => ({
					...obj,
					id:
						resp.data.getLineChartData?.objects?.length > 1
							? `Net Revenue (${getReadableDateFilter(i > 0)})${i !== 0 ? "*" : ""}`
							: "Net Revenue",
					tooltipYName: `Net Revenue${i !== 0 ? "*" : ""}`,
					data: obj.data.map((pt) => {
						if (Math.round(pt.y) > maxValue) {
							maxValue = Math.round(pt.y);
						}
						return {
							...pt,
							df: btoa(pt.x + JSON.stringify(durationObject))
						};
					})
			  }))
			: [];

		updateRevenueAnalyticsState(metric, {
			chartLoading: false,
			graphData,
			yScaleMax: getNearestRoundValue(maxValue)
		});
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchRevenueBreakdownChart = async (metric) => {
	const { selectedChart, breakdownBy, graphData, legends } = store.getState().revenueAnalytics[metric];
	updateRevenueAnalyticsState(metric, { chartLoading: true });
	try {
		const { durationObject, filters, bizId, isMultibrandEnabled } = getAllAppliedFilters();
		const pieChartBreakdownQueries = {
			platform: "revenue_breakdown_plt_pie",
			brand: "revenue_breakdown_brand_pie"
		};
		const lineChartBreakdownQueries = {
			platform: "revenue_breakdown_plt_line",
			brand: "revenue_breakdown_brand_line"
		};
		const updatedBreakdownBy =
			isMultibrandEnabled && filters.find((filter) => filter.field === "brand_id")
				? { label: "Platform", value: "platform" }
				: isMultibrandEnabled && filters.find((filter) => filter.field === "platform_names")
				? { label: "Brand", value: "brand" }
				: breakdownBy;

		const variables = {
			query:
				selectedChart === "pie"
					? pieChartBreakdownQueries[updatedBreakdownBy?.value]
					: lineChartBreakdownQueries[updatedBreakdownBy?.value],
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};
		if (selectedChart === "pie") {
			variables.sort = {
				field: "pie",
				order: "DESC"
			};
		}

		const resp = await client.query({
			query: selectedChart === "pie" ? GET_PIE_CHART_DATA : GET_GROUPED_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const legendColors = { ...legends };
		if (selectedChart === "pie" && resp.data.getPieChartData?.objects) {
			resp.data.getPieChartData.objects.forEach((obj, i) => {
				legendColors[obj.name?.toLowerCase()] =
					i > 4 ? ANALYTICS_DEFAULT_COLORS[5] : ANALYTICS_DEFAULT_COLORS[String(i).slice(-1)];
			});
		} else if (resp.data.getGroupedLineChartData?.objects) {
			resp.data.getGroupedLineChartData.objects.forEach((obj, i) => {
				legendColors[obj.id?.split("#")?.[0]?.toLowerCase()] =
					ANALYTICS_DEFAULT_COLORS[
						durationObject?.comparisonDuration ? String(Math.floor(i / 2)).slice(-1) : String(i).slice(-1)
					];
			});
		}

		let maxValue = 0;
		const updatedGraphData = {
			...graphData,
			[selectedChart]:
				selectedChart === "pie"
					? getPieChartData(resp.data.getPieChartData?.objects || [])
					: resp.data.getGroupedLineChartData?.objects?.map((obj, i) => ({
							...obj,
							id:
								durationObject?.comparisonDuration && i % 2 !== 0
									? `${obj.id?.split("#")?.[0]}*`
									: obj.id?.split("#")?.[0],
							color: ANALYTICS_DEFAULT_COLORS[
								durationObject?.comparisonDuration
									? String(Math.floor(i / 2)).slice(-1)
									: String(i).slice(-1)
							],
							data: obj.data.map((pt) => {
								if (Math.round(pt.y) > maxValue) {
									maxValue = Math.round(pt.y);
								}
								return pt;
							})
					  })) || []
		};

		updateRevenueAnalyticsState(metric, {
			chartLoading: false,
			breakdownBy: updatedBreakdownBy,
			graphData: updatedGraphData,
			legends: legendColors,
			yScaleMax: getNearestRoundValue(maxValue)
		});
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchRevenueBreakdownTable = async (metric) => {
	const { sort, breakdownBy } = store.getState().revenueAnalytics[metric];
	updateRevenueAnalyticsState(metric, { tableLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const tableBreakdownQueries = {
			platform: "revenue_breakdown_plt_tabular",
			brand: "revenue_breakdown_brand_tabular"
		};
		const variables = {
			query: tableBreakdownQueries[breakdownBy.value],
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateRevenueAnalyticsState(metric, {
			tableLoading: false,
			tabularData: getTabularData(resp.data.getTabularData?.objects || {}, metric),
			hideColumns: breakdownBy.value === "platform" ? ["brand"] : ["platform"]
		});
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { tableLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchAverageOrderValueMetrics = async (metric) => {
	updateRevenueAnalyticsState(metric, { metricsLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "revenue_avg_order_value",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateRevenueAnalyticsState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchAverageOrderValueChart = async (metric) => {
	updateRevenueAnalyticsState(metric, { chartLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_value_distribution",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});
		let totalOrders = {};
		resp.data.getLineChartData.objects.forEach((line, i) => {
			let total = 0;
			line.data.forEach((pt) => {
				total += Math.round(pt.y);
			});
			totalOrders[i] = total;
		});

		let maxValue = 0;
		const graphData = resp.data.getLineChartData
			? resp.data.getLineChartData?.objects?.map((obj, i) => ({
					...obj,
					id:
						resp.data.getLineChartData?.objects?.length > 1
							? `Number of Orders (${getReadableDateFilter(i > 0)})${i !== 0 ? "*" : ""}`
							: "Number of Orders",
					tooltipYName: `Number of Orders${i !== 0 ? "*" : ""}`,
					data: obj.data.map((pt) => {
						if (Math.round(pt.y) > maxValue) {
							maxValue = Math.round(pt.y);
						}
						const point = { ...pt };
						point.percent =
							totalOrders[i] > 0 ? ((Math.round(pt.y) / totalOrders[i]) * 100).toFixed(2) : 0.0;
						return point;
					})
			  }))
			: [];

		updateRevenueAnalyticsState(metric, {
			chartLoading: false,
			graphData,
			yScaleMax: getNearestRoundValue(maxValue)
		});
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchRevenueByLocation = async (metric = "revenue_by_location", applFilters = {}) => {
	const {
		limit,
		offset,
		sort,
		tabularData,
		tableColumnsSelected,
		searchFieldSelected = {},
		searchFieldValue = ""
	} = store.getState().revenueAnalytics[metric];
	updateRevenueAnalyticsState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "revenue_by_location_tabular",
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject,
				limit,
				offset
			}
		};

		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}

		// detail view filters
		Object.keys(applFilters).forEach((f) => {
			if (applFilters[f] && applFilters[f]?.value && applFilters[f]?.value !== "all") {
				variables.filters.push({
					field: f,
					value: applFilters[f]?.value
				});
			}
		});

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const tableData = getTabularData(resp.data.getTabularData?.objects || {}, metric);

		updateRevenueAnalyticsState(metric, {
			loading: false,
			tabularData: {
				...tabularData,
				...tableData
			},
			tableColumnsSelected: getTableColumnsSelectorFields(tableData, tableColumnsSelected?.columns),
			searchKeywords: resp.data.getTabularData?.searchKeywords,
			filters: resp.data.getTabularData?.filters
		});
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchRevenueByItem = async (metric = "revenue_by_item", applFilters = {}) => {
	const {
		limit,
		offset,
		sort,
		tabularData,
		tableColumnsSelected,
		searchFieldSelected = {},
		searchFieldValue = ""
	} = store.getState().revenueAnalytics[metric];
	updateRevenueAnalyticsState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "revenue_by_item_tabular",
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject,
				limit,
				offset
			}
		};

		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}

		// detail view filters
		Object.keys(applFilters).forEach((f) => {
			if (applFilters[f] && applFilters[f]?.value && applFilters[f]?.value !== "all") {
				variables.filters.push({
					field: f,
					value: applFilters[f]?.value
				});
			}
		});

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const tableData = getTabularData(resp.data.getTabularData?.objects || {}, metric);

		updateRevenueAnalyticsState(metric, {
			loading: false,
			tabularData: {
				...tabularData,
				...tableData
			},
			tableColumnsSelected: getTableColumnsSelectorFields(tableData, tableColumnsSelected?.columns),
			searchKeywords: resp.data.getTabularData?.searchKeywords,
			filters: resp.data.getTabularData?.filters
		});
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchLostRevenueMetrics = async (metric) => {
	updateRevenueAnalyticsState(metric, { metricsLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "revenue_lost_revenue",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateRevenueAnalyticsState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchLostRevenueChart = async (metric) => {
	const { selectedChart, showComparison, graphData, yScaleMax, maxValue } = store.getState().revenueAnalytics[metric];
	updateRevenueAnalyticsState(metric, { chartLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: selectedChart === "bar" ? "revenue_lost_revenue_bar" : "revenue_lost_revenue_line",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// delete comparison duration object
		if (!showComparison) {
			delete variables.requiredFilters.comparisonDuration;
		}

		const resp = await client.query({
			query: selectedChart === "bar" ? GET_BAR_CHART_DATA : GET_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let chartMaxValue = 0;
		const updatedGraphData = {
			...graphData,
			[selectedChart]:
				selectedChart === "bar"
					? resp.data.getBarChartData
						? getBarChartData(resp.data.getBarChartData?.objects, "grouped", true, showComparison).map(
								(bar) => {
									Object.keys(bar).forEach((key) => {
										if (typeof bar[key] === "number" && Math.round(bar[key]) > chartMaxValue) {
											chartMaxValue = Math.round(bar[key]);
										}
									});
									return bar;
								}
						  )
						: []
					: resp.data.getLineChartData
					? resp.data.getLineChartData?.objects?.map((obj, i) => ({
							...obj,
							id:
								resp.data.getLineChartData?.objects?.length > 1
									? `Lost Revenue (${getReadableDateFilter(i > 0)})${i !== 0 ? "*" : ""}`
									: "Lost Revenue",
							tooltipYName: `Lost Revenue${i !== 0 ? "*" : ""}`,
							data: obj.data.map((pt) => {
								if (Math.round(pt.y) > chartMaxValue) {
									chartMaxValue = Math.round(pt.y);
								}
								return pt;
							})
					  }))
					: []
		};

		updateRevenueAnalyticsState(metric, {
			chartLoading: false,
			graphData: updatedGraphData,
			showComparison: !durationObject?.comparisonDuration ? false : showComparison,
			maxValue: selectedChart === "bar" ? getNearestRoundValue(chartMaxValue) : maxValue,
			yScaleMax: selectedChart === "line" ? getNearestRoundValue(chartMaxValue) : yScaleMax
		});
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchLostRevenueTable = async (metric) => {
	const { sort } = store.getState().revenueAnalytics[metric];
	updateRevenueAnalyticsState(metric, { tableLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "revenue_lost_revenue_tabular",
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateRevenueAnalyticsState(metric, {
			tableLoading: false,
			tabularData: getTabularData(resp.data.getTabularData?.objects || {}, metric)
		});
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { tableLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchLostRevenueBreakdownChart = async (metric) => {
	const { selectedChart, showComparison, breakdownBy, graphData, yScaleMax, maxValue } =
		store.getState().revenueAnalytics[metric];
	updateRevenueAnalyticsState(metric, { chartLoading: true });
	try {
		const { durationObject, filters, bizId, isMultibrandEnabled } = getAllAppliedFilters();
		const barChartBreakdownQueries = {
			platform: "revenue_lost_revenue_plt_stacked_bar",
			brand: "revenue_lost_revenue_brand_stacked_bar"
		};
		const lineChartBreakdownQueries = {
			platform: "revenue_lost_revenue_breakdown_plt_line",
			brand: "revenue_lost_revenue_breakdown_brand_line"
		};
		const updatedBreakdownBy =
			isMultibrandEnabled && filters.find((filter) => filter.field === "brand_id")
				? { label: "Platform", value: "platform" }
				: isMultibrandEnabled && filters.find((filter) => filter.field === "platform_names")
				? { label: "Brand", value: "brand" }
				: breakdownBy;

		const variables = {
			query:
				selectedChart === "bar"
					? barChartBreakdownQueries[updatedBreakdownBy?.value]
					: lineChartBreakdownQueries[updatedBreakdownBy?.value],
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// delete comparison duration object
		if (!showComparison) {
			delete variables.requiredFilters.comparisonDuration;
		}

		const resp = await client.query({
			query: selectedChart === "bar" ? GET_STACKED_BAR_CHART_DATA : GET_GROUPED_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let chartMaxValue = 0;
		const updatedGraphData = {
			...graphData,
			[selectedChart]:
				selectedChart === "bar"
					? resp.data.getStackedBarChartData
						? getBarChartData(
								resp.data.getStackedBarChartData?.objects,
								showComparison && durationObject?.comparisonDuration ? "grouped-stacked" : "stacked",
								false,
								showComparison
						  ).map((bar) => {
								let tempMaxValue = 0;
								Object.keys(bar).forEach((key) => {
									if (
										(!showComparison || !durationObject?.comparisonDuration) &&
										typeof bar[key] === "number"
									) {
										// stacked bar chart
										tempMaxValue += Math.round(bar[key]);
									} else if (typeof bar[key] === "number" && Math.round(bar[key]) > chartMaxValue) {
										// grouped-stacked bar chart
										chartMaxValue = Math.round(bar[key]);
									}
								});
								if (tempMaxValue > 0 && tempMaxValue > chartMaxValue) {
									chartMaxValue = tempMaxValue;
								}
								return bar;
						  })
						: []
					: resp.data.getGroupedLineChartData?.objects?.map((obj, i) => ({
							...obj,
							id:
								showComparison && durationObject?.comparisonDuration && i % 2 !== 0
									? `${obj.id?.split("#")?.[0]}*`
									: obj.id?.split("#")?.[0],
							color: ANALYTICS_DEFAULT_COLORS[
								showComparison && durationObject?.comparisonDuration
									? String(Math.floor(i / 2)).slice(-1)
									: String(i).slice(-1)
							],
							data: obj.data.map((pt) => {
								if (Math.round(pt.y) > chartMaxValue) {
									chartMaxValue = Math.round(pt.y);
								}
								return pt;
							})
					  })) || []
		};

		updateRevenueAnalyticsState(metric, {
			chartLoading: false,
			breakdownBy: updatedBreakdownBy,
			graphData: updatedGraphData,
			showComparison: !durationObject?.comparisonDuration ? false : showComparison,
			maxValue: selectedChart === "bar" ? getNearestRoundValue(chartMaxValue) : maxValue,
			yScaleMax: selectedChart === "line" ? getNearestRoundValue(chartMaxValue) : yScaleMax
		});
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchLostRevenueBreakdownTable = async (metric) => {
	const { sort, breakdownBy } = store.getState().revenueAnalytics[metric];
	updateRevenueAnalyticsState(metric, { tableLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const tableBreakdownQueries = {
			platform: "revenue_lost_revenue_plt_breakdown_tabular",
			brand: "revenue_lost_revenue_brand_breakdown_tabular"
		};
		const variables = {
			query: tableBreakdownQueries[breakdownBy.value],
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateRevenueAnalyticsState(metric, {
			tableLoading: false,
			tabularData: getTabularData(resp.data.getTabularData?.objects || {}, metric),
			hideColumns: breakdownBy.value === "platform" ? ["brand"] : ["platform"]
		});
	} catch (error) {
		console.log(error);
		updateRevenueAnalyticsState(metric, { tableLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

// order analytics
export const updateOrderAnalyticsState = (metric, data) => {
	store.dispatch({
		type: ActionTypes.UPDATE_ORDER_ANALYTICS_STATE,
		payload: {
			metric,
			data
		}
	});
};

export const fetchOrdersReceivedMetrics = async (metric) => {
	updateOrderAnalyticsState(metric, { metricsLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_received_orders_metrics",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateOrderAnalyticsState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOrdersReceivedChart = async (metric) => {
	updateOrderAnalyticsState(metric, { chartLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_received_line",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let maxValue = 0;
		const graphData = resp.data.getLineChartData
			? resp.data.getLineChartData?.objects?.map((obj, i) => ({
					...obj,
					id:
						resp.data.getLineChartData?.objects?.length > 1
							? `Orders Received (${getReadableDateFilter(i > 0)})${i !== 0 ? "*" : ""}`
							: "Orders Received",
					tooltipYName: `Orders Received${i !== 0 ? "*" : ""}`,
					data: obj.data.map((pt) => {
						if (Math.round(pt.y) > maxValue) {
							maxValue = Math.round(pt.y);
						}
						return pt;
					})
			  }))
			: [];

		updateOrderAnalyticsState(metric, {
			chartLoading: false,
			graphData,
			yScaleMax: getNearestRoundValue(maxValue)
		});
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOrderPerformanceMetrics = async (metric) => {
	updateOrderAnalyticsState(metric, { metricsLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_overall_performance",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateOrderAnalyticsState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOrderPerformanceChart = async (metric) => {
	updateOrderAnalyticsState(metric, { chartLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_performance_sankey",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};
		const colors = {
			"Orders Received": ANALYTICS_SANKEY_COLORS["sankey1"],
			"Orders Completed": ANALYTICS_SANKEY_COLORS["sankeyPositive"],
			// 'Rejected': ANALYTICS_SANKEY_COLORS['sankeyIntermediate1'],
			"Cancelled Pre-Ack": ANALYTICS_SANKEY_COLORS["sankeyIntermediate2"],
			"Cancelled Post-Ack": ANALYTICS_SANKEY_COLORS["sankeyNegative"]
		};

		const resp = await client.query({
			query: GET_SANKEY_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});
		const sankeyData = resp.data.getSankeyChartData.objects;

		// calculate total orders
		let totalOrders = 0;
		sankeyData.links.forEach((link) => {
			totalOrders += Math.round(link.value);
		});

		let maxValue = 0;
		const graphData = {
			...sankeyData,
			nodes: sankeyData.nodes.map((node) => ({
				...node,
				nodeColor: colors[node.id]
			})),
			links: sankeyData.links
				.map((link) => {
					if (Math.round(link.value) > maxValue) {
						maxValue = Math.round(link.value);
					}
					// calculate percentage values to show on sankey chart
					return {
						...link,
						value: totalOrders > 0 ? (Math.round(link.value) / totalOrders) * 100 : 0.0,
						count: Math.round(link.value),
						startColor: colors[link.target],
						endColor: colors[link.target]
					};
				})
				.filter((link) => link.value > 0)
		};
		updateOrderAnalyticsState(metric, {
			chartLoading: false,
			graphData,
			maxValue
		});
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOrdersBreakdownChart = async (metric) => {
	const { selectedChart, breakdownBy, graphData, legends } = store.getState().ordersAnalytics[metric];
	updateOrderAnalyticsState(metric, { chartLoading: true });
	try {
		const { durationObject, filters, bizId, isMultibrandEnabled } = getAllAppliedFilters();
		const pieChartBreakdownQueries = {
			platform: "order_received_breakdown_plt_pie",
			brand: "order_received_breakdown_brand_pie"
		};
		const lineChartBreakdownQueries = {
			platform: "order_breakdown_plt_line",
			brand: "order_breakdown_brand_line"
		};
		const updatedBreakdownBy =
			isMultibrandEnabled && filters.find((filter) => filter.field === "brand_id")
				? { label: "Platform", value: "platform" }
				: isMultibrandEnabled && filters.find((filter) => filter.field === "platform_names")
				? { label: "Brand", value: "brand" }
				: breakdownBy;

		const variables = {
			query:
				selectedChart === "pie"
					? pieChartBreakdownQueries[updatedBreakdownBy?.value]
					: lineChartBreakdownQueries[updatedBreakdownBy?.value],
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};
		if (selectedChart === "pie") {
			variables.sort = {
				field: "pie",
				order: "DESC"
			};
		}

		const resp = await client.query({
			query: selectedChart === "pie" ? GET_PIE_CHART_DATA : GET_GROUPED_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const legendColors = { ...legends };
		if (selectedChart === "pie" && resp.data.getPieChartData?.objects) {
			resp.data.getPieChartData.objects.forEach((obj, i) => {
				legendColors[obj.name?.toLowerCase()] =
					i > 4 ? ANALYTICS_DEFAULT_COLORS[5] : ANALYTICS_DEFAULT_COLORS[String(i).slice(-1)];
			});
		} else if (resp.data.getGroupedLineChartData?.objects) {
			resp.data.getGroupedLineChartData.objects.forEach((obj, i) => {
				legendColors[obj.id?.split("#")?.[0]?.toLowerCase()] =
					ANALYTICS_DEFAULT_COLORS[
						durationObject?.comparisonDuration ? String(Math.floor(i / 2)).slice(-1) : String(i).slice(-1)
					];
			});
		}

		let maxValue = 0;
		const updatedGraphData = {
			...graphData,
			[selectedChart]:
				selectedChart === "pie"
					? getPieChartData(resp.data.getPieChartData?.objects || [])
					: resp.data.getGroupedLineChartData?.objects?.map((obj, i) => ({
							...obj,
							id:
								durationObject?.comparisonDuration && i % 2 !== 0
									? `${obj.id?.split("#")?.[0]}*`
									: obj.id?.split("#")?.[0],
							color: ANALYTICS_DEFAULT_COLORS[
								durationObject?.comparisonDuration
									? String(Math.floor(i / 2)).slice(-1)
									: String(i).slice(-1)
							],
							data: obj.data.map((pt) => {
								if (Math.round(pt.y) > maxValue) {
									maxValue = Math.round(pt.y);
								}
								return pt;
							})
					  })) || []
		};

		updateOrderAnalyticsState(metric, {
			chartLoading: false,
			breakdownBy: updatedBreakdownBy,
			graphData: updatedGraphData,
			legends: legendColors,
			yScaleMax: getNearestRoundValue(maxValue)
		});
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOrdersBreakdownTable = async (metric) => {
	const { sort, breakdownBy } = store.getState().ordersAnalytics[metric];
	updateOrderAnalyticsState(metric, { tableLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const tableBreakdownQueries = {
			platform: "order_breakdown_plt_tabular",
			brand: "order_breakdown_brand_tabular"
		};
		const variables = {
			query: tableBreakdownQueries[breakdownBy.value],
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateOrderAnalyticsState(metric, {
			tableLoading: false,
			tabularData: getTabularData(resp.data.getTabularData?.objects || {}, metric),
			hideColumns: breakdownBy.value === "platform" ? ["brand"] : ["platform"]
		});
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { tableLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOrdersByLocation = async (metric = "orders_by_location", applFilters = {}) => {
	const {
		limit,
		offset,
		sort,
		tabularData,
		tableColumnsSelected,
		searchFieldSelected = {},
		searchFieldValue = ""
	} = store.getState().ordersAnalytics[metric];
	updateOrderAnalyticsState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_by_location_tabular",
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject,
				limit,
				offset
			}
		};

		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}

		// detail view filters
		Object.keys(applFilters).forEach((f) => {
			if (applFilters[f] && applFilters[f]?.value && applFilters[f]?.value !== "all") {
				variables.filters.push({
					field: f,
					value: applFilters[f]?.value
				});
			}
		});

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const tableData = getTabularData(resp.data.getTabularData?.objects || {}, metric);

		updateOrderAnalyticsState(metric, {
			loading: false,
			tabularData: {
				...tabularData,
				...tableData
			},
			tableColumnsSelected: getTableColumnsSelectorFields(tableData, tableColumnsSelected?.columns),
			searchKeywords: resp.data.getTabularData?.searchKeywords,
			filters: resp.data.getTabularData?.filters
		});
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOrderFrequency = async (metric) => {
	const { compare, showComparison } = store.getState().ordersAnalytics[metric];
	updateOrderAnalyticsState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const compareOptions = [
			{ label: "Hours", value: "ORDER_CREATED_DATE_HOUR" },
			{ label: "Days of the Week", value: "ORDER_CREATED_WEEK_DAY" },
			{ label: "Dates", value: "ORDER_CREATED_DATE" },
			{ label: "Weeks", value: "ORDER_CREATED_MONTH_WEEK" },
			{ label: "Months", value: "ORDER_CREATED_MONTH" }
		];
		const { currCompare, filteredOptions, compareFilterDisabled } = verifyAndGetCompareFilter(
			compare,
			compareOptions
		);

		const variables = {
			query: "order_order_distribution_heatmap",
			filters: [...filters, { field: "group_by", value: currCompare.value }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// delete comparison duration object
		if (!showComparison) {
			delete variables.requiredFilters.comparisonDuration;
		}

		const resp = await client.query({
			query: GET_HEATMAP_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const graphData = getHeatmapChartData(resp.data.getHeatmapChartData?.objects, showComparison);
		let maxValue = 0;
		graphData.forEach((obj) => {
			obj.data.forEach((cell) => {
				if (cell.y > maxValue) {
					maxValue = cell.y;
				}
			});
		});

		updateOrderAnalyticsState(metric, {
			loading: false,
			graphData,
			maxValue,
			compare: currCompare,
			applCompare: currCompare,
			compareOptions: filteredOptions,
			compareFilterDisabled,
			showComparison: !durationObject?.comparisonDuration ? false : showComparison,
			applShowComparison: !durationObject?.comparisonDuration ? false : showComparison
		});
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOrdersByItem = async (metric = "orders_by_item", applFilters = {}) => {
	const {
		limit,
		offset,
		sort,
		tabularData,
		tableColumnsSelected,
		searchFieldSelected = {},
		searchFieldValue = ""
	} = store.getState().ordersAnalytics[metric];
	updateOrderAnalyticsState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_by_item_tabular",
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject,
				limit,
				offset
			}
		};

		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}

		// detail view filters
		Object.keys(applFilters).forEach((f) => {
			if (applFilters[f] && applFilters[f]?.value && applFilters[f]?.value !== "all") {
				variables.filters.push({
					field: f,
					value: applFilters[f]?.value
				});
			}
		});

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const tableData = getTabularData(resp.data.getTabularData?.objects || {}, metric);

		updateOrderAnalyticsState(metric, {
			loading: false,
			tabularData: {
				...tabularData,
				...tableData
			},
			tableColumnsSelected: getTableColumnsSelectorFields(tableData, tableColumnsSelected?.columns),
			searchKeywords: resp.data.getTabularData?.searchKeywords,
			filters: resp.data.getTabularData?.filters
		});
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchLostOrdersMetrics = async (metric) => {
	updateOrderAnalyticsState(metric, { metricsLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_lost_orders",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateOrderAnalyticsState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchLostOrdersChart = async (metric) => {
	const { selectedChart, showComparison, graphData, yScaleMax, maxValue } = store.getState().ordersAnalytics[metric];
	updateOrderAnalyticsState(metric, { chartLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: selectedChart === "bar" ? "lost_orders_bar" : "order_lost_orders_line",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// delete comparison duration object
		if (!showComparison) {
			delete variables.requiredFilters.comparisonDuration;
		}

		const resp = await client.query({
			query: selectedChart === "bar" ? GET_BAR_CHART_DATA : GET_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let chartMaxValue = 0;
		const updatedGraphData = {
			...graphData,
			[selectedChart]:
				selectedChart === "bar"
					? resp.data.getBarChartData
						? getBarChartData(resp.data.getBarChartData?.objects, "grouped", true, showComparison).map(
								(bar) => {
									Object.keys(bar).forEach((key) => {
										if (typeof bar[key] === "number" && Math.round(bar[key]) > chartMaxValue) {
											chartMaxValue = Math.round(bar[key]);
										}
									});
									return bar;
								}
						  )
						: []
					: resp.data.getLineChartData
					? resp.data.getLineChartData?.objects?.map((obj, i) => ({
							...obj,
							id:
								resp.data.getLineChartData?.objects?.length > 1
									? `Lost Orders (${getReadableDateFilter(i > 0)})${i !== 0 ? "*" : ""}`
									: "Lost Orders",
							tooltipYName: `Lost Orders${i !== 0 ? "*" : ""}`,
							data: obj.data.map((pt) => {
								if (Math.round(pt.y) > chartMaxValue) {
									chartMaxValue = Math.round(pt.y);
								}
								return pt;
							})
					  }))
					: []
		};

		updateOrderAnalyticsState(metric, {
			chartLoading: false,
			graphData: updatedGraphData,
			showComparison: !durationObject?.comparisonDuration ? false : showComparison,
			maxValue: selectedChart === "bar" ? getNearestRoundValue(chartMaxValue) : maxValue,
			yScaleMax: selectedChart === "line" ? getNearestRoundValue(chartMaxValue) : yScaleMax
		});
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchLostOrdersTable = async (metric) => {
	const { sort } = store.getState().ordersAnalytics[metric];
	updateOrderAnalyticsState(metric, { tableLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_lost_order_tabular",
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateOrderAnalyticsState(metric, {
			tableLoading: false,
			tabularData: getTabularData(resp.data.getTabularData?.objects || {}, metric)
		});
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { tableLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchLostOrdersBreakdownChart = async (metric) => {
	const { selectedChart, showComparison, breakdownBy, graphData, yScaleMax, maxValue } =
		store.getState().ordersAnalytics[metric];
	updateOrderAnalyticsState(metric, { chartLoading: true });
	try {
		const { durationObject, filters, bizId, isMultibrandEnabled } = getAllAppliedFilters();
		const barChartBreakdownQueries = {
			platform: "order_lost_orders_plt_stacked_bar",
			brand: "order_lost_orders_brand_stacked_bar"
		};
		const lineChartBreakdownQueries = {
			platform: "order_lost_order_breakdown_plt_line",
			brand: "order_lost_order_breakdown_brand_line"
		};
		const updatedBreakdownBy =
			isMultibrandEnabled && filters.find((filter) => filter.field === "brand_id")
				? { label: "Platform", value: "platform" }
				: isMultibrandEnabled && filters.find((filter) => filter.field === "platform_names")
				? { label: "Brand", value: "brand" }
				: breakdownBy;

		const variables = {
			query:
				selectedChart === "bar"
					? barChartBreakdownQueries[updatedBreakdownBy?.value]
					: lineChartBreakdownQueries[updatedBreakdownBy?.value],
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// delete comparison duration object
		if (!showComparison) {
			delete variables.requiredFilters.comparisonDuration;
		}

		const resp = await client.query({
			query: selectedChart === "bar" ? GET_STACKED_BAR_CHART_DATA : GET_GROUPED_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let chartMaxValue = 0;
		const updatedGraphData = {
			...graphData,
			[selectedChart]:
				selectedChart === "bar"
					? resp.data.getStackedBarChartData
						? getBarChartData(
								resp.data.getStackedBarChartData?.objects,
								showComparison && durationObject?.comparisonDuration ? "grouped-stacked" : "stacked",
								false,
								showComparison
						  ).map((bar) => {
								let tempMaxValue = 0;
								Object.keys(bar).forEach((key) => {
									if (
										(!showComparison || !durationObject?.comparisonDuration) &&
										typeof bar[key] === "number"
									) {
										// stacked bar chart
										tempMaxValue += Math.round(bar[key]);
									} else if (typeof bar[key] === "number" && Math.round(bar[key]) > chartMaxValue) {
										// grouped-stacked bar chart
										chartMaxValue = Math.round(bar[key]);
									}
								});
								if (tempMaxValue > 0 && tempMaxValue > chartMaxValue) {
									chartMaxValue = tempMaxValue;
								}
								return bar;
						  })
						: []
					: resp.data.getGroupedLineChartData?.objects?.map((obj, i) => ({
							...obj,
							id:
								showComparison && durationObject?.comparisonDuration && i % 2 !== 0
									? `${obj.id?.split("#")?.[0]}*`
									: obj.id?.split("#")?.[0],
							color: ANALYTICS_DEFAULT_COLORS[
								showComparison && durationObject?.comparisonDuration
									? String(Math.floor(i / 2)).slice(-1)
									: String(i).slice(-1)
							],
							data: obj.data.map((pt) => {
								if (Math.round(pt.y) > chartMaxValue) {
									chartMaxValue = Math.round(pt.y);
								}
								return pt;
							})
					  })) || []
		};

		updateOrderAnalyticsState(metric, {
			chartLoading: false,
			breakdownBy: updatedBreakdownBy,
			graphData: updatedGraphData,
			showComparison: !durationObject?.comparisonDuration ? false : showComparison,
			maxValue: selectedChart === "bar" ? getNearestRoundValue(chartMaxValue) : maxValue,
			yScaleMax: selectedChart === "line" ? getNearestRoundValue(chartMaxValue) : yScaleMax
		});
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchLostOrdersBreakdownTable = async (metric) => {
	const { sort, breakdownBy } = store.getState().ordersAnalytics[metric];
	updateOrderAnalyticsState(metric, { tableLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const tableBreakdownQueries = {
			platform: "order_lost_order_plt_breakdown_tabular",
			brand: "order_lost_order_brand_breakdown_tabular"
		};
		const variables = {
			query: tableBreakdownQueries[breakdownBy.value],
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateOrderAnalyticsState(metric, {
			tableLoading: false,
			tabularData: getTabularData(resp.data.getTabularData?.objects || {}, metric),
			hideColumns: breakdownBy.value === "platform" ? ["brand"] : ["platform"]
		});
	} catch (error) {
		console.log(error);
		updateOrderAnalyticsState(metric, { tableLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

// operations analytics
export const updateOperationsAnalyticsState = (metric, data) => {
	store.dispatch({
		type: ActionTypes.UPDATE_OPERATIONS_ANALYTICS_STATE,
		payload: {
			metric,
			data
		}
	});
};

export const fetchOperationsSummaryMetrics = async (metric) => {
	updateOperationsAnalyticsState(metric, { metricsLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "operations_order_completion_metrics",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateOperationsAnalyticsState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
	} catch (error) {
		console.log(error);
		updateOperationsAnalyticsState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOrderCompletionTimeMetrics = async (metric) => {
	updateOperationsAnalyticsState(metric, { metricsLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "operation_order_avg_completion_time",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateOperationsAnalyticsState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
	} catch (error) {
		console.log(error);
		updateOperationsAnalyticsState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOrderCompletionTimeChart = async (metric) => {
	updateOperationsAnalyticsState(metric, { chartLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "operation_order_completion_bar",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_BAR_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let chartMaxValue = 0;
		const graphData = resp.data.getBarChartData
			? getBarChartData(resp.data.getBarChartData?.objects, "grouped")
					?.reverse()
					?.map((bar) => {
						let _bar = { ...bar };
						Object.keys(bar).forEach((key) => {
							let val = typeof bar[key] === "number" ? bar[key] / 60 : bar[key];
							_bar[key === "value" ? "time" : key] = val;
							if (typeof bar[key] === "number" && key !== "compare" && val > chartMaxValue) {
								chartMaxValue = val;
							}
						});
						_bar.percent =
							_bar.time > 0 ? (((_bar.time - _bar.compare) * 100) / _bar.time).toFixed(1) : 0.0;
						return _bar;
					})
			: [];

		updateOperationsAnalyticsState(metric, {
			chartLoading: false,
			graphData,
			maxValue: getNearestRoundValue(chartMaxValue !== 1 ? chartMaxValue : 2, 1.5)
		});
	} catch (error) {
		console.log(error);
		updateOperationsAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOrderCompletionFunnelChart = async (metric) => {
	updateOperationsAnalyticsState(metric, { chartLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "operation_order_completion_funnel_sankey",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};
		const colors = {
			Received: ANALYTICS_SANKEY_COLORS["sankey1"],
			Acknowledged: ANALYTICS_SANKEY_COLORS["sankey2"],
			Prepared: ANALYTICS_SANKEY_COLORS["sankey3"],
			Dispatched: ANALYTICS_SANKEY_COLORS["sankey4"],
			Delivered: ANALYTICS_SANKEY_COLORS["sankeyPositive"],
			"Merchant Cancellation": ANALYTICS_SANKEY_COLORS["sankeyIntermediate1"],
			"Aggregator Cancellation": ANALYTICS_SANKEY_COLORS["sankeyIntermediate2"]
			// 'Rejected': ANALYTICS_SANKEY_COLORS['sankeyNegative'],
		};
		const nodesOrderByIndex = {
			Received: 0,
			Acknowledged: 1,
			Prepared: 2,
			Dispatched: 3,
			Delivered: 4,
			"Aggregator Cancellation": 5,
			"Merchant Cancellation": 6
			// 'Rejected': 7,
		};

		const resp = await client.query({
			query: GET_SANKEY_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});
		const sankeyData = resp.data.getSankeyChartData.objects;

		// calculate total orders and percentage values to show on sankey cahrt
		let totalOrders = 0;
		let linkValues = [];
		sankeyData.links.slice(0, 3).forEach((link) => {
			totalOrders += Math.round(link.value);
		});
		sankeyData.links.slice(0, 3).forEach((link) => {
			linkValues.push(totalOrders > 0 ? (Math.round(link.value) / totalOrders) * 100 : 0);
		});
		sankeyData.links.slice(3, 6).forEach((link) => {
			linkValues.push(
				Math.round(sankeyData.links[0].value) > 0
					? (Math.round(link.value) / Math.round(sankeyData.links[0].value)) * linkValues[0]
					: 0
			);
		});
		sankeyData.links.slice(6, 9).forEach((link) => {
			linkValues.push(
				Math.round(sankeyData.links[3].value) > 0
					? (Math.round(link.value) / Math.round(sankeyData.links[3].value)) * linkValues[3]
					: 0
			);
		});
		sankeyData.links.slice(9).forEach((link) => {
			linkValues.push(
				Math.round(sankeyData.links[6].value) > 0
					? (Math.round(link.value) / Math.round(sankeyData.links[6].value)) * linkValues[6]
					: 0
			);
		});

		// re-arrange nodes order
		let nodes = ["", "", "", "", "", "", ""];
		sankeyData.nodes.forEach((node) => {
			nodes[nodesOrderByIndex[node.id]] = node;
		});

		let maxValue = 0;
		const graphData = {
			...sankeyData,
			nodes: nodes.map((node) => ({
				...node,
				nodeColor: colors[node.id]
			})),
			links: sankeyData.links
				.map((link, i) => {
					if (Math.round(link.value) > maxValue) {
						maxValue = Math.round(link.value);
					}
					return {
						...link,
						value: linkValues[i],
						count: Math.round(link.value),
						startColor: colors[link.target],
						endColor: colors[link.target]
					};
				})
				.filter((link) => link.value > 0)
		};
		updateOperationsAnalyticsState(metric, {
			chartLoading: false,
			graphData,
			maxValue
		});
	} catch (error) {
		console.log(error);
		updateOperationsAnalyticsState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchRestaurantAvailability = async (metric) => {
	const { compare, showComparison } = store.getState().operationsAnalytics[metric];
	updateOperationsAnalyticsState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const compareOptions = [
			{ label: "Hours", value: "LOCATION_ACTION_DATE_HOUR", type: "hour" },
			{ label: "Days of the Week", value: "LOCATION_ACTION_WEEK_DAY", type: "day" },
			{ label: "Dates", value: "LOCATION_ACTION_DATE", type: "day" },
			{ label: "Weeks", value: "LOCATION_ACTION_MONTH_WEEK", type: "week" },
			{ label: "Months", value: "LOCATION_ACTION_MONTH", type: "month" }
		];
		let { currCompare, filteredOptions, compareFilterDisabled } = verifyAndGetCompareFilter(
			compare,
			compareOptions
		);

		// remove Dates as an option as Avg & Compare will not work for this granularity; for: last 15 days, last 30 days
		if (["LAST_15_DAYS", "LAST_30_DAYS"].includes(durationObject?.duration?.preset)) {
			const validOptions = ["Hours", "Days of the Week"];
			filteredOptions = filteredOptions.filter((opt) => validOptions.includes(opt.label));
			currCompare = !validOptions.includes(currCompare?.label) ? filteredOptions[0] : currCompare;
		}

		const variables = {
			query: "operation_location_availability_heatmap",
			filters: [...filters, { field: "group_by", value: currCompare.value }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// delete comparison duration object
		if (!showComparison) {
			delete variables.requiredFilters.comparisonDuration;
		}

		const resp = await client.query({
			query: GET_AVAILABILITY_HEATMAP_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const graphData = getHeatmapChartData(
			resp.data.getAvailabilityHeatmapChartData?.objects,
			showComparison,
			false
		);
		let maxValue = 0;
		graphData.forEach((obj) => {
			obj.data.forEach((cell) => {
				if (cell.y > maxValue) {
					maxValue = cell.y;
				}
			});
		});

		updateOperationsAnalyticsState(metric, {
			loading: false,
			graphData,
			maxValue,
			compare: currCompare,
			applCompare: currCompare,
			compareOptions: filteredOptions,
			compareFilterDisabled,
			showComparison: !durationObject?.comparisonDuration ? false : showComparison,
			applShowComparison: !durationObject?.comparisonDuration ? false : showComparison
		});
	} catch (error) {
		console.log(error);
		updateOperationsAnalyticsState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

// catalogue analytics
export const updateCatalogueAnalyticsState = (metric, data) => {
	store.dispatch({
		type: ActionTypes.UPDATE_CATALOGUE_ANALYTICS_STATE,
		payload: {
			metric,
			data
		}
	});
};

export const fetchCatalogueMetrics = async (metric) => {
	updateCatalogueAnalyticsState(metric, { metricsLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_category_performance_metrics",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateCatalogueAnalyticsState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
	} catch (error) {
		console.log(error);
		updateCatalogueAnalyticsState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchCategoryPerformanceTable = async (metric = "category_performance", applFilters = {}) => {
	const {
		limit,
		offset,
		sort,
		tabularData,
		tableColumnsSelected,
		searchFieldSelected = {},
		searchFieldValue = ""
	} = store.getState().catalogueAnalytics[metric];
	updateCatalogueAnalyticsState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_category_performance_tabular",
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject,
				limit,
				offset
			}
		};

		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}

		// detail view filters
		Object.keys(applFilters).forEach((f) => {
			if (applFilters[f] && applFilters[f]?.value && applFilters[f]?.value !== "all") {
				variables.filters.push({
					field: f,
					value: applFilters[f]?.value
				});
			}
		});

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const tableData = getTabularData(resp.data.getTabularData?.objects || {}, metric);

		updateCatalogueAnalyticsState(metric, {
			loading: false,
			tabularData: {
				...tabularData,
				...tableData
			},
			tableColumnsSelected: getTableColumnsSelectorFields(tableData, tableColumnsSelected?.columns),
			searchKeywords: resp.data.getTabularData?.searchKeywords,
			searchFieldSelected: resp.data.getTabularData?.searchKeywords?.[0] || searchFieldSelected,
			filters: resp.data.getTabularData?.filters
		});
	} catch (error) {
		console.log(error);
		updateCatalogueAnalyticsState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchItemPerformanceTable = async (metric = "item_performance", applFilters = {}) => {
	const {
		limit,
		offset,
		sort,
		tabularData,
		tableColumnsSelected,
		searchFieldSelected = {},
		searchFieldValue = ""
	} = store.getState().catalogueAnalytics[metric];
	updateCatalogueAnalyticsState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_item_performance_tabular",
			filters,
			sort,
			requiredFilters: {
				bizId,
				...durationObject,
				limit,
				offset
			}
		};

		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}

		// detail view filters
		Object.keys(applFilters).forEach((f) => {
			if (applFilters[f] && applFilters[f]?.value && applFilters[f]?.value !== "all") {
				variables.filters.push({
					field: f,
					value: applFilters[f]?.value
				});
			}
		});

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const tableData = getTabularData(resp.data.getTabularData?.objects || {}, metric);

		updateCatalogueAnalyticsState(metric, {
			loading: false,
			tabularData: {
				...tabularData,
				...tableData
			},
			tableColumnsSelected: getTableColumnsSelectorFields(tableData, tableColumnsSelected?.columns),
			searchKeywords: resp.data.getTabularData?.searchKeywords,
			filters: resp.data.getTabularData?.filters
		});
	} catch (error) {
		console.log(error);
		updateCatalogueAnalyticsState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

// analytics entity detail
export const updateAnalyticsEntityDetailState = (metric, data) => {
	store.dispatch({
		type: ActionTypes.UPDATE_ANALYTICS_ENTITY_DETAIL_STATE,
		payload: {
			metric,
			data
		}
	});
};

// entity detail - item performance
export const fetchRevenueTrendMetrics = async (metric, itemId) => {
	updateAnalyticsEntityDetailState(metric, { metricsLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_revenue_metrics",
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateAnalyticsEntityDetailState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchRevenueTrendChart = async (metric, itemId) => {
	updateAnalyticsEntityDetailState(metric, { chartLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_revenue_line",
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let maxValue = 0;
		const graphData = resp.data.getLineChartData
			? resp.data.getLineChartData?.objects?.map((obj, i) => ({
					...obj,
					id:
						resp.data.getLineChartData?.objects?.length > 1
							? `Net Revenue (${getReadableDateFilter(i > 0)})${i !== 0 ? "*" : ""}`
							: "Net Revenue",
					tooltipYName: `Net Revenue${i !== 0 ? "*" : ""}`,
					data: obj.data.map((pt) => {
						if (Math.round(pt.y) > maxValue) {
							maxValue = Math.round(pt.y);
						}
						return {
							...pt,
							df: btoa(pt.x + JSON.stringify(durationObject))
						};
					})
			  }))
			: [];

		updateAnalyticsEntityDetailState(metric, {
			chartLoading: false,
			graphData,
			yScaleMax: getNearestRoundValue(maxValue)
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchUnitsSoldMetrics = async (metric, itemId) => {
	updateAnalyticsEntityDetailState(metric, { metricsLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_units_sold_metrics",
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateAnalyticsEntityDetailState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchUnitsSoldChart = async (metric, itemId) => {
	updateAnalyticsEntityDetailState(metric, { chartLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_units_sold_line",
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let maxValue = 0;
		const graphData = resp.data.getLineChartData
			? resp.data.getLineChartData?.objects?.map((obj, i) => ({
					...obj,
					id:
						resp.data.getLineChartData?.objects?.length > 1
							? `Units Sold (${getReadableDateFilter(i > 0)})${i !== 0 ? "*" : ""}`
							: "Units Sold",
					tooltipYName: `Units Sold${i !== 0 ? "*" : ""}`,
					data: obj.data.map((pt) => {
						if (Math.round(pt.y) > maxValue) {
							maxValue = Math.round(pt.y);
						}
						return {
							...pt,
							df: btoa(pt.x + JSON.stringify(durationObject))
						};
					})
			  }))
			: [];

		updateAnalyticsEntityDetailState(metric, {
			chartLoading: false,
			graphData,
			yScaleMax: getNearestRoundValue(maxValue)
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchCatalogueOrderFrequency = async (metric, itemId) => {
	const { compare, showComparison } = store.getState().analyticsEntityDetail?.[metric] || {};
	updateAnalyticsEntityDetailState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const compareOptions = [
			{ label: "Hours", value: "ORDER_ITEM_CREATED_DATE_HOUR" },
			{ label: "Days of the Week", value: "ORDER_ITEM_CREATED_WEEK_DAY" },
			{ label: "Dates", value: "ORDER_ITEM_CREATED_DATE" },
			{ label: "Weeks", value: "ORDER_ITEM_CREATED_MONTH_WEEK" },
			{ label: "Months", value: "ORDER_ITEM_CREATED_MONTH" }
		];
		const { currCompare, filteredOptions, compareFilterDisabled } = verifyAndGetCompareFilter(
			compare,
			compareOptions
		);

		const variables = {
			query: "catalogue_order_distribution_heatmap",
			filters: [...filters, { field: "item", value: itemId }, { field: "group_by", value: currCompare.value }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// delete comparison duration object
		if (!showComparison) {
			delete variables.requiredFilters.comparisonDuration;
		}

		const resp = await client.query({
			query: GET_HEATMAP_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const graphData = getHeatmapChartData(resp.data.getHeatmapChartData?.objects, showComparison);
		let maxValue = 0;
		graphData.forEach((obj) => {
			obj.data.forEach((cell) => {
				if (cell.y > maxValue) {
					maxValue = cell.y;
				}
			});
		});

		updateAnalyticsEntityDetailState(metric, {
			loading: false,
			graphData,
			maxValue,
			compare: currCompare,
			applCompare: currCompare,
			compareOptions: filteredOptions,
			compareFilterDisabled,
			showComparison: !durationObject?.comparisonDuration ? false : showComparison,
			applShowComparison: !durationObject?.comparisonDuration ? false : showComparison
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchCatalogueLocationPerformanceTable = async (
	metric = "location_performance",
	applFilters = {},
	itemId
) => {
	const {
		limit = 10,
		offset = 0,
		sort,
		tabularData,
		tableColumnsSelected,
		searchFieldSelected = {},
		searchFieldValue = ""
	} = store.getState().analyticsEntityDetail?.[metric] || {};
	updateAnalyticsEntityDetailState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_location_performance_tabular",
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject,
				limit,
				offset
			}
		};

		// sort
		if (sort?.field) {
			variables.sort = sort;
		}

		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}

		// detail view filters
		Object.keys(applFilters).forEach((f) => {
			if (applFilters[f] && applFilters[f]?.value && applFilters[f]?.value !== "all") {
				variables.filters.push({
					field: f,
					value: applFilters[f]?.value
				});
			}
		});

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const tableData = getTabularData(resp.data.getTabularData?.objects || {}, metric);

		updateAnalyticsEntityDetailState(metric, {
			loading: false,
			tabularData: {
				...tabularData,
				...tableData
			},
			tableColumnsSelected: getTableColumnsSelectorFields(tableData, tableColumnsSelected?.columns),
			searchKeywords: resp.data.getTabularData?.searchKeywords,
			filters: resp.data.getTabularData?.filters
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchPopularAddOnsTable = async (metric = "popular_add_ons", applFilters = {}, itemId) => {
	const {
		limit = 10,
		offset = 0,
		sort,
		tabularData,
		tableColumnsSelected,
		searchFieldSelected = {},
		searchFieldValue = ""
	} = store.getState().analyticsEntityDetail?.[metric] || {};
	updateAnalyticsEntityDetailState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_popular_addon_tabular",
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject,
				limit,
				offset
			}
		};

		// sort
		if (sort?.field) {
			variables.sort = sort;
		}

		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}

		// detail view filters
		Object.keys(applFilters).forEach((f) => {
			if (applFilters[f] && applFilters[f]?.value && applFilters[f]?.value !== "all") {
				variables.filters.push({
					field: f,
					value: applFilters[f]?.value
				});
			}
		});

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const tableData = getTabularData(resp.data.getTabularData?.objects || {}, metric);

		updateAnalyticsEntityDetailState(metric, {
			loading: false,
			tabularData: {
				...tabularData,
				...tableData
			},
			tableColumnsSelected: getTableColumnsSelectorFields(tableData, tableColumnsSelected?.columns),
			searchKeywords: resp.data.getTabularData?.searchKeywords,
			searchFieldSelected: resp.data.getTabularData?.searchKeywords?.[0] || searchFieldSelected,
			filters: resp.data.getTabularData?.filters
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchVariantPerformanceChart = async (metric, itemId, variantId) => {
	const { selectedChart = "pie", graphData, legends } = store.getState().analyticsEntityDetail?.[metric] || {};
	updateAnalyticsEntityDetailState(metric, { chartLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: selectedChart === "pie" ? "catalogue_variant_performance_pie" : "catalogue_variant_performance_line",
			filters: [...filters, { field: "item", value: itemId }, { field: "option_group", value: variantId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};
		if (selectedChart === "pie") {
			variables.sort = {
				field: "pie",
				order: "DESC"
			};
		}

		const resp = await client.query({
			query: selectedChart === "pie" ? GET_PIE_CHART_DATA : GET_GROUPED_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const legendColors = { ...legends };
		if (selectedChart === "pie" && resp.data.getPieChartData?.objects) {
			resp.data.getPieChartData.objects.forEach((obj, i) => {
				legendColors[obj.name?.toLowerCase()] =
					i > 4 ? ANALYTICS_DEFAULT_COLORS[5] : ANALYTICS_DEFAULT_COLORS[String(i).slice(-1)];
			});
		} else if (resp.data.getGroupedLineChartData?.objects) {
			resp.data.getGroupedLineChartData.objects.forEach((obj, i) => {
				legendColors[obj.id?.split("#")?.[0]?.toLowerCase()] =
					ANALYTICS_DEFAULT_COLORS[
						durationObject?.comparisonDuration ? String(Math.floor(i / 2)).slice(-1) : String(i).slice(-1)
					];
			});
		}

		let maxValue = 0;
		const updatedGraphData = {
			...graphData,
			[selectedChart]:
				selectedChart === "pie"
					? getPieChartData(resp.data.getPieChartData?.objects || [])
					: resp.data.getGroupedLineChartData?.objects?.map((obj, i) => ({
							...obj,
							id:
								durationObject?.comparisonDuration && i % 2 !== 0
									? `${obj.id?.split("#")?.[0]}*`
									: obj.id?.split("#")?.[0],
							color: ANALYTICS_DEFAULT_COLORS[
								durationObject?.comparisonDuration
									? String(Math.floor(i / 2)).slice(-1)
									: String(i).slice(-1)
							],
							data: obj.data.map((pt) => {
								if (Math.round(pt.y) > maxValue) {
									maxValue = Math.round(pt.y);
								}
								return pt;
							})
					  })) || []
		};

		updateAnalyticsEntityDetailState(metric, {
			chartLoading: false,
			graphData: updatedGraphData,
			legends: legendColors,
			yScaleMax: getNearestRoundValue(maxValue)
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchVariantPerformanceTable = async (metric, itemId, variantId) => {
	const { sort, tableColumnsSelected } = store.getState().analyticsEntityDetail?.[metric] || {};
	updateAnalyticsEntityDetailState(metric, { tableLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_variant_performance_tabular",
			filters: [...filters, { field: "item", value: itemId }, { field: "option_group", value: variantId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// sort
		if (sort?.field) {
			variables.sort = sort;
		}

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const tableData = getTabularData(resp.data.getTabularData?.objects || {}, metric);

		updateAnalyticsEntityDetailState(metric, {
			tableLoading: false,
			tabularData: {
				...tableData
			},
			tableColumnsSelected: getTableColumnsSelectorFields(tableData, tableColumnsSelected?.columns)
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { tableLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOfflineCountMetrics = async (metric, itemId) => {
	updateAnalyticsEntityDetailState(metric, { metricsLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_offline_item_metrics",
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateAnalyticsEntityDetailState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchOfflineCountChart = async (metric, itemId) => {
	updateAnalyticsEntityDetailState(metric, { chartLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_offline_items_line",
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let maxValue = 0;
		const graphData = resp.data.getLineChartData
			? resp.data.getLineChartData?.objects?.map((obj, i) => ({
					...obj,
					id:
						resp.data.getLineChartData?.objects?.length > 1
							? `Offline Count (${getReadableDateFilter(i > 0)})${i !== 0 ? "*" : ""}`
							: "Offline Count",
					tooltipYName: `Offline Count${i !== 0 ? "*" : ""}`,
					data: obj.data.map((pt) => {
						if (Math.round(pt.y) > maxValue) {
							maxValue = Math.round(pt.y);
						}
						return {
							...pt,
							df: btoa(pt.x + JSON.stringify(durationObject))
						};
					})
			  }))
			: [];

		updateAnalyticsEntityDetailState(metric, {
			chartLoading: false,
			graphData,
			yScaleMax: getNearestRoundValue(maxValue)
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchItemAvailability = async (metric, itemId) => {
	const { compare, showComparison } = store.getState().analyticsEntityDetail?.[metric] || {};
	updateAnalyticsEntityDetailState(metric, { loading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const compareOptions = [
			{ label: "Hours", value: "ITEM_ACTION_DATE_HOUR", type: "hour" },
			{ label: "Days of the Week", value: "ITEM_ACTION_WEEK_DAY", type: "day" },
			{ label: "Dates", value: "ITEM_ACTION_DATE", type: "hour" },
			{ label: "Weeks", value: "ITEM_ACTION_MONTH_WEEK", type: "week" },
			{ label: "Months", value: "ITEM_ACTION_MONTH", type: "month" }
		];
		let { currCompare, filteredOptions, compareFilterDisabled } = verifyAndGetCompareFilter(
			compare,
			compareOptions
		);

		// remove Dates as an option as Avg & Compare will not work for this granularity; for: last 15 days, last 30 days
		if (["LAST_15_DAYS", "LAST_30_DAYS"].includes(durationObject?.duration?.preset)) {
			const validOptions = ["Hours", "Days of the Week"];
			filteredOptions = filteredOptions.filter((opt) => validOptions.includes(opt.label));
			currCompare = !validOptions.includes(currCompare?.label) ? filteredOptions[0] : currCompare;
		}

		const variables = {
			query: "catalogue_item_availability_heatmap",
			filters: [...filters, { field: "item", value: itemId }, { field: "group_by", value: currCompare.value }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// delete comparison duration object
		if (!showComparison) {
			delete variables.requiredFilters.comparisonDuration;
		}

		const resp = await client.query({
			query: GET_AVAILABILITY_HEATMAP_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const graphData = getHeatmapChartData(
			resp.data.getAvailabilityHeatmapChartData?.objects,
			showComparison,
			false
		);
		let maxValue = 0;
		graphData.forEach((obj) => {
			obj.data.forEach((cell) => {
				if (cell.y > maxValue) {
					maxValue = cell.y;
				}
			});
		});

		updateAnalyticsEntityDetailState(metric, {
			loading: false,
			graphData,
			maxValue,
			compare: currCompare,
			applCompare: currCompare,
			compareOptions: filteredOptions,
			compareFilterDisabled,
			showComparison: !durationObject?.comparisonDuration ? false : showComparison,
			applShowComparison: !durationObject?.comparisonDuration ? false : showComparison
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { loading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchCatalogueLostOrdersMetrics = async (metric, itemId) => {
	updateAnalyticsEntityDetailState(metric, { metricsLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_lost_orders",
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateAnalyticsEntityDetailState(metric, {
			metricsLoading: false,
			metrics: getMetricsData(resp.data.getMetricData?.objects)
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { metricsLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchCatalogueLostOrdersChart = async (metric, itemId) => {
	const {
		selectedChart = "bar",
		showComparison,
		graphData,
		yScaleMax = "auto",
		maxValue = "auto"
	} = store.getState().analyticsEntityDetail?.[metric] || {};
	updateAnalyticsEntityDetailState(metric, { chartLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: selectedChart === "bar" ? "catalogue_lost_order_bar" : "catalogue_lost_orders_line",
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// delete comparison duration object
		if (!showComparison) {
			delete variables.requiredFilters.comparisonDuration;
		}

		const resp = await client.query({
			query: selectedChart === "bar" ? GET_BAR_CHART_DATA : GET_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let chartMaxValue = 0;
		const updatedGraphData = {
			...graphData,
			[selectedChart]:
				selectedChart === "bar"
					? resp.data.getBarChartData
						? getBarChartData(resp.data.getBarChartData?.objects, "grouped", true, showComparison).map(
								(bar) => {
									Object.keys(bar).forEach((key) => {
										if (typeof bar[key] === "number" && Math.round(bar[key]) > chartMaxValue) {
											chartMaxValue = Math.round(bar[key]);
										}
									});
									return bar;
								}
						  )
						: []
					: resp.data.getLineChartData
					? resp.data.getLineChartData?.objects?.map((obj, i) => ({
							...obj,
							id:
								resp.data.getLineChartData?.objects?.length > 1
									? `Lost Orders (${getReadableDateFilter(i > 0)})${i !== 0 ? "*" : ""}`
									: "Lost Orders",
							tooltipYName: `Lost Orders${i !== 0 ? "*" : ""}`,
							data: obj.data.map((pt) => {
								if (Math.round(pt.y) > chartMaxValue) {
									chartMaxValue = Math.round(pt.y);
								}
								return pt;
							})
					  }))
					: []
		};

		updateAnalyticsEntityDetailState(metric, {
			chartLoading: false,
			graphData: updatedGraphData,
			showComparison: !durationObject?.comparisonDuration ? false : showComparison,
			maxValue: selectedChart === "bar" ? getNearestRoundValue(chartMaxValue) : maxValue,
			yScaleMax: selectedChart === "line" ? getNearestRoundValue(chartMaxValue) : yScaleMax
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchCatalogueLostOrdersTable = async (metric, itemId) => {
	const { sort } = store.getState().analyticsEntityDetail?.[metric] || {};
	updateAnalyticsEntityDetailState(metric, { tableLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_lost_orders_tabular",
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// sort
		if (sort?.field) {
			variables.sort = sort;
		}

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateAnalyticsEntityDetailState(metric, {
			tableLoading: false,
			tabularData: getTabularData(resp.data.getTabularData?.objects || {}, metric)
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { tableLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchCatalogueLostOrdersBreakdownChart = async (metric, itemId) => {
	const {
		selectedChart = "bar",
		showComparison,
		breakdownBy,
		graphData,
		yScaleMax = "auto",
		maxValue = "auto"
	} = store.getState().analyticsEntityDetail?.[metric] || {};
	updateAnalyticsEntityDetailState(metric, { chartLoading: true });
	try {
		const { durationObject, filters, bizId, isMultibrandEnabled } = getAllAppliedFilters();
		const barChartBreakdownQueries = {
			platform: "catalogue_lost_orders_breakdown_stacked_bar",
			brand: "catalogue_lost_orders_brand_breakdown_stacked_bar"
		};
		const lineChartBreakdownQueries = {
			platform: "catalogue_lost_orders_breakdown_line",
			brand: "catalogue_lost_orders_breakdown_brand_line"
		};
		const updatedBreakdownBy =
			isMultibrandEnabled && filters.find((filter) => filter.field === "brand_id")
				? { label: "Platform", value: "platform" }
				: isMultibrandEnabled && filters.find((filter) => filter.field === "platform_names")
				? { label: "Brand", value: "brand" }
				: breakdownBy || { label: "Platform", value: "platform" };

		const variables = {
			query:
				selectedChart === "bar"
					? barChartBreakdownQueries[updatedBreakdownBy?.value]
					: lineChartBreakdownQueries[updatedBreakdownBy?.value],
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// delete comparison duration object
		if (!showComparison) {
			delete variables.requiredFilters.comparisonDuration;
		}

		const resp = await client.query({
			query: selectedChart === "bar" ? GET_STACKED_BAR_CHART_DATA : GET_GROUPED_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let chartMaxValue = 0;
		const updatedGraphData = {
			...graphData,
			[selectedChart]:
				selectedChart === "bar"
					? resp.data.getStackedBarChartData
						? getBarChartData(
								resp.data.getStackedBarChartData?.objects,
								showComparison && durationObject?.comparisonDuration ? "grouped-stacked" : "stacked",
								false,
								showComparison
						  ).map((bar) => {
								let tempMaxValue = 0;
								Object.keys(bar).forEach((key) => {
									if (
										(!showComparison || !durationObject?.comparisonDuration) &&
										typeof bar[key] === "number"
									) {
										// stacked bar chart
										tempMaxValue += Math.round(bar[key]);
									} else if (typeof bar[key] === "number" && Math.round(bar[key]) > chartMaxValue) {
										// grouped-stacked bar chart
										chartMaxValue = Math.round(bar[key]);
									}
								});
								if (tempMaxValue > 0 && tempMaxValue > chartMaxValue) {
									chartMaxValue = tempMaxValue;
								}
								return bar;
						  })
						: []
					: resp.data.getGroupedLineChartData?.objects?.map((obj, i) => ({
							...obj,
							id:
								showComparison && durationObject?.comparisonDuration && i % 2 !== 0
									? `${obj.id?.split("#")?.[0]}*`
									: obj.id?.split("#")?.[0],
							color: ANALYTICS_DEFAULT_COLORS[
								showComparison && durationObject?.comparisonDuration
									? String(Math.floor(i / 2)).slice(-1)
									: String(i).slice(-1)
							],
							data: obj.data.map((pt) => {
								if (Math.round(pt.y) > chartMaxValue) {
									chartMaxValue = Math.round(pt.y);
								}
								return pt;
							})
					  })) || []
		};

		updateAnalyticsEntityDetailState(metric, {
			chartLoading: false,
			breakdownBy: updatedBreakdownBy,
			graphData: updatedGraphData,
			showComparison: !durationObject?.comparisonDuration ? false : showComparison,
			maxValue: selectedChart === "bar" ? getNearestRoundValue(chartMaxValue) : maxValue,
			yScaleMax: selectedChart === "line" ? getNearestRoundValue(chartMaxValue) : yScaleMax
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { chartLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchCatalogueLostOrdersBreakdownTable = async (metric, itemId) => {
	const { sort, breakdownBy } = store.getState().analyticsEntityDetail?.[metric] || {};
	updateAnalyticsEntityDetailState(metric, { tableLoading: true });
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const tableBreakdownQueries = {
			platform: "catalogue_lost_orders_breakdown_tabular",
			brand: "catalogue_lost_orders_brand_breakdown_tabular"
		};
		const variables = {
			query: breakdownBy ? tableBreakdownQueries[breakdownBy?.value] : tableBreakdownQueries.platform,
			filters: [...filters, { field: "item", value: itemId }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		// sort
		if (sort?.field) {
			variables.sort = sort;
		}

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		updateAnalyticsEntityDetailState(metric, {
			tableLoading: false,
			tabularData: getTabularData(resp.data.getTabularData?.objects || {}, metric),
			hideColumns: breakdownBy.value === "platform" ? ["brand"] : ["platform"]
		});
	} catch (error) {
		console.log(error);
		updateAnalyticsEntityDetailState(metric, { tableLoading: false });
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

// metric apis map
export const analyticsMetrics = {
	// revenue
	"revenue-by-location": fetchRevenueByLocation,
	"revenue-by-item": fetchRevenueByItem,

	// orders
	"orders-by-location": fetchOrdersByLocation,
	"orders-by-item": fetchOrdersByItem,

	// catalogue
	"category-performance": fetchCategoryPerformanceTable,
	"item-performance": fetchItemPerformanceTable,
	"location-performance": fetchCatalogueLocationPerformanceTable,
	"popular-add-ons": fetchPopularAddOnsTable
};

// update analytics state map
export const updateAnalyticsState = {
	revenue: updateRevenueAnalyticsState,
	orders: updateOrderAnalyticsState,
	catalogue: updateCatalogueAnalyticsState,
	detail: updateAnalyticsEntityDetailState
};
