import React, { useState } from "react";

// component
import { FormSidebar } from "../_commons/FormSidebar";
import FormTable from "../_commons/FormTable";
import Image from "../_commons/Image";
import FoodTypeIndicator from "../_commons/FoodTypeIndicator";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SearchFilter } from "../_commons/SearchFilter";
import { SelectFilter } from "../_commons/SelectFilter";
import { CATALOGUE_PLATFORMS_LOGO } from "../../client-config";

const STEPWISE_SUBMIT_TITLE = {
	1: "Next: Select Location",
	2: "Next: Select Platform",
	3: "Next: Confirm Cart",
	4: "Place Test Order"
};

const STEPWISE_SUBTITLE = {
	1: "Push a test order to your POS",
	2: "Select location to place test order on",
	3: "Select platform to test cart on",
	4: "Push a test order to your POS"
};

const TestCart = ({ isTestCartOpen, showHideTestCart }) => {
	const [testOrderStep, setTestOrderStep] = useState(1);

	const stepwiseComponents = {
		1: () => <ItemQuantitySelectionTable />,
		2: () => <LocationSelectionTable />,
		3: () => <PlatformSelection />,
		4: () => null
	};

	const handleSubmitAction = () => {
		setTestOrderStep((current) => (current === 4 ? current : current + 1));
	};

	return (
		<FormSidebar
			isOpen={isTestCartOpen}
			title="Test Cart"
			subTitle={STEPWISE_SUBTITLE[testOrderStep]}
			submitTitle={STEPWISE_SUBMIT_TITLE[testOrderStep]}
			submit={handleSubmitAction}
			submitButtonWidth={200}
			close={() => showHideTestCart(false)}
		>
			<div className="test-cart-container">{stepwiseComponents[testOrderStep]()}</div>
		</FormSidebar>
	);
};
export default TestCart;

const ItemQuantitySelectionTable = () => {
	const itemsTableColumn = [
		{
			title: "Product",
			dataIndex: "item",
			render: (data) => (
				<div className="item-details-container">
					<Image
						src={data?.imageUrl || "/assets/empty-dish.jpg"}
						alt={data?.name}
						className="item-img"
						errorOverrideImage="/assets/empty-dish.jpg"
					/>
					<FoodTypeIndicator foodType={data?.foodType} />
					<div className="item-title">
						<span className="hyperlink hyperlink--black-color">{data?.name}</span>
					</div>
				</div>
			)
		},
		{
			title: "Quantity",
			dataIndex: "quantity",
			render: (data) => data
		},
		{
			title: "Base Price",
			dataIndex: "price",
			render: (data) => (
				<div>
					{data?.value}
					{/* <ContextMenu /> */}
				</div>
			)
		},
		{
			title: "total",
			dataIndex: "total",
			render: (data) => data
		}
	];

	const itemsData = [
		{
			id: 1,
			item: {
				name: "Paneer Biryani",
				foodType: "veg",
				imageUrl: "https://static.toiimg.com/thumb/60042143.cms?imgsize=352935&width=400&height=400"
			},
			crmTitle: "test-crm-title",
			category: "Indian Cuisine",
			issues: [],
			quantity: 1,
			price: {
				value: "200",
				id: 1
			},
			total: 400
		},
		{
			id: 2,
			item: {
				name: "Paneer Biryani",
				foodType: "non-veg",
				imageUrl: "https://static.toiimg.com/thumb/60042143.cms?imgsize=352935&width=400&height=400"
			},
			crmTitle: "test-crm-title",
			category: "Indian Cuisine",
			issues: [],
			quantity: 2,
			price: {
				value: "200",
				id: 2
			},
			total: 400
		},
		{
			id: 3,
			item: {
				name: "Paneer Biryani",
				foodType: "egg",
				imageUrl: "https://static.toiimg.com/thumb/60042143.cms?imgsize=352935&width=400&height=400"
			},
			crmTitle: "test-crm-title",
			category: "Indian Cuisine",
			issues: [],
			quantity: 2,
			price: {
				value: "200",
				id: 3
			},
			total: 400
		},
		{
			id: 4,
			item: {
				name: "Paneer Biryani",
				foodType: "veg",
				imageUrl: "https://static.toiimg.com/thumb/60042143.cms?imgsize=352935&width=400&height=400"
			},
			crmTitle: "test-crm-title",
			category: "Indian Cuisine",
			issues: [],
			quantity: 2,
			price: {
				value: "200",
				id: 4
			},
			total: 400
		}
	];

	return (
		<div className="item-qty-selection-table">
			<FormTable columns={itemsTableColumn} dataSource={itemsData} />
		</div>
	);
};

const LocationSelectionTable = () => {
	const locationsTableColumns = [
		{
			title: "location",
			dataIndex: "name",
			render: (data) => data
		},
		{
			title: "city",
			dataIndex: "city",
			render: (data) => data
		}
	];

	const locationsData = [
		{
			name: "Indiranagar Store",
			city: "Bangalore"
		},
		{
			name: "Koramangala Store",
			city: "Bangalore"
		}
	];

	return (
		<div className="locations-selection-table-container">
			<div className="filters-container">
				<SelectFilter placeholder="City" />
				<SearchFilter setFilter={() => {}} placeholder="Search" />
			</div>
			<FormTable
				columns={locationsTableColumns}
				dataSource={locationsData}
				isSelectionEnabled
				handleAllFieldsSelection={() => {}}
				handleSingleFieldSelection={() => {}}
			/>
		</div>
	);
};

const PlatformSelection = () => {
	const platforms = ["zomato", "swiggy", "ubereats", "talabat"];
	return (
		<div className="platform-selection-container">
			<div className="header">PLATFORMS</div>
			{platforms.map((platform, i) => (
				<div className="radio-value-container" key={i}>
					<div className="radio-selector">
						<input type="radio" name="platform" value="2" />
						<label for="html">{platform}</label>
					</div>
					<div className="platform-logo-container">
						<Image src={CATALOGUE_PLATFORMS_LOGO[platform]} alt={platform} className="platform-logo" />
					</div>
				</div>
			))}
		</div>
	);
};
