import React from "react";

// components
import { Configurations } from "./Configurations";

// config
import { CATALOGUE_PLATFORMS_LOGO, PLATFORM_NAME_MAP } from "../../client-config";

const PlatformConfigs = ({ data, loading, handleForm, selectedLocation = {}, validations }) => {
	return (
		<React.Fragment>
			{data.map(
				(platform) =>
					platform.keys.filter((config) => !config.key.includes("auto")).length > 0 && (
						<div channel={platform.name.toLowerCase()} className="card-container" key={platform.name}>
							<div className="card-header">
								<div className="platform-info">
									<img
										src={
											platform?.logo ||
											CATALOGUE_PLATFORMS_LOGO[platform.name.toLowerCase()] ||
											"/assets/icons/icons8-globe-40.png"
										}
									/>
									<span>{PLATFORM_NAME_MAP[platform?.name?.toLowerCase()] || platform.name}</span>
								</div>
							</div>
							<div className="form-container">
								<div className="form-row">
									<Configurations
										platform={platform.name}
										options={platform.keys.filter(
											(config) =>
												!config.key.includes("auto") &&
												!config.key.includes("careem_delete_menu")
										)}
										handleForm={handleForm}
										booleanActions={true}
										selectedLocation={selectedLocation}
										validations={validations || {}}
										isReadOnlyEnabled
									/>
								</div>
							</div>
						</div>
					)
			)}
			{!loading && data.length === 0 && <div className="no-items-placeholder">No Hub Configurations found!</div>}
			{loading && data.length === 0 && (
				<div className="P(10px 0)">
					<div className="shimmer H(60px) Mb(10px)" />
					<div className="shimmer H(60px) Mb(10px)" />
				</div>
			)}
		</React.Fragment>
	);
};
export default PlatformConfigs;
