import React, { useCallback, useEffect, useRef, useState } from "react";

// third party
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Transition, animated, config } from "react-spring/renderprops";

// component
import Header from "../components/BrandsList/Header";
import { CheckBox } from "../components/_commons/CheckBox";
import { CommonTable } from "../components/_commons/CommonTable";
import BrandFilters from "../components/BrandsList/BrandFilters";
import MonogramGenerator from "../components/_commons/MonogramGenerator";
import { fetchBrandsList, fetchBrandsListDebounced } from "../actions/brands";
import { Paginator } from "../components/_commons/Paginator";
import { RightClickMenuContext } from "../components/_commons/RightClickContext";
import ArchiveRestoreModal from "../components/_commons/ArchiveRestoreModal";
import CreateIcon from "../components/_commons/CreateIcon";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";

// actions
import { store } from "../store/configureStore";
import { ActionTypes } from "../actions/_types";

// utils
import history from "../history";

// constants
import { NESTED_ENTITY_TYPES } from "../client-config";
import { adjustNestedContainer } from "../atlas-utils";

const CLICK_MENU_DATA_INIT_STATE = {
	show: false,
	anchorPoint: { x: 0, y: 0 },
	id: null
};
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const BrandsList = ({ configItems, biz, brandsListState, access }) => {
	const { data, error, loading } = brandsListState;
	const [offset, setOffset] = useState(0);
	const [isActive, setIsActive] = useState(true);
	const [clickMenuData, setClickMenuData] = useState(CLICK_MENU_DATA_INIT_STATE);
	const [isArchiveRestoreOpen, setArchiveRestoreState] = useState(false);
	const [archiveRestoreObject, setArchiveRestoreObject] = useState({});
	const [selectedBrands, setSelectedBrands] = useState({});
	const [isCheckedAll, setCheckedAll] = useState(false);
	const [sortData, setSortData] = useState(null);
	const [contextMenu, setContextMenu] = useState({ id: null });
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();

	const searchQuery = brandsListState.searchQuery || "";
	const setSearchQuery = (value) => {
		store.dispatch({
			type: ActionTypes.UPDATE_BRANDS_SEARCH_TEXT,
			payload: value
		});
	};

	useEffect(() => {
		fetchBrandsList(offset, isActive, searchQuery, sortData);
	}, [offset, isActive, sortData]);

	useEffect(() => {
		return () => {
			setSortData(null);
		};
	}, []);

	const columns = [
		{
			name: "Brands",
			field: "brand",
			sortKey: "brand",
			render: (record, i, archived, cs, ht, checkbox, rest) => {
				return (
					<div className="at-table-cell at-cell-text brand" title={record.title} key={i}>
						<div className="location-title">
							{checkbox && (
								<CheckBox
									checked={rest.selectedBrands?.[record?.id]}
									clickHandler={() => {
										rest.handleBrandSelection(record.id, record);
									}}
									title={record.title || record.id}
								/>
							)}
							<div className="title-image-container">
								{record.image ? (
									<img src={record.image} alt={record.name} className="brand-image" />
								) : (
									<MonogramGenerator value={record.name} />
								)}
								<span>
									<Link
										className={"hyperlink hyperlink--black-color " + archived}
										to={`/brands/edit/${record.id}`}
									>
										{record.name || record.id}
									</Link>
								</span>
							</div>
						</div>
					</div>
				);
			}
		},
		{
			name: "assoc. items",
			field: "assoc_items",
			render: (record, i) => (
				<div
					className="at-table-cell at-cell-text assoc_items"
					title={(record.brandItemsCount === 0 ? "0" : record.brandItemsCount) || "--"}
					key={i}
				>
					<div>{(record.brandItemsCount === 0 ? "0" : record.brandItemsCount) || "--"}</div>
				</div>
			)
		},
		{
			name: "assoc. locations",
			field: "assoc_locations",
			render: (record, i, ar, cs, ht, checkbox, rest) => (
				<div className="at-table-cell at-cell-text assoc_locations" key={i}>
					<div title={(record.brandLocationsCount === 0 ? "0" : record.brandLocationsCount) || "--"}>
						{(record.brandLocationsCount === 0 ? "0" : record.brandLocationsCount) || "--"}
					</div>
					{/* <ContextMenu
						handleOutsideClick={() => console.log('clicked outside')}

					/> */}
					{/* <div className='dotted-dropdown' onClick={(e) => {rest.handleClickMenuRender(e.target.getBoundingClientRect(),record.id); rest.setArchiveRestoreObject(record)}}>
						<TripleDotIcon />
					</div> */}
				</div>
			)
		}
	];

	const handlePagination = (page) => {
		setOffset((page - 1) * 10);
		setCheckedAll(false);
	};

	const handleActiveFilter = (field, value) => {
		setIsActive(value.value);
	};

	const handleSearchByName = (field, value) => {
		setSearchQuery(value);
		fetchBrandsListDebounced(offset, isActive, value);
	};

	const handleArchiveRestore = () => {
		setArchiveRestoreState(true);
		//setClickMenuData(CLICK_MENU_DATA_INIT_STATE)
		setContextMenu({
			id: null
		});
	};

	const closeArchiveRestore = () => {
		setArchiveRestoreState(false);
		setClickMenuData(CLICK_MENU_DATA_INIT_STATE);
		fetchBrandsList(offset, isActive, searchQuery);
		setSelectedBrands({});
		setCheckedAll(false);
	};

	const handleClickMenuRender = (boundedRect, id) => {
		if (!boundedRect) {
			return;
		}
		setClickMenuData((current) => ({
			...current,
			show: !(current.id === id),
			id: current.id === id ? null : id,
			anchorPoint: {
				x: boundedRect.x - 150,
				y: boundedRect.y + 28
			}
		}));
	};

	const handleBrandSelection = (brandId, data) => {
		const currentBrandSelection = {
			...selectedBrands
		};

		if (currentBrandSelection[brandId]) {
			delete currentBrandSelection[brandId];
			setCheckedAll(false);
		} else {
			currentBrandSelection[brandId] = data;
		}
		setSelectedBrands(currentBrandSelection);
	};

	const handleCheckAll = () => {
		if (isCheckedAll) {
			setSelectedBrands({});
			setCheckedAll(false);
		} else {
			const currentBrandSelection = {};
			data.objects.forEach((obj) => {
				currentBrandSelection[obj.id] = { ...obj };
			});
			setSelectedBrands(currentBrandSelection);
			setCheckedAll(true);
		}
	};
	const handleBulkArchiveRestore = () => {
		setArchiveRestoreState(true);
		setArchiveRestoreObject((current) => ({
			...current,
			isMulti: true,
			selectedBrands
		}));
	};

	const selectedBrandsCount = Object.keys(selectedBrands).length;

	const sortList = () => {
		if (!sortData) {
			setSortData({
				field: "name",
				order: "ASC"
			});
		} else if (sortData.order === "ASC") {
			setSortData({
				field: "name",
				order: "DESC"
			});
		} else {
			setSortData({
				field: "name",
				order: "ASC"
			});
		}
	};

	const openContextMenu = (record) => {
		setContextMenu((current) => ({
			...current,
			id: current.id === record.id ? null : record.id
		}));
	};
	const closeContextMenu = () => {
		setContextMenu({
			id: null
		});
	};

	const renderMenuItems = (record) => {
		setArchiveRestoreObject(record);
		return (
			<React.Fragment>
				<div
					className="action-item"
					onClick={() => handleArchiveRestore()}
					style={{ color: isActive ? "#ff425c" : "#2ecc71" }}
				>
					{isActive ? "Archive" : "Restore"}
				</div>
			</React.Fragment>
		);
	};

	const placeholderContent = {
		placeholderText: "No brands created yet!",
		placeholderImageUrl: "/assets/empty_states/graphics-empty-brands.svg",
		placeholderButtonContent: (
			<>
				<CreateIcon />
				<span>Create New</span>
			</>
		),
		placeholderButtonClickAction: () => {
			history.push("/brands/new");
		},
		// redirectionLink: '/piper-academy/items',
		redirectionLinkText: "learn more about brands",
		size: "medium"
	};

	const handleNestedEntity = (toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({
				show: true,
				type,
				id,
				showCourses: true
			});
		}
		adjustNestedContainer(toOpen);
	};

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "multi-brand"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	return (
		<div className="section-container-common brandslist-section">
			<Header handlePiperAcademy={handlePiperAcademy} isAdmin={access.isAdmin} />
			<BrandFilters
				handleActiveFilter={handleActiveFilter}
				isActive={isActive}
				handleSearch={handleSearchByName}
				searchQuery={searchQuery}
			/>
			{
				<Transition
					native
					from={{ opacity: 0, height: 0 }}
					enter={{ opacity: 1, height: "auto" }}
					leave={{ opacity: 0, height: 0 }}
					items={selectedBrandsCount > 0}
					config={config.stiff}
				>
					{(isOpen) =>
						isOpen &&
						((props) => (
							<animated.div style={props} className="bulk-actions-container">
								<div className="bulk-actions">
									<div>{selectedBrandsCount} brand(s) selected</div>
									<div
										className={"link-text hyperlink " + (isActive ? "archive" : "restore")}
										onClick={handleBulkArchiveRestore}
									>
										{isActive ? "Archive" : "Restore"}
									</div>
								</div>
							</animated.div>
						))
					}
				</Transition>
			}
			<CommonTable
				loading={loading}
				columns={columns}
				data={data?.objects || []}
				content={"Brands"}
				classes="brands-list-table-container"
				handleClickMenuRender={handleClickMenuRender}
				setArchiveRestoreObject={setArchiveRestoreObject}
				checkbox={access.isAdmin}
				handleBrandSelection={handleBrandSelection}
				selectedBrands={selectedBrands}
				handleCheckAll={handleCheckAll}
				isCheckedAll={isCheckedAll}
				sortList={sortList}
				sortedField={sortData?.field === "name" ? "brand" : ""}
				openContextMenu={openContextMenu}
				closeContextMenu={closeContextMenu}
				contextMenuId={contextMenu.id}
				renderMenuItems={renderMenuItems}
				showContextMenu={access.isAdmin}
				showPlaceholder
				placeholderContent={placeholderContent}
			/>
			<Paginator count={data.count || 0} limit={10} goToPage={handlePagination} offset={offset} />
			<NestedEntityContainer
				show={nestedEntity.show}
				type={nestedEntity.type}
				id={nestedEntity.id}
				showCourses={nestedEntity?.showCourses}
				closeNestedContainer={() => handleNestedEntity(false)}
				nestedRef={nestedRef}
				isNested={false}
				isForeignSource={true}
			/>
			<ArchiveRestoreModal
				isOpen={isArchiveRestoreOpen}
				close={closeArchiveRestore}
				entityName="Brand"
				dataObject={archiveRestoreObject}
				mode={isActive ? "archive" : "restore"}
				entityType="brands"
			/>
			{clickMenuData.show && (
				<RightClickMenuContext isUnbordered anchorPoint={clickMenuData.anchorPoint}>
					<li onClick={() => handleArchiveRestore()} style={{ color: isActive ? "#ff425c" : "#2ecc71" }}>
						{isActive ? "Archive" : "Restore"}
					</li>
				</RightClickMenuContext>
			)}
		</div>
	);
};
const mapStateToProps = (store) => ({
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems,
	brandsListState: store.brandsListState,
	access: store.login.loginDetail.access
});
export default connect(mapStateToProps)(BrandsList);
