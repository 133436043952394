import gql from "graphql-tag";

export const GET_TIMING_GROUPS_LIST = gql`
	query getTimingGroupsList(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$brand: String
	) {
		timingsGroup(limit: $limit, offset: $offset, filters: $filters, sort: $sort, brand: $brand) {
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			objects {
				id
				title
				isActive
				timeSlots {
					starttime
					endtime
					day
				}
				associatedLocationsCount
				associatedBrands {
					id
					name
					image
				}
			}
		}
	}
`;

export const GET_HOLIDAY_SCHEDULE_LIST = gql`
	query getHolidayScheduleList(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$brand: String
	) {
		holidayHours(limit: $limit, offset: $offset, filters: $filters, sort: $sort, brand: $brand) {
			count
			objects {
				id
				title
				isActive
				date
				timeSlots {
					starttime
					endtime
					day
				}
				associatedLocationsCount
				associatedBrands {
					id
					name
					image
				}
			}
		}
	}
`;

export const CREATE_TIMING_GROUP = gql`
	mutation createTimingGroup($title: String!, $description: String, $daySlots: [TimingsGroupDayInput]) {
		saveTimingsGroup(input: { title: $title, description: $description, daySlots: $daySlots }) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				title
				description
				slotsCount
			}
		}
	}
`;
export const CREATE_HOLIDAY_TIMING_GROUP = gql`
	mutation saveHolidayHour($title: String!, $daySlots: [TimingsGroupDayInput], $date: Date!) {
		saveHolidayHour(input: { title: $title, daySlots: $daySlots, date: $date }) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				title
				slotsCount
			}
		}
	}
`;
export const GET_HOLIDAY_SCHEDULE_DETAIL = gql`
	query holidayHourDetail($id: Int, $brand: String) {
		holidayHourDetail(id: $id) {
			id
			title
			isActive
			date
			timeSlots {
				day
				starttime
				endtime
			}
			holidaysRelatedLocations(brand: $brand, limit: 100) {
				objects {
					id
					name
					city
					associatedBrandsData {
						id
						name
						image
					}
				}
			}
		}
	}
`;
export const EDIT_HOLIDAY_SCHEDULE_DETAIL = gql`
	mutation editHolidaySchedule(
		$id: Int
		$title: String!
		$daySlots: [TimingsGroupDayInput]
		$date: Date!
		$isActive: Boolean
	) {
		saveHolidayHour(input: { title: $title, daySlots: $daySlots, date: $date, id: $id, isActive: $isActive }) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				title
				isActive
				date
				timeSlots {
					day
					starttime
					endtime
				}
			}
		}
	}
`;
export const GET_TIMING_GROUP_DETAIL = gql`
	query getTimingsGroupDetail($id: Int, $brand: String) {
		timingsGroupDetail(id: $id) {
			id
			title
			description
			isActive
			timeSlots {
				day
				starttime
				endtime
			}
			slotsRelatedLocations(brand: $brand, limit: 100) {
				objects {
					id
					name
					city
					associatedBrandsData {
						id
						name
						image
					}
				}
			}
		}
	}
`;

export const EDIT_TIMING_GROUP_DETAIL = gql`
	mutation editTimingGroup(
		$id: Int
		$title: String!
		$description: String
		$isActive: Boolean
		$daySlots: [TimingsGroupDayInput]
	) {
		saveTimingsGroup(
			input: { id: $id, title: $title, description: $description, isActive: $isActive, daySlots: $daySlots }
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				title
				description
				slotsCount
			}
		}
	}
`;

export const GET_HOLIDAY_SCHEDULE_LIST_FOR_LOCATION_TAB = gql`
	query getHolidayScheduleListForLocationTab($filters: [ListFilterArgument], $sort: SortInput, $brand: String) {
		holidayHours(limit: 100, offset: 0, filters: $filters, sort: $sort, brand: $brand) {
			count
			objects {
				id
				title
				date
			}
		}
	}
`;

export const GET_SCHEDULE_ENTITY_LOCATIONS = gql`
	query entityLocationAssociations(
		$entityId: Int!
		$entityType: LocationAssociationEntityType!
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$brandId: Int
	) {
		entityLocationAssociations(
			entityId: $entityId
			entityType: $entityType
			limit: $limit
			offset: $offset
			sort: $sort
			filters: $filters
			brandId: $brandId
		) {
			count
			objects {
				bizLocationId
				bizLocationNickname
				city
				isAssociated
				tags
			}
		}
	}
`;

export const UPDATE_SCHEDULE_ENTITY_LOCATIONS = gql`
	mutation updateEntityLocationstItem(
		$entityId: Int!
		$entityType: LocationAssociationEntityType!
		$locationsToAssociate: [Int]
		$locationsToDisassociate: [Int]
		$associateAll: Boolean
		$disassociateAll: Boolean
		$filters: [ListFilterArgument]
		$brandId: Int
	) {
		updateEntityLocations(
			input: {
				entityId: $entityId
				entityType: $entityType
				locationsToAssociate: $locationsToAssociate
				locationsToDisassociate: $locationsToDisassociate
				associateAll: $associateAll
				disassociateAll: $disassociateAll
				filters: $filters
				brandId: $brandId
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const GET_TIMING_GROUP_BIZ_LOCATION_GROUPS = gql`
	query getLocationGroups {
		locationGroups {
			objects {
				id
				title
			}
		}
	}
`;

export const UPDATE_SCHEDULE_ENTITY_LOCATIONS_CASCADE = gql`
	mutation cascadeTgBrandLocations(
		$entityType: LocationAssociationEntityType!
		$timingGroupId: Int!
		$rootLocationsToAssociate: [Int] = []
		$associateAll: Boolean = false
		$filters: [ListFilterArgument] = []
	) {
		cascadeTgBrandLocations(
			input: {
				entityType: $entityType
				timingGroupId: $timingGroupId
				rootLocationsToAssociate: $rootLocationsToAssociate
				associateAll: $associateAll
				filters: $filters
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
