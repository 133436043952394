import React, { useState } from "react";

// components
import { RadioButton } from "../_commons/RadioButton";
import { SearchFilter } from "../_commons/SearchFilter";

// third party
import { connect } from "react-redux";

// utils
import { extractInitials } from "../../atlas-utils";

// actions
import { fetchBrandsDebounced } from "../../actions/actions";

const BrandSelection = ({ brands, dspIntegration, updateDspIntegrationState }) => {
	const [searchBrand, setSearchBrand] = useState("");
	const { selectedBrand } = dspIntegration;

	const handleSelect = (brand) => {
		updateDspIntegrationState({
			selectedBrand: brand
		});
	};

	const handleSearchBrand = (field, search) => {
		setSearchBrand(search);
		fetchBrandsDebounced(search);
	};

	return (
		<div className="dsp-brand-selection">
			<div className="header">
				<div className="header-text">Select a brand to start mapping locations</div>
				<SearchFilter
					filterOption={{ field: "searchBrand" }}
					value={searchBrand}
					setFilter={handleSearchBrand}
					placeholder="Search"
				/>
			</div>
			<div className={"brands" + (brands?.items?.length > 0 && brands?.isLoading ? " disabled" : "")}>
				{brands.items.length > 0 &&
					brands.items
						?.filter((brand) => brand.id !== "all")
						?.map((brand, i) => (
							<div
								key={i}
								className={"brand-container" + (selectedBrand?.id === brand?.id ? " selected" : "")}
								onClick={() => handleSelect(brand)}
							>
								<RadioButton
									checked={selectedBrand?.id === brand?.id}
									clickHandler={(e) => {
										e.stopPropagation();
										handleSelect(brand);
									}}
								/>
								<div className={"brand-initials " + brand.color}>
									{brand.image ? (
										<img src={brand.image} alt="" />
									) : (
										extractInitials(brand?.name?.split(" "))
									)}
								</div>
								<div className="brand-name" title={brand.name}>
									{brand.name}
								</div>
							</div>
						))}
				{brands.items.length === 0 && !brands.isLoading && (
					<div className="no-items-placeholder">No brands found</div>
				)}
				{brands.isLoading && (
					<div className="P(10px)">
						<div className="shimmer H(60px) Mb(10px)" />
						<div className="shimmer H(60px) Mb(10px)" />
					</div>
				)}
			</div>
		</div>
	);
};
const mapStateToProps = (store) => ({
	brands: store.configItems.brands
});
export default connect(mapStateToProps)(BrandSelection);
