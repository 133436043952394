// store
import { store } from "../store/configureStore";

// utils
import { formatDate } from "../atlas-utils";

/**
 * for line charts with compare date filter
 */
export const getReadableDateFilter = (compare = false) => {
	const { appliedDateFilter } = store.getState().analyticsFiltersState;
	const currTimestamp = appliedDateFilter?.current?.dateFilter;
	const compTimestamp = appliedDateFilter?.compare?.dateFilter;

	let readableDateFilter = "";
	const presetMap = {
		TODAY: "Today",
		YESTERDAY: "Yesterday",
		THIS_WEEK: "This Week",
		THIS_MONTH: "This Month",
		THIS_YEAR: "This Year",
		LAST_7_DAYS: "Last 7D",
		LAST_15_DAYS: "Last 15D",
		LAST_30_DAYS: "Last 30D",
		LAST_90_DAYS: "Last 90D"
	};
	const comparePresetMap = {
		TODAY: "Yesterday",
		YESTERDAY: "Day Before Yesterday",
		THIS_WEEK: "Last Week",
		THIS_MONTH: "Last Month",
		THIS_YEAR: "Last Year",
		LAST_7_DAYS: "Prev 7D",
		LAST_15_DAYS: "Prev 15D",
		LAST_30_DAYS: "Prev 30D",
		LAST_90_DAYS: "Prev 90D"
	};

	if (compare) {
		if (comparePresetMap[currTimestamp]) {
			readableDateFilter = comparePresetMap[currTimestamp];
		} else {
			const dates = compTimestamp.split(",");
			readableDateFilter = formatDate(dates[0], "DD MMM, YYYY") + " - " + formatDate(dates[1], "DD MMM, YYYY");
		}
	} else {
		if (presetMap[currTimestamp]) {
			readableDateFilter = presetMap[currTimestamp];
		} else {
			const dates = currTimestamp.split(",");
			readableDateFilter = formatDate(dates[0], "DD MMM, YYYY") + " - " + formatDate(dates[1], "DD MMM, YYYY");
		}
	}
	return readableDateFilter;
};
