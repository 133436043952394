import React from "react";

// components
import { CustomTable } from "../_commons/CustomTable";
import { Paginator } from "../_commons/Paginator";
import { Filters } from "./LocationsAndMenu";

// constants
import { getColumns } from "./LocationsAndMenu";
import { PLATFORM_MAP } from "../Hub/Integrations";

const ReviewLocationAssociation = ({
	dsp,
	dspIntegration = {},
	stores,
	handleSearchFilter,
	handleStatusFilter,
	handlePagination,
	handlePageSize,
	handleAssociationUpdates,
	mappedLocations = {},
	editMode = true,
	isMultibrandEnabled = false,
	renderBrandSelection
}) => {
	const { loading, limit, offset, data, searchFieldValue, appliedFilters, associationUpdates, selectedBrand } =
		dspIntegration;
	return (
		<div className="form-content">
			<div
				className="status-alert-container"
				style={{
					borderLeft: `6px solid #E5B917`,
					backgroundColor: "#FEF6E9"
				}}
			>
				<div className="error-icon">
					<img src="/assets/icons/warning-icon.svg" />
				</div>
				<div className="error-text-link">
					<div className="main-error-desc">
						Before syncing, confirm UrbanPiper matches {PLATFORM_MAP[dsp] || dsp} location(s). This will
						replace any existing {PLATFORM_MAP[dsp] || dsp} integration.
					</div>
				</div>
			</div>
			<Filters
				searchFieldValue={searchFieldValue}
				handleSearchFilter={handleSearchFilter}
				statusFilter={data?.filters?.find((f) => f.field === "store_status") || {}}
				handleStatusFilter={handleStatusFilter}
				statusValue={appliedFilters["store_status"] || null}
				renderBrandSelection={renderBrandSelection}
				isMultibrandEnabled={isMultibrandEnabled}
			/>
			{data.count > 0 && (
				<div className="locations-status-count">{data.count} Locations found that need to be reviewed</div>
			)}
			<CustomTable
				bordered={true}
				loading={loading}
				data={data?.objects || []}
				columns={getColumns(dsp, editMode, isMultibrandEnabled, selectedBrand)}
				classes="locations-table-container"
				content="locations"
				stores={stores}
				mappedLocations={mappedLocations}
				associationUpdates={associationUpdates}
				handleAssociationUpdates={handleAssociationUpdates}
				hideColumns={["menu", "status"]}
			/>
			<Paginator
				limit={limit}
				offset={offset}
				count={data?.count || 0}
				goToPage={handlePagination}
				setPageSize={handlePageSize}
				showPageSize={true}
			/>
		</div>
	);
};
export default ReviewLocationAssociation;
