import gql from "graphql-tag";

export const GET_METRIC_DATA = gql`
	query getMetricData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: MetricsQuery!
		$sort: SortInput
	) {
		getMetricData(requiredFilters: $requiredFilters, filters: $filters, query: $query, sort: $sort) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				selection
				value
				compareValue
				percentageChange
				isPrimary
				description
				type
				valueType
			}
		}
	}
`;

export const GET_LINE_CHART_DATA = gql`
	query getLineChartData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: LineQuery!
		$sort: SortInput
	) {
		getLineChartData(requiredFilters: $requiredFilters, filters: $filters, query: $query, sort: $sort) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				id
				data {
					x
					y
					z
				}
			}
		}
	}
`;

export const GET_GROUPED_LINE_CHART_DATA = gql`
	query getGroupedLineChartData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: GroupedLineQuery!
		$sort: SortInput
	) {
		getGroupedLineChartData(requiredFilters: $requiredFilters, filters: $filters, query: $query, sort: $sort) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				id
				data {
					x
					y
					z
				}
			}
		}
	}
`;

export const GET_PIE_CHART_DATA = gql`
	query getPieChartData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: PieQuery!
		$sort: SortInput
	) {
		getPieChartData(requiredFilters: $requiredFilters, filters: $filters, query: $query, sort: $sort) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				id
				name
				value
				compareValue
			}
		}
	}
`;

export const GET_BAR_CHART_DATA = gql`
	query getBarChartData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: BarQuery!
		$sort: SortInput
	) {
		getBarChartData(requiredFilters: $requiredFilters, filters: $filters, query: $query, sort: $sort) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				label
				values {
					key
					value
					compareValue
				}
			}
		}
	}
`;

export const GET_STACKED_BAR_CHART_DATA = gql`
	query getStackedBarChartData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: StackedBarQuery!
		$sort: SortInput
	) {
		getStackedBarChartData(requiredFilters: $requiredFilters, filters: $filters, query: $query, sort: $sort) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				label
				values {
					key
					value
					compareValue
				}
			}
		}
	}
`;

export const GET_SANKEY_CHART_DATA = gql`
	query getSankeyChartData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: SankeyQuery!
		$sort: SortInput
	) {
		getSankeyChartData(requiredFilters: $requiredFilters, filters: $filters, query: $query, sort: $sort) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				nodes {
					id
				}
				links {
					source
					target
					value
				}
			}
		}
	}
`;

export const GET_HEATMAP_CHART_DATA = gql`
	query getHeatmapChartData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: HeapMapQuery!
		$sort: SortInput
	) {
		getHeatmapChartData(requiredFilters: $requiredFilters, filters: $filters, query: $query, sort: $sort) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				y
				data {
					x
					value
					compareValue
				}
			}
		}
	}
`;

export const GET_AVAILABILITY_HEATMAP_CHART_DATA = gql`
	query getAvailabilityHeatmapChartData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: AvailabilityHeatMapQuery!
		$sort: SortInput
	) {
		getAvailabilityHeatmapChartData(
			requiredFilters: $requiredFilters
			filters: $filters
			query: $query
			sort: $sort
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				y
				data {
					x
					value
					compareValue
					timestamp
				}
			}
		}
	}
`;

export const GET_TABULAR_DATA = gql`
	query getTabularData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: TabularQuery!
		$search: [SearchArgument]
		$sort: SortInput
	) {
		getTabularData(
			requiredFilters: $requiredFilters
			filters: $filters
			query: $query
			search: $search
			sort: $sort
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				count
				columns {
					key
					displayName
					subDisplayName
					isDefault
					isPrimary
					isSortable
					valueType
					value
					type
				}
				rows {
					key
					value
					compareValue
					percentageChange
					description
				}
			}
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			searchKeywords {
				key
				valueForDisplay
			}
		}
	}
`;

export const GET_ITEM_MODIFIER_GROUPS = gql`
	query getItemModifierGroups($id: Int, $limit: Int, $offset: Int, $filters: [ListFilterArgument]) {
		item(id: $id) {
			optionGroups(limit: $limit, offset: $offset, filters: $filters) {
				objects {
					id
					optionGroupTitle
					isVariant
				}
			}
		}
	}
`;

export const GET_AVAILABILITY_HEATMAP_DETAIL_DATA = gql`
	query getAvailabilityHeatmapDetailData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: AvailabilityHeatMapDetailQuery!
		$sort: SortInput
	) {
		getAvailabilityHeatmapDetailData(
			requiredFilters: $requiredFilters
			filters: $filters
			query: $query
			sort: $sort
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				locationId
				locationName
				offlineSeconds
				availabilityPercentage
				platformCount
				brandCount
			}
		}
	}
`;

export const GET_AVAILABILITY_HEATMAP_LOCATION_DETAIL_DATA = gql`
	query getAvailabilityHeatmapLocationDetailData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: AvailabilityHeatMapDetailQuery!
	) {
		getAvailabilityHeatmapLocationDetailData(requiredFilters: $requiredFilters, filters: $filters, query: $query) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				timingGroup {
					timingGroupId
					brandName
					slots {
						start
						end
					}
				}
				locationData {
					brandId
					brandName
					brandLogo
					offlineSeconds
					expectedAvailability
					availabilityPercentage
					platform
				}
			}
		}
	}
`;
