import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { useTable, useSortBy } from "react-table";
import LocationCell from "../../../components/Periscope/LocationAvailability/LocationCell";
import BrandCell from "../../../components/Periscope/LocationAvailability/BrandCell";
import PlatformCell from "../../../components/Periscope/LocationAvailability/PlatformCell";
import { fetchLocationAvailabilityTableMetrics } from "../../../actions/periscope";
import { Paginator } from "../../../components/_commons/Paginator";
import { store } from "../../../store/configureStore";
import { scroll } from "../../../atlas-utils";
import Tooltip from "../../../components/Periscope/LocationAvailability/Tooltip";
import TablePlatforms from "../../../components/Periscope/LocationAvailability/TablePlatforms";
import TableLocationBrand from "../../../components/Periscope/LocationAvailability/TableLocationBrand";
import LocationTableLoading from "../../../components/Periscope/LocationAvailability/LocationTableLoading";
import { ActionTypes } from "../../../actions/_types";

const sidebarLocation = {
	verticalAlign: "top",
	padding: "20px 16px 20px 24px ",
	minWidth: "176px"
};

function useInstance(instance) {
	const { allColumns } = instance;
	let rowSpanHeaders = [];

	allColumns.forEach((column, i) => {
		const { id, enableRowSpan } = column;
		if (enableRowSpan) {
			rowSpanHeaders = [...rowSpanHeaders, { id, topCellValue: null, topCellIndex: 0 }];
		}
	});

	Object.assign(instance, { rowSpanHeaders });
}

const LocationAvailabilityTable = ({
	login,
	periscopeLocationAvailabilityState,
	status,
	selectedBrand,
	selectedCity,
	tableRef,
	periscopeLocationAvailabilityData,
	defaultPlatforms,
	refreshBtn
}) => {
	const data = periscopeLocationAvailabilityData?.data?.objects?.data;
	const loading = periscopeLocationAvailabilityData?.loading;
	const error = periscopeLocationAvailabilityData?.error;
	const dataCount = periscopeLocationAvailabilityState.count;
	const limit = periscopeLocationAvailabilityState.limit;
	const offset = periscopeLocationAvailabilityState.offset;

	const handlePagination = async (page) => {
		const offset = (page - 1) * limit;
		store.dispatch({
			type: ActionTypes.LOCATION_AVAILABILITY_LIST_STATE_CHANGE,
			payload: { offset: offset }
		});
		if (tableRef.current) {
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		}
	};

	const handlePageSize = async (field, size) => {
		store.dispatch({
			type: ActionTypes.LOCATION_AVAILABILITY_LIST_STATE_CHANGE,
			payload: { limit: size.value, offset: 0 }
		});

		if (tableRef.current) {
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		}
	};

	const columns = useMemo(() => {
		return Object.keys(data?.[0] || {}).map((column) => ({
			accessor: column,
			enableRowSpan: column == "location",
			Cell: (props) => {
				if (column == "location") {
					return <LocationCell props={props} />;
				} else if (column == "brand") {
					return <BrandCell props={props} />;
				} else {
					return <PlatformCell props={props.value} />;
				}
			}
		}));
	}, [data]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, rowSpanHeaders } = useTable(
		{ columns, data },
		useSortBy,
		(hooks) => {
			hooks.useInstance.push(useInstance);
		}
	);

	const dispatchSortFunction = (sort = null) => {
		if (sort === null) {
			store.dispatch({
				type: ActionTypes.LOCATION_AVAILABILITY_LIST_INITIAL_STATE_SORT
			});
		} else {
			store.dispatch({
				type: ActionTypes.LOCATION_AVAILABILITY_LIST_STATE_CHANGE,
				payload: sort
			});
		}
	};

	const handleClickSort = (column) => {
		if (column !== "location" && column !== "brand") {
			store.dispatch({
				type: ActionTypes.LOCATION_AVAILABILITY_LIST_STATE_CHANGE,
				payload: { offset: 0 }
			});
			const { field, order } = periscopeLocationAvailabilityState?.sort || {};
			let newOrder = "asc";
			if (field === column) {
				if (order === "asc") {
					newOrder = "desc";
				} else if (order === "desc") {
					newOrder = null;
				}
			}
			const sort = newOrder ? { sort: { field: column, order: newOrder } } : null;
			dispatchSortFunction(sort);
		}
	};

	const platformValues = periscopeLocationAvailabilityState?.appliedFilters?.platform?.value
		? periscopeLocationAvailabilityState.appliedFilters.platform.value.split(",")
		: defaultPlatforms.split(",");
	let newTable = ["location"];
	if (selectedBrand?.id === "all") {
		newTable.push("brand");
	}
	const tableData = [...newTable, ...platformValues];
	const platformCount = `${String(100 / platformValues.length)}%`;

	const tHeadclassMap = {
		brand: "brand-location-availability",
		location: "location-cell",
		platform: "platform-cell"
	};
	const tBodyClassNames = {
		false: "offline-color",
		true: "online-color",
		null: "partial-color"
	};
	return (
		<>
			{defaultPlatforms && defaultPlatforms.length > 0 ? (
				<>
					<div className="table-div-locations">
						<table
							{...getTableProps()}
							style={{ borderCollapse: "collapse", border: "1px solid  #EAEAEA", background: "#FFFFFF" }}
						>
							<thead className="location-availability-thead">
								<tr>
									{tableData.map((table, index) => {
										return (
											<th
												key={index}
												onClick={() => {
													handleClickSort(table);
												}}
												style={{
													...(table !== "brand" && table !== "location"
														? { width: `${platformCount}`, cursor: "pointer" }
														: {}),
													...(index == 0 && selectedBrand?.id !== "all"
														? { borderRight: "1px solid #EAEAEA" }
														: {})
												}}
												className={`${tHeadclassMap[table]} ${
													index == 0 ? "zero-index" : ""
												}  `}
											>
												{index === 0 || (index === 1 && table === "brand") ? (
													<TableLocationBrand tableName={table} />
												) : (
													<TablePlatforms tableName={table} textRight={true} />
												)}
											</th>
										);
									})}
								</tr>
							</thead>

							{loading || error ? (
								<>
									<LocationTableLoading
										refreshBtn={refreshBtn}
										error={error}
										tableData={tableData}
										selectedBrand={selectedBrand}
									/>
								</>
							) : (
								<>
									<tbody
										data-testid="location-table-data"
										{...getTableBodyProps()}
										className="location-availability-tbody"
									>
										{rows.map((row, i) => {
											prepareRow(row);

											for (let j = 0; j < row.allCells.length; j++) {
												let cell = row.allCells[j];
												let rowSpanHeader = rowSpanHeaders.find((x) => {
													return x.id === cell.column.id;
												});

												if (rowSpanHeader !== undefined) {
													if (
														rowSpanHeader.topCellValue === null ||
														rowSpanHeader.topCellValue.name !== cell.value.name
													) {
														cell.isRowSpanned = false;
														rowSpanHeader.topCellValue = cell.value;
														rowSpanHeader.topCellIndex = i;
														cell.rowSpan = 1;
													} else {
														rows[rowSpanHeader.topCellIndex].allCells[j].rowSpan++;
														cell.isRowSpanned = true;
													}
												}
											}
											return null;
										})}

										{rows.map((row, index) => {
											let rowIndex = -1;
											if (index > 0) {
												if (
													row.original.location.name !==
													rows[index - 1].original.location.name
												) {
													rowIndex = row.index;
												}
											}
											let lastRowId = rows[rows.length - 1].index;

											return (
												<tr {...row.getRowProps()} key={index}>
													{row.cells.map((cell, index) => {
														if (cell.isRowSpanned) return null;
														else
															return (
																<td
																	key={index}
																	className={`${tBodyClassNames[cell.value] || ""}  `}
																	style={{
																		...(cell.column.id === "location"
																			? sidebarLocation
																			: {}),

																		...(cell.row.id == rowIndex
																			? {
																					borderTop: "1px solid   #EAEAEA"
																			  }
																			: {}),
																		...(cell.column.id == "brand"
																			? {
																					borderRight: "1px solid #EAEAEA"
																			  }
																			: {}),

																		...(row.index == 0
																			? {
																					borderTop: "1px solid  #EAEAEA",
																					borderBottom: " 1px dashed #EAEAEA",
																					height: "100%"
																			  }
																			: cell.row.id == lastRowId
																			? {
																					borderBottom: "1px solid green"
																			  }
																			: {
																					borderBottom: "1px dashed #EAEAEA"
																			  }),

																		...(row.index == lastRowId
																			? {
																					borderBottom: "1px solid #EAEAEA"
																			  }
																			: {}),
																		...(index == 0 && selectedBrand?.id != "all"
																			? {
																					borderRight: "1px solid #EAEAEA"
																			  }
																			: {})
																	}}
																	rowSpan={cell.rowSpan}
																	{...cell.getCellProps()}
																>
																	{cell.value == false ||
																	cell.value == true ||
																	cell.value == null ? (
																		<>
																			<div className="tooltip-location">
																				{cell.render("Cell")}
																				<Tooltip value={cell.value} />
																			</div>
																		</>
																	) : (
																		<>{cell.render("Cell")}</>
																	)}
																</td>
															);
													})}
												</tr>
											);
										})}
									</tbody>
								</>
							)}

							{defaultPlatforms.length > 0 && dataCount > 0 && !loading ? (
								<>
									<tfoot>
										<tr>
											<td
												colSpan="100%"
												style={{ textAlign: "right" }}
												className="paginator-location"
											>
												<Paginator
													limit={limit}
													offset={offset}
													count={dataCount || 0}
													showPageSize={true}
													goToPage={handlePagination}
													setPageSize={handlePageSize}
												/>
											</td>
										</tr>
									</tfoot>
								</>
							) : (
								<> </>
							)}
						</table>
					</div>
				</>
			) : (
				<></>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	configItems: state.configItems,
	login: state.login,
	periscopeLocationAvailabilityState: state.periscopeLocationAvailabilityState,
	periscopeLocationAvailabilityData: state.periscopeLocationAvailabilityData
});

export default connect(mapStateToProps)(LocationAvailabilityTable);
