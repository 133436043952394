import React from "react";
import { connect } from "react-redux";
import StackProgressBar from "./StackProgressBar";
import SkeletonLoader from "../SkeletonLoader";
import Tooltip from "./Tooltip";
import { removeTrailingZeros } from "../../../helpers/periscope";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import history from "../../../history";

const ItemAvailability = ({ periscopeDashboard }) => {
	const itemMetrics = periscopeDashboard.itemMetrics;
	const totalItems = itemMetrics?.data?.totalItems;
	const offlineItems = itemMetrics?.data?.offlineItems || 0;
	const partialOfflineItems = itemMetrics?.data?.partialOfflineItems || 0;
	const isLoading = itemMetrics?.loading;
	const error = itemMetrics?.error;
	const dashboardMetrics = periscopeDashboard.dashboardMetrics;

	let offlineItemsPercent = dashboardMetrics?.data?.offlineItemsPercentage?.toFixed(2) || 0;
	if (offlineItemsPercent) {
		offlineItemsPercent = removeTrailingZeros(offlineItemsPercent);
	}

	const itemStackProgressBarData = [
		{
			itemOfflineCount: Math.ceil(itemMetrics?.data?.offlineItemsPercentage) || 0,
			itemPartialOfflineCount: Math.ceil(itemMetrics?.data?.partialOfflineItemsPercentage) || 0,
			itemRemainingCount:
				100 - (itemMetrics?.data?.offlineItemsPercentage + itemMetrics?.data?.partialOfflineItemsPercentage) ||
				0
		}
	];

	const progressBarKeys = ["itemOfflineCount", "itemPartialOfflineCount", "itemRemainingCount"];
	const keysColorMapping = {
		itemOfflineCount: "#B02B2B",
		itemPartialOfflineCount: "#FFA05B",
		itemRemainingCount: "#C6D2FF"
	};

	return (
		<div className="  availability border">
			<div className="title-action-peri ai-center">
				Item Availability
				<div className="tooltip">
					<img className="tooltip-icon-peri" src="../../../assets/periscope/tooltip-icon.svg" />
					<Tooltip
						title={"Item Availability"}
						description={"Breakdown of items that went offline on all or certain storefronts"}
					/>
				</div>
			</div>
			<div className="information-container">
				<div className="available-indicator-periscope justify-content-between ai-center">
					<div className="ai-center">
						<img className="main-icon-availability" src="../../../assets/periscope/spoon-white.svg" />
						<span className="small-size-text ">Total Items</span>
					</div>
					<span className="no-of-locations-peri">{totalItems}</span>
				</div>
				{isLoading || error ? (
					<div>
						<SkeletonLoader classname={`loader-availability ${error == true ? "stop-moving" : ""}`} />
					</div>
				) : (
					<>
						{offlineItems > 0 || partialOfflineItems > 0 ? (
							<div className="bargraph-dashboard">
								<div className="">
									<StackProgressBar
										outerDivClass={"item-loc-availability"}
										data={itemStackProgressBarData}
										keysColorMapping={keysColorMapping}
										keys={progressBarKeys}
										padding={1}
										borderRadius={2}
									/>
								</div>
								<div className="legend-peri ai-center">
									{offlineItems > 0 ? (
										<div
											className="graph-peri"
											onClick={() => {
												history.push("/periscope/item-availability?status=offline");
											}}
										>
											<div className="graph-text">
												<div className="circle offline"></div>
												<span className="status">Offline</span>
												<div className="tooltip">
													<img
														src="../../../assets/periscope/tooltip-icon.svg"
														className="tool-peri"
													/>
													<Tooltip
														title={"Offline Items"}
														description={"Items that are offline on all storefronts"}
													/>
												</div>
											</div>
											<div>
												<span className="info">{offlineItems}</span>
												{/* <img
													className="arrow-top"
													src="../../../assets/periscope/icons8-arrow-top.svg"
												/> */}
											</div>
										</div>
									) : (
										""
									)}

									{partialOfflineItems > 0 ? (
										<div
											className="graph-peri"
											onClick={() => {
												history.push("/periscope/item-availability?status=partial_offline");
											}}
										>
											<div className="graph-text ai-center">
												<div className="circle partial-offline"></div>
												<span className="status">Partially Offline</span>

												<div className="tooltip">
													<img
														src="../../../assets/periscope/tooltip-icon.svg"
														className="tool-peri"
													/>
													<Tooltip
														title={"Partially Offline Items"}
														description={
															"Items that are offline on one or more storefronts"
														}
														classname="show-left"
													/>
												</div>
											</div>
											<div>
												<span className="info"> {partialOfflineItems} </span>
												{/* <img
													className="arrow-top"
													src="../../../assets/periscope/icons8-arrow-top.svg"
												/> */}
											</div>
										</div>
									) : (
										""
									)}
								</div>
							</div>
						) : (
							<div className="online-locations ">
								<div>
									<img className="validation" src="../../../assets/periscope/correct-icon.svg" />
								</div>
								<div className="display">
									<span className="total-locations">{totalItems}</span>
									<span className="online"> Items Available</span>
								</div>
							</div>
						)}
					</>
				)}
			</div>
			<div className="info-display-periscope justify-content-between">
				<div className="ai-center">
					<img className="image-info-peri " src="../../../assets/periscope/spoon-black.svg" />
					<span className="small-size-text ">Item unavailable across storefronts</span>
				</div>
				<div className="ai-center percentage-display-peri">
					<div
						className={`availability-percentage justify-content-center  ${
							offlineItemsPercent > 0 ? "availability-red" : "availability-green"
						} `}
					>
						{isLoading || error ? (
							<SkeletonLoader classname={`show-percentage ${error == true ? "stop-moving" : ""}`} />
						) : (
							<span>{offlineItemsPercent}%</span>
						)}
					</div>
					<Link to="/periscope/item-availability">
						<img className="arrow-periscope" src="../../../assets/periscope/sidearrow.svg" />
					</Link>
				</div>
			</div>
			<div className="last-div-la-periscope ">
				<Link to="/periscope/item-availability">
					<span className="ai-center medium-size-text detailed-info-peri">
						View all items
						<img className="right-arrow-peri" src="../../../assets/periscope/right-arrow.svg" />
					</span>
				</Link>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	periscopeDashboard: state.periscopeDashboard
});
export default connect(mapStateToProps)(ItemAvailability);
