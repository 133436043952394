import React, { useState, useEffect } from "react";

// components
import { CheckBox } from "./CheckBox";
import ContextMenu from "./ContextMenu";
import Placeholder from "./Placeholder";
import { ButtonIcon } from "./ButtonIcon";
import ActionButton from "../Analytics/Common/ActionButton";

// third party
import { useTrail, config, animated } from "react-spring";

export const CustomTable = ({
	data = [],
	columns = [],
	sortedField,
	sortedOrder,
	sortList,
	loading = false,
	tableLoader = false,
	classes = "",
	tableOverflow = false,
	maxCellWidth = 250,
	forAnalytics = false,
	isRowClickable = false,
	rowClickHandler,
	content = "",
	customPlaceholder = "",
	hideColumns = [],
	archived = "",
	bordered = false,
	checkbox = false,
	disableCheckbox = false,
	showContextMenu = false,
	disableContextMenu = false,
	contextMenuCondition = null,
	renderMenuItems,
	contextMenuId = null,
	openContextMenu,
	closeContextMenu,
	showPlaceholder,
	placeholderContent,
	hasLimitedRows = false,
	limitRows = 5,
	viewAction = "",
	viewActionVariant,
	viewActionHref,
	handleViewAction,
	isPartiallyChecked = false,
	...rest
}) => {
	const [columnWidths, setColumnWidths] = useState({});
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});

	const getTextWidth = (text, font = "14px Source Sans Pro") => {
		// calculate text width using canvas
		const canvas = document.createElement("canvas");
		const context = canvas.getContext("2d");
		context.font = font;
		return context.measureText(text).width;
	};

	const calculateColumnsWidth = () => {
		columns.forEach((col) => {
			// cell width in header
			let maxWidth = getTextWidth(col.name);

			// cell content width in each row
			data.forEach((row) => {
				const cellWidth = getTextWidth(row[col.field]);
				maxWidth = Math.max(cellWidth, maxWidth);
			});

			setColumnWidths((current) => ({
				...current,
				[col.field]: maxWidth <= maxCellWidth ? maxWidth + 50 : maxCellWidth + 50 // Adding some padding
			}));
		});
	};

	useEffect(() => {
		if (tableOverflow) {
			calculateColumnsWidth();
		}
	}, [columns, data]);

	if (showPlaceholder && data.length === 0 && !loading) {
		return <Placeholder {...placeholderContent} />;
	}

	return (
		<div
			className={
				"custom-table-container " +
				(bordered ? "bordered " : "") +
				(forAnalytics ? "analytics " : "") +
				(data.length > 0 && loading ? "disabled " : "") +
				classes
			}
		>
			<TableHeader
				columns={columns}
				columnWidths={columnWidths}
				tableOverflow={tableOverflow}
				hideColumns={hideColumns}
				sortList={sortList}
				sortedField={sortedField}
				sortedOrder={sortedOrder}
				archived={archived}
				checkbox={checkbox}
				disableCheckbox={disableCheckbox}
				forAnalytics={forAnalytics}
				isRowClickable={isRowClickable}
				isPartiallyChecked={isPartiallyChecked}
				{...rest}
			/>
			{trails.slice(0, hasLimitedRows ? limitRows : data.length).map(({ rotate }, rowIndex) => (
				<TableList
					key={rowIndex}
					loading={loading}
					style={{
						transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
					}}
					rowIndex={rowIndex}
					data={data[rowIndex] || {}}
					columns={columns}
					columnWidths={columnWidths}
					tableOverflow={tableOverflow}
					archived={archived}
					checkbox={checkbox}
					hideColumns={hideColumns}
					showContextMenu={showContextMenu}
					contextMenuCondition={contextMenuCondition}
					renderMenuItems={renderMenuItems}
					contextMenuId={contextMenuId}
					openContextMenu={openContextMenu}
					closeContextMenu={closeContextMenu}
					disableContextMenu={disableContextMenu}
					disableCheckbox={disableCheckbox}
					forAnalytics={forAnalytics}
					isRowClickable={isRowClickable}
					rowClickHandler={rowClickHandler}
					sortedField={sortedField}
					{...rest}
				/>
			))}
			{data.length === 0 && !loading && content && !customPlaceholder && (
				<div className="no-items-placeholder">No {content} found!</div>
			)}
			{data.length === 0 && !loading && customPlaceholder && (
				<div className="custom-placeholder">{customPlaceholder}</div>
			)}
			{data.length === 0 && loading && !tableLoader && (
				<div className="P(10px)">
					<div className="shimmer H(60px) Mb(10px)" />
					<div className={`shimmer H(60px) ${forAnalytics ? "Mb(220px)" : ""}`} />
				</div>
			)}
			{data.length === 0 && loading && tableLoader && <TableLoader />}
			{viewAction && (handleViewAction || (viewActionVariant && viewActionHref)) && (
				<div className="view-action-row">
					<ActionButton variant={viewActionVariant} href={viewActionHref} clickHandler={handleViewAction}>
						{viewAction}
					</ActionButton>
				</div>
			)}
		</div>
	);
};

const TableHeader = ({
	columns,
	columnWidths,
	tableOverflow = false,
	hideColumns,
	sortList,
	sortedField,
	sortedOrder,
	archived,
	checkbox,
	disableCheckbox,
	forAnalytics,
	isRowClickable,
	isPartiallyChecked = false,
	...rest
}) => {
	if (columns.length === 0) {
		return null;
	}
	return (
		<div
			className={"table-header" + (forAnalytics ? " analytics" : "")}
			style={
				tableOverflow ? { minWidth: Object.values(columnWidths).reduce((total, val) => val + total, 0) } : {}
			}
		>
			{columns.map((obj, i) => {
				return !hideColumns.includes(obj.field) ? (
					<div
						key={i}
						className={
							`table-cell ${obj.field} ${obj.classes || ""}` +
							(obj.sortKey ? " clickable" : "") +
							(sortedField && sortedField === obj.sortKey
								? ` active ${archived} ${sortedOrder ? "dark" : ""}`
								: "") +
							(isRowClickable ? " is-row-clickable" : "")
						}
						onClick={obj.sortKey ? () => sortList(obj.sortKey) : void 0}
						style={tableOverflow && columnWidths[obj.field] ? { minWidth: columnWidths[obj.field] } : {}}
					>
						{checkbox && i === 0 && (
							<CheckBox
								checked={rest.isCheckedAll}
								clickHandler={(e) => rest.handleCheckAll(e, !rest.isCheckedAll)}
								readOnly={disableCheckbox}
								isPartiallyChecked={isPartiallyChecked}
							/>
						)}
						<span className={obj.isRequired ? "required-sign" : ""}>
							{obj.name}
							{obj.subName && <div className="sub-name">{obj.subName}</div>}
						</span>
						{obj.sortKey && !sortedOrder && (
							<span>
								<img src="/assets/icons/icon-sort.svg" alt="" />
							</span>
						)}
						{obj.sortKey && sortedOrder && (
							<span className="sort-icons">
								<ButtonIcon
									icon="triangle-up"
									color={
										sortedField && sortedField === obj.sortKey && sortedOrder === "ASC"
											? "#3B5FEB"
											: "#D0D0D0"
									}
								/>
								<ButtonIcon
									icon="triangle-down"
									color={
										sortedField && sortedField === obj.sortKey && sortedOrder === "DESC"
											? "#3B5FEB"
											: "#D0D0D0"
									}
								/>
							</span>
						)}
					</div>
				) : null;
			})}
		</div>
	);
};

const TableList = ({ data, columns, hideColumns, forAnalytics, isRowClickable, rowClickHandler, style, ...rest }) => {
	const {
		showContextMenu,
		renderMenuItems,
		openContextMenu,
		closeContextMenu,
		disableContextMenu,
		columnWidths,
		tableOverflow
	} = rest;
	return (
		<animated.div
			// style={style}
			style={
				tableOverflow ? { minWidth: Object.values(columnWidths).reduce((total, val) => val + total, 0) } : {}
			}
			className={"table-list" + (forAnalytics ? " analytics" : "") + (isRowClickable ? " clickable" : "")}
			onClick={isRowClickable && rowClickHandler ? () => rowClickHandler(data, rest) : () => {}}
		>
			{columns.map((obj, i) => {
				return !hideColumns.includes(obj.field) ? obj.render(data, i, rest) : null;
			})}
			{showContextMenu && (
				<ContextMenu
					isOpen={rest.contextMenuId === data.id || (!data.id && rest.contextMenuId === rest.rowIndex)}
					data={data}
					disableContextMenu={
						(rest.contextMenuCondition ? rest.contextMenuCondition(data, rest) : false) ||
						disableContextMenu
					}
					renderMenuItems={renderMenuItems}
					handleOpenMenu={() => openContextMenu(data, rest)}
					handleOutsideClick={
						rest.contextMenuId === data.id || (!data.id && rest.contextMenuId === rest.rowIndex)
							? () => closeContextMenu(data, rest)
							: () => {}
					}
					clickEvent="mousedown"
					rest={rest}
				/>
			)}
			{isRowClickable && rowClickHandler && <ButtonIcon icon="chevron" color="#aaaaaa" />}
		</animated.div>
	);
};

const TableLoader = () => {
	const rows = [...Array(10).keys()];
	return (
		<div className="table-loader">
			{rows.map((row) => (
				<div className="table-list" key={row}>
					<div className="table-cell small">
						<div className="shimmer H(25px) W(25px)" />
					</div>
					<div className="table-cell large">
						<div className="shimmer H(25px) W(25px)" />
						<div className="shimmer H(25px) W(150px)" />
						<div className="shimmer H(25px) W(25px)" />
					</div>
					<div className="table-cell medium">
						<div className="shimmer H(25px) W(80px)" />
					</div>
					<div className="table-cell medium">
						<div className="shimmer H(25px) W(80px)" />
					</div>
					<div className="table-cell medium">
						<div className="shimmer H(25px) W(80px)" />
					</div>
					<div className="table-cell medium">
						<div className="shimmer H(25px) W(80px)" />
					</div>
					<div className="table-cell medium">
						<div className="shimmer H(25px) W(80px)" />
					</div>
				</div>
			))}
		</div>
	);
};
