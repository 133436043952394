import moment from "moment";

export const removeTrailingZeros = (number) => {
	return parseFloat(number).toString();
};

export const getBrandDetail = (brandId, brands) => {
	if (brands && brands.length > 0) {
		return brands.find((brand) => brand?.id == brandId);
	}
	return null;
};

export const getPlatformDetailViaName = (platformName, platforms) => {
	if (platforms && platforms.length > 0) {
		return platforms.find((plt) => plt?.platformName?.toLowerCase() == platformName?.toLowerCase());
	}
	return null;
};

export const getBrandDetailViaName = (brandName, brands) => {
	if (brands && brands.length > 0) {
		return brands.find((brand) => (brand?.name).toLowerCase() == brandName.toLowerCase());
	}
	return null;
};

export const getPlatformNames = (bizPlatforms, lowercase = false) => {
	if (bizPlatforms && bizPlatforms.length > 0) {
		return bizPlatforms
			.map((platform) => {
				if (lowercase) {
					if (platform?.platformName.toLowerCase() == "all platforms") {
						return null;
					}
					return platform?.platformName.toLowerCase();
				} else {
					return platform?.platformName;
				}
			})
			.filter((name) => name !== null);
	}
};

export const getPlatformsDetailsViaId = (platformId, platforms) => {
	if (platforms && platforms.length > 0) {
		return platforms.find((platform) => platform.id == platformId);
	}
	return null;
};

export const dateFormat = (dateString) => {
	if (dateString) {
		const localDate = moment.utc(dateString).local();
		const currentDate = moment();
		if (localDate.isSame(currentDate, "day")) {
			return `Updated: Today ${localDate.format("h:mm A")}`;
		} else {
			return `Updated: ${localDate.format("D MMMM, h:mm A")}`;
		}
	}
};

export const getFutureDate = (days, format = "D MMM YYYY") => {
	return moment().add(days, "days").format(format);
};

export const roundAndFormatNumber = (number, decimalPlaces) => {
	if (isNaN(number)) return "0";

	let roundedNumber = number.toFixed(decimalPlaces);

	// Remove trailing zeros
	let formattedNumber = roundedNumber.replace(/(\.\d*[1-9])0+$|\.0*$/, "$1");
	return formattedNumber;
};
