import React from "react";

// components
import ReportsList from "./ReportsList";
import ReportDownload from "./ReportDownload";
import ReportPreview from "./ReportPreview";

// third party
import { Route, Redirect } from "react-router-dom";

const ReportsNew = () => {
	return (
		<div className="reports-container">
			<Route path="/reports/list/:tab" component={ReportsList} />
			<Route exact path="/reports/list/overview/:id" component={ReportDownload} />
			<Route exact path="/reports/preview/:id/:name" component={ReportPreview} />
			<Route exact path="/reports" render={() => <Redirect to="/reports/list/overview" />} />
		</div>
	);
};
export default ReportsNew;
