import React, { useState, useEffect, useRef } from "react";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import { TagsInputWrapper } from "../_commons/TagsInputWrapper";
import { RadioButton } from "../_commons/RadioButton";
import { ButtonIcon } from "../_commons/ButtonIcon";

// third party
import { connect } from "react-redux";

// store
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";
import { generateReportV2 } from "../../actions/reports";

const ShareOnEmail = ({ isOpen = false, close, reportDetail }) => {
	const [isFormOpen, setFormOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [exportType, setExportType] = useState(undefined);
	const [currEmail, setCurrEmail] = useState("");
	const { data, emails, validations } = reportDetail;
	const tagsRef = useRef();

	useEffect(() => {
		if (isOpen) {
			setTimeout(() => setFormOpen(true), 60);
			if (data?.exports?.length === 1) {
				setExportType(data.exports[0]);
			}
		}
	}, [isOpen]);

	const updateReportDetail = (payload) => {
		store.dispatch({
			type: ActionTypes.UPDATE_REPORT_DETAIL,
			payload
		});
	};

	const handleClose = (emailExportSuccess = false) => {
		setFormOpen(false);
		setTimeout(() => {
			// reset form state
			updateReportDetail({
				emails: [],
				validations: {
					...validations,
					emails: ""
				}
			});
			close(emailExportSuccess, exportType);
		}, 100);
	};

	const setEmailFilter = (tags) => {
		updateReportDetail({
			emails: tags
		});
	};

	const handleTagInput = (val) => {
		setCurrEmail(val);
		if (validations?.emails) {
			updateReportDetail({
				validations: {
					...validations,
					emails: ""
				}
			});
		}
	};

	const handleExportReport = async () => {
		// export report
		setIsLoading(true);
		const status = await generateReportV2(exportType);
		if (status.success) {
			handleClose(true);
		}
		setIsLoading(false);
	};

	return (
		<div className="report-download share-on-email">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleClose}
				isNested={false}
				loading={isLoading}
				title="Share on Email"
				submitTitle="Send"
				submit={handleExportReport}
				disabled={!emails.length || !exportType}
				overlaySidebarClasses="small"
			>
				<div className="filters-container">
					<TagsInputWrapper
						ref={tagsRef}
						tags={emails}
						onChange={setEmailFilter}
						tagInput={currEmail}
						onChangeInput={handleTagInput}
						requiredLabel={true}
						placeholder={"Enter an email"}
						validationRegex={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
						onValidationReject={() =>
							updateReportDetail({
								validations: {
									...validations,
									emails: "Please enter a valid email"
								}
							})
						}
						validationMessage={validations?.emails || ""}
						showDropdown={true}
					>
						Recipient Email IDs
					</TagsInputWrapper>
				</div>
				<div className="choose-format">
					<div className="title">Choose A Format</div>
					<div className="export-types">
						{data?.exports?.map((type, i) => (
							<div
								className={"type" + (exportType?.id === type.id ? " selected" : "")}
								key={i}
								onClick={() => setExportType(type)}
							>
								<RadioButton
									checked={exportType?.id === type.id}
									clickHandler={(e) => {
										e.stopPropagation();
										setExportType(type);
									}}
								/>
								<div className="icon">
									<ButtonIcon
										icon={type.id}
										color={exportType?.id === type.id ? "#3B5FEB" : "#aaaaaa"}
									/>
								</div>
							</div>
						))}
					</div>
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	reportDetail: store.reportDetail
}))(ShareOnEmail);
