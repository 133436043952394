import React, { useState, useEffect, useMemo } from "react";

// components
import { Button } from "../_commons/Button";
import { CustomTable } from "../_commons/CustomTable";
import { Paginator } from "../_commons/Paginator";
import { SelectFilter } from "../_commons/SelectFilter";
import { SearchFilter } from "../_commons/SearchFilter";
import { Modal } from "../_commons/Modal";
import { NumericalStepDisplay } from "../_commons/NumericalStepDisplay";
import { FormContainer } from "../../containers/Meraki";
import BrandSelection from "./BrandSelection";
import ReviewLocationAssociation from "./ReviewLocationAssociation";
import AssociateMenu from "./AssociateMenu";
import PublishAndActivate from "./PublishAndActivate";

// third party
import { connect } from "react-redux";
import history from "../../history";
import { debounce } from "lodash";

// store
import { store } from "../../store/configureStore";

// services
import NotificationServices from "../../services/NotificationService";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchStoresDebounced, fetchMenusDebounced } from "../../actions/actions";
import {
	fetchDspPlatformLocationsList,
	handleDspUpdatePlatformLocations,
	handleDspActivateStoresAndPublishMenu,
	handleDspPlatformSwitchAccount
} from "../../actions/dspIntegration";

// constants
import { DSP_INTEGRATION_INITIAL_STATE } from "../../reducers/dspIntegration";
import { CATALOGUE_PLATFORMS_LOGO } from "../../client-config";
import { PLATFORM_MAP } from "../Hub/Integrations";
const INIT_CARD_PROPS = {
	header: {
		text: "",
		subText: "",
		icon: ""
	},
	footer: {
		primary: "",
		primaryHandler: () => {}
	}
};

const LocationsAndMenu = ({
	match,
	editMode = false,
	dspIntegration = {},
	stores,
	menus,
	isMultibrandEnabled = false,
	renderBrandSelection,
	tempBrandSelected = null,
	setTempBrandSelected,
	brands
}) => {
	const {
		loading,
		limit,
		offset,
		data,
		platform,
		selectedTab,
		searchFieldValue,
		appliedFilters,
		mappedLocations,
		associationUpdates,
		selectedBrand
	} = dspIntegration;
	const storeStatusCount = useMemo(
		() => ({
			all: data?.mappedStoresCount,
			mapped: data?.mappedStoresCount,
			active: data?.activeStoresCount,
			unmapped: data?.unmappedStoresCount
		}),
		[data?.mappedStoresCount, data?.activeStoresCount, data?.unmappedStoresCount]
	);
	const [modalOpen, setModalOpen] = useState(false);
	const [brandSelectionOpen, setBrandSelectionOpen] = useState(false);
	const [switchAccountOpen, setSwitchAccountOpen] = useState(false);
	const [cardProps, setCardProps] = useState(INIT_CARD_PROPS);
	const [activationSuccess, setActivationSuccess] = useState(undefined);
	const [isFormTouched, setFormTouched] = useState(false);
	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		if (platform) {
			fetchDspPlatformLocationsList(isMultibrandEnabled ? selectedTab === 3 : selectedTab === 2);
		}
	}, [platform, limit, offset, appliedFilters, selectedTab]);

	useEffect(() => {
		if ((isMultibrandEnabled && selectedTab !== 1) || !isMultibrandEnabled) {
			fetchStoresDebounced("", 50, selectedBrand?.id, false, "no-cache");
			fetchMenusDebounced("", selectedBrand?.id, "no-cache");
		}
	}, [selectedBrand, selectedTab]);

	useEffect(() => {
		if (isMultibrandEnabled && !selectedBrand && !editMode) {
			updateDspIntegrationState({ selectedBrand: brands?.items?.[0] || null });
		}
	}, [brands?.items]);

	useEffect(() => {
		if (tempBrandSelected) {
			setBrandSelectionOpen(true);
		}
	}, [tempBrandSelected]);

	const updateDspIntegrationState = (payload) => {
		store.dispatch({
			type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
			payload
		});
	};

	const handlePagination = (page) => {
		// set new offset
		const offset = (page - 1) * limit;
		updateDspIntegrationState({
			offset
		});
	};

	const handlePageSize = async (field, size) => {
		// set new limit
		if (size && size?.value !== limit) {
			updateDspIntegrationState({
				[field]: size.value,
				offset: 0
			});
		}
	};

	const handleStatusFilter = (field, value) => {
		const filters = {
			...appliedFilters
		};
		filters[field] = value;
		updateDspIntegrationState({
			currentFilters: filters,
			appliedFilters: filters,
			offset: 0
		});
	};

	const applySearchFilter = debounce(
		() => fetchDspPlatformLocationsList(isMultibrandEnabled ? selectedTab === 3 : selectedTab === 2),
		500
	);

	const handleSearchFilter = (field, value) => {
		updateDspIntegrationState({
			[field]: value,
			offset: 0
		});
		applySearchFilter();
	};

	const handleAssociationUpdates = (record, field, value, prevValue = null) => {
		// update association updates
		const newAssociationUpdates = { ...associationUpdates };

		// check if the 'value' is same as already associated location. If yes, then
		// avoid/remove 'value' from association updates and 'prevValue' from mapped locations
		if (field === "location" && record?.location?.id === value?.id) {
			delete newAssociationUpdates[record?.id];
		} else {
			newAssociationUpdates[record?.id] = {
				...(associationUpdates?.[record?.id] ?? {}),
				id: record?.id,
				externalId: record?.externalId,
				locationName: record?.locationName,
				platformName: record?.platformName,
				location: record?.location || null,
				isStoreMapped: record?.isStoreMapped,
				[field]: value
			};
		}

		// update mapped locations
		const updatedMappedLocations = { ...mappedLocations };
		if (field === "location") {
			updatedMappedLocations[value?.id] = true;
			if (prevValue && prevValue?.id !== value?.id) {
				updatedMappedLocations[prevValue?.id] = false;
			}
		}

		// update dsp integration state
		updateDspIntegrationState({
			associationUpdates: newAssociationUpdates,
			mappedLocations: updatedMappedLocations
		});

		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleModalAction = (primary = false) => {
		if (primary && editMode) {
			updateDspIntegrationState({
				associationUpdates: {},
				mappedLocations: {}
			});
			history.push(match.url?.replace("/edit", ""));
		}
		if (primary && !editMode) {
			history.push(`${match?.url}/edit`);
		}
		setModalOpen(false);
	};

	const resetDspIntegrationState = (selectedTab = 1, props = {}) => {
		updateDspIntegrationState({
			selectedTab,
			offset: 0,
			currentFilters: DSP_INTEGRATION_INITIAL_STATE.currentFilters,
			appliedFilters: DSP_INTEGRATION_INITIAL_STATE.appliedFilters,
			searchFieldValue: "",
			associationUpdates: {},
			mappedLocations: {},
			...props
		});
	};

	const handleCancel = (reset = false) => {
		if (isMultibrandEnabled) {
			if (selectedTab === 1 || reset) {
				if (reset) {
					updateDspIntegrationState({
						associationUpdates: {},
						mappedLocations: {},
						selectedTab: 1
					});
				}
				history.push(reset ? "/hub/integrations" : match.url?.replace("/edit", ""));
				return;
			}
			if (selectedTab === 2) {
				if (Object.keys(associationUpdates)?.length === 0) {
					updateDspIntegrationState({
						associationUpdates: {},
						mappedLocations: {},
						selectedTab: 1
					});
					return;
				}
				// show modal confirmation to discard association updates
				setModalOpen(true);
				return;
			}
			if (selectedTab === 3) {
				resetDspIntegrationState(2);
				return;
			}
		}
		if (selectedTab === 1 || reset) {
			if (Object.keys(associationUpdates)?.length === 0 || reset) {
				updateDspIntegrationState({
					associationUpdates: {},
					mappedLocations: {},
					selectedTab: 1
				});
				history.push(reset ? "/hub/integrations" : match.url?.replace("/edit", ""));
				return;
			}
			// show modal confirmation to discard association updates
			setModalOpen(true);
			return;
		}
		if (selectedTab === 2) {
			resetDspIntegrationState(1);
			return;
		}
	};

	const handleSubmit = async () => {
		if (isMultibrandEnabled && selectedTab === 1) {
			updateDspIntegrationState({ selectedTab: 2 });
			return;
		}
		if ((!isMultibrandEnabled && selectedTab === 1) || (isMultibrandEnabled && selectedTab === 2)) {
			// handle dsp update platform locations if any associations are done
			if (
				Object.keys(associationUpdates)?.length > 0 &&
				Object?.values(associationUpdates)?.filter(
					(loc) => (loc?.isStoreMapped && loc?.location === null) || loc?.location !== null
				)?.length > 0
			) {
				// first validate if all location associations are present in associationUpdates
				let isValid = true;
				for (let id in associationUpdates) {
					// invalidate only if the association already existed previously, but has been removed now.
					let record = data?.objects?.find((loc) => loc?.id === id);
					if (record?.location && !associationUpdates[id]?.location) {
						isValid = false;
					}
				}
				if (!isValid) {
					NotificationServices.pushNotification({
						message: "Please check for missing required location associations and add them.",
						timeout: 5000,
						type: "error",
						isClosable: true,
						theme: "dark"
					});
					return;
				}
				// proceed once validation is done
				setLoading(true);
				const status = (await handleDspUpdatePlatformLocations()) || {};
				if (status?.success) {
					NotificationServices.pushNotification({
						message: "Progress Saved!",
						timeout: 5000,
						type: "success",
						isClosable: true,
						theme: "dark"
					});
					setLoading(false);
				} else {
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: "Something went wrong.",
							timeout: 2000,
							error: true
						}
					});
					setLoading(false);
					return;
				}
			}
			// reset state for menu association step
			resetDspIntegrationState(isMultibrandEnabled ? 3 : 2);
			return;
		}
		if ((!isMultibrandEnabled && selectedTab === 2) || (isMultibrandEnabled && selectedTab === 3)) {
			// handle dsp menu association, publish and store activation
			setLoading(true);
			const status = (await handleDspActivateStoresAndPublishMenu()) || {};
			if (status?.success) {
				setCardProps({
					header: {
						text: "Activation Request Received",
						subText:
							"Processing takes a few minutes. We'll email you once done. Post activation, you will start receiving online orders on our platform and/or in your POS.",
						icon: "/assets/icons/icon-check.svg"
					},
					footer: {
						primary: "Go back to Integrations",
						primaryHandler: () => handleCancel(true)
					}
				});
				setActivationSuccess(true);
			} else {
				setCardProps({
					header: {
						text: status?.messages?.[0]?.message || "Something went wrong!",
						subText: "Publish and Store activation failed.",
						icon: "/assets/icons/icon-cross.svg"
					},
					footer: {
						primary: "Go back to Integrations",
						primaryHandler: () => handleCancel(true)
					}
				});
				setActivationSuccess(false);
			}
			setLoading(false);
		}
	};

	const handleDisableFormSubmit = () => {
		if (isMultibrandEnabled && selectedTab === 1 && !selectedBrand) {
			return true;
		}
		if (selectedTab === 1 || (isMultibrandEnabled && selectedTab === 2)) {
			return false;
		}
		if (
			((!isMultibrandEnabled && selectedTab === 2) || (isMultibrandEnabled && selectedTab === 3)) &&
			Object.keys(associationUpdates)?.length > 0 &&
			Object.values(associationUpdates)?.filter((loc) => loc?.associatedMenu !== null)?.length > 0
		) {
			return false;
		}
		return true;
	};

	const handleSwitchAccount = async (modalConfirmed = false) => {
		if (modalConfirmed) {
			setLoading(true);
			const status = await handleDspPlatformSwitchAccount();
			if (status?.success) {
				NotificationServices.pushNotification({
					message: "Account logged out successfully",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
				history.push("/hub/integrations");
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: status?.messages?.[0]?.message || "Something went wrong.",
						timeout: 3000,
						error: true
					}
				});
			}
			setLoading(false);
		}
		setSwitchAccountOpen(!switchAccountOpen);
	};

	const handleBrandSelectionModalAction = (primary = false) => {
		if (primary) {
			resetDspIntegrationState(1, { selectedBrand: tempBrandSelected });
		}
		setBrandSelectionOpen(false);
		setTempBrandSelected(null);
	};

	const renderTitle = () => {
		if (isMultibrandEnabled && selectedTab === 1) {
			return "Select a brand";
		}
		if ((!isMultibrandEnabled && selectedTab === 1) || (isMultibrandEnabled && selectedTab === 2)) {
			return "Associate Locations";
		}
		if ((!isMultibrandEnabled && selectedTab === 2) || (isMultibrandEnabled && selectedTab === 3)) {
			return "Associate Menu";
		}
	};

	const handleFormSubmitTitle = () => {
		if (isMultibrandEnabled) {
			if (selectedTab === 1) {
				return "Continue to Associate Location";
			}
			if (selectedTab === 2) {
				return "Continue to Associate Menu";
			}
			if (selectedTab === 3) {
				return "Publish & Activate Store";
			}
		}
		if (selectedTab === 1) {
			return "Continue to Associate Menu";
		}
		if (selectedTab === 2) {
			return "Publish & Activate Store";
		}
	};

	if (activationSuccess !== undefined) {
		return (
			<PublishAndActivate
				activationSuccess={activationSuccess}
				cardProps={cardProps}
				dspIntegration={dspIntegration}
				fetchDspPlatformLocationsList={fetchDspPlatformLocationsList}
				handleSearchFilter={handleSearchFilter}
				handleStatusFilter={handleStatusFilter}
				handlePagination={handlePagination}
				handlePageSize={handlePageSize}
				handleAssociationUpdates={handleAssociationUpdates}
				updateDspIntegrationState={updateDspIntegrationState}
				handleCancel={handleCancel}
			/>
		);
	}

	return (
		<div className="dsp-locations">
			<FormContainer
				cancel={handleCancel}
				cancelTitle={selectedTab === 1 ? "Back" : "Previous"}
				submit={handleSubmit}
				submitLoading={isLoading}
				disableSubmit={handleDisableFormSubmit()}
				submitTitle={handleFormSubmitTitle()}
				submitClass={isMultibrandEnabled && selectedTab === 1 ? "W(250px)" : "W(200px)"}
				hideActions={!editMode}
			>
				{editMode ? (
					<NumericalStepDisplay
						count={isMultibrandEnabled ? 3 : 2}
						selectedValue={selectedTab}
						hideProgress={true}
						showProgressBar={true}
						title={renderTitle()}
						renderContentRight={
							<div className="actions">
								<div className="action" onClick={() => handleSwitchAccount(false)}>
									Switch Account
								</div>
							</div>
						}
					/>
				) : (
					<div className="credits-section-header hub-config-header">
						<div className="header-text">
							<div className="title">Associated Locations and Menu</div>
							<div className="subtitle">Manage your store location and menu associations</div>
						</div>
						<div className="header-action-button">
							<div className="action" onClick={() => handleSwitchAccount(false)}>
								Switch Account
							</div>
							<Button
								clickHandler={() => setModalOpen(true)}
								classes={data?.count === 0 ? "disabled" : ""}
							>
								<img src="/assets/icons/icon-edit--filled-white.svg" alt="" />
								<span>Edit</span>
							</Button>
						</div>
					</div>
				)}
				{!editMode && (
					<div className="form-content">
						<Filters
							searchFieldValue={searchFieldValue}
							handleSearchFilter={handleSearchFilter}
							statusFilter={data?.filters?.find((f) => f.field === "store_status") || {}}
							handleStatusFilter={handleStatusFilter}
							statusValue={appliedFilters["store_status"] || null}
							renderBrandSelection={renderBrandSelection}
							isMultibrandEnabled={isMultibrandEnabled}
						/>
						{data?.storesCount > 0 && (
							<div className="locations-status-count">
								{loading ? (
									<div className="shimmer W(150px) H(20px)"></div>
								) : (
									<div>
										{storeStatusCount[appliedFilters["store_status"]?.value]}/{data?.storesCount}{" "}
										Locations{" "}
										{appliedFilters["store_status"]?.value !== "all"
											? appliedFilters["store_status"]?.valueForDisplay
											: "Mapped"}
									</div>
								)}
							</div>
						)}
						<CustomTable
							bordered={true}
							loading={loading}
							data={data?.objects || []}
							columns={getColumns(match?.params?.dsp, editMode, isMultibrandEnabled, selectedBrand)}
							classes="locations-table-container"
							content="locations"
							stores={stores}
							menus={menus}
							mappedLocations={mappedLocations}
						/>
						<Paginator
							limit={limit}
							offset={offset}
							count={data?.count || 0}
							goToPage={handlePagination}
							setPageSize={handlePageSize}
							showPageSize={true}
						/>
					</div>
				)}
				{editMode && (
					<React.Fragment>
						{selectedTab === 1 &&
							(isMultibrandEnabled ? (
								<BrandSelection
									dspIntegration={dspIntegration}
									updateDspIntegrationState={updateDspIntegrationState}
								/>
							) : (
								<ReviewLocationAssociation
									dsp={match?.params?.dsp}
									dspIntegration={dspIntegration}
									stores={stores}
									handleSearchFilter={handleSearchFilter}
									handleStatusFilter={handleStatusFilter}
									handlePagination={handlePagination}
									handlePageSize={handlePageSize}
									handleAssociationUpdates={handleAssociationUpdates}
									editMode={editMode}
									mappedLocations={mappedLocations}
									isMultibrandEnabled={isMultibrandEnabled}
									renderBrandSelection={renderBrandSelection}
								/>
							))}
						{selectedTab === 2 &&
							(isMultibrandEnabled ? (
								<ReviewLocationAssociation
									dsp={match?.params?.dsp}
									dspIntegration={dspIntegration}
									stores={stores}
									handleSearchFilter={handleSearchFilter}
									handleStatusFilter={handleStatusFilter}
									handlePagination={handlePagination}
									handlePageSize={handlePageSize}
									handleAssociationUpdates={handleAssociationUpdates}
									editMode={editMode}
									mappedLocations={mappedLocations}
									isMultibrandEnabled={isMultibrandEnabled}
									renderBrandSelection={renderBrandSelection}
								/>
							) : (
								<AssociateMenu
									dsp={match?.params?.dsp}
									dspIntegration={dspIntegration}
									menus={menus}
									handlePagination={handlePagination}
									handlePageSize={handlePageSize}
									handleAssociationUpdates={handleAssociationUpdates}
									updateDspIntegrationState={updateDspIntegrationState}
									editMode={editMode}
									isMultibrandEnabled={isMultibrandEnabled}
								/>
							))}
						{isMultibrandEnabled && selectedTab === 3 && (
							<AssociateMenu
								dsp={match?.params?.dsp}
								dspIntegration={dspIntegration}
								menus={menus}
								handlePagination={handlePagination}
								handlePageSize={handlePageSize}
								handleAssociationUpdates={handleAssociationUpdates}
								updateDspIntegrationState={updateDspIntegrationState}
								editMode={editMode}
								isMultibrandEnabled={isMultibrandEnabled}
							/>
						)}
					</React.Fragment>
				)}
			</FormContainer>
			<Modal
				isOpen={modalOpen}
				close={handleModalAction}
				title={`${editMode ? "Discard" : "Edit"} Location and Menu Associations`}
				showSubmitAction={true}
				submitTitle={editMode ? "Confirm" : "Continue"}
				submitAction={() => handleModalAction(true)}
				showCancelAction={true}
				cancelTitle="Cancel"
				showCloseIcon={false}
			>
				{editMode
					? "Location and Menu Associations will be discarded, are you sure you want to go back?"
					: "Are you sure you want to edit Location and Menu Associations?"}
			</Modal>
			<Modal
				isOpen={brandSelectionOpen}
				close={handleBrandSelectionModalAction}
				title="Alert"
				showSubmitAction={true}
				submitTitle="Yes"
				submitAction={() => handleBrandSelectionModalAction(true)}
				showCancelAction={true}
				cancelTitle="Cancel"
				showCloseIcon={false}
			>
				Switching the brand will loose your current progress and will reset the flow, do you want to continue?
			</Modal>
			<Modal
				isOpen={switchAccountOpen}
				close={() => handleSwitchAccount(false)}
				title="Switch Account"
				showSubmitAction={true}
				submitTitle="Continue"
				submitAction={() => handleSwitchAccount(true)}
				submitBtnLoading={isLoading}
				showCancelAction={true}
				cancelTitle="Cancel"
				showCloseIcon={false}
			>
				Are you sure you want to switch account? You will loose all location and menu associations for the
				current account.
			</Modal>
		</div>
	);
};
const mapStateToProps = (store) => ({
	dspIntegration: store.dspIntegration,
	stores: store.configItems.stores,
	menus: store.configItems.menus
});
export default connect(mapStateToProps)(LocationsAndMenu);

export const Filters = ({
	searchFieldValue,
	handleSearchFilter,
	statusFilter = {},
	handleStatusFilter,
	statusValue,
	showStoreStatus = true,
	showActivationStatus = false,
	renderBrandSelection = () => null,
	isMultibrandEnabled = false
}) => {
	return (
		<div className="filters-container">
			<div className="filters-left">
				{isMultibrandEnabled && renderBrandSelection({ readOnly: true })}
				{showStoreStatus && (
					<SelectFilter
						options={[{ valueForDisplay: "All", value: "all" }, ...(statusFilter?.values ?? [])]}
						currValue={statusValue}
						field={statusFilter?.field || ""}
						setFilter={handleStatusFilter}
						isClearable={false}
						customDropdownLabel={
							statusValue ? (
								<div className="custom-value">
									Status <span>{statusValue?.valueForDisplay}</span>
								</div>
							) : null
						}
						labelKey="valueForDisplay"
						valueKey="value"
						placeholder="Select Status"
					/>
				)}
				{showActivationStatus && (
					<SelectFilter
						options={[
							{ valueForDisplay: "All", value: "all" },
							{ valueForDisplay: "Failed", value: "failed" },
							{ valueForDisplay: "Success", value: "Success" }
						]}
						currValue={statusValue}
						field={statusFilter?.field || ""}
						// setFilter={handleStatusFilter}
						setFilter={() => {}}
						isClearable={false}
						customDropdownLabel={
							statusValue ? (
								<div className="custom-value">
									Status <span>{statusValue?.valueForDisplay}</span>
								</div>
							) : null
						}
						labelKey="valueForDisplay"
						valueKey="value"
						placeholder="Select Status"
					/>
				)}
			</div>
			<SearchFilter
				filterOption={{ field: "searchFieldValue" }}
				value={searchFieldValue}
				setFilter={handleSearchFilter}
				placeholder="Search"
			/>
		</div>
	);
};

export const getColumns = (dsp, editMode = false, isMultibrandEnabled = false, selectedBrand = null) => {
	const columns = [
		{
			name: (
				<div className="platform">
					<img
						className="logo"
						src={CATALOGUE_PLATFORMS_LOGO[dsp] || CATALOGUE_PLATFORMS_LOGO["default"]}
						alt=""
					/>
					<div className="name">{PLATFORM_MAP[dsp] || dsp} Store Locations</div>
				</div>
			),
			field: "dsp-stores",
			render: (record, i) => (
				<div className="table-cell dsp-stores" title={record?.locationName} key={i}>
					{record?.locationName || "--"}
				</div>
			)
		},
		{
			name: (
				<div className="platform">
					<img
						className="logo"
						src={CATALOGUE_PLATFORMS_LOGO["urbanpiper"] || CATALOGUE_PLATFORMS_LOGO["default"]}
						alt=""
					/>
					<div className="name">UrbanPiper Store Locations</div>
				</div>
			),
			field: "up-stores",
			render: (record, i, rest) => {
				const currValue = rest?.associationUpdates?.hasOwnProperty(record?.id)
					? rest?.associationUpdates?.[record?.id]?.location
					: record?.location;
				return (
					<div className="table-cell up-stores" key={i}>
						<SelectFilter
							options={[
								...(rest?.stores?.items
									?.map((loc) => ({
										...loc,
										...(loc?.brandLocation ?? {})
									}))
									?.sort((a, b) =>
										a?.id === currValue?.id
											? -1
											: !!rest.mappedLocations[a?.id] - !!rest.mappedLocations[b?.id]
									) ?? [])
							]}
							isLoading={rest?.stores?.isLoading}
							field="location"
							currValue={currValue || null}
							setFilter={(field, value) =>
								!value || !rest?.mappedLocations?.[value?.id]
									? rest.handleAssociationUpdates(record, field, value, currValue)
									: null
							}
							isAsync={true}
							isSearchable={true}
							isClearable={true}
							isNull={record?.location && rest?.associationUpdates?.[record?.id]?.location === null}
							renderCustomOption={(children, props) => (
								<div
									className={
										"custom-option" + (!!rest.mappedLocations[props?.data?.id] ? " disabled" : "")
									}
								>
									{children || ""}
								</div>
							)}
							handleSearch={(search) => fetchStoresDebounced(search, 50, selectedBrand?.id)}
							labelKey="name"
							valueKey="id"
							readOnly={!editMode}
							placeholder="Associate Location"
							validationMessage={
								record?.location && rest?.associationUpdates?.[record?.id]?.location === null
									? "Location association is required"
									: ""
							}
						/>
					</div>
				);
			}
		},
		{
			name: <div className="name">Associated Menu</div>,
			field: "menu",
			render: (record, i, rest) => (
				<div className="table-cell menu" key={i}>
					<SelectFilter
						options={rest?.menus?.items || []}
						isLoading={rest?.menus?.isLoading}
						field="associatedMenu"
						currValue={
							rest?.associationUpdates?.[record?.id]?.associatedMenu
								? rest?.associationUpdates?.[record?.id]?.associatedMenu
								: record?.associatedMenu
						}
						setFilter={(field, value) => rest.handleAssociationUpdates(record, field, value)}
						isAsync={true}
						isSearchable={true}
						isClearable={false}
						handleSearch={(search) => fetchMenusDebounced(search, selectedBrand?.id)}
						labelKey="name"
						valueKey="menuId"
						readOnly={!editMode}
						placeholder="Associate Menu"
					/>
				</div>
			)
		},
		{
			name: <div className="name">Store Status</div>,
			field: "status",
			render: (record, i) => (
				<div className="table-cell status" key={i}>
					<div className={"tag " + (record?.storeStatus?.toLowerCase() || "unmapped")}>
						{record?.storeStatus || "Unmapped"}
					</div>
				</div>
			)
		}
	];
	return columns;
};
