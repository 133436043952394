import gql from "graphql-tag";

const LOGIN_USER = gql`
	mutation loginUser($username: String!, $password: String!, $recaptchaToken: String!) {
		login(input: { username: $username, password: $password, token: $recaptchaToken }) {
			token
			cannySsoToken
			status {
				success
				messages {
					field
					message
				}
			}
			userInfo {
				id
				username
				firstName
				lastName
				fullName
				email
				phone
				isdCode
				uleEmail
				ulePhone
				uleIsdCode
				access {
					isCatalogueManagement
					isHubManagement
					isAdmin
					isSalesAnalysis
					isOperationsAnalysis
					isSupport
					isBilling
					isMarketing
					isNonHqReport
					isNonHqAnalytics
					isClosedBetaAccess
					analyticsAccess
					tempAnalyticsAccess
					primeAccess {
						id
						name
						posEnabled
					}
				}
				associatedLocations {
					id
					name
					brandName
					isAssociated
				}
				verificationDetails {
					email
					phone
					emailValidated
					phoneValidated
					uuid
					passwordSet
				}
			}
			biz {
				__typename
				currency
				currencySymbol
				billingCurrency
				billingCurrencySymbol
				timezone
				id
				name
				modulesEnabled
				feedbackChoiceTexts
				credits
				logo
				address
				gstin
				billingPhones
				billingEmails
				contactEmails
				created
				contactPersonName
				registeredBusinessName
				tin
				bizWebsite
				bizIsdCode
				fssaiLicenseNumber
				isActive
				isTest
				addressLine1
				addressLine2
				city
				state
				country
				postalCode
				isEnforcedOla
				orderPrepTime
				busyOrderPrepTime
				eisEnabled
				eisSupportEnabled
				description
				seoDescription
				supportedLanguages {
					valueForDisplay
					value
				}
				meraki {
					apiKey
					username
				}
				differentialPricingEnabled
				isReconEnabled
				isMultibrandEnabled
				isPgServiceEnabled
				isMenuOverCatalogueEnabled
				scheduledActionsAndNotifications {
					actions {
						action
						message
						applicable
					}
					notifications {
						action
						ctaPrimaryLink
						ctaPrimaryText
						ctaSecondaryLink
						ctaSecondaryText
						dismissible
						message
						type
						applicable
					}
				}
			}
			creditsCost {
				country
				amountPerCredits
			}
			creditsMapping {
				objects {
					typeName
					typesCreditsRatio
					country
				}
			}
			allCreditsCost {
				amountPerCredits
				tier {
					lowerBound
					upperBound
				}
			}
		}
	}
`;

const PASS_RESET_TKN = gql`
	mutation sendPasswordResetToken($username: String!) {
		sendPasswordResetToken(username: $username) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

const CHANGE_PASS = gql`
	mutation changePassword($username: String!, $token: String!, $newPassword: String!) {
		changePassword(username: $username, token: $token, newPassword: $newPassword) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
export { LOGIN_USER, PASS_RESET_TKN, CHANGE_PASS };
