import React from "react";

// components
import Onboardingnextup, { NextUpStates } from "@urbanpiper-engineering/horizon/dist/cells/onboardingnextup";
import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/atoms/text";
import StatusCard, { StatusCardState } from "@urbanpiper-engineering/horizon/dist/cells/statuscard";
import Button, { ButtonVariant, ButtonSize } from "@urbanpiper-engineering/horizon/dist/atoms/button";

import history from "../history";

const ALL_STEPS_LIST = [
	{
		state: StatusCardState.Checked,
		title: "Complete company details",
		subTitle: "Add your restaurant's basic information",
		onCtaClick: () => {},
		infoComponent: null
	},
	{
		state: StatusCardState.Pending,
		title: "Create brands",
		subTitle: "Add all your restaurant's brands",
		onCtaClick: () => {
			history.push("/onboarding/brands");
		},
		infoComponent: null
	},
	{
		state: StatusCardState.UnChecked,
		title: "Configure locations",
		subTitle: "Add the locations where your restaurant operates",
		onCtaClick: () => {},
		infoComponent: null
	},
	{
		state: StatusCardState.UnChecked,
		title: "Configure menus",
		subTitle: "Create menus for your locations",
		onCtaClick: () => {},
		infoComponent: null
	},
	{
		state: StatusCardState.UnChecked,
		title: "Go live on all locations",
		subTitle: "Go live on delivery platforms with all your locations",
		onCtaClick: () => {},
		infoComponent: null
	},
	{
		state: StatusCardState.UnChecked,
		title: "Invite users",
		subTitle: "Invite staff to your UrbanPiper account",
		onCtaClick: () => {},
		infoComponent: null
	}
];

const ONBOARDING_HELPER_LIST = [
	{
		title: "How does DSP sync and POS services work?",
		ctaText: "Watch video",
		ctaUrl: "/"
	},
	{
		title: "How to set up locations and menus manually?",
		ctaText: "Watch video",
		ctaUrl: "/"
	},
	{
		title: "View the list of all our Frequently Asked Questions",
		ctaText: "View FAQs",
		ctaUrl: "/piper-academy"
	}
];

function Onboarding() {
	const [allStepsList, setAllStepsList] = React.useState(ALL_STEPS_LIST);
	const completedSteps = allStepsList.filter((step) => step.state === StatusCardState.Checked).length;
	const totalSteps = allStepsList.length;

	return (
		<div className="onboarding-container section-container-common">
			<div className="onboarding-header"></div>
			<div className="onboarding-step">
				<Text className="onboarding-step-heading" variant={TextVariant.HEADING4}>
					Complete setup to get started with UrbanPiper
				</Text>
				<Onboardingnextup
					className="onboarding-nextup-card"
					currentState={NextUpStates.IMPORT_FROM_INTEGRATIONS}
					onCtaClick={() => {}}
				/>
			</div>
			<div className="onboarding-all-steps">
				<Text className="onboarding-all-steps-heading" variant={TextVariant.HEADING4}>
					{`${completedSteps} of ${totalSteps} steps completed`}
				</Text>
				<div className="onboarding-all-steps-list">
					{allStepsList.map((step) => {
						return (
							<StatusCard
								key={step.title}
								className="onboarding-status-card"
								state={step.state}
								title={step.title}
								subTitle={step.subTitle}
								onCtaClick={() => step.onCtaClick()}
								infoComponent={step.infoComponent}
							/>
						);
					})}
				</div>
			</div>

			<div className="onboarding-helper">
				<Text className="onboarding-helper-heading" variant={TextVariant.HEADING4}>
					Don’t know where to start?
				</Text>

				<div className="onboarding-helper-list">
					{ONBOARDING_HELPER_LIST.map((helper) => {
						return (
							<div key={helper.title} className="onboarding-helper-card">
								<img
									className="onboarding-helper-card-icon"
									src="/assets/icons/icon-question-two.svg"
								/>
								<Text className="onboarding-helper-card-title" variant={TextVariant.HEADING5}>
									{helper.title}
								</Text>
								<Button
									onClick={() => history.push(helper.ctaUrl)}
									className="onboarding-helper-card-cta"
									variant={ButtonVariant.Link}
									size={ButtonSize.MD}
									rightIcon={
										<img
											className="onboarding-helper-card-cta-icon"
											src="/assets/icons/icon-right-two.svg"
										/>
									}
								>
									{helper.ctaText}
								</Button>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}

export default Onboarding;
