import React, { useEffect, useState } from "react";

// third party
import { connect } from "react-redux";
import moment from "moment";

// components
import FormTable from "../_commons/FormTable";
import ContextMenu from "../_commons/ContextMenu";
import Popover from "../_commons/Popover";
import ListShimmer from "../_commons/ListShimmer";
import { SearchFilter } from "../_commons/SearchFilter";
import { SelectFilter } from "../_commons/SelectFilter";

// utils
import { formatDate, trackEvent } from "../../atlas-utils";

// actions
import { fetchMenuVersions, handleDestructiveSave } from "../../actions/menus";

// constant
import { CATALOGUE_PLATFORMS_LOGO, TRACKING_EVENT_NAMES } from "../../client-config";
import { store } from "../../store/configureStore";
import { ActionTypes } from "../../actions/_types";

// services
import NotificationServices from "../../services/NotificationService";

// client
import { clientMenu } from "../../client-menu";

// graphql
import { GET_VERSION_DETAILS } from "../../graphql/menus";

const PUBLISH_STATUS_CLASSNAMES_MAPPING = {
	"validation failed": "failed",
	draft: "draft",
	"publish scheduled": "intermediate-state",
	"publish in progress": "intermediate-state",
	"partially published": "intermediate-state",
	published: "success",
	"publish failed": "failed",
	"partially failed": "failed"
};

const PUBLISH_HISTORY_TABLE_COLUMNS = [
	{
		title: "version",
		dataIndex: "version",
		render: (data, renderValuesAndFunctions, record) => (
			<span
				className="hyperlink hyperlink--black-color"
				onClick={() => renderValuesAndFunctions.handleVersionSelection(data, record)}
			>
				Version {data}
			</span>
		)
	},
	{
		title: "Assoc. items",
		dataIndex: "associatedItemCount",
		render: (data) => data
	},
	{
		title: "Locations",
		dataIndex: "associatedLocations",
		render: (data, renderValuesAndFunctions) => (
			<div className="locations-list">
				<Popover
					showOnHover
					renderPopover={renderValuesAndFunctions.renderPopover}
					data={[...data]}
					position="top-right"
				>
					<img src="/assets/icons/icon-eye.svg" alt="see locations" />
				</Popover>
				{(data ?? []).length}
			</div>
		)
	},
	{
		title: "platforms",
		dataIndex: "associatedPlatforms",
		render: (data) => (
			<div className="platform-logos">
				{(data ?? []).map((plf, i) => (
					<img key={i} className="platform-logo" src={CATALOGUE_PLATFORMS_LOGO[plf.toLowerCase()]} />
				))}
			</div>
		)
	},
	{
		title: "last published on",
		dataIndex: "lastPublishedOn",
		render: (data) => (
			<div className="last-publish-data-time">
				<div className="date">{formatDate(data, "DD MMMM YYYY")}</div>
				<div className="time">{formatDate(data, "hh : mm A")}</div>
			</div>
		)
	},
	{
		title: "",
		dataIndex: "contextMenu",
		render: (contextMenu, renderValuesAndFunctions) => (
			<ContextMenu
				isOpen={renderValuesAndFunctions.contextMenuId === contextMenu}
				handleOutsideClick={
					contextMenu === renderValuesAndFunctions.contextMenuId
						? renderValuesAndFunctions.handleContextMenuOutsideClick
						: () => {}
				}
				renderMenuItems={renderValuesAndFunctions.renderMenuItems}
				handleOpenMenu={() => renderValuesAndFunctions.handleOpenContextMenu(contextMenu)}
				data={contextMenu}
			/>
		)
	}
];

const PublishHistory = ({
	menuId,
	publishHistoryListsData,
	renderRestoreVersionModal,
	showHideMenuPublishDrawer,
	metadata
}) => {
	const [contextMenuId, setContextMenuId] = useState(undefined);
	const [offset, setOffset] = useState(0);
	const { publishHistoryData, isLoading, selectedPublishedVersion } = publishHistoryListsData;

	const renderMenuItems = (record) => {
		return (
			<React.Fragment>
				<div className="action-item" onClick={() => renderRestoreVersionModal(true, record)}>
					Restore Menu Configuration
				</div>
				{/* <div className="action-item" onClick={() => {}}>
					Copy to New Menu
				</div> */}
			</React.Fragment>
		);
	};

	const renderPopover = (data) => data.map((locName) => <div className="loc-name">{locName}</div>);

	const handleOpenContextMenu = (id) => {
		if (!contextMenuId || contextMenuId !== id) {
			setContextMenuId(id);
			return;
		}
		setContextMenuId(undefined);
	};

	const handleContextMenuOutsideClick = () => {
		setContextMenuId(undefined);
	};

	const handleVersionSelection = (version, record) => {
		store.dispatch({
			type: ActionTypes.UPDATE_PUBLISHED_VERSIONS_STATE,
			payload: {
				selectedPublishedVersion: version,
				selectedPublishedVersionDetails: {
					...record,
					associatedLocations: record.associatedLocations.map((loc) => loc.toLowerCase()),
					associatedPlatforms: record.associatedPlatforms.map((plf) => plf.toLowerCase())
				}
			}
		});
	};

	const renderValuesAndFunctions = {
		handleOpenContextMenu,
		renderMenuItems,
		handleContextMenuOutsideClick,
		contextMenuId,
		renderPopover,
		handleVersionSelection
	};

	useEffect(() => {
		fetchMenuVersions(menuId, offset);
	}, []);

	if (isLoading && selectedPublishedVersion) {
		return (
			<div className="publish-history-details-container">
				<ListShimmer />
			</div>
		);
	}

	if (selectedPublishedVersion >= 0 && selectedPublishedVersion !== null) {
		return <PublishedVersionDetails version={selectedPublishedVersion} menuId={menuId} />;
	}

	return (
		<>
			<div className="publish-history-table-container">
				<FormTable
					columns={PUBLISH_HISTORY_TABLE_COLUMNS}
					dataSource={[
						...(publishHistoryData?.objects ?? []).map((data) => ({ ...data, contextMenu: data?.version }))
					]}
					renderValuesAndFunctions={renderValuesAndFunctions}
					isLoading={!(publishHistoryData?.objects ?? []).length && isLoading}
					contentTableClass={isLoading ? "disabled" : ""}
					placeholderContent={{
						placeholderImageUrl: "/assets/empty_states/graphics-empty-dashboard.svg",
						placeholderText: "Menu not published yet!",
						placeholderSubtext:
							"This menu has not been associated with any location. Publish it on your selected locations and platforms for customers to view",
						placeholderButtonContent: "Publish Menu",
						size: "medium",
						placeholderButtonClickAction: () => {
							if (metadata?.pendingChangesCount > 0) {
								handleDestructiveSave(menuId, "SAVE");
								trackEvent(TRACKING_EVENT_NAMES.MENU_CHANGES_SAVED, {
									type: "publish_triggered",
									no_of_changes: metadata?.pendingChangesCount
								});
							}
							showHideMenuPublishDrawer(true);
						}
					}}
				/>
			</div>
		</>
	);
};
const mapStateToProps = (store) => ({
	publishHistoryListsData: store.publishHistoryListsData
});
export default connect(mapStateToProps)(PublishHistory);

const PublishedVersionDetails = ({ version, menuId }) => {
	const [publishedVersionDetails, setPublishedVersionDetails] = useState([]);
	const [selectedPublishIndex, setSelectedPublishIndex] = useState(0);
	const [isVersionDetailsLoading, setVersionDetailsLoading] = useState(true);
	const fetchVersionDetails = async () => {
		setVersionDetailsLoading(true);
		try {
			const versionDetailsResp = await clientMenu.query({
				query: GET_VERSION_DETAILS,
				variables: {
					menuId,
					version
				},
				fetchPolicy: "no-cache"
			});

			if (versionDetailsResp?.data?.versionPublishHistoryV2) {
				setPublishedVersionDetails(versionDetailsResp?.data?.versionPublishHistoryV2);
			} else {
				NotificationServices.pushNotification({
					message: "Failed to fetch published version details",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
			}
		} catch (e) {
			console.log(e);
			NotificationServices.pushNotification({
				message: "Failed to fetch published version details",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
		}
		setVersionDetailsLoading(false);
	};

	const handleIndexSelection = (index) => {
		setSelectedPublishIndex(index);
	};

	useEffect(() => {
		fetchVersionDetails();
	}, [version]);

	if (isVersionDetailsLoading) {
		return (
			<div className="publish-history-details-container">
				<ListShimmer />
			</div>
		);
	}

	return (
		<div className="publish-history-details-container">
			<DateTimeHistoryList
				publishedVersionDetails={publishedVersionDetails}
				handleIndexSelection={handleIndexSelection}
				selectedPublishIndex={selectedPublishIndex}
			/>
			<SelectedTimeStampLocationsList
				publishedVersionDetails={publishedVersionDetails}
				selectedPublishIndex={selectedPublishIndex}
			/>
		</div>
	);
};

const DateTimeHistoryList = ({ publishedVersionDetails = [], handleIndexSelection, selectedPublishIndex }) => {
	return (
		<div className="date-time-history-list">
			{publishedVersionDetails.map((val, i) => (
				<div
					key={i}
					className={`timestamp-author-container ${
						i === selectedPublishIndex ? "timestamp-author-container--selected" : ""
					}`}
					onClick={() => handleIndexSelection(i)}
				>
					<div className="timestamp-container">
						{formatDate(moment(val?.publishedAt), "DD MMM, YYYY hh:mm A")}
					</div>
					<div className="author-name-container" title={val?.publishedBy}>
						by {val?.publishedBy}
					</div>
				</div>
			))}
		</div>
	);
};

const DEFAULT_LOCATIONS_INFO_COLUMNS = [
	{
		title: "location name",
		dataIndex: "locationName",
		render: (data) => data
	},
	{
		title: "city",
		dataIndex: "city",
		render: (data) => data
	}
];

const SelectedTimeStampLocationsList = ({ publishedVersionDetails, selectedPublishIndex }) => {
	const [searchKey, setSearchKey] = useState("");
	const [locationsInfoColumns, setLocationsInfoColumns] = useState(DEFAULT_LOCATIONS_INFO_COLUMNS);
	const [dataSource, setDataSource] = useState([]);
	const [cityOptions, setCityOptions] = useState([]);
	const [selectedCity, setSelectedCity] = useState(null);

	const handleSearch = (_, value) => {
		setSearchKey(value);
	};

	const renderPopover = (publishStatus) => <div className="platform-publish-status">{publishStatus}</div>;

	const setCityFilter = (_, value) => {
		setSelectedCity(value);
	};

	const selectedTimestampContent = publishedVersionDetails?.[selectedPublishIndex] ?? {};

	const renderValuesAndFunctions = {
		renderPopover
	};

	useEffect(() => {
		const selectedTimestampContent = publishedVersionDetails?.[selectedPublishIndex] ?? {};
		const _dataSource = (selectedTimestampContent?.locationsInfo ?? [])
			?.filter((loc) => loc.locationName.toLowerCase().includes(searchKey.toLowerCase()))
			.map((loc) => ({ ...loc, platforms: [...loc?.platformsInfo] }));
		setDataSource(_dataSource);
	}, [searchKey, publishedVersionDetails, selectedPublishIndex]);

	useEffect(() => {
		let _locationsInfoColumns = [...DEFAULT_LOCATIONS_INFO_COLUMNS];
		if (dataSource?.length) {
			dataSource.forEach((loc) => {
				if (loc?.platforms?.length) {
					loc.platforms.forEach((plf) => {
						if (_locationsInfoColumns.find((col) => col?.title === plf?.platformName)) return; // avoid duplicate columns

						_locationsInfoColumns.push({
							title: plf?.platformName,
							dataIndex: "publishStatus",
							render: () => {
								const status =
									PUBLISH_STATUS_CLASSNAMES_MAPPING[plf?.publishStatus?.toLowerCase()] || "--";
								const icon =
									status === "success" ? (
										<img className="publish-success-icon" src="/assets/icons/icon-check-two.svg" />
									) : null;
								return icon || <span className={`pub-stat-span ${status}`}>{status}</span>;
							}
						});
					});
				}
			});
		}
		setLocationsInfoColumns(_locationsInfoColumns);

		const _cityOptions = dataSource?.reduce((acc, loc) => {
			if (loc?.city) {
				acc.push({
					valueKey: loc?.locationId,
					valueForDisplay: loc?.city
				});
			}
			return acc;
		}, []);
		setCityOptions(_cityOptions);
	}, [dataSource]);

	const publishStatus =
		PUBLISH_STATUS_CLASSNAMES_MAPPING[selectedTimestampContent?.publishStatus?.toLowerCase()] || "--";
	const publishedAt = formatDate(moment(selectedTimestampContent?.publishedAt), "DD MMMM YYYY, hh:mm A");
	const numberOfPlatforms =
		selectedTimestampContent?.locationsInfo?.reduce((acc, loc) => {
			if (loc.platformsInfo?.length) {
				loc.platformsInfo.forEach((plf) => {
					if (acc.includes(plf.platformName)) return; // avoid duplicate platformNames
					acc.push(plf.platformName);
				});
			}
			return acc;
		}, []).length || 0;
	const numberOfLocations = selectedTimestampContent?.locationsInfo?.length || 0;

	return (
		<div className="selected-timestamp-locations-list">
			<div className="metadata-filters-container">
				<div className="metadata-publish-status">
					<span className={`pub-stat-span ${publishStatus}`}>{publishStatus}</span>
				</div>
				<div className="metadata-container">
					<div className="timestamp">{publishedAt}</div>
					<div className="platform-count">{numberOfPlatforms} platforms,</div>
					<div className="loc-count">{numberOfLocations} locations</div>
				</div>
				<div className="metadata-filters-row">
					<SelectFilter
						classes="metadata-filter-city"
						options={cityOptions}
						field="location_id"
						currValue={selectedCity}
						setFilter={setCityFilter}
						labelKey="valueForDisplay"
						valueKey="value"
						placeholder="City"
					/>
					<SearchFilter
						placeholder="Search"
						value={searchKey}
						filterOption={{
							field: "location"
						}}
						setFilter={handleSearch}
					/>
				</div>
			</div>
			<div className="form-table-wrapper">
				<FormTable
					columns={locationsInfoColumns}
					dataSource={dataSource?.filter((loc) =>
						selectedCity ? loc?.locationId === selectedCity?.valueKey : true
					)}
					renderValuesAndFunctions={renderValuesAndFunctions}
				/>
			</div>
		</div>
	);
};
